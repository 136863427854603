import React, { Fragment } from 'react';
import './Bingo.css';
import '../../App.css';
import { GetImgUrl, LOG_ENABLE, gotoFirstPage, gotoTalkPage, handleAppNavi, mobileOn, withNavigation } from '../../URL/AppUrl';
import {  GetDataDateAndTime, GetFormattedCNum2, getShortedPathName, IsValidS, IsValidV, } from '../../Util/Util';
import Loading from '../Loading/Loading';
import FooterBar from '../Footer/Footer';
import { BingoStoreInstance } from '../../Stores/BingoStore';


//빙고 상세등록 1단계
class BingoStep1 extends React.Component
{
  state = {
    loading: false,

    productType: -1, //상품분류,식품(0) / 생활(1) / 전자제품(2) / 뷰티(3) / 의류(4) / 여행·취미(5) / 스포츠(6) / 출산·육아(7) /인테리어(8) / 도서(9) / 패션잡화(10) / 기타(11)
    left: -1, //왼쪽 버튼기능
    right: -1, //오른쪽 버튼 기능
    leftLink: '',
    rightLink: '',
  };

  changed = false; //내용변경이 있는가?

  setLoading = (v) =>
  {
    this.setState((prevState) => ({...prevState, loading : v}));
  }

  //상품분류 설정
  setProductType = (v) =>
  {
    if (v !== this.state.productType)
      this.changed = true;

    this.setState((prevState) => ({...prevState, productType: v}));
  }

  setLeftBtn = (v) =>
  {
    if (v !== this.state.left)
      this.changed = true;

    this.setState((prevState) => ({...prevState, left: v}));
  }

  setRightBtn = (v) =>
  {
    if (v !== this.state.right)
      this.changed = true;

    this.setState((prevState) => ({...prevState, right: v}));
  }

  setLeftLink = (v) =>
  {
    if (v !== this.state.leftLink)
      this.changed = true;

    this.setState((prevState) => ({...prevState, leftLink: v}));
  }

  setRightLink = (v) =>
  {
    if (v !== this.state.rightLink)
      this.changed = true;

    this.setState((prevState) => ({...prevState, rightLink: v}));
  }

  componentDidMount() {
    if (LOG_ENABLE)
      console.log(`BingoStep1.componentDidMount`);

    if (IsValidV(this.props.step1))
    {
      this.changed = !this.props.step1.saved;

      const oldChanged = this.changed;

      this.setProductType(this.props.step1.pdType);
      this.setLeftBtn(this.props.step1.left);
      this.setRightBtn(this.props.step1.right);

      this.setLeftLink(IsValidS(this.props.step1.leftLink) && this.props.step1.leftLink !== "n" ? this.props.step1.leftLink : '');
      this.setRightLink(IsValidS(this.props.step1.rightLink) && this.props.step1.rightLink !== "n" ? this.props.step1.rightLink : '');

      this.changed = oldChanged;
    }
  }

  componentWillUnmount = () =>
  {
    if (LOG_ENABLE)
      console.log(`BingoStep1.componentWillUnmount`);
  }

  saveData = null;

  getValidLink = (v) =>
  {
    return IsValidS(v) ? v.trim() : "n";
  }

  getValidLink2 = (v) =>
  {
    return IsValidS(v) ? v.trim() : v;
  }

  //다음 버튼 눌림
  handleNext = (forSave) =>
  {
    if (this.state.loading)
      return;

    const checkValid = false;

    if (checkValid)
    {
      if (this.state.productType < 0 || this.state.productType > 11)
      {
        alert(`상품 분류를 선택해 주세요.`);
        return;
      }
  
      if (this.state.left < 0 || this.state.left > 7)
      {
        alert(`왼쪽 버튼 문구를 선택해 주세요.`);
        return;
      }
  
      if (!IsValidS(this.state.leftLink))
      {
        alert(`왼쪽 버튼 링크 주소를 입력해 주세요.`);
        return;
      }
  
      if (this.state.right < 10 || this.state.right > 22)
      {
        alert(`오른쪽 버튼 문구를 선택해 주세요.`);
        return;
      }
  
      if (!IsValidS(this.state.rightLink))
      {
        alert(`오른쪽 버튼 링크 주소를 입력해 주세요.`);
        return;
      }
    }

    if (forSave)
    {//변경사항을 광고에 저장한다.

      if (!this.changed)
      {
        alert(`수정된 항목이 없습니다.`);
        return;
      }

      this.saveData = {
        pt: this.state.productType,
        lbt: this.state.left,
        rbt: this.state.right,
        llk: this.getValidLink(this.state.leftLink),
        rlk: this.getValidLink(this.state.rightLink),
      };

      if (this.saveData.pt < 0 || this.saveData.pt > 11)
        this.saveData.pt = 0;

      if (this.saveData.lbt < 0 || this.saveData.lbt > 7)
        this.saveData.lbt = 0;

      if (this.saveData.rbt < 10 || this.saveData.rbt > 22)
        this.saveData.rbt = 10;

      if (!IsValidS(this.saveData.llk))
        this.saveData.llk = "n";

      if (!IsValidS(this.saveData.rlk))
        this.saveData.rlk = "n";

      this.setLoading(true);
      BingoStoreInstance.editStep1(this.props.oidx, this.saveData.pt, this.saveData.lbt, this.saveData.rbt, this.saveData.llk, this.saveData.rlk, this.onSaveResult);
    }
    else
    {
      this.props.handleNextStep(3, {saved: !this.changed, pdt: this.state.productType, lbt: this.state.left, rbt: this.state.right, llk: this.getValidLink2(this.state.leftLink), rlk: this.getValidLink2(this.state.rightLink)});
    }
  }

  onSaveResult = (resultCode) =>
  {
    this.setLoading(false);

    switch(resultCode)
    {
      case 0:
        this.changed = false;
        alert(`변경사항이 저장되었습니다.`);
        break;
      case 1:
        alert('잘못된 요청입니다.');
        return;
      case 3:
        alert('대상 주문을 찾을 수 없습니다.');
        return;
      default:
        alert(`임시 저장에 실패 했습니다.\n오류코드 ${resultCode}`);
        return;
    }

    /*if (this.props.handleSaved)
      this.props.handleSaved(this.props.oidx, this.saveData);*/
  }

  render()
  {
    return (
      <div className='bingocont colel' style={{width: this.props.contWidth, height: this.props.height}}>
        {
          this.state.loading ? (<Loading/>) : null
        }
        <div className='bingocont2 coltl' style={{width: this.props.contWidth - 40 - 50, height: this.props.height - 100}}>
          <label className='font20 wt600 color31 mgtop20'>빙고팡팡 광고</label>

          <div className='wd100p rowlt mgtop20 mgbt50'>
            <div className='bingoodleft'>
              <div className='bingoodbox1 colcc'>
                <div className='bingoodin coltl'>
                  <label className='font20 wt500 color31'>광고할 상품의 정보를 작성합니다</label>

                  <label className='font15 wt500 color31 mgtop10'>상품 분류</label>

                  <select className='bingoodinp font16 wt400 color31 btncur mgtop5' value={this.state.productType.toString()} onChange={e => this.setProductType(parseInt(e.target.value))}>
                    <option value="-1">상품 분류를 선택해 주세요.</option>
                    <option value="0">식품</option>
                    <option value="1">생활</option>
                    <option value="2">전자제품</option>
                    <option value="3">뷰티</option>
                    <option value="4">의류</option>
                    <option value="5">여행·취미</option>
                    <option value="6">스포츠</option>
                    <option value="7">출산·육아</option>
                    <option value="8">인테리어</option>
                    <option value="9">도서</option>
                    <option value="10">패션잡화</option>
                    <option value="12">게임</option>
                    <option value="13">어플리케이션</option>
                    <option value="14">서비스</option>
                    <option value="11">기타</option>
                  </select>
                </div>
              </div>

              <div className='bingoodbox1 colcc mgtop10'>
                <div className='bingoodin coltl'>
                  <label className='font15 wt500 color31'>왼쪽 버튼</label>

                  <label className='font15 wt500 color31 mgtop5'>URL</label>
                  <input type='text' value={this.state.leftLink} onChange={e => this.setLeftLink(e.target.value)} className='bingoodinp font16 wt400 color31 mgtop5' placeholder='왼쪽 버튼 클릭 시 연결할 URL을 입력해 주세요' maxLength={280}/>

                  <label className='font15 wt500 color31 mgtop20'>버튼 문구</label>
                  <select className='bingoodinp font16 wt400 color31 btncur mgtop5' value={this.state.left.toString()} onChange={e => this.setLeftBtn(parseInt(e.target.value))}>
                    <option value="-1">왼쪽 버튼 문구를 선택해 주세요</option>
                    <option value="1">영상시청</option>
                    <option value="2">구경하기</option>
                    <option value="3">체험하기</option>
                    <option value="4">혜택확인</option>
                    <option value="5">영상보기</option>
                    <option value="6">상세보기</option>
                  </select>
                </div>
              </div>

              <div className='bingoodbox1 colcc mgtop10'>
                <div className='bingoodin coltl'>
                  <label className='font15 wt500 color31'>오른쪽 버튼</label>

                  <label className='font15 wt500 color31 mgtop5'>URL</label>
                  <input type='text' value={this.state.rightLink} onChange={e => this.setRightLink(e.target.value)} className='bingoodinp font16 wt400 color31 mgtop5' placeholder='왼쪽 버튼 클릭 시 연결할 URL을 입력해 주세요' maxLength={280}/>

                  <label className='font15 wt500 color31 mgtop20'>버튼 문구</label>
                  <select className='bingoodinp font16 wt400 color31 btncur mgtop5' value={this.state.right.toString()} onChange={e => this.setRightBtn(parseInt(e.target.value))}>
                    <option value="-1">오른쪽 버튼 문구를 선택해 주세요</option>
                    <option value="10">지금구매</option>
                    <option value="11">구매하기</option>
                    <option value="13">신청하기</option>
                    <option value="14">문의하기</option>
                    <option value="15">앱 다운</option>
                    <option value="16">다운로드</option>
                    <option value="17">더 알아보기</option>
                    <option value="18">카톡추가</option>
                    <option value="19">구독하기</option>
                    <option value="20">쇼핑하기</option>
                    <option value="21">혜택받기</option>
                    <option value="22">가입하기</option>
                  </select>

                  <div className='wd100p rowsc mgtop50'>
                    <button className='bingobtn6 font16 wt600 colorw btncur' onClick={() => this.handleNext(true)}>임시저장</button>
                    <button className='bingobtn7 font16 wt600 colorw btncur' onClick={() => this.handleNext(false)}>다음</button>
                  </div>
                </div>
              </div>
              
            </div>

            <div className='bingoodright colcc mglt30'>
              <div className='bingoodin coltl'>
                <label className='font20 wt600 color31'>빙고팡팡 버튼 문구 안내</label>

                <label className='font15 wt400 color31 mgtop10'>빙고팡팡 버튼 및 문구 안내<br/>빙고팡팡 버튼은 머니팡 유저가 왼쪽, 오른쪽 2개의 버튼을 클릭하고 각각 일정 수준의 리워드를 지급받을 수 있어 유저들의 버튼 클릭을 유도하고 있습니다.<br/>왼쪽, 오른쪽 버튼에는 광고 내용과 관련된 URL을 넣고 해당 링크에 접속하는 버튼으로 활용되기에, 빙고팡팡을 통해 더욱 높은 광고 효과를 달성하는 데 기여할 수 있습니다.</label>

                <label className='font15 wt400 color31 mgtop10 lht23'><span className='wt600'>왼쪽 버튼은</span><br/>머니팡 유저들에게 알리고 싶은 광고 주체의 상세한 내용을 전달하는 버튼입니다. 빙고 팡팡 게임 내 배너에 알리고 있는 정보의 상세 내용이 담긴 링크를 넣어주세요.<br/><span className='wt600'>오른쪽 버튼은</span><br/>고 전환을 만들어내기 위한 버튼입니다.클릭을 통하여 구매 가능 링크, 데이터베이스 입력, 앱 다운로드 링크 등 광고주의 매출 증대에 활용 가능한 링크를 넣어주세요.</label>

                <div className='wd100p rowcc'>
                  <img src={GetImgUrl("bingo/btndesc.png")} alt="버튼" className='bgst1descimg'/>
                </div>
              </div>
            </div>
            
          </div>
          
        </div>
        <FooterBar handleMenu={this.handleMenu}/>
      </div>
    );
  }
}

export default BingoStep1;