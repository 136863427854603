import './OrderList.css';
import '../../App.css';
import { GetImgUrl, mobileOn } from '../../URL/AppUrl';

const OrderListSelBgItem = (props) =>
{
  return (
    <div className='olmyselbgitem flexcolcc btncur' onClick={() => props.handleSelect(props.adsType)}>
      <div className='fullwidth flexrowsc'>

        <div className='flexrowcc'>
          <img src={GetImgUrl(props.img)} alt="광고" className='olselbgmgl'/>
          <label className='font14b_2 btncur'>&nbsp;{props.caption}</label>
        </div>

      </div>
    </div>
  );
}

const OrderListSel = (props) =>
{
  /*if (mobileOn)
  {
    return (
      <div className='olmyselbg_mb flexcoltc'>
        <div className='olmyselbg2 flexrowsc' onClick={(e) => {props.handleAdsSel(-1); e.preventDefault()}}>
          <label className='olselbgmgl font14b_2 btncur'>전체 광고</label>
  
          <img src={GetImgUrl("com/up.png")} alt="확장" className='olselbgmgr'/>
        </div>
  
        <div className='olmyselbgline'></div>
        <OrderListSelBgItem caption="인스타그램 좋아요 늘리기" handleSelect={props.handleAdsSel} adsType={0} img="list/instas.png"/>
        <div className='olmyselbgline'></div>
        <OrderListSelBgItem caption="인스타그램 팔로우 늘리기" handleSelect={props.handleAdsSel} adsType={1} img="list/instas.png"/>
        <div className='olmyselbgline'></div>
        <OrderListSelBgItem caption="인스타그램 댓글 늘리기" handleSelect={props.handleAdsSel} adsType={2} img="list/instas.png"/>
        <div className='olmyselbgline'></div>
        
        <OrderListSelBgItem caption="페이스북 좋아요 늘리기" handleSelect={props.handleAdsSel} adsType={10} img="list/facebooks.png"/>
        <div className='olmyselbgline'></div>
        <OrderListSelBgItem caption="페이스북 프로필 팔로우 늘리기" handleSelect={props.handleAdsSel} adsType={11} img="list/facebooks.png"/>
        <div className='olmyselbgline'></div>
        <OrderListSelBgItem caption="페이스북 게시물 댓글 늘리기" handleSelect={props.handleAdsSel} adsType={12} img="list/facebooks.png"/>
        <div className='olmyselbgline'></div>
        <OrderListSelBgItem caption="페이스북 페이지 팔로우 늘리기" handleSelect={props.handleAdsSel} adsType={14} img="list/facebooks.png"/>
        <div className='olmyselbgline'></div>
        
        <OrderListSelBgItem caption="유튜브 좋아요 늘리기" handleSelect={props.handleAdsSel} adsType={20} img="list/youtubes.png"/>
        <div className='olmyselbgline'></div>
        <OrderListSelBgItem caption="유튜브 구독자 늘리기" handleSelect={props.handleAdsSel} adsType={21} img="list/youtubes.png"/>
        <div className='olmyselbgline'></div>
        <OrderListSelBgItem caption="유튜브 댓글 늘리기" handleSelect={props.handleAdsSel} adsType={22} img="list/youtubes.png"/>
        <div className='olmyselbgline'></div>
        
        <OrderListSelBgItem caption="틱톡 동영상 좋아요 늘리기" handleSelect={props.handleAdsSel} adsType={30} img="list/ticktocks.png"/>
        <div className='olmyselbgline'></div>
        <OrderListSelBgItem caption="틱톡 팔로우 늘리기" handleSelect={props.handleAdsSel} adsType={31} img="list/ticktocks.png"/>
        <div className='olmyselbgline'></div>
        <OrderListSelBgItem caption="틱톡 댓글 늘리기" handleSelect={props.handleAdsSel} adsType={32} img="list/ticktocks.png"/>
        <div className='olmyselbgline'></div>
        

        <button className='olselclosebtn font14btn4 btncur margintop30 marginbottom20'
          onClick={(e) => {props.handleAdsSel(-2); e.preventDefault()}}
        >닫기</button>
      </div>
    );
  }
  else*/
  {
    return (
      <div className='olmyselbg flexcoltc'>
        <div className='olmyselbg2 flexrowsc' onClick={(e) => {props.handleAdsSel(-1); e.preventDefault()}}>
          <label className='olselbgmgl font14b_2 btncur'>전체 광고</label>
  
          <img src={GetImgUrl("com/up.png")} alt="확장" className='olselbgmgr'/>
        </div>
  
        <div className='olmyselbgline'></div>
        {/*<OrderListSelBgItem caption="인스타그램 좋아요 늘리기" handleSelect={props.handleAdsSel} adsType={0} img="list/instas.png"/>
        <div className='olmyselbgline'></div>*/}
        <OrderListSelBgItem caption="인스타그램" handleSelect={props.handleAdsSel} adsType={1} img="list/instas.png"/>
        <div className='olmyselbgline'></div>
        {/*<OrderListSelBgItem caption="인스타그램 댓글 늘리기" handleSelect={props.handleAdsSel} adsType={2} img="list/instas.png"/>
        <div className='olmyselbgline'></div>*/}

        {/*<OrderListSelBgItem caption="페이스북 좋아요 늘리기" handleSelect={props.handleAdsSel} adsType={10} img="list/facebooks.png"/>
        <div className='olmyselbgline'></div>
        <OrderListSelBgItem caption="페이스북" handleSelect={props.handleAdsSel} adsType={11} img="list/facebooks.png"/>
        <div className='olmyselbgline'></div>
        <OrderListSelBgItem caption="페이스북 게시물 댓글 늘리기" handleSelect={props.handleAdsSel} adsType={12} img="list/facebooks.png"/>
        <div className='olmyselbgline'></div>
        <OrderListSelBgItem caption="페이스북 페이지 팔로우 늘리기" handleSelect={props.handleAdsSel} adsType={14} img="list/facebooks.png"/>
        <div className='olmyselbgline'></div>*/}

        {/*<OrderListSelBgItem caption="유튜브 좋아요 늘리기" handleSelect={props.handleAdsSel} adsType={20} img="list/youtubes.png"/>
        <div className='olmyselbgline'></div>*/}
        <OrderListSelBgItem caption="유튜브" handleSelect={props.handleAdsSel} adsType={21} img="list/youtubes.png"/>
        <div className='olmyselbgline'></div>
        {/*<OrderListSelBgItem caption="유튜브 댓글 늘리기" handleSelect={props.handleAdsSel} adsType={22} img="list/youtubes.png"/>
        <div className='olmyselbgline'></div>*/}
        
        {/*<OrderListSelBgItem caption="틱톡 동영상 좋아요 늘리기" handleSelect={props.handleAdsSel} adsType={30} img="list/ticktocks.png"/>
        <div className='olmyselbgline'></div>*/}
        <OrderListSelBgItem caption="틱톡" handleSelect={props.handleAdsSel} adsType={31} img="list/ticktocks.png"/>
        {/*<div className='olmyselbgline'></div>
        <OrderListSelBgItem caption="틱톡 댓글 늘리기" handleSelect={props.handleAdsSel} adsType={32} img="list/ticktocks.png"/>*/}
      </div>
    );  
  }
}

export default OrderListSel;