import './ToastMessage.css';
import '../../App.css';

const ToastMessage = (props) =>
{
  return (
    <div className="toastmsgbg rowcc">
      <label className="font14 colorw wt400">{props.msg}</label>
    </div>
  );
}

export default ToastMessage;