import { GetBankNameByCode, GetFormattedNum, GetPurchaseOrderStatus, PurchaseModeToText, SNSPM_TRANS, SNSPM_VBANK, SNSPM_VBANK_PONE } from "../../Util/Util";
import './Purchase.css';
import '../../App.css';

const GetOrderBankAccount = (item) =>
{
  if (item.pmd !== SNSPM_TRANS && item.pmd !== SNSPM_VBANK && item.pmd !== SNSPM_VBANK_PONE)
    return "";

  if (item.pmd === SNSPM_TRANS) //무통장입금
    return item.bi.bn;

  return item.bi.bn;
}

const getPurchaseStatusComp = (item, props) =>
{
  if (item.st === 1)
  {
    return (
      <div className="purstbg flexrowcc btncur" onClick={() => props.handleOrder(item)}>
        <label className="font15 wt500 colorw btncur">{GetPurchaseOrderStatus(item.st)}</label>
      </div>
    );
  }
  else
  {
    return <label className="font15 wt400 color31">{GetPurchaseOrderStatus(item.st)}</label>
  }
}

const PurchaseListItem = (props) =>
{
  const clickable = props.item.st === 1;
  const lableStyle = clickable ? 'font15 wt400 color31 btncur' : 'font15 wt400 color31';

  return (
    <div className="purlistitem flexcolec">

      <div className={clickable ? "fullwidth flexrowcc btncur" : "fullwidth flexrowcc"} onClick={() => {
        if (clickable)
          props.handleOrder(props.item);
      }}>
        {/*충전일시 */}
        <div className="purtt1 flexrowcc">
          <label className={lableStyle}>{props.item.rd2}</label>
        </div>

        {/* 충전금액*/}
        <div className="purtt2 flexrowcc">
          <label className={lableStyle}>{`${GetFormattedNum(props.item.prc)}원`}</label>
        </div>

        {/*충전방식 */}
        <div className="purtt3 flexrowcc">
          <label className={lableStyle}>{PurchaseModeToText(props.item.pmd)}</label>
        </div>

        {/*결제계좌 */}
        <div className="purtt4 flexrowcc">
          <label className={lableStyle}>{GetOrderBankAccount(props.item)}</label>
        </div>

        {/* 주문상태*/}
        <div className="purtt5 flexrowcc">
          {getPurchaseStatusComp(props.item, props)}
        </div>
      </div>
      

      <div className="purlistitemline margintop15"></div>
    </div>
  );
}

export default PurchaseListItem;