import React from 'react';
import './Bingo.css';
import '../../App.css';
import { GetImgUrl } from '../../URL/AppUrl';
import FooterBar from '../Footer/Footer';
import BingoCard from './BingoCard';
import { isValidBingoGame } from '../../Util/Util';

/*
빙고게임코드
1 : 3x3 쇼츠형 프리미엄 100만
2: 3x1 일반형 프리미엄 100만
3: 3x1 일반형 디럭스 45만
4: 3x1 일반형 베이직 9만
5: 3x1 일반형 스타터 1만
7: 3x1 쇼츠형 디럭스 45만
8: 3x1 쇼츠형 프리미엄 100만
*/
//빙고 주문하기 1단계, 빙고팡팡 게임 종류 선택
class BingoOrder1 extends React.Component
{
  state = {
    loading: false,

    bingoType: 0, //일반형(0), 쇼츠형3x3(1), 쇼츠형3x1(2)
    gameType: 5, //선택된 게임코드
  };

  setLoading = (v) =>
  {
    this.setState((prevState) => ({...prevState, loading : v}));
  }

  setBingoType = (v) =>
  {
    this.setState((prevState) => ({...prevState, bingoType : v}));

    switch(v)
    {
      case 0: //일반형
        this.setGameType(5);
        break;
      case 1: //쇼츠형 3x3
        this.setGameType(1);
        break;
      case 2: //쇼츠형 3x1
        this.setGameType(7);
        break;
      default:
        this.setGameType(-1);
        break;
    }
  }

  setGameType = (v) =>
  {
    this.setState((prevState) => ({...prevState, gameType : v}));
  }

  //사이징----------------------------------------------------------------------------------------------
  
  componentDidMount() {

    if (this.props.orderInfo.bingoCategory === 0) //일반형
    {
      this.setBingoType(0);
    }
    else //쇼츠형
    {
      if (this.props.orderInfo.bingoSubCategory === 0) //한즐빙고
      {
        this.setBingoType(2);
      }
      else //세줄 빙고
      {
        this.setBingoType(1);
      }
    }
  }

  componentWillUnmount = () =>
  {
  }

  handleSelectGame = (gameNum) =>
  {
    this.setGameType(gameNum);
  }

  handleStartGame = () =>
  {
    if (!isValidBingoGame(this.state.gameType))
    {
      alert(`주문하실 광고를 선택해 주세요.`);
      return;
    }

    this.props.handleNextStep(0, {gameType: this.state.gameType});
  }

  renderCurrentBingo = () =>
  {
    if (this.state.bingoType === 0) //일반형 한줄빙고
    {
      return (
        <div className='wd100p rowlc mgtop15 mgbt50'>
          <BingoCard key={1} selected={this.state.gameType} index={5} price={10000} title="일반형 스타터 빙고" uc={800} tc={4000} cpm={1130} bingoType={0} handleSelectGame={this.handleSelectGame}/>
          <BingoCard key={2} selected={this.state.gameType} index={4} price={90000} title="일반형 베이직 빙고" uc={6000} tc={30000} cpm={1350} bingoType={0} handleSelectGame={this.handleSelectGame}/>
          <BingoCard key={3} selected={this.state.gameType} index={3} price={450000} title="일반형 디럭스 빙고" uc={36000} tc={180000} cpm={1130} bingoType={0} handleSelectGame={this.handleSelectGame}/>
          <BingoCard key={4} selected={this.state.gameType} index={2} price={1000000} title="일반형 프리미엄 빙고" uc={90000} tc={450000} cpm={1000} bingoType={0} handleSelectGame={this.handleSelectGame}/>
        </div>
      );
    }
    else if (this.state.bingoType === 1) //쇼츠형 3줄 빙고
    {
      return (
        <div className='wd100p rowlc mgtop20 mgbt50'>
          <BingoCard key={6} selected={this.state.gameType} index={1} price={1000000} title="쇼츠형 프리미엄 빙고" uc={16000} tc={400000} cpm={1225} bingoType={1} handleSelectGame={this.handleSelectGame}/>
        </div>
      );
    }
    else if (this.state.bingoType === 2) //쇼츠형 한줄 빙고
    {
      return (
        <div className='wd100p rowlc mgtop20 mgbt50'>
          <BingoCard key={7} selected={this.state.gameType} index={7} price={450000} title="쇼츠형 디럭스 빙고" uc={6000} tc={150000} cpm={1470} handleSelectGame={this.handleSelectGame}/>
          <BingoCard key={8} selected={this.state.gameType} index={8} price={1000000} title="쇼츠형 프리미엄 빙고" uc={16000} tc={400000} cpm={1225} handleSelectGame={this.handleSelectGame}/>
        </div>
      );
    }
    else
      return null;
  }

  render()
  {
    return (
      <div className='bingocont colel' style={{width: this.props.contWidth, height: this.props.height}}>
        <div className='bingocont2 coltl' style={{width: this.props.contWidth - 40 - 50, height: this.props.height - 100}}>
          <label className='font20 wt600 color31 mgtop20'>빙고팡팡 주문</label>

          {
            this.state.bingoType === 0 ? (
              <div className='bingotooltip mgtop20 rowcl' style={{backgroundImage: `url("${GetImgUrl("bingo/tooltip.png")}")`}}>
                <label className='font16 wt500 colorff36 mgtop2'>이 상품은 최초 1회만 구매 가능합니다.</label>
              </div>
            ) : null
          }          

          {this.renderCurrentBingo()}
          
          <div className='rowcc mgbt50'>
            <button className='bingood0btn2 font16 wt500 colorw btncur mgrt10' onClick={this.props.handlePrevious}>이전</button>
            <button className='bingood0btn3 font16 wt500 colorw btncur'onClick={this.handleStartGame}>광고 생성하기</button>
          </div>
        </div>
        <FooterBar handleMenu={this.props.handleMenu}/>
      </div>
    );
  }
}

export default BingoOrder1;