import React from 'react';

import './Login.css';
import '../../App.css';
import { APP_MODE, GetImgUrl, mobileOn } from '../../URL/AppUrl';
import Loading from '../Loading/Loading';
import { GetSHA256, IsValidS, replaceLine } from '../../Util/Util';
import { LoginStoreInstance } from '../../Stores/LoginStore';
import TimeUtil from '../../Util/TimeUtil';

class FindId extends React.Component
{
  state = {
    loading: false,
    authStep: 0, //인증 단계, 번호인증(0), 인증성공(1)
    userId: APP_MODE === 0 ? 'test' : '', //찾은 ID
    phone: '', //전화번호
    authNum: '', //인증번호

    errorMsg: '', //오류메시지

    smsSent: false, //sms발송되었나?
  };

  smsSentCount = 0;
  smsTime = null; //sms발송한 시점의 시간이 기록됨
  authData = null;
  authCode = null;
  authDisabled = false; //당일 인증이 더이상 불가능함

  showLoading = (v) =>
  {
    this.setState((prevState) => ({...prevState, loading: v}));
  }

  setAuthStep = (v) =>
  {
    this.setState((prevState) => ({...prevState, authStep: v}));
  }

  setUserId = (v) =>
  {
    this.setState((prevState) => ({...prevState, userId: v}));
  }

  setPhone = (v) =>
  {
    this.setState((prevState) => ({...prevState, phone: v}));
  }

  setAuthNumber = (v) =>
  {
    this.setState((prevState) => ({...prevState, authNum: v}));
  }

  setErrorMsg = (v) =>
  {
    this.setState((prevState) => ({...prevState, errorMsg: v}));
  }

  setSmsSent = (v) =>
  {
    this.setState((prevState) => ({...prevState, smsSent: v}));
  }

  componentDidMount() {
  }

  componentWillUnmount() {
  }

  handleSendSms = () =>
  {
    if (this.state.loading)
      return;

    if (this.state.smsSent)
    {
      this.setErrorMsg('인증번호가 발송되었습니다.');
      return;
    }

    if (this.authDisabled || this.smsSentCount > 10)
    {
      this.setErrorMsg('당일 인증 횟수를 초과 했습니다. 내일 이용해 주세요.');
      return;
    }

    if (!IsValidS(this.state.phone))
    {
      this.setErrorMsg('휴대폰 번호를 입력해 주세요.');
      return;
    }

    ++this.smsSentCount;
    this.setErrorMsg('');

    const phone = replaceLine(this.state.phone);

    this.showLoading(true);
    LoginStoreInstance.findAccount(0, phone, null, this.onFindIdResult1);
  }

  onFindIdResult1 = (resultCode, authData, authCode) =>
  {
    this.showLoading(false);

    switch(resultCode)
    {
      case 0: //정상
        this.authData = authData;
        this.authCode = authCode;

        if (this.smsTime === null)
          this.smsTime = new TimeUtil();

        this.smsTime.catch();
        this.setSmsSent(true);
        this.setErrorMsg('');
        this.setAuthNumber('');
        break;
      case 1: //잘못된 요청
        this.setErrorMsg('잘못된 요청입니다.');
        return;
      case 2: //전화 또는 이메일 없음
        this.setErrorMsg('해당 번호로 가입된 계정이 없습니다.');
        return;
      case 5: //당일 인증가능횟수 초과
        this.authDisabled = true;
        this.setErrorMsg('당일 인증 횟수를 초과 했습니다. 내일 이용해 주세요.');
        return;
      default:
        this.setErrorMsg('인증 요청 중 오류가 발생했습니다. 잠시 후 이용해 주세요.');
        return;
    }
  }

  handleFindId = () =>
  {
    if (this.state.loading)
      return;

    if (this.authDisabled)
    {
      this.setErrorMsg('당일 인증 횟수를 초과 했습니다. 내일 이용해 주세요.');
      return;
    }

    //sms발송 후 3분이상 경과한경우
    if (this.smsTime && this.smsTime.getPassedTime2() > (60 * 3))
    {
      this.setErrorMsg('인증시간이 경과 되었습니다. 인증번호를 재발송해 주세요.');
      this.setSmsSent(false);
      return;
    }

    if (!this.state.smsSent)
    {
      this.setErrorMsg('인증번호 버튼을 눌러 인증번호를 발송해 주세요.');
      return;
    }

    if (!IsValidS(this.state.authNum))
    {
      this.setErrorMsg(`인증번호를 입력해 주세요.`);
      return;
    }

    //인증번호가 일치하지 않으면
    if (GetSHA256(this.state.authNum) !== this.authCode)
    {
      if (APP_MODE === 0)
        console.log(`ac : ${this.authCode}, current : ${GetSHA256(this.state.authNum)}`);

      this.setErrorMsg(`인증번호가 올바르지 않습니다.`);
      return;
    }

    this.setErrorMsg(``);

    this.showLoading(true);
    LoginStoreInstance.checkFindAccount(0, this.state.phone, this.authData, this.state.authNum, this.onFindIdResult2);
  }

  onFindIdResult2 = (resultCode, userId, rv) =>
  {
    this.showLoading(false);

    switch(resultCode)
    {
      case 0: //정상
        break;
      case 1: //잘못된 요청
        this.setErrorMsg('잘못된 요청입니다.');
        return;
      case 2: //인증정보 없음
        this.setErrorMsg('인증 정보가 존재하지 않습니다.');
        return;
      case 3: //인증코드 불일치
        this.setErrorMsg('인증번호가 올바르지 않습니다.');
        return;
      case 4: //인증시간경과
        this.setErrorMsg('인증시간이 경과 되었습니다. 인증번호를 재발송해 주세요.');
        this.setSmsSent(false);
        return;
      default:
        this.setErrorMsg('인증오류가 발생했습니다.\n잠시 후 이용해 주세요.');
        return;
    }

    this.setUserId(userId);
    LoginStoreInstance.setUserId(userId);
    this.setAuthStep(1);
  }

  renderAuthStepPc1 = () =>
  {
    return (
      <div className='findidbg flexcolcc'>
        {
          this.state.loading ? (<Loading/>): null
        }
        <div className='findidpop flexcoltc'>
            <div className='findidpop_in flexcoltl'>
              <div className='fullwidth flexrowsc'>
                <label className='font20b2'>아이디 찾기</label>
                <img src={GetImgUrl("com/close.png")} alt="닫기" className='btncur menubtn' onClick={this.props.handleClose}/>
              </div>

              <label className='font16b margintop5'>회원가입시 입력했던 휴대폰 번호로 휴대폰 인증을 진행해 주세요.</label>

              <div className='fullwidth flexrowsc margintop50'>
                <input type="number" value={this.state.phone} onChange={(e) => this.setPhone(e.target.value)} className='findidinp1 font14b_2'
                  placeholder='휴대폰 번호를 입력해 주세요.'
                />

                <button className='findidbtn1 font14btn5 btncur menubtn' onClick={this.handleSendSms}>인증번호</button>
              </div>

              {
                this.state.smsSent ? (
                  <input type="number" value={this.state.authNum} onChange={(e) => this.setAuthNumber(e.target.value)}
                    className='findidinp2 font14b_2 margintop30'
                    placeholder='인증번호를 입력해 주세요.'
                  />
                ) : null
              }
              

              {
                IsValidS(this.state.errorMsg) ? (<label className='font14ff margintop5'>{this.state.errorMsg}</label>) : null
              }

              <button
                className={this.state.smsSent ? 'findidbtn2 font16btn btncur margintop50 menubtn' : 'findidbtn3 font16btn btncur margintop50'}
                onClick={this.handleFindId}>확인</button>

              <div className='fullwidth flexrowcc margintop15'>
                <label className='font16 colorb3 wt400  fontunderline btncur menubtn' onClick={this.props.handleRegister}>회원가입</label>
              </div>
            </div>
        </div>
      </div>
    );
  }

  renderAuthStepPc2 = () =>
  {
    return (
      <div className='findidbg flexcolcc'>
        {
          this.state.loading ? (<Loading/>): null
        }
        <div className='findidpop flexcoltc'>
            <div className='findidpop_in flexcoltl'>
              <div className='fullwidth flexrowsc'>
                <label className='font20b2'>아이디 찾기 완료</label>
                <img src={GetImgUrl("com/close.png")} alt="닫기" className='btncur menubtn' onClick={this.props.handleClose}/>
              </div>

              <div className='findidbox flexcolcc mgtop100'>
                <label className='font16b'>{this.state.userId}</label>
              </div>


              <button
                className='findidbtn2 font16btn btncur margintop15 menubtn'
                onClick={this.props.handleLogin}>로그인</button>

              <button className='findidbtn3 font15 wt600 color20 margintop15 menubtn btncur' onClick={this.props.handleFindPw}>
                비밀번호 찾기
              </button>
            </div>
        </div>
      </div>
    );
  }

  renderAuthStepMobile1 = () =>
  {
    return (
      <div className='findidbg flexcolcc'>
        {
          this.state.loading ? (<Loading/>): null
        }
        <div className='findidpop_mb flexcoltc'>
            <div className='loginconttop_mb flexrowsc'>
              <img src={GetImgUrl("com/mb/toplogo.png")} alt="snsfly" className='img9421'/>
              <img src={GetImgUrl("com/mb/close.png")} alt="close" className='btncur menubtn img1414' onClick={this.props.handleClose}/>
            </div>
            <div className='loginline'></div>

            <div className='findidpop_in_mb flexcoltl'>
              <label className='font18b'>아이디 찾기</label>

              <label className='font14b_2 margintop5'>회원가입시 입력했던 휴대폰 번호로 휴대폰 인증을 진행해 주세요.</label>

              <div className='fullwidth flexrowsc margintop50'>
                <input type="number" value={this.state.phone} onChange={(e) => this.setPhone(e.target.value)} className='findidinp1_mb font14b_2'
                  placeholder='휴대폰 번호를 입력해 주세요.'
                />

                <button className='findidbtn1_mb font14btn5 btncur menubtn' onClick={this.handleSendSms}>인증번호</button>
              </div>

              {
                this.state.smsSent ? (
                  <input type="number" value={this.state.authNum} onChange={(e) => this.setAuthNumber(e.target.value)}
                    className='findidinp2_mb font14b_2 margintop30'
                    placeholder='인증번호를 입력해 주세요.'
                  />
                ) : null
              }
              

              {
                IsValidS(this.state.errorMsg) ? (<label className='font14ff margintop5'>{this.state.errorMsg}</label>) : null
              }

              <button
                className={this.state.smsSent ? 'findidbtn2_mb font14btn4 btncur margintop50 menubtn' : 'findidbtn3_mb font14btn4 btncur margintop50'}
                onClick={this.handleFindId}>휴대폰 인증하기</button>

              <div className='fullwidth flexrowcc margintop15'>
                <label className='font16 wt400 colorb3 btncur menubtn' onClick={this.props.handleRegister}>&nbsp;&nbsp;&nbsp;회원가입</label>
              </div>
            </div>
        </div>
      </div>
    );
  }

  renderAuthStepMobile2 = () =>
  {
    return (
      <div className='findidbg flexcolcc'>
        {
          this.state.loading ? (<Loading/>): null
        }
        <div className='findidpop_mb flexcoltc'>
            <div className='loginconttop_mb flexrowsc'>
              <img src={GetImgUrl("com/mb/toplogo.png")} alt="snsfly"  className='img9421'/>
              <img src={GetImgUrl("com/mb/close.png")} alt="close" className='btncur menubtn img1414' onClick={this.props.handleClose}/>
            </div>
            <div className='loginline'></div>

            <div className='findidpop_in_mb flexcoltl'>
              <label className='font18b'>아이디 찾기 완료</label>

              <label className='font14b_2 margintop5'>아이디 찾기를 완료하였습니다.</label>

              <div className='fullwidth flexcolcc margintop120'>
                <label className='font16b'>{this.state.userId}</label>
                <div className='findidline margintop5'></div>
              </div>


              <button
                className='findidbtn2_mb font14btn4 btncur margintop15 menubtn'
                onClick={this.props.handleLogin}>로그인</button>

              <div className='fullwidth flexrowcc margintop15'>
                <label className='font14b_2'>비밀번호가 기억나지 않나요?</label>
                <label className='font1420 btncur menubtn' onClick={this.props.handleFindPw}>&nbsp;&nbsp;&nbsp;비밀번호 찾기</label>
              </div>

            </div>
        </div>
      </div>
    );
  }

  render()
  {
    /*if (mobileOn)
    {
      if (this.state.authStep === 0)
        return this.renderAuthStepMobile1();
      else
        return this.renderAuthStepMobile2();
    }
    else*/
    {
      if (this.state.authStep === 0)
        return this.renderAuthStepPc1();
      else
        return this.renderAuthStepPc2();
    }
  }
}

export default FindId;