import { useState } from "react";
import './Notice.css';
import '../../App.css';
import { GetImgUrl } from "../../URL/AppUrl";
import { GetDataDate, IsValidS } from "../../Util/Util";

const getNoticeTag = (item) =>
{
  if (item.nt === 0) //공지
  {
    return (
      <div className="ntctag1 flexrowcc">
        <label className="font10w_1">공지</label>
      </div>
    );
  }
  else //이벤트
  {
    return (
      <div className="ntctag2 flexrowcc">
        <label className="font10w_1">이벤트</label>
      </div>
    );
  }
}

const getNoticeDesc = (item) =>
{
  if (item.dc.indexOf("\n") >= 0)
  {
    const temp = item.dc.split("\n");

    if (!IsValidS(temp))
      return item.dc;

    let temp2 = [];

    for (let i = 0;i < temp.length; ++i)
    {
      temp2.push(temp[i]);

      if (i !== temp.length - 1)
        temp2.push(<br/>);
    }

    return temp2;
  }

  return item.dc;
}

const NoticeItem = (props) =>
{
  const [enabled, setEnabled] = useState(false);

  if (enabled)
  {
    return (
      <tr className="ntctbrow">
        <td className="ntctbrowitem">
          <div className="ntctbrowdiv rowcc">
            <label className="font16 wt400 color31">{props.item.idx}</label>
          </div>
        </td>

        <td className="ntctbrowitem2 btncur">
          <div className="ntctbrowdiv coltl" onClick={() => setEnabled(!enabled)}>
            <label className="font16 wt400 color31 mglt15 btncur mgtop10">{props.item.tt}</label>
            <label className="font16 wt400 color31 mglt15 btncur wd90p textleft mgbt10 mgtop5">{getNoticeDesc(props.item)}</label>
          </div>
        </td>

        <td className="ntctbrowitem3">
          <div className="ntctbrowdiv rowcc">
            <label className="font16 wt400 color31">{GetDataDate(props.item.rd)}</label>
          </div>
        </td>
      </tr>
    );
  }
  else
  {
    return (
      <tr className="ntctbrow">
        <td className="ntctbrowitem">
          <div className="ntctbrowdiv rowcc">
            <label className="font16 wt400 color31">{props.item.idx}</label>
          </div>
        </td>

        <td className="ntctbrowitem2 btncur">
          <div className="ntctbrowdiv rowlc" onClick={() => setEnabled(!enabled)}>
            <label className="font16 wt400 color31 mglt15 btncur">{props.item.tt}</label>
          </div>
        </td>

        <td className="ntctbrowitem3">
          <div className="ntctbrowdiv rowcc">
            <label className="font16 wt400 color31">{GetDataDate(props.item.rd)}</label>
          </div>
        </td>
      </tr>
    );
  }
}

export default NoticeItem;