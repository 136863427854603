import './Bingo.css';
import '../../App.css';

const BingoDescVideo = (props) =>
{
  return (
    <div className='wd100p coltl mgtop10'>
      <label className='font15 wt400 color31 lht23'>집중형 빙고팡팡을 주문하는 경우 광고 영상이 필수이며, 광고 영상이 있어야 빙고가 생성됩니다.<br/><br/>준비된 광고 영상이 없는 경우 “영상이 없어서 제작 대행(유료)을 맡기고 싶어요”를 체크해 주세요. 주문이 접수되면 머니팡애즈에서 확인 후 영상 제작을 위해 담당자가 별도로 연락드리겠습니다.<br/><br/>광고 영상은 재생 길이와 관계없이 9:16 비율, 50MB 이하의 MP4 포맷 파일을 업로드할 수 있습니다.단, 재생 길이가 30초가 넘는 영상의 경우, 머니팡 유저가 30초 이후부터 영상 시청 스킵을 선택할 수 있도록 설정되어 있습니다. 따라서 주요 내용을 30초 이내에 담으면 더욱 효과적인 광고 영상이 될 수 있습니다.</label>
    </div>
  );
}

export default BingoDescVideo;