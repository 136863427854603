import { GetImgUrl, mobileOn } from "../../URL/AppUrl";
import './Paging.css';
import '../../App.css';

const Paging = (props) =>
{
  let pageItem = [];

  for (let i = props.startPage;i <= props.endPage; ++i)
  {
    pageItem.push(<label key={i} className={props.currentPage === i ? "pagemargin font1620" : "pagemargin font16b_2 btncur"} onClick={() => props.handlePage(i)}>{i}</label>);
  }

  if (mobileOn)
  {
    return (
      <div className={`pagecont flexrowcc ${props.pgStyle}`}>
        {
          props.needAfter ? (<img src={GetImgUrl("com/mb/larrow.png")} alt="이전" className="marginright15 btncur img2020" onClick={() => props.handlePage(-1)}/>) : null
        }
        {pageItem}
        {
          props.needNext ? (<img src={GetImgUrl("com/mb/rarrow.png")} alt="다음" className="marginleft15 btncur img2020" onClick={() => props.handlePage(-2)}/>) : null
        }
      </div>
    );
  }
  else
  {
    return (
      <div className={`pagecont flexrowcc ${props.pgStyle}`}>
        {
          props.needAfter ? (<img src={GetImgUrl("com/larrow.png")} alt="이전" className="marginright15 btncur" onClick={() => props.handlePage(-1)}/>) : null
        }
        {pageItem}
        {
          props.needNext ? (<img src={GetImgUrl("com/rarrow.png")} alt="다음" className="marginleft15 btncur" onClick={() => props.handlePage(-2)}/>) : null
        }
      </div>
    );
  }
}

export default Paging;