import './Bingo.css';
import '../../App.css';
import { GetImgUrl } from '../../URL/AppUrl';

const BingoDescLBanner = (props) =>
{
  return (
    <div className='wd100p coltl mgtop10'>
      <label className='font15 wt400 color31 lht23'>빙고팡팡 게임 입장 전 머니팡 유저들이 배너 이미지를 볼 수 있어, 이를 통해 브랜드나 상품의 노출로 유저들에게 도입 각인 효과를 줄 수 있습니다.<br/><br/><span className='color20 wt600'>빙고팡팡 로비 배너 제작 가이드</span></label>
      
      <ul className='wd90p font15 wt400 color31 lht23'>
        <li>빙고팡팡 로비 배너 사이즈 : 950*360px</li>
        <li>이미지 화질이 깨지는 경우, 이미지를 교체해 주세요.</li>
        <li>배너 내 요소는 자유롭게 구성할 수 있지만, 플랫폼 운영 정책에 위배되는 이미지나 텍스트가 포함된 경우 반영되지 않으니 유의해 주세요.</li>
      </ul>

      <div className='wd100p rowcc'>
        <img src={GetImgUrl("bingo/stp22.png")} alt="설명" className='bgst2img2'/>
      </div>
    </div>
  );
}

export default BingoDescLBanner;