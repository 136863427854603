import React, { Fragment } from 'react';
import './Bingo.css';
import '../../App.css';
import { GetImgUrl, LOG_ENABLE } from '../../URL/AppUrl';
import {  checkVideoBingo, getShortedPathName, getValidFileName, getValidString, isBingo3to1, IsValidS, IsValidV, UploadFile, } from '../../Util/Util';
import Loading from '../Loading/Loading';
import FooterBar from '../Footer/Footer';
import { BingoStoreInstance } from '../../Stores/BingoStore';
import BingoDescDesign from './BingoDescDesign';
import BingoDescLBanner from './BingoDescLBanner';
import BingoDescSb from './BingoDescSb';
import BingoDescGBanner from './BingoDescGBanner';
import BingoDescVideo from './BingoDescVideo';
import { saveAs } from 'file-saver';
import BingoDescAllBingo from './BingoDescAllBingo';


//빙고 상세등록 2단계
class BingoStep2 extends React.Component
{
  state = {
    loading: false,

    bingoDesign: 0, //A형(0), B형(1)
    bingoName: '',
    mainBannerName: '',
    mainBannerFile: null,
    symbolName: '',
    symbolFile: null,
    bannerName: '',
    bannerFile: null,
    videoName: '',
    videoFile: null,

    gift: 0, //진행안함(0), 진행(1)
    giftCount: '', //경품 제공수

    videoOutsourcing: false, //영상 외주 요청

    selectedHelp: 0, //오른쪽 영역 선택된 도움말

    kw1: '', //심볼키워드1
    kw2: '', //심볼키워드2
    kw3: '', //심볼키워드3
    dc: '', //요청사항
  };

  saveData = null;
  changed = false; //내용변경이 있는가?
  mbChanged = false; //메인배너 바뀌었나?
  sbChanged = false; //심볼바뀌었나?
  bnChanged = false; //게임배너바뀌었나?
  vdChanged = false; //비디오바뀌었나?
  lastUploadType = 0; //메인배너(0), 심볼(1), 배너(2), 비디오(3)
  
  //-----------------------------------------------------------------------------------------------------------------------------------------
  setLoading = (v) =>
  {
    this.setState((prevState) => ({...prevState, loading : v}));
  }

  //상품분류 설정
  setBingoDesign = (v) =>
  {
    if (v !== this.state.bingoDesign)
      this.changed = true;

    this.setState((prevState) => ({...prevState, bingoDesign: v}));
  }

  setBingoName = (v) =>
  {
    if (v !== this.state.bingoName)
      this.changed = true;

    this.setState((prevState) => ({...prevState, bingoName: v}));
  }

  setMainBanner = (name, file) =>
  {
    if (name !== this.state.mainBannerName || file !== this.state.mainBannerFile)
    {
      this.changed = true;
      this.mbChanged = true; //메인배너 바뀌었나?
    }

    this.setState((prevState) => ({...prevState, mainBannerName: name, mainBannerFile: file}));
  }

  setSymbol = (name, file) =>
  {
    if (name !== this.state.symbolName || file !== this.state.symbolFile)
    {
      this.changed = true;
      this.sbChanged = true; //심볼바뀌었나?
    }

    this.setState((prevState) => ({...prevState, symbolName: name, symbolFile: file}));
  }

  setBanner = (name, file) =>
  {
    if (name !== this.state.bannerName || file !== this.state.bannerFile)
    {
      this.changed = true;
      this.bnChanged = true; //게임배너바뀌었나?
    }

    this.setState((prevState) => ({...prevState, bannerName: name, bannerFile: file}));
  }

  setVideo = (name, file) =>
  {
    if (name !== this.state.videoName || file !== this.state.videoFile)
    {
      this.changed = true;
      this.vdChanged = true; //비디오바뀌었나?
    }

    this.setState((prevState) => ({...prevState, videoName: name, videoFile: file}));
  }

  setGiftMode = (v) =>
  {
    if (v !== this.state.gift)
      this.changed = true;

    this.setState((prevState) => ({...prevState, gift: v}));
  }

  setGiftCount = (v) =>
  {
    if (v !== this.state.giftCount)
      this.changed = true;

    this.setState((prevState) => ({...prevState, giftCount: v}));
  }
 
  setVideOutsourcing = (v) =>
  {
    if (v !== this.state.videoOutsourcing)
      this.changed = true;

    this.setState((prevState) => ({...prevState, videoOutsourcing: v}));
  }

  setSelectedHelpHover = (v) =>
  {
    if (LOG_ENABLE)
      console.log(`setSelectedHelpHover : ${v}`);
    
    /*if (this.state.selectedHelp === v)
      return;

    this.setState((prevState) => ({...prevState, selectedHelp: v}));*/
  }

  setSelectedHelp = (v) =>
  {
    if (LOG_ENABLE)
      console.log(`setSelectedHelp : ${v}`);
    
    if (this.state.selectedHelp === v)
      return;

    this.setState((prevState) => ({...prevState, selectedHelp: v}));
  }

  //심볼 키워드 설정
  setKeyword = (index, v) =>
  {
    switch(index)
    {
      case 0:
        if (v !== this.state.kw1)
          this.changed = true;
    
        this.setState((prevState) => ({...prevState, kw1: v}));
        break;
      case 1:
        if (v !== this.state.kw2)
          this.changed = true;
    
        this.setState((prevState) => ({...prevState, kw2: v}));
        break;
      case 2:
        if (v !== this.state.kw3)
          this.changed = true;
    
        this.setState((prevState) => ({...prevState, kw3: v}));
        break;
      default:
        return;
    }
  }

  //요청사항
  setRequestInfo = (v) =>
  {
    if (v !== this.state.dc)
      this.changed = true;

    this.setState((prevState) => ({...prevState, dc: v}));
  }
  //-----------------------------------------------------------------------------------------------------------------------------------------
  componentDidMount() {
    if (LOG_ENABLE)
      console.log(`BingoStep2.componentDidMount`);

    if (IsValidV(this.props.step2))
    {
      this.changed = !this.props.step2.saved;
      
      if (this.changed)
      {
        if (this.props.step2.mainBannerFile)
          this.mbChanged = true;

        if (this.props.step2.symbolFile)
          this.sbChanged = true;

        if (this.props.step2.bannerFile)
          this.bnChanged = true;

        if (this.props.step2.videoFile)
          this.vdChanged = true;
      }

      let oldChanged = this.changed;

      this.saveData = null;
      this.setBingoName(IsValidS(this.props.step2.bingoName) && this.props.step2.bingoName !== "n" ? this.props.step2.bingoName : "");
      this.setBingoDesign(this.props.step2.bingoDesign);

      this.setMainBanner(this.props.step2.mainBannerName, this.props.step2.mainBannerFile);
      this.setSymbol(this.props.step2.symbolName, this.props.step2.symbolFile);
      this.setBanner(this.props.step2.bannerName, this.props.step2.bannerFile);
      this.setVideo(this.props.step2.videoName, this.props.step2.videoFile);
      this.setGiftMode(this.props.step2.gift);
      this.setGiftCount(this.props.step2.giftCount > 0 ? this.props.step2.giftCount.toString() : '');
      this.setVideOutsourcing(this.props.step2.vos);

      this.setKeyword(0, IsValidS(this.props.step2.kw1) && this.props.step2.kw1 !== "n" ? this.props.step2.kw1 : "");
      this.setKeyword(1, IsValidS(this.props.step2.kw2) && this.props.step2.kw2 !== "n" ? this.props.step2.kw2 : "");
      this.setKeyword(2, IsValidS(this.props.step2.kw3) && this.props.step2.kw3 !== "n" ? this.props.step2.kw3 : "");
      this.setRequestInfo(IsValidS(this.props.step2.dc) && this.props.step2.dc !== "n" ? this.props.step2.dc : "");

      this.changed = oldChanged;
    }

    if (LOG_ENABLE)
      console.log(`chg=${this.changed}, mb=${this.mbChanged}, sb=${this.sbChanged}, bb=${this.bnChanged}, vd=${this.vdChanged}`);
  }

  componentWillUnmount = () =>
  {
    if (LOG_ENABLE)
      console.log(`BingoStep2.componentWillUnmount`);
  }

  //다음 버튼 눌림
  handleNext = (forSave) =>
  {
    if (this.state.loading)
      return;

    if (forSave) //변경사항 저장 후 이전
    {
      if (!this.changed) //저장할 변경사항이 없음
      {
        //alert(`수정된 항목이 없습니다.`);
        this.props.handleNextStep(8, null);
        return;
      }
     
      //변경사항을 저장한다.
      this.saveData = {
        bn: getValidString(this.state.bingoName, "n"), //게임타이틀
        dn: this.state.bingoDesign, //빙고디자인번호

        mb: getValidString(this.state.mainBannerName, "n"), //메인배너파일이름
        sb: getValidString(this.state.symbolName, "n"), //심볼첨부파일이름
        bb: getValidString(this.state.bannerName, "n"), //게임배너파일이름
        vd: getValidString(this.state.videoName, "n"), //첨부동영상파일이름

        mbFile: this.state.mainBannerFile,
        sbFile: this.state.symbolFile,
        bnFile: this.state.bannerFile,
        vdFile: this.state.videoFile,

        gc: 0,
        vos: this.state.videoOutsourcing ? 1 : 0, //영상 제작 아웃소싱여부

        kw1: getValidString(this.state.kw1, "n"),
        kw2: getValidString(this.state.kw2, "n"),
        kw3: getValidString(this.state.kw3, "n"),
        dc: getValidString(this.state.dc, "n"),
      };

      if (this.state.gift === 1)
      {
        if (!IsValidS(this.state.giftCount) || isNaN(this.state.giftCount))
        {
          alert(`경품 지급 개수를 5 ~ 20개 이내로 입력해 주세요.`);
          return;
        }

        const giftCount = parseInt(this.state.giftCount);

        if (giftCount < 5 || giftCount > 20)
        {
          alert(`경품 지급 개수를 5 ~ 20개 이내로 입력해 주세요.`);
          return;
        }

        this.saveData.gc = giftCount;
      }

      this.setLoading(true);
      this.uploadFiles();
    }
    else
    {
      const data = {
        saved: !this.changed,
        bingoName: this.state.bingoName, //게임 타이틀
        bingoDesign: this.state.bingoDesign, //빙고디자인번호

        mainBannerName: this.state.mainBannerName, //로비배너
        mainBannerFile: this.state.mainBannerFile,
        
        symbolFile: this.state.symbolFile, //심볼첨부파일
        symbolName: this.state.symbolName,
        
        bannerFile: this.state.bannerFile, //게임배너
        bannerName: this.state.bannerName,

        videoFile: this.state.videoFile, //첨부동영상
        videoName: this.state.videoName,

        gift: this.state.gift, //경품제공여부
        giftCount: 0, //경품제공개수
        vos: this.state.videoOutsourcing ? 1 : 0, //영상 제작 아웃소싱여부

        kw1: getValidString(this.state.kw1, "n"), //심볼키워드1
        kw2: getValidString(this.state.kw2, "n"), //심볼키워드2
        kw3: getValidString(this.state.kw3, "n"), //심볼키워드3
        dc: getValidString(this.state.dc, "n"), //요청사항
      };

      if (this.state.gift === 1)
      {
        if (!IsValidS(this.state.giftCount) || isNaN(this.state.giftCount))
        {
          alert(`경품 지급 개수를 5 ~ 20개 이내로 입력해 주세요.`);
          return;
        }

        const giftCount = parseInt(this.state.giftCount);

        if (giftCount < 5 || giftCount > 20)
        {
          alert(`경품 지급 개수를 5 ~ 20개 이내로 입력해 주세요.`);
          return;
        }

        data.giftCount = giftCount;
      }

      this.props.handleNextStep(4, data);
    }
    
  }

  //변경사항이 있는 파일부터 하나씩 차례로 업로드를 수행함
  uploadFiles = () =>
  {
    if (this.mbChanged && this.saveData.mbFile) //메인배너가 바뀌었다면
    {
      if (LOG_ENABLE)
        console.log(`upload main banner`);

      this.lastUploadType = 0; //메인배너(0), 심볼(1), 배너(2), 비디오(3)
      UploadFile(11, this.saveData.mbFile, this.onUploadDone);
      return;
    }
    else if (this.sbChanged && this.saveData.sbFile) //심볼파일바뀌었다면
    {
      if (LOG_ENABLE)
        console.log(`upload symbol`);

      this.lastUploadType = 1; //메인배너(0), 심볼(1), 배너(2), 비디오(3)
      UploadFile(11, this.saveData.sbFile, this.onUploadDone);
      return;
    }
    else if (this.bnChanged && this.saveData.bnFile) //심볼파일바뀌었다면
    {
      if (LOG_ENABLE)
        console.log(`upload banner`);

      this.lastUploadType = 2; //메인배너(0), 심볼(1), 배너(2), 비디오(3)
      UploadFile(11, this.saveData.bnFile, this.onUploadDone);
      return;
    }
    else if (this.vdChanged && this.saveData.vdFile) //심볼파일바뀌었다면
    {
      if (LOG_ENABLE)
        console.log(`upload video`);

      this.lastUploadType = 3; //메인배너(0), 심볼(1), 배너(2), 비디오(3)
      UploadFile(11, this.saveData.vdFile, this.onUploadDone);
      return;
    }
    else //업로드할게 없다면, 내용을 갱신해준다.
    {
      BingoStoreInstance.editStep2(this.props.oidx, 
        this.saveData.dn, //다지인타입
        this.saveData.bn, //게임타이틀
        this.saveData.mb, //메인배너
        this.saveData.sb,  //심볼참조이미지
        this.saveData.bb, //게임배너
        this.saveData.vd, //첨부동영상
        this.saveData.gc,  //경품개수
        this.saveData.vos, //영상외주신청여부
        this.saveData.kw1, //키워드1
        this.saveData.kw2, //키워드2
        this.saveData.kw3, //키워드3
        this.saveData.dc, //요청사항
        this.onSaveDone);
    }
  }

  //변경사항 저장결과
  onSaveDone = (resultCode) =>
  {
    this.setLoading(false);

    if (resultCode !== 0)
    {
      alert(`변경 사항 저장에 실패 했습니다.\n오류코드 ${resultCode}`);

      if (IsValidV(this.state.mainBannerFile))
        this.mbChanged = true;

      if (IsValidV(this.state.symbolFile))
        this.sbChanged = true;

      if (IsValidV(this.state.bannerFile))
        this.bnChanged = true;

      if (IsValidV(this.state.videoFile))
        this.vdChanged = true;
      
      return;
    }

    //alert(`변경사항이 저장되었습니다.`);

    //변경플래그는 클리어
    this.changed = false;
    this.mbChanged = false;
    this.sbChanged = false;
    this.bnChanged = false;
    this.vdChanged = false;

    //대상 광고의 정보를 수정된 정보로 갱신한다.

    this.props.item.dn = this.saveData.dn; //빙고디자인 번호
    this.props.item.gti = this.saveData.bn; //게임타이틀
    this.props.item.gfc = this.saveData.gc; //경품지급개수

    //각종첨부파일 및 영상제작요청
    if (!this.props.item.imgInfo)
      this.props.item.imgInfo = {};

    this.props.item.imgInfo.bimg = getValidFileName(this.saveData.mb, this.props.item.imgInfo.bimg, "n");
    this.props.item.imgInfo.simg = getValidFileName(this.saveData.sb, this.props.item.imgInfo.simg, "n");
    this.props.item.imgInfo.gad = getValidFileName(this.saveData.bb, this.props.item.imgInfo.gad, "n"); //게임배너 참조자료
    this.props.item.imgInfo.vdf = getValidFileName(this.saveData.vd, this.props.item.imgInfo.vdf, "n");
    this.props.item.imgInfo.vos = this.saveData.vos;

    //키워드 및 요청사항
    this.props.item.kw1 = this.saveData.kw1;
    this.props.item.kw2 = this.saveData.kw2;
    this.props.item.kw3 = this.saveData.kw3;
    this.props.item.dc = this.saveData.dc;

    const data = {
      saved: true,
      bingoName: this.state.bingoName,
      bingoDesign: this.state.bingoDesign,

      mainBannerName: this.state.mainBannerName,
      mainBannerFile: this.state.mainBannerFile,
      
      symbolFile: this.state.symbolFile,
      symbolName: this.state.symbolName,
      
      bannerFile: this.state.bannerFile,
      bannerName: this.state.bannerName,

      videoFile: this.state.videoFile,
      videoName: this.state.videoName,

      gift: this.state.gift,
      giftCount: 0,
      vos: this.state.videoOutsourcing ? 1 : 0, //영상 제작 아웃소싱여부

      kw1: this.saveData.kw1,
      kw2: this.saveData.kw2,
      kw3: this.saveData.kw3,
      dc: this.saveData.dc,
    };

    this.props.handleNextStep(40, data);
  }
  
  onUploadDone = (success, oldName, newName) =>
  {
    if (!success)
    {
      this.setLoading(false);
      alert(`파일 업로드 중 오류가 발생했습니다.\n임시 저장에 실패 했습니다.`);
      return;
    }

    if (LOG_ENABLE)
      console.log(`onUploadDone : ${oldName}/${newName}`);

    switch(this.lastUploadType)
    {
      case 0:
        this.mbChanged = false;
        this.saveData.mb = newName;
        break;
      case 1:
        this.sbChanged = false;
        this.saveData.sb = newName;
        break;
      case 2:
        this.bnChanged = false;
        this.saveData.bb = newName;
        break;
      case 3:
        this.vdChanged = false;
        this.saveData.vd = newName;
        break;
      default:
        break;
    }

    this.uploadFiles();
  }

  handleSelectFile = (fileType) =>
  {
    let fileId = null;

    switch(fileType)
    {
      case 0: //메인배너
        fileId = "idmain";
        break;
      case 1: //심벌
        fileId = "idsymbol";
        break;
      case 2: //광고배너
        fileId = "idbanner";
        break;
      case 3: //비디오
        fileId = "idvideo";
        break;
      default:
        return;
    }

    const input = document.getElementById(fileId);

    if (input)
      input.click();
  }

  //로비배너 제작 가이드 라인 다운로드
  handleMainBannerDesc = () =>
  {
    saveAs("https://cdn.snsfly.kr/bingo/%EB%B9%99%EA%B3%A0%ED%8C%A1%ED%8C%A1+%EB%B0%B0%EB%84%88+%EA%B0%80%EC%9D%B4%EB%93%9C%EB%9D%BC%EC%9D%B8-1031.pdf", "빙고팡팡 배너 가이드라인-1031.pdf");
  }

  //심볼이미지 가이드라인다운로드
  handleSymbolDesc = () =>
  {
    saveAs("https://cdn.snsfly.kr/bingo/%EB%B9%99%EA%B3%A0%ED%8C%A1%ED%8C%A1+%EC%8B%AC%EB%B3%BC+%EA%B0%80%EC%9D%B4%EB%93%9C%EB%9D%BC%EC%9D%B8-1031.pdf", "빙고팡팡 심볼 가이드라인-1031.pdf");
  }
  
  //올빙고 가이드라인 다운로드
  handleKeywordDesc = () =>
  {
    saveAs("https://cdn.snsfly.kr/bingo/%EB%B9%99%EA%B3%A0%ED%8C%A1%ED%8C%A1+%EC%98%AC%EB%B9%99%EA%B3%A0+%EC%8B%AC%EB%B3%BC+%EA%B0%80%EC%9D%B4%EB%93%9C%EB%9D%BC%EC%9D%B8.pdf", "빙고팡팡 올빙고 심볼 가이드라인.pdf");
  }

  checkImageSize = (file, width, height, callback) =>
  {
    try
    {
      // 파일을 읽기 위한 FileReader 객체를 생성합니다.
      const reader = new FileReader();
          
      // 파일 로드가 완료되면 실행될 콜백 함수를 정의합니다.
      reader.onload = function(event) {
        // 이미지 객체를 생성합니다.
        var image = new Image();

        // 이미지 객체의 로드가 완료되면 실행될 콜백 함수를 정의합니다.
        image.onload = function() {
          // 이미지 객체의 너비와 높이를 가져옵니다.
          var imageWidth = this.width;
          var imageHeight = this.height;

          // 이미지의 크기를 체크합니다.
          if (imageWidth !== width || imageHeight !== height) {
            callback(false, file);
          }
          else
            callback(true, file);
        };

        // 이미지 객체에 파일 데이터를 설정하고 로드합니다.
        image.src = event.target.result;
      };

      // FileReader 객체에 파일 데이터를 설정하고 로드합니다.
      reader.readAsDataURL(file);
    }
    catch(e)
    {
      callback(false, file);
    }
  }

  getCurrentDesc = () =>
  {
    switch(this.state.selectedHelp)
    {
      case 0:
        return <BingoDescDesign/>
      case 1:
        return <BingoDescLBanner/>
      case 2:
        return <BingoDescSb/>
      case 3:
        return <BingoDescGBanner/>
      case 4:
        return <BingoDescVideo/>
      case 5:
        return <BingoDescAllBingo/>
      default:
        return null;
    }
  }

  render()
  {
    const bingo3to1 = isBingo3to1(this.props.item.gt);
    const videoBingo = checkVideoBingo(this.props.item.gt);

    return (
      <div className='bingocont colel' style={{width: this.props.contWidth, height: this.props.height}}>
        {
          this.state.loading ? (<Loading/>) : null
        }
        <div className='bingocont2 coltl' style={{width: this.props.contWidth - 40 - 50, height: this.props.height - 100}}>
          <label className='font20 wt600 color31 mgtop20'>빙고팡팡 광고</label>

          <div className='wd100p rowlt mgtop20 mgbt50'>
            <div className='bingoodleft'>
              <div className='bingoodbox1 colcc'>
                <div className='bingoodin coltl'>
                  <label className='font20 wt500 color31'>빙고를 생성하기 위해 정보를 작성해 주세요</label>

                  <label className='font15 wt500 color31 mgtop10'>빙고 디자인</label>

                  <div className='rowcc mgtop10' onMouseOver={() => this.setSelectedHelpHover(0)}>
                    <div className={this.state.bingoDesign === 0 ? 'bingoselbox1 rowlc btncur mgrt5' : 'bingoselbox2 rowlc btncur mgrt5'} onClick={() => this.setBingoDesign(0)}>
                      <img src={GetImgUrl(this.state.bingoDesign === 0 ? 'bingo/sel.png' : 'bingo/sel2.png')} alt="선택" className='mglt5'/>
                      <label className={this.state.bingoDesign === 0 ? 'font15 wt500 color20 mglt5 btncur' : 'font15 wt500 color31 mglt5 btncur'}>자연</label>
                    </div>

                    {/*<div className={this.state.bingoDesign === 1 ? 'bingoselbox1 rowlc btncur' : 'bingoselbox2 rowlc btncur'} onClick={() => this.setBingoDesign(1)}>
                      <img src={GetImgUrl(this.state.bingoDesign === 1 ? 'bingo/sel.png' : 'bingo/sel2.png')} alt="선택" className='mglt5'/>
                      <label className={this.state.bingoDesign === 1 ? 'font15 wt500 color20 mglt5 btncur' : 'font15 wt500 color31 mglt5 btncur'}>B형</label>
                    </div>*/}
                  </div>

                  <label className='font15 wt500 color31 mgtop20' onMouseOver={() => this.setSelectedHelpHover(0)}>빙고 이름</label>

                  <input type='text' value={this.state.bingoName} onChange={e => this.setBingoName(e.target.value)} className='bingoodinp font16 wt400 color31 mgtop5' placeholder='띄어쓰기 포함 최대 17자까지 입력 가능합니다' maxLength={17} onMouseOver={() => this.setSelectedHelpHover(0)}/>

                  <label className='font15 wt500 color31 mgtop20'>빙고팡팡 로비 배너</label>

                  <div className='wd100p rowsc mgtop5' onMouseOver={() => this.setSelectedHelpHover(1)}>
                    <div className='bingofilebox1 rowlc'>
                      <label className='font15 wt500 color31 mglt10'>{getShortedPathName(this.state.mainBannerName, 30)}</label>
                    </div>

                    <button className='bingobtn8 font16 wt600 color20 btncur' onClick={() => this.handleSelectFile(0)}>첨부하기</button>
                  </div>

                  <button className='bingobtn9 font15 wt500 colorff96 btncur mgtop10' onClick={this.handleMainBannerDesc} onMouseOver={() => this.setSelectedHelpHover(1)}>배너 가이드라인 다운로드</button>

                  <label className='font15 wt400 colorb3'>※ 950 X 360 사이즈 PNG, JPEG포맷의 파일만 업로드 가능합니다.</label>

                  <label className='font15 wt500 color31 mgtop20'>{bingo3to1 ? "광고 심볼" : "광고 이미지 심볼"}</label>

                  <div className='wd100p rowsc mgtop5' onMouseOver={() => this.setSelectedHelpHover(2)}>
                    <div className='bingofilebox1 rowlc'>
                      <label className='font15 wt500 color31 mglt10'>{getShortedPathName(this.state.symbolName, 30)}</label>
                    </div>

                    <button className='bingobtn8 font16 wt600 color20 btncur' onClick={() => this.handleSelectFile(1)}>첨부하기</button>
                  </div>

                  <button className='bingobtn9 font15 wt500 colorff96 btncur mgtop10' onClick={this.handleSymbolDesc} onMouseOver={() => this.setSelectedHelpHover(2)}>심볼 가이드라인 다운로드</button>

                  {
                    bingo3to1 ? (
                      <Fragment>
                        <label className='font15 wt500 color31 mgtop20'>올빙고 심볼 키워드</label>
                        <label className='font15 wt400 color31 mgtop10'>1번 심볼</label>
                        <input type='text' value={this.state.kw1} onChange={e => this.setKeyword(0, e.target.value)} className='bingoodinp font16 wt400 color31 mgtop5' placeholder='예) 가치를 더하는 광고' maxLength={28}/>

                        <label className='font15 wt400 color31 mgtop15'>2번 심볼</label>
                        <input type='text' value={this.state.kw2} onChange={e => this.setKeyword(1, e.target.value)} className='bingoodinp font16 wt400 color31 mgtop5' placeholder='예) 효과적인 마케팅 빙고팡팡' maxLength={28}/>

                        <label className='font15 wt400 color31 mgtop15'>3번 심볼</label>
                        <input type='text' value={this.state.kw3} onChange={e => this.setKeyword(2, e.target.value)} className='bingoodinp font16 wt400 color31 mgtop5' placeholder='예) 몰입형 마케팅 제공' maxLength={28}/>

                        <button className='bingobtn9 font15 wt500 colorff96 btncur mgtop10' onClick={this.handleKeywordDesc}>올빙고 가이드라인 다운로드</button>
                      </Fragment>
                    ) : null
                  }

                  <label className='font15 wt500 color31 mgtop20'>빙고팡팡 게임 배너</label>

                  <div className='wd100p rowsc mgtop5' onMouseOver={() => this.setSelectedHelpHover(3)}>
                    <div className='bingofilebox1 rowlc'>
                      <label className='font15 wt500 color31 mglt10'>{getShortedPathName(this.state.bannerName, 30)}</label>
                    </div>

                    <button className='bingobtn8 font16 wt600 color20 btncur' onClick={() => this.handleSelectFile(2)}>첨부하기</button>
                  </div>
                  <label className='font15 wt400 colorb3'>※10MB 이하의 배너이미지 또는 배너 제작 소재 파일만 첨부 가능합니다.</label>

                  { videoBingo ? (
                    <Fragment>
                      <label className='font15 wt500 color31 mgtop20'>광고 영상</label>

                      <div className='wd100p rowsc mgtop5' onMouseOver={() => this.setSelectedHelpHover(4)}>
                        <div className='bingofilebox1 rowlc'>
                          <label className='font15 wt500 color31 mglt10'>{this.state.videoName}</label>
                        </div>

                        <button className='bingobtn8 font16 wt600 color20 btncur' onClick={() => this.handleSelectFile(3)}>첨부하기</button>
                      </div>

                      <div className={this.state.videoOutsourcing ? 'bpvdosbg1 rowlc btncur mgtop10' : 'bpvdosbg2 rowlc btncur mgtop10'} onClick={() => this.setVideOutsourcing(!this.state.videoOutsourcing)} onMouseOver={() => this.setSelectedHelpHover(4)}>
                        <img src={GetImgUrl(this.state.videoOutsourcing ? 'bingo/sel.png' : 'bingo/sel2.png')} alt="선택" className='mglt5'/>
                        <label className={this.state.videoOutsourcing ? 'font15 wt500 color20 mglt5 btncur' : 'font15 wt500 color31 mglt5 btncur'}>영상이 없어서 제작대행(유료) 맡기고 싶어요</label>
                      </div>
                      <label className='font15 wt400 colorb3 mgtop5'>※ 9:16비율, 50MB이하의 mp4포맷의 파일만 업로드 가능합니다.</label>
                    </Fragment>
                  ) : null}
                  

                  <label className='font15 wt500 color31 mgtop20'>경품 지급</label>

                  <div className='wd100p rowsc mgtop5'>
                    <div className={this.state.gift === 1 ? 'bingoselbox3 rowcc btncur mgrt5' : 'bingoselbox4 rowcc btncur mgrt5'} onClick={() => this.setGiftMode(1)}>
                      <label className={this.state.gift === 1 ? 'font15 wt500 color20 mglt5 btncur' : 'font15 wt500 color31 mglt5 btncur'}>진행</label>
                    </div>

                    <div className={this.state.gift === 0 ? 'bingoselbox3 rowcc btncur' : 'bingoselbox4 rowcc btncur'} onClick={() => this.setGiftMode(0)}>
                      <label className={this.state.gift === 0 ? 'font15 wt500 color20 mglt5 btncur' : 'font15 wt500 color31 mglt5 btncur'}>진행안함</label>
                    </div>
                  </div>

                  <div className='bingodescbox5 rowcc mgtop10'>
                    <label className='font16 wt400 color31'>경품으로 상품을 제공하게 될 경우 참여도와 관심이 증대돼요</label>
                  </div>

                  {
                    this.state.gift === 1 ? (
                      <input type='number' value={this.state.giftCount} onChange={e => this.setGiftCount(e.target.value)} className='bingoodinp font16 wt400 color31 mgtop20' placeholder='경품 지급 개수를 5개에서 20개 이내로 입력해 주세요.' maxLength={5}/>
                    ) : null
                  }

                  <label className='font15 wt500 color31 mgtop20'>요청사항</label>

                  <div className='bingoodtareabg rowcc'>
                    <textarea value={this.state.dc} onChange={e => this.setRequestInfo(e.target.value)} className='bingoodtarea font16 wt400 color31 mgtop5' maxLength={1000}/>
                  </div>

                  <div className='wd100p rowsc mgtop30'>
                    <button className='bingobtn6 font16 wt600 colorw btncur' onClick={() => this.handleNext(true)}>저장 후 이전</button>
                    <button className='bingobtn7 font16 wt600 colorw btncur' onClick={() => this.handleNext(false)}>다음</button>
                  </div>
                </div>
              </div>
              
            </div>

            <div className='bingoodright colcc mglt30'>
              <div className='bingoodin3 coltl'>
                <label className='font20 wt500 color31'>빙고 디자인 작업가이드 안내 및 TIP</label>

                <div className={this.state.selectedHelp === 0 ? 'bingodescbox7 rowsc btncur mgtop15' : 'bingodescbox8 rowsc btncur mgtop15'} onClick={() => this.setSelectedHelp(0)}>
                  <label className='font16 wt400 color31 btncur'>빙고 디자인은 어떻게 구성되어 있나요?</label>
                  <img src={GetImgUrl("bingo/right.png")} alt="확인"/>
                </div>

                <div className={this.state.selectedHelp === 1 ? 'bingodescbox7 rowsc btncur mgtop10' : 'bingodescbox8 rowsc btncur mgtop10'} onClick={() => this.setSelectedHelp(1)}>
                  <label className='font16 wt400 color31 btncur'>빙고팡팡 로비 배너가 어디에 노출되나요?</label>
                  <img src={GetImgUrl("bingo/right.png")} alt="확인"/>
                </div>

                <div className={this.state.selectedHelp === 2 ? 'bingodescbox7 rowsc btncur mgtop10' : 'bingodescbox8 rowsc btncur mgtop10'} onClick={() => this.setSelectedHelp(2)}>
                  <label className='font16 wt400 color31 btncur'>심볼이란 무엇이며, 파일을 어떻게 첨부해야 하나요?</label>
                  <img src={GetImgUrl("bingo/right.png")} alt="확인"/>
                </div>

                <div className={this.state.selectedHelp === 5 ? 'bingodescbox7 rowsc btncur mgtop10' : 'bingodescbox8 rowsc btncur mgtop10'} onClick={() => this.setSelectedHelp(5)}>
                  <label className='font16 wt400 color31 btncur'>올빙고 심볼의 키워드는 어떻게 작성하는 것이 효과적일까요?</label>
                  <img src={GetImgUrl("bingo/right.png")} alt="확인"/>
                </div>

                <div className={this.state.selectedHelp === 3 ? 'bingodescbox7 rowsc btncur mgtop10' : 'bingodescbox8 rowsc btncur mgtop10'} onClick={() => this.setSelectedHelp(3)}>
                  <label className='font16 wt400 color31 btncur'>빙고팡팡 게임 배너가 어디에 노출되나요?</label>
                  <img src={GetImgUrl("bingo/right.png")} alt="확인"/>
                </div>

                <div className={this.state.selectedHelp === 4 ? 'bingodescbox7 rowsc btncur mgtop10' : 'bingodescbox8 rowsc btncur mgtop10'} onClick={() => this.setSelectedHelp(4)}>
                  <label className='font16 wt400 color31 btncur'>광고 영상이 없는데 꼭 업로드해야 할까요?</label>
                  <img src={GetImgUrl("bingo/right.png")} alt="확인"/>
                </div>
                
                {this.getCurrentDesc()}
              </div>
            </div>
          </div>
          
          <input type="file" id="idmain" className="bingohiddenfile" accept="image/png, image/jpeg"
            onChange={(e) => {

              if (!IsValidV(e) || !IsValidV(e.target) || !IsValidS(e.target.files))
                return;

              this.checkImageSize(e.target.files[0], 950, 360, (success, file) => {

                if (!success)
                {
                  alert(`배너 이미지 크기가 올바르지 않습니다.\n950 X 360 사이즈 PNG, JPEG파일만 업로드 가능합니다.`);
                }
                else
                  this.setMainBanner(e.target.files[0].name, e.target.files[0]);
              });
          }}/>

          <input type="file" id="idsymbol" className="bingohiddenfile" accept=".png, .jpg, .jpeg, .gif, .pdf, .doc, .docx, .xlsx, .pptx, .zip, .rar, .alz, .egg, .tar, .lzh"
            onChange={(e) => {

              if (!IsValidV(e) || !IsValidV(e.target) || !IsValidS(e.target.files))
                return;

              if (e.target.files[0].size > (1024 * 1024 * 10))
              {
                alert(`첨부파일 용량이 초과되었습니다.\n10MB이하의 파일만 업로드 가능합니다.`);
              }
              else
                this.setSymbol(e.target.files[0].name, e.target.files[0]);
          }}/>

          <input type="file" id="idbanner" className="bingohiddenfile" accept=".png, .jpg, .jpeg, .gif, .pdf, .doc, .docx, .xlsx, .pptx, .zip, .rar, .alz, .egg, .tar, .lzh"
            onChange={(e) => {

              if (!IsValidV(e) || !IsValidV(e.target) || !IsValidS(e.target.files))
                return;

              if (e.target.files[0].size > (1024 * 1024 * 10))
              {
                alert(`첨부 파일 용량이 초과되었습니다.\n10MB이하의 파일만 업로드 가능합니다.`);
              }
              else
                this.setBanner(e.target.files[0].name, e.target.files[0]);
          }}/>

          <input type="file" id="idvideo" className="bingohiddenfile" accept="video/mp4"
            onChange={(e) => {

              if (!IsValidV(e) || !IsValidV(e.target) || !IsValidS(e.target.files))
                return;

              if (e.target.files[0].size > (1024 * 1024 * 50))
              {
                alert(`광고 영상 용량이 초과되었습니다.\n9:16비율 50MB이하의 mp4 파일만 업로드 가능합니다.`);
              }
              else
                this.setVideo(e.target.files[0].name, e.target.files[0]);
          }}/>

        </div>
        <FooterBar handleMenu={this.handleMenu}/>
      </div>
    );
  }
}

export default BingoStep2;