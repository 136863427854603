
import './OrderList.css';
import '../../App.css';
import { GetImgUrl } from '../../URL/AppUrl';
import { AdsAgeInfoToText, AdsGenderInfoToText, AdsRepeatInfoToText, AdsTypeToText, getAdsPlatformName, GetDataDate, GetDataDateAndTime, GetFormattedNum, getShortedPathName, isPostTypeAds } from '../../Util/Util';

const getOrderItemImage = (item) =>
{
  switch(item.pf)
  {
    case 0: //인스타
      return GetImgUrl("list/insta.png");
    case 1: //페이스북
      return GetImgUrl("list/facebook.png");
    case 2: //유튜브
      return GetImgUrl("list/youtube.png");
    case 3: //틱톡
      return GetImgUrl("list/ticktock.png");
    default:
      return GetImgUrl("list/ticktock.png");
  }
}

const getAsType = (props, item) =>
{
  if (item.st === 0)
  {
    return <div className='olasbg3_mb flexrowcc btncur' onClick={() => props.handlePrepareAds(item)}><label className='font12w btncur'>시작대기</label></div>
  }
  else if (item.st === 1)
  {
    return <div className='olasbg2_mb flexrowcc btncur' onClick={() => props.handleCancel(item)}><label className='font12w btncur'>주문취소</label></div>
  }
  else if (item.st === 2)
  {
    if (item.ast === 0 && isPostTypeAds(item.adt))
      return <div className='olasbg1_mb flexrowcc btncur' onClick={() => props.handleAs(0, item)}><label className='font12w btncur'>AS문의</label></div>
    else if (item.ast === 1)
      return <div className='olasbg1_mb flexrowcc btncur' onClick={() => props.handleAs(1, item)}><label className='font12w btncur'>AS접수</label></div>
    else if (item.ast === 2)
      return <div className='olasbg4_mb flexrowcc btncur' onClick={() => props.handleAs(2, item)}><label className='font12w btncur'>문의완료</label></div>
  }

  return null;
}

const getTotalOrderCount = (item) =>
{
  if (item.tcnt > 0)
    return GetFormattedNum(item.tcnt * item.cnt);

  return GetFormattedNum(item.cnt);
}

const getRemainedCount = (item) =>
{
  const total = getTotalOrderCount(item);

  let v = total - item.tcc;

  if (v < 0)
    return 0;

  return v;
}

const getRemainedCountText = (rc, st) =>
{
  if (st === 2)
    return "완료";
  else if (st === 3 || st === 4)
    return `${GetFormattedNum(rc)} 부분완료`;
  else
    return GetFormattedNum(rc);
}

const OrderListItem = (props) =>
{
  const remainedCount = getRemainedCount(props.item);
  const done = props.item.st === 2 || props.item.st === 3 || props.item.st === 4;

  return (
    <tr className="ntctbrow">
      <td className="odltbrowitem">
        <div className="ntctbrowdiv rowcc">
          <label className="font16 wt400 color31">{getRemainedCountText(remainedCount, props.item.st)}</label>
        </div>
      </td>

      <td className="odltbrowitem2">
        <div className="ntctbrowdiv rowcc">
          <label className="font16 wt400 color31">{props.item.idx}</label>
        </div>
      </td>

      <td className="odltbrowitem2">
        <div className="ntctbrowdiv rowcc">
          <label className="font16 wt400 color31">{GetDataDateAndTime(props.item.rd)}</label>
        </div>
      </td>

      <td className="odltbrowitem2">
        <div className="ntctbrowdiv rowcc">
          <label className="font16 wt400 color31">{getAdsPlatformName(props.item.pf)}</label>
        </div>
      </td>

      {/*<td className="odltbrowitem2">
        <div className="ntctbrowdiv rowcc">
          <label className="font16 wt400 color31">{AdsTypeToText(props.item.adt)}</label>
        </div>
      </td>*/}

      <td className="odltbrowitem2 btncur">
        <div className="ntctbrowdiv rowcc">
          <label className="font16 wt400 color31 btncur">{getShortedPathName(props.item.lk, 45)}</label>
        </div>
      </td>

      <td className="odltbrowitem2">
        <div className="ntctbrowdiv rowcc">
          <label className="font16 wt400 color31">{getTotalOrderCount(props.item)}</label>
        </div>
      </td>

      <td className="odltbrowitem2">
        <div className="ntctbrowdiv rowcc">
          <label className="font16 wt400 color31">{AdsGenderInfoToText(props.item.gen)}</label>
        </div>
      </td>

      <td className="odltbrowitem2">
        <div className="ntctbrowdiv rowcc">
          <label className="font16 wt400 color31">{AdsAgeInfoToText(props.item.age)}</label>
        </div>
      </td>

      {/*<td className="odltbrowitem2 btncur">
        <div className="ntctbrowdiv rowcc">
          <label className="font16 wt400 color31">{AdsRepeatInfoToText(props.item.tcnt, props.item.cnt, props.item.dy)}</label>
        </div>
      </td>*/}

      <td className="odltbrowitem2">
        <div className="ntctbrowdiv rowcc">
          <label className="font16 wt400 color31">{GetFormattedNum(props.item.tp)}</label>
        </div>
      </td>

      <td className="odltbrowitem3">
        <div className="ntctbrowdiv rowcc">
          {getAsType(props, props.item)}
        </div>
      </td>
    </tr>
  );
}

export default OrderListItem;