import './Bingo.css';
import '../../App.css';
import { GetImgUrl } from '../../URL/AppUrl';

const BingoDescGBanner = (props) =>
{
  return (
    <div className='wd100p coltl mgtop10'>
      <label className='font15 wt400 color31 lht23'>빙고팡팡 게임 내 노출되어 있으며, 게임이 진행되는 동안 광고 배너 이미지가 노출되어 광고주의 브랜드를 반복적으로 노출할 수 있습니다.<br/>머니팡애즈에 광고 배너 이미지를 제작해드리기에 필요한 이미지와 텍스트를 첨부해주시면 제작을 도와드리겠습니다.<br/><br/><span className='wt600 color20'>빙고팡팡 게임 배너 제작 가이드</span><br/></label>
      
      <li className='font15 wt400 color31 lht23'>게임 배너 종류는 두 가지입니다. 이미지 유형 또는 텍스트 유형 중 하나를 선택해 주세요.</li>
      <li className='font15 wt400 color31 lht23'>양식 작성은 임의의 텍스트 파일에 메인 문구와 서브 문구를 구별하여 작성한 후 첨부해 주세요.<br/>이미지 유형으로 선택한 경우, 양식과 이미지를 압축하여 첨부해 주시기 바랍니다.</li>

      <label className='font15 wt400 color31 lht23'><br/>
        <span className='wt600'>이미지형 배너</span><br/>
        ① 메인 문구 : 띄어쓰기 포함 14자<br/>
        ② 서브 문구 : 띄어쓰기 포함 19자<br/>
        ③ 서브 문구 : 띄어쓰기 포함 19자<br/>
        ④ 이미지 : 300*150px 크기의 JPEG 또는 PNG 이미지 1개<br/>
      </label>

      <label className='font15 wt400 color31 lht23'><br/>
        <span className='wt600'>텍스트형 배너</span><br/>
        ② 메인 문구 : 띄어쓰기 포함 21자<br/>
        ③ 서브 문구 : 띄어쓰기 포함 28자<br/>
      </label>

      <div className='wd100p rowcc mgtop20'>
        <img src={GetImgUrl("bingo/stp24n.png")} alt="설명" className='bgst2img5'/>
      </div>
    </div>
  );
}

export default BingoDescGBanner;