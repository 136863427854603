import './Order.css';
import '../../App.css';
import { getAdsDescImage, getDesc, getDescTitle } from './OrderDescUtil';


const OrderLeft = (props) =>
{
  return (
    <div className="orderleft flexcoltc mglt30" style={{height: props.height}}>
      <div className="orderleftcont1 flexcoltc">
        
        <div className='orderleftcontin flexcolcl'>
          <label className="font14 wt500 color31 margintop10">{getDescTitle(props.platform, props.adsType)}</label>
          {getDesc(props.platform, props.adsType)}

          <label className="font14 wt500 color31 margintop15">주의사항</label>

          <label className="font14b_2 margintop5 odleftlh">· 비공개 계정은 작업이 불가능합니다.<br/>· 진행 중인 주문 건 추가 주문 시 진행 중인 주문 건이 진행 완료된 후 주문해 주세요.<br/>· 작업 중 아이디 변경 및 비공개 계정 (게시물) 전환 시 작업이 불가능합니다.<br/>· URL을 정확히 확인 후 입력해 주세요. 잘못 입력하거나 실수로 인한 주문은 취소 및 환불이 불가능합니다. </label>
        </div>
      </div>

      <div className="orderleftcont2 rowcc margintop20">
        <img src={getAdsDescImage(props.platform, props.adsType)} alt="설명" className='oddescimg'/>
      </div>
    </div>
  );
}

export default OrderLeft;