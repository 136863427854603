import React, { Fragment } from 'react';
import './Bingo.css';
import '../../App.css';
import { LOG_ENABLE, getBingoImageUrl, gotoFirstPage, gotoTalkPage, handleAppNavi, mobileOn, useBackListener, withNavigation } from '../../URL/AppUrl';
import {  BPM_TRANS, DownloadImageBlob, getBingoDefaultPrice, getFilExtension, GetNextDateFormat2, getValidString, IsValidS, IsValidV, SaveMultiFileToZip, UploadFile, } from '../../Util/Util';
import Loading from '../Loading/Loading';
import TopBar from '../Top/TopBar';
import FooterBar from '../Footer/Footer';
import { LoginStoreInstance } from '../../Stores/LoginStore';
import Term from '../Term/Term';
import Privacy from '../Term/Privacy';
import Talk from '../Talk/Talk';
import NaviBar from '../Navi/Navi';
import { AdsStoreInstance } from '../../Stores/AdsStore';
import { resetAllUserData } from '../../Stores/StoreManager';
import Login from '../Login/Login';
import FindId from '../Login/FindId';
import FindPw from '../Login/FindPw';
import { observer } from 'mobx-react';
import BingoItem from './BingoItem';
import Paging from '../Paging/Paging';
import BingoOrder1 from './BingoOrder1';
import BingoOrder2 from './BingoOrder2';
import BingoStep1 from './BingoStep1';
import BingoStep2 from './BingoStep2';
import BingoStep3 from './BingoStep3';
import BingoReorder from './BingoReorder';
import BingoBankInfo from './BingoBankInfo';
import { BingoStoreInstance } from '../../Stores/BingoStore';
import BingoOrderResult from './BingoOrderResult';
import { saveAs } from 'file-saver';
import { printBingoReport } from './BingoReportUtil';
import { printBingoOrder } from './BingoUtil';
import BingoPlay from './BingoPlay';
import BingoOrder0 from './BingoOrder0';


//빙고팡팡주문내역
class Bingo extends React.Component
{
  state = {
    loading: false,
    showTerm: false,
    showPrivacy: false,
    showLogin: false,
    showFindId: false,
    showFindPw: false,

    //사이징처리
    naviWidth: 0,
    contWidth: 0,

    height: 0,

    //빙고팡팡
    step: 0, //목록(0), 주문1단계(1), 주문2단계(2),상세1단계(3), 상세2단계(4), 상세3단계(5)
    showReorder: false, //재주문 팝업

    //주문내역
    orderList: null,
    startPage: 1, //현재의 첫페이지
    endPage: 1, //현재의 끝페이지
    lastPage: 1, //마지막 페이지번호
    pageCount: 0, //전체 페이지의 개수
    currentPage: 1, //현재 선택된 페이지

    //검색
    searchWord: '',
    searchMode: false,

    stepInfo1: null,//상세 1단계정보
    stepInfo2: null,//상세 2단계정보

    showBannkInfo: false, //무통장입금 은행 정보 출력

    showPurchaseResult: false, //계좌이체 주문 결과 팝업

    showBingoPlay: false, //빙고게임 샘플게임
  };

  totalItemCount = 0;
  lastOrderIdx = -1;
  lastPurchaseAmount= 0; //마지막 주문의 총 결제 금액
  tempKey = 0;
  newOrderInfo = null; //신규 주문 정보
  stepInfo1 = null; //상세 1단계정보
  stepInfo2 = null; //상세 2단계정보

  editItem = null; //수정중인 상품 정보
  
  popupIntervald = -1; //결제팝업체크용
  currentPopup = null; //결제팝업
  needOrderEdit = false; //마지막으로 주문한 주문의 편집이 필요하다.

  showTerm = (v) =>
  {
    this.setState((prevState) => ({...prevState, showTerm: v}));
  }

  showPrivacy = (v) =>
  {
    this.setState((prevState) => ({...prevState, showPrivacy: v}));
  }

  setBingoStep = (v) =>
  {
    this.setState((prevState) => ({...prevState, step: v}));
  }

  setShowReorder = (v) =>
  {
    this.setState((prevState) => ({...prevState, showReorder: v}));
  }

  setStepInfo1 = (v) =>
  {
    this.setState((prevState) => ({...prevState, stepInfo1: v}));
  }

  setStepInfo2 = (v) =>
  {
    this.setState((prevState) => ({...prevState, stepInfo2: v}));
  }

  setCurrentPage = (v) =>
  {
    this.setState((prevState) => ({...prevState, currentPage: v}));
  }

  setPageInfo = (start, end, lastPage, resetCurrentPage) =>
  {
    if (resetCurrentPage)
      this.setState((prevState) => ({...prevState, startPage: start, endPage: end, lastPage: lastPage, currentPage: start}));
    else
      this.setState((prevState) => ({...prevState, startPage: start, endPage: end, lastPage: lastPage}));
  }

  setOrderList = (v) =>
  {
    this.setState((prevState) => ({...prevState, orderList: v}));
  }

  setSearchWord = (v) =>
  {
    this.setState((prevState) => ({...prevState, searchWord: v}));
  }

  setSearchMode = (v) =>
  {
    this.setState((prevState) => ({...prevState, searchMode: v}));
  }

  setShowBankInfo = (v) =>
  {
    this.setState((prevState) => ({...prevState, showBannkInfo: v}));
  }

  setShowPurchaseResult = (v) =>
  {
    this.setState((prevState) => ({...prevState, showPurchaseResult: v}));
  }

  setShowBingoPlay = (v) =>
  {
    this.setState((prevState) => ({...prevState, showBingoPlay: v}));
  }
  //-----------------------------------------------------------------------------------------------------------------------------
  //전체 페이지 개수를 카운트
  calcPageCount = (totalCount) =>
  {
    let pageCount = Math.floor(totalCount / 10);

    if ((totalCount % 10) !== 0)
      ++pageCount;

    let endPage = 5;

    if (endPage > pageCount)
      endPage = pageCount;

    this.setState((prevState) => ({...prevState, startPage : 1, endPage : endPage, currentPage : 1, pageCount : pageCount, lastPage: pageCount}));
  }

  //페이지 선택처리
  handlePage = (pageNumber) =>
  {
    if (pageNumber === -1) //이전 페이지
    {
      let startPage = this.state.startPage - this.maxPageNum;
      let endPage = this.state.endPage - this.maxPageNum;

      if (startPage < 1)
        startPage = 1;
      
      if (endPage > this.state.pageCount)
        endPage = this.state.pageCount;

      this.setState((prevState) => ({...prevState, currentPage : startPage, startPage : startPage, endPage : endPage}));

      this.loadOrderList(startPage);
    }
    else if (pageNumber === -2) //다음 페이지
    {
      let startPage = this.state.endPage + 1;
      let endPage = startPage + this.maxPageNum;

      if (startPage > this.state.pageCount)
        startPage = this.state.pageCount;
      
      if (endPage > this.state.pageCount)
        endPage = this.state.pageCount;

      this.setState((prevState) => ({...prevState, currentPage : startPage, startPage : startPage, endPage : endPage}));
      this.loadOrderList(startPage);
    }
    else //특정 페이지 선택
    {
      this.setCurrentPage(pageNumber);
      this.loadOrderList(pageNumber);
    }
  }
  //-----------------------------------------------------------------------------------------------------------------------------
  showLogin = (v) =>
  {
    this.setState((prevState) => ({...prevState, showLogin: v}));
  }

  showFindId = (v) =>
  {
    this.setState((prevState) => ({...prevState, showFindId: v}));
  }

  showFindPw = (v) =>
  {
    this.setState((prevState) => ({...prevState, showFindPw: v}));
  }

  handleLoginEvent = (eventCode) =>
  {
    if (this.state.loading)
      return;

    switch(eventCode)
    {
      case 0: //닫기
        this.showLogin(false);
        return;
      case 1: //회원가입
        this.handleRegister();
        return;
      case 2: //id찾기
        this.showLogin(false);
        this.showFindId(true);
        return;
      case 3: //비번찾기
        this.showLogin(false);
        this.showFindPw(true);
        return;
      case 100: //로그인 성공
        this.showLogin(false);
        this.loadOrderList(1);
        return;
    }
  }

  handleRegister = () =>
  {
    if (this.state.loading)
      return;

    this.showLogin(false);
    this.showFindId(false);
    this.showFindPw(false);
    this.handleNavi(5);
  }

  handleFindPw = () =>
  {
    if (this.state.loading)
      return;

    this.showLogin(false);
    this.showFindId(false);
    this.showFindPw(true);
  }

  handleFindIdLogin = () =>
  {
    if (this.state.loading)
      return;

    this.showLogin(true);
    this.showFindId(false);
    this.showFindPw(false);
  }
  //-----------------------------------------------------------------------------------------------------------------------------

  handleNavi = (naviIndex) =>
  {
    if (this.state.loading)
      return;

    if (LOG_ENABLE)
      console.log(`handleNavi : ${naviIndex}`);
    
    handleAppNavi(this.props.navigate, naviIndex);
  }

  handleSnsOrder = (orderType) =>
  {
    if (this.state.loading)
      return;

    if (LOG_ENABLE)
      console.log(`handleSnsOrder : ${orderType}`);

    AdsStoreInstance.setOrderType(orderType);

    handleAppNavi(this.props.navigate, 0);
  }

  handleMenu = (menuIndex) =>
  {
    if (this.state.loading)
      return;

    switch(menuIndex)
    {
      case 0: //로그인
        this.showLogin(true);
        return;
      case 6: //이용약관
        this.showTerm(true);
        return;
      case 7: //개인정보 처리방침
        this.showPrivacy(true);
        return;
      case 100: //로그아웃
        if (!window.confirm('로그아웃 할까요?'))
          return;

        this.processLogOut();
        return;
    }
  }

  processLogOut = () =>
  {
    resetAllUserData();
    gotoFirstPage();
  }

  //사이징----------------------------------------------------------------------------------------------
  setSize = (navi, cont, height) =>
  {
    this.setState((prevState) => ({...prevState, naviWidth : navi, contWidth : cont, height : height}));
  }

  resetContsize = () =>
  {
    //네비게이션 영역은 전체의 16%정도
    let naviWidth = 0.16 * window.innerWidth;

    if (naviWidth < 300) //최소크기300
      naviWidth = 300;

    //콘텐츠영역크기 계산
    let contWidth = window.innerWidth - naviWidth;

    if (contWidth < 1220)
      contWidth = 1220;

    this.setSize(naviWidth, contWidth, window.innerHeight - 70)
  }

  onResize = () => {
    this.resetContsize();
  }
  //사이징----------------------------------------------------------------------------------------------
  
  //뒤로가기버튼처리----------------------------------------------------------------------------------------------
  processBackButton = () =>
  {
    if (LOG_ENABLE)
      console.log(`back btn url : ${ window.location.href}`);

    /*if (this.state.step === 4)
    {
      this.generateStepInfo(0);
      this.setBingoStep(3);
    }
    else if (this.state.step === 5)
    {
      this.generateStepInfo(1);
      this.setBingoStep(4);
    }*/

    window.history.pushState(null, '', window.location.href);

    /*setTimeout(() => {
      if (LOG_ENABLE)
        console.log(`push state./......`);

      window.history.pushState(null, '', window.location.href);
    }, 10);*/
  }

  addBackButtonListener = () =>
  {
    window.history.pushState(null, '', window.location.href);
    window.addEventListener('popstate', this.processBackButton);
  }

  removeBackButtonListener = () =>
  {
    window.removeEventListener('popstate', this.processBackButton);
  }
  //뒤로가기버튼처리----------------------------------------------------------------------------------------------
  componentDidMount() {
    this.resetContsize();

    window.addEventListener("resize", this.onResize);

    //로그인되어 있지 않은경우, 혹시 새로고침했을수도 있다.
    if (!LoginStoreInstance.loggedIn)
    {
      if (LoginStoreInstance.loadToken()) //인증토큰 정보가 존재한다면
      {
        this.setLoading(true);
        LoginStoreInstance.loadProfile(this.onProfileLoadedForRefresh);
      }
    }
    else
    {
      if (!LoginStoreInstance.isProfileReady) //프로필이 준비되지 않았다면
      {
        this.setLoading(true);
        LoginStoreInstance.loadProfile(this.onProfileLoaded);
      }
      else
      {
        //마지막 포인트 조회 시점부터 현재까지 경과 시간이 1분이상 경과한경우
        if (LoginStoreInstance.getPassedPointTime() > 60)
        {
          LoginStoreInstance.catchPointRefreshTime();
          LoginStoreInstance.refreshUserPoint(null);
        }

        this.loadOrderList(1);
      }
    }

    //테스트
    //this.setOrderList(generateBingoTestData());
       
    this.addBackButtonListener();
  }

  componentWillUnmount = () =>
  {
    window.removeEventListener("resize", this.onResize);
    this.removeBackButtonListener();
  }

  onProfileLoadedForRefresh = (resultCode) =>
  {
    this.setLoading(false);

    if (resultCode !== 0)
    {
      if (LOG_ENABLE)
        console.log(`invalid profile`);

      LoginStoreInstance.removeToken();

      return;
    }

    this.loadOrderList(1);
  }

  onProfileLoaded = (resultCode) =>
  {
    this.setLoading(false);

    if (resultCode !== 0)
    {
      alert(`프로필 읽기 중 오류가 발생했습니다.\n다시 로그인해 주세요.`);
      this.processLogOut();
      return;
    }

    this.loadOrderList(1);
  }

  loadOrderList = (pageNum) =>
  {
    this.setLoading(true);
    BingoStoreInstance.loadOrders((pageNum - 1) * 10, this.onLoadOrders);
  }

  onLoadOrders = (resultCode, first, totalCount, list) =>
  {
    this.setLoading(false);

    if (resultCode !== 0)
    {
      alert(`주문 목록 조회 중 오류가 발생했습니다.\n오류코드 ${resultCode}`);
      return;
    }

    if (first === 1)
    {
      this.totalItemCount = totalCount;

      this.calcPageCount(totalCount);
    }

    this.setOrderList(list);

    //마지막 주문한 항목 편집이 필요하면
    if (this.needOrderEdit)
    {
      this.needOrderEdit = false;
      this.setLoading(true);
      BingoStoreInstance.getOrderDetail(this.lastOrderIdx, (resultCode, info) => {
        this.setLoading(false);

        if (resultCode !== 0 || !info)
        {
          alert(`주문 정보 조회에 실패 했습니다.\n페이지를 새로 고침해 주세요.`);
          return;
        }

        this.editItem = info;
        
        if (info.st === 1) //등록대기 주문이면
        {
          this.setBingoStep(3);
        }

        if (info.pm === BPM_TRANS) //계좌이체 주문이면
          this.setShowPurchaseResult(true);
      });
    }
  }

  setLoading = (v) =>
  {
    this.setState((prevState) => ({...prevState, loading : v}));
  }

  handleTalk = () =>
  {
    gotoTalkPage();
  }

  //------------------------------------------------------------------------------------------------------------------------------------------------------
  handleKeyPress = (e) =>
  {
    if (LOG_ENABLE)
      console.log("handleKeyPress");

    if (e.key === "Enter")
    {
      this.handleSearch();
    }
  }

  handleSearch = () =>
  {
    if (this.state.loading)
      return;

    if (!IsValidS(this.state.searchWord))
    {
      alert('광고명을 입력해 주세요.');
      return;
    }

    this.setLoading(true);
    BingoStoreInstance.searchOrders(this.state.searchWord, this.onSearchResult);
  }

  onSearchResult = (resultCode, list) =>
  {
    this.setLoading(false);

    if (resultCode !== 0)
    {
      alert(`자주 묻는 질문 검색 중 오류가 발생했습니다.\n오류코드 : ${resultCode}`);
      return;
    }

    this.setSearchMode(true);

    if (IsValidS(list))
      this.setOrderList([...list]);
    else
      this.setOrderList(null);
  }

  //검색 결과를 지우고 목록을 다시봄
  handleResetSearch = () =>
  {
    if (this.state.loading)
      return;

    this.setSearchMode(false);
    this.loadOrderList(1);
  }

  //새로운 빙고팡팡주문하기
  handleNewOrder = () =>
  {
    if (this.state.loading)
      return;

    if (!LoginStoreInstance.loggedIn)
    {
      this.showLogin(true);
      return;
    }

    //로그인되어 있지 않으면 로그인창을 팝업해야한다.
    this.editItem = null;
    this.newOrderInfo = null;
    this.stepInfo1 = null;
    this.stepInfo2 = null;
    this.lastOrderIdx = -1;
    this.lastPurchaseAmount = 0;
    this.needOrderEdit = false; //마지막으로 주문한 주문의 편집이 필요하다.
    this.setStepInfo1(null);
    this.setStepInfo2(null);
    this.setBingoStep(6); //새로운 빙고팡팡주문 스텝으로
  }

  isValidBingoImg = (img) =>
  {
    return IsValidS(img) && img !== "n";
  }

  isStepDataValid = (index) =>
  {
    if (index === 0)
    {
      if (!this.stepInfo1)
      {
        if (LOG_ENABLE)
          console.log('invalid data 1');

        return false;
      }

      if (this.stepInfo1.pdType < 0 || this.stepInfo1.pdType > 11)
      {
        if (LOG_ENABLE)
          console.log('invalid data 2');
        return false;
      }

      if (this.stepInfo1.left < 1 || this.stepInfo1.left > 7)
      {
        if (LOG_ENABLE)
          console.log('invalid data 3');
        return false;
      }

      if (this.stepInfo1.right < 10 || this.stepInfo1.right > 22)
      {
        if (LOG_ENABLE)
          console.log('invalid data 4');
        return false;
      }

      if (!IsValidS(this.stepInfo1.leftLink))
      {
        if (LOG_ENABLE)
          console.log('invalid data 5');
        return false;
      }

      if (!IsValidS(this.stepInfo1.rightLink))
      {
        if (LOG_ENABLE)
          console.log('invalid data 6');
        return false;
      }

      return true;
    }
    else
    {
      if (!this.stepInfo2)
      {
        if (LOG_ENABLE)
          console.log('invalid data 7');
        return false;
      }

      if (!IsValidS(this.stepInfo2.bingoName))
      {
        if (LOG_ENABLE)
          console.log('invalid data 8');
        return false;
      }

      if (this.stepInfo2.bingoDesign < 0 || this.stepInfo2.bingoDesign > 1)
      {
        if (LOG_ENABLE)
          console.log('invalid data 9');
        return false;
      }

      if (!IsValidS(this.stepInfo2.mainBannerName) && !this.stepInfo2.mainBannerFile)
      {
        if (LOG_ENABLE)
          console.log('invalid data 10');
        return false;
      }

      if (!IsValidS(this.stepInfo2.symbolName) && !this.stepInfo2.symbolFile)
      {
        if (LOG_ENABLE)
          console.log('invalid data 11');
        return false;
      }

      if (!IsValidS(this.stepInfo2.bannerName) && !this.stepInfo2.bannerFile)
      {
        if (LOG_ENABLE)
          console.log('invalid data 12');
        return false;
      }

      if (this.editItem && this.editItem.gt === 1) //쇼츠형 광고인데 동영상없으면
      {
        if (!IsValidS(this.stepInfo2.videoName) && !this.stepInfo2.videoFile)
        {
          if (LOG_ENABLE)
            console.log('invalid data 13');
          return false;
        }
      }
      
      return true;
    }
  }
  //상세 정보 수정을 위해서, 스텝정보를 다시 설정함
  generateStepInfo = (step) =>
  {
    if (step === 0) //상세 1단계
    {
      if (!this.stepInfo1) //수정중인 상세정보가 없으면
      {
        if (this.editItem)
        {
          this.stepInfo1 = {
            saved: true,
            pdType: this.editItem.pt,
            left: this.editItem.lbt,
            right: this.editItem.rbt,
            leftLink: this.editItem.llk,
            rightLink: this.editItem.rlk,
          };

          this.setStepInfo1(this.stepInfo1);
        }
      }
    }
    else if (step === 1) //상세 2단계
    {
      if (!this.stepInfo2) //수정중인 상세정보가 없으면
      {
        if (this.editItem)
        {
          this.stepInfo2 = {
            saved: true,
            bingoName: this.editItem.gti, //게임타이틀
            bingoDesign: this.editItem.dn, //빙고디자인번호

            mainBannerFile: null, //로비배너파일
            symbolFile: null, //심볼첨부파일
            bannerFile: null, //게임배너파일
            videoFile: null, //첨부비디오

            //각각, 로비배너, 심볼첨부파일, 게임배너파일, 첨부비디오 파일이름
            mainBannerName: this.editItem.imgInfo && this.isValidBingoImg(this.editItem.imgInfo.bimg) ? this.editItem.imgInfo.bimg: '',
            symbolName: this.editItem.imgInfo && this.isValidBingoImg(this.editItem.imgInfo.simg) ? this.editItem.imgInfo.simg: '',
            bannerName: this.editItem.imgInfo && this.isValidBingoImg(this.editItem.imgInfo.gad) ? this.editItem.imgInfo.gad: '', //게임배너 참조자료
            videoName: this.editItem.imgInfo && this.isValidBingoImg(this.editItem.imgInfo.vdf) ? this.editItem.imgInfo.vdf: '',

            //경품 제공 여부와 개수
            gift: this.editItem.gfc > 0 ? 1 : 0,
            giftCount: this.editItem.gfc.toString(),

            //동영상 제작요청여부
            vos: this.editItem.imgInfo && this.editItem.imgInfo.vos ? this.editItem.imgInfo.vos : 0,

            //키워드 1~3, 요청사항
            kw1: this.editItem.kw1,
            kw2: this.editItem.kw2,
            kw3: this.editItem.kw3,
            dc: this.editItem.dc,
          };

          this.setStepInfo2(this.stepInfo2);
        }
      }
    }
  }

  findOrder = (idx) =>
  {
    if (!IsValidS(this.state.orderList))
      return null;

    for (let i = 0;i < this.state.orderList.length; ++i)
    {
      if (this.state.orderList[i].idx === idx)
        return this.state.orderList[i];
    }

    return null;
  }

  findOrder2 = (idx, list) =>
  {
    if (!IsValidS(list))
      return null;

    for (let i = 0;i < list.length; ++i)
    {
      if (list[i].idx === idx)
        return list[i];
    }

    return null;
  }

  //빙고상세 1단계 저장이 완료됨, 대상 주문에 대해서 관련 수정 사항을 반영해줘야 한다.
  handleSaved1 = (oidx, saveData) =>
  {
    const orderInfo = this.findOrder(oidx);

    if (orderInfo)
    {
      orderInfo.pt = saveData.pt;
      orderInfo.lbt = saveData.lbt;
      orderInfo.rbt = saveData.rbt;
      orderInfo.llk = saveData.llk;
      orderInfo.rlk = saveData.rlk;

      this.setOrderList([...this.state.orderList]);
    }
  }

  //빙고상세 2단계 저장이 완료됨, 대상 주문에 대해서 관련 수정 사항을 반영해줘야 한다.
  handleSaved2 = (oidx, saveData) =>
  {
    const orderInfo = this.findOrder(oidx);

    if (orderInfo)
    {
      orderInfo.gti = saveData.bn;
      orderInfo.dn = saveData.dn;
      orderInfo.imgInfo = {
        bimg: saveData.mb,
        simg: saveData.sb,
        gad: saveData.bb, //게임배너 참조자료
        vdf: saveData.vd,
        vos: saveData.vos,
      };

      orderInfo.gfc = saveData.gc;

      this.setOrderList([...this.state.orderList]);
    }
  }

  updateOrderStatus = (oidx, st, sst) =>
  {
    const orderInfo = this.findOrder(oidx);

    if (orderInfo)
    {
      orderInfo.st = st;
      orderInfo.sst = sst;
      this.setOrderList([...this.state.orderList]);
    }
  }

  updateOrderStatus2 = (oidx, st) =>
  {
    const orderInfo = this.findOrder(oidx);

    if (orderInfo)
    {
      orderInfo.st = st;
      this.setOrderList([...this.state.orderList]);
    }
  }

  updateOrderStatus3 = (oidx, st, sdt) =>
  {
    const orderInfo = this.findOrder(oidx);

    if (orderInfo)
    {
      orderInfo.st = st;
      orderInfo.sdt = sdt;
      this.setOrderList([...this.state.orderList]);
    }
  }

  //빙고팡팡 주문단계처리
  //mode : 결제주문1단계(0), 결제 주문2단계(1), 이전단계(2)
  handleNewOrderStep = (mode, gameInfo) =>
  {
    if (this.state.loading)
    {
      if (LOG_ENABLE)
        console.log(`handleNewOrderStep failed: loading, ${mode}`);
      return;
    }

    if (LOG_ENABLE)
      console.log(`handleNewOrderStep : ${mode}`);

    if (mode === 100) //빙고팡팡 주문 0단계처리
    {
      if (!gameInfo || (gameInfo.bingoCategory !== 0 && gameInfo.bingoCategory !== 1))
      {
        alert(`광고 유형을 선택해 주세요.`);
        return;
      }

      if (gameInfo.bingoSubCategory !== 0 && gameInfo.bingoSubCategory !== 1)
      {
        alert(`빙고 유형을 선택해 주세요.`);
        return;
      }

      this.newOrderInfo = {
        bingoCategory: gameInfo.bingoCategory, //일반형(0), 쇼츠형(1)
        bingoSubCategory: gameInfo.bingoSubCategory, //한줄빙고(0), 3줄빙고(1)
        gameType: -1, //상세 게임타입
        adsName: null,
        ageMask: 0,
        gender: 0,
        requestDate: null,
        contactName: null,
        contactPhone: null,
        contactEmail: null,
        payMode: 0,
        wName: null,
      };

      window.history.pushState(null, '', window.location.href);
      this.setBingoStep(1);
    }
    else if (mode === 0) //빙고 주문 1단계
    {
      this.newOrderInfo.gameType = gameInfo.gameType;
  
      window.history.pushState(null, '', window.location.href);
      this.setBingoStep(2);
    }
    else if (mode === 1) //빙고주문 2단계
    {
      //결제를 수행한다.
      this.newOrderInfo.adsName = gameInfo.adsName;
      this.newOrderInfo.ageMask = gameInfo.ageMask;
      this.newOrderInfo.gender = gameInfo.gender;
      this.newOrderInfo.requestDate = gameInfo.requestDate;
      this.newOrderInfo.contactName = gameInfo.contactName;
      this.newOrderInfo.contactPhone = gameInfo.contactPhone;
      this.newOrderInfo.contactEmail = gameInfo.contactEmail;
      this.newOrderInfo.payMode = gameInfo.payMode;
      this.newOrderInfo.wName = gameInfo.wName;

      //서버에 주문 등록하고 결제 루틴을 진행해야함
      this.lastOrderIdx = -1;
      this.lastPurchaseAmount = getBingoDefaultPrice(this.newOrderInfo.gameType); //마지막 주문의 총 결제 금액
      this.lastPurchaseAmount += Math.floor(this.lastPurchaseAmount * 0.1);

      this.setLoading(true);
      BingoStoreInstance.addNewOrder(this.newOrderInfo.gameType, this.newOrderInfo.adsName, this.newOrderInfo.ageMask, this.newOrderInfo.gender,
        this.newOrderInfo.requestDate, this.newOrderInfo.contactName, this.newOrderInfo.contactPhone, this.newOrderInfo.contactEmail, 
        this.newOrderInfo.payMode,  this.newOrderInfo.payMode === 2 ? this.newOrderInfo.wName : "n", this.onPurchaseStep1);
    }
    else if (mode === 2) //빙고주문 2단계에서 이전단계로
    {
      window.history.pushState(null, '', window.location.href);
      this.setBingoStep(1);
    }
    else if (mode === 3) //상세1단계 다음처리
    {
      this.stepInfo1 = {
        saved: gameInfo.saved, //변경사항저장여부
        pdType: gameInfo.pdt,
        left: gameInfo.lbt,
        right: gameInfo.rbt,
        leftLink: gameInfo.llk,
        rightLink: gameInfo.rlk,
      };

      this.setStepInfo1(this.stepInfo1);

      window.history.pushState(null, '', window.location.href);
      this.setBingoStep(4);
    }
    else if (mode === 4) //상세2단계 다음처리
    {
      this.stepInfo2 = {
        saved: gameInfo.saved, //변경사항저장여부
        bingoName: gameInfo.bingoName,
        bingoDesign: gameInfo.bingoDesign,
        
        mainBannerFile: gameInfo.mainBannerFile,
        mainBannerName: gameInfo.mainBannerName,

        symbolFile: gameInfo.symbolFile,
        symbolName: gameInfo.symbolName,
        
        bannerFile: gameInfo.bannerFile, //게임배너 참조자료
        bannerName: gameInfo.bannerName,

        videoFile: gameInfo.videoFile, //첨부비디오
        videoName: gameInfo.videoName,
        
        gift: gameInfo.gift, //경품여부
        giftCount: gameInfo.giftCount, //경품개수
        vos: gameInfo.vos, //동영상 제작요청여부

        kw1: gameInfo.kw1, //키워드1
        kw2: gameInfo.kw2, //키워드2
        kw3: gameInfo.kw3, //키워드3
        dc: gameInfo.dc, //요청사항
      };

      window.history.pushState(null, '', window.location.href);
      this.setStepInfo2(this.stepInfo2);
      this.setBingoStep(5);
    }
    else if (mode === 5) //상세 3단계 등록하기 처리
    {
      if (!this.stepInfo1)
        this.generateStepInfo(0);
  
      if (!this.stepInfo2)
        this.generateStepInfo(1);

      if (!this.isStepDataValid(0) || !this.isStepDataValid(1))
      {
        if (!window.confirm('상세 정보가 완전히 등록되지 않았습니다.\n등록시 검수 신청이됩니다.\n그래도 등록할까요?'))
          return;
      }
      else if (this.editItem && (this.editItem.sst & 0x1) === 0)
      {
        if (!window.confirm('아직 결제가 완료되지 않았습니다.\n정해진 기간까지 미입금시 주문은 자동취소 됩니다.\n그래도 등록할까요?'))
          return;
      }

      this.submitBingo();
    }
    else if (mode === 8) //상세 1단계 다시 수정
    {
      window.history.pushState(null, '', window.location.href);
      this.generateStepInfo(0);
      this.setBingoStep(3);
    }
    else if (mode === 9) //상세 2단계 다시 수정
    {
      window.history.pushState(null, '', window.location.href);
      this.generateStepInfo(1);
      this.setBingoStep(4);
    }
    else if (mode === 40) //상세2단계 저장후 이전으로 이동
    {
      if (gameInfo)
      {
        this.stepInfo2 = {
          saved: gameInfo.saved, //변경사항저장여부
          bingoName: gameInfo.bingoName,
          bingoDesign: gameInfo.bingoDesign,
          
          mainBannerFile: gameInfo.mainBannerFile, //빙고로비 배너
          mainBannerName: gameInfo.mainBannerName,
  
          symbolFile: gameInfo.symbolFile, //심볼 제작 소재 파일
          symbolName: gameInfo.symbolName,
          
          bannerFile: gameInfo.bannerFile, //게임배너 참조자료
          bannerName: gameInfo.bannerName,
  
          videoFile: gameInfo.videoFile, //첨부 비디오
          videoName: gameInfo.videoName,
          
          gift: gameInfo.gift, //경품지급여부, 0 또는 1
          giftCount: gameInfo.giftCount, //경품지급개수
          vos: gameInfo.vos, //영상제작요청여부, 0 또는 1

          kw1: gameInfo.kw1, //키워드1
          kw2: gameInfo.kw2, //키워드2
          kw3: gameInfo.kw3, //키워드3
          dc: gameInfo.dc, //요청사항
        };

        this.setStepInfo2(this.stepInfo2);
      }

      window.history.pushState(null, '', window.location.href);
      this.generateStepInfo(0);
      this.setBingoStep(3);
    }
  }

  //재주문 수행
  handleReorder = (reodItem, purchaseCount, payMode, wName, requestDate) =>
  {
    if (this.state.loading)
      return;

    this.setShowReorder(false);

    this.setLoading(true);
    BingoStoreInstance.addReOrder(reodItem.idx, payMode, IsValidS(wName) ? wName : "n", requestDate, purchaseCount, this.onPurchaseStep1);
  }

  //주문 결제 결과 수신
  onPurchaseStep1 = (resultCode, kicc, orderIdx) =>
  {
    switch(resultCode)
    {
      case 0:
      case 5:
        break;
      case 1: //잘못된 요청
        alert(`잘못된 요청입니다.`);
        break;
      case 2: //이미 구매한 게임
      case 6: //1회만 구매가능
        alert(`해당 광고는 한번 이상 구매가 불가능합니다.`);
        break;
      case 3: //점검중
        alert(`시스템 점검으로 인해 주문을 진행할 수 없습니다.`);
        break;
      case 7: //재집행불가
        alert(`재집행이 불가능한 주문입니다.`);
        break;
      case 8: //주문이 존재하지 않음
        alert(`대상 주문이 존재하지 않습니다.`);
        break;
      default:
        alert(`주문 등록에 실패 했습니다.\n오류코드 ${resultCode}`);
        break;
    }

    if (resultCode !== 0 && resultCode !== 5)
    {
      this.setLoading(false);
      return;
    }

    this.lastOrderIdx = orderIdx;

    if (resultCode === 5) //계좌이체 주문의 등록이 완료되었음을 의미한다.
    {
      this.needOrderEdit = true; //마지막으로 주문한 주문의 편집이 필요하다.
      this.loadOrderList(1);
    }
    else
    {
      //팝업띄우기
      if (mobileOn)
        this.currentPopup = window.open(kicc, "리워드 광고 주문", "width = 100%, height = 100%, top = 0, left = 0, location = no");
      else
        this.currentPopup = window.open(kicc, "리워드 광고 주문", "width = 500, height = 700, top = 50, left = 50, location = no");

      if (this.popupIntervald === -1)
      {
        this.popupIntervald = setInterval(this.checkPopupClosed, 300);
      }
    }
  }

  //결제 팝업이 닫혔는지 확인하기
  checkPopupClosed = () =>
  {
    if (this.currentPopup && this.currentPopup.closed)
    {
      if (LOG_ENABLE)
        console.log(`popup closed`);

      this.setLoading(false);
      this.currentPopup = null;
      clearInterval(this.popupIntervald);
      this.popupIntervald = -1;

      this.handleAuthPopupClose();
    }
  }

  //결제창이 닫힘
  handleAuthPopupClose = () =>
  {
    if (this.lastOrderIdx > 0) //마지막 주문 번호가 있으면
    {
      this.setLoading(true);
      this.refreshLastOrder();
    }
  }

  refreshLastOrder = () =>
  {
    BingoStoreInstance.getOrderStatus(this.lastOrderIdx, this.onRefreshLastOrder);
  }

  onRefreshLastOrder = (resultCode, info) => {

    if (LOG_ENABLE)
      console.log(`onRefreshLastOrder : ${resultCode}/${JSON.stringify(info)}`);

    if (resultCode !== 0)
    {
      if (!window.confirm("주문 정보를 갱신 할 수 없습니다. 다시 시도할까요?"))
      {
        if (LOG_ENABLE)
          console.log(`order refresh failed`);

        this.setLoading(true);
        return;
      }

      this.refreshLastOrder();
    }
    else
    {
      if (!info || info.st === 8) //상태 정보가 없거나 취소상태면
      {
        if (LOG_ENABLE)
          console.log(`order info not found`);

        this.setLoading(false);
        this.setBingoStep(0);
        return;
      }

      if (info.st > 0) //주문의 상태가 임시등록상태가 아니면 결제 루틴이 완료되었다는 의미다, 현재 페이지를 다시 로딩한다.
      {
        if (LOG_ENABLE)
          console.log(`refresh order list`);

        this.needOrderEdit = true; //마지막으로 주문한 주문의 편집이 필요하다.
        this.loadOrderList(1);
      }
      else
      {
        this.setLoading(false);
        this.setBingoStep(0);
      }
    }
  }

  saveStep2 = null;

  //수정된 빙고팡팡 데이터를 서버에 전송하고 검수를 요청한다
  submitBingo = () =>
  {
    if (!this.stepInfo1.saved) //1단계정보 저장이 완료되지 않았다면
    {
      if (LOG_ENABLE)
        console.log(`save step1`);

      //1단계정보 저장을 시도한다.
      this.setLoading(true);

      const saveData = {
        pd: this.stepInfo1.pdType,
        lbt: this.stepInfo1.left,
        rbt: this.stepInfo1.right,
        llk: this.stepInfo1.leftLink,
        rlk: this.stepInfo1.rightLink
      };

      if (!IsValidS(saveData.llk))
        saveData.llk = "n";

      if (!IsValidS(saveData.rlk))
        saveData.rlk = "n";

      if (LOG_ENABLE)
        console.log(`edit step1 : ${JSON.stringify(saveData)}`);

      BingoStoreInstance.editStep1(this.editItem.idx, saveData.pd, saveData.lbt, saveData.rbt, 
        saveData.llk, saveData.rlk, (resultCode) => {
          if (resultCode !== 0)
          {
            this.setLoading(false);
            alert(`상세 정보 저장에 실패하여 등록되지 않았습니다.`);
            return;
          }

          this.stepInfo1.saved = true;
          this.handleSaved1(this.editItem.idx, saveData);
          this.submitBingo();
        }
      );
      return;
    }

    if (!this.stepInfo2.saved) //2단계정보 저장이 완료되지 않았다면
    {
      //2단계정보 저장을 시도한다.
      this.saveStep2 = {
        bn: getValidString(this.stepInfo2.bingoName, "n"),
        dn: this.stepInfo2.bingoDesign,

        mb: getValidString(this.stepInfo2.mainBannerName, "n"),
        sb: getValidString(this.stepInfo2.symbolName, "n"),
        bb: getValidString(this.stepInfo2.bannerName, "n"),
        vd: getValidString(this.stepInfo2.videoName, "n"),

        gc: this.stepInfo2.giftCount,

        mf: this.stepInfo2.mainBannerFile,
        sf: this.stepInfo2.symbolFile,
        bf: this.stepInfo2.bannerFile,
        vf: this.stepInfo2.videoFile,
        vos: this.stepInfo2.vos,

        kw1: getValidString(this.stepInfo2.kw1, "n"),
        kw2: getValidString(this.stepInfo2.kw2, "n"),
        kw3: getValidString(this.stepInfo2.kw3, "n"),

        dc: getValidString(this.stepInfo2.dc, "n"),
      };

     
      if (typeof this.saveStep2.gc === "string" && !IsValidS(this.saveStep2.gc))
        this.saveStep2.gc = "0";

      if (LOG_ENABLE)
        console.log(`edit step2-1 : ${JSON.stringify(this.saveStep2)}`);

      this.setLoading(true);
      this.uploadStep2();

      return;
    }

    if (LOG_ENABLE)
      console.log(`submit bingo`);

    //최종적으로 검수요청을 한다.
    this.setLoading(true);

    BingoStoreInstance.registerOrder(this.editItem.idx, (resultCode, result) => {
      this.setLoading(false);

      if (resultCode !== 0)
      {
        alert(`등록 요청이 실패 했습니다.\n오류코드 ${resultCode}`);
        return;
      }

      alert(`주문이 정상 등록 되었습니다.`);
      this.updateOrderStatus(this.editItem.idx, result.st, result.sst);
      this.setBingoStep(0);
    });
  }

  uploadStep2 = () =>
  {
    if (IsValidV(this.saveStep2.mf))
    {
      if (LOG_ENABLE)
        console.log(`upload main banner`);

      UploadFile(11, this.saveStep2.mf, (success, oldName, newName) => {
        if (!success)
        {
          this.setLoading(false);
          alert(`첨부파일 업로드에 실패 했습니다.`);
          return;
        }

        this.saveStep2.mf = null;
        this.saveStep2.mb = newName;

        this.uploadStep2();
      });
      return;
    }

    if (IsValidV(this.saveStep2.sf))
    {
      if (LOG_ENABLE)
        console.log(`upload symbol`);

      UploadFile(11, this.saveStep2.sf, (success, oldName, newName) => {
        if (!success)
        {
          this.setLoading(false);
          alert(`첨부파일 업로드에 실패 했습니다.`);
          return;
        }

        this.saveStep2.sf = null;
        this.saveStep2.sb = newName;

        this.uploadStep2();
      });
      return;
    }

    if (IsValidV(this.saveStep2.bf))
    {
      if (LOG_ENABLE)
        console.log(`upload banner`);

      UploadFile(11, this.saveStep2.bf, (success, oldName, newName) => {
        if (!success)
        {
          this.setLoading(false);
          alert(`첨부파일 업로드에 실패 했습니다.`);
          return;
        }

        this.saveStep2.bf = null;
        this.saveStep2.bb = newName;

        this.uploadStep2();
      });
      return;
    }

    if (IsValidV(this.saveStep2.vf))
    {
      if (LOG_ENABLE)
        console.log(`upload video`);

      UploadFile(11, this.saveStep2.vf, (success, oldName, newName) => {
        if (!success)
        {
          this.setLoading(false);
          alert(`첨부파일 업로드에 실패 했습니다.`);
          return;
        }

        this.saveStep2.vf = null;
        this.saveStep2.vd = newName;

        this.uploadStep2();
      });

      return;
    }

    if (LOG_ENABLE)
      console.log(`edit step2-2`);

    //모든 업로드가 끝났으면, 변경사항을 반영처리한다.
    BingoStoreInstance.editStep2(this.editItem.idx, this.saveStep2.dn, this.saveStep2.bn, this.saveStep2.mb, this.saveStep2.sb,
      this.saveStep2.bb, this.saveStep2.vd, this.saveStep2.gc, this.saveStep2.vos, 
      this.saveStep2.kw1, this.saveStep2.kw2, this.saveStep2.kw3, this.saveStep2.dc, 
      (resultCode) => {

        if (resultCode !== 0)
        {
          this.setLoading(false);
          alert(`변경사항 저정중에 오류가 발생했습니다.\n오류코드 ${resultCode}`);
          return;
        }

        this.handleSaved2(this.editItem.idx, this.saveStep2);

        this.stepInfo2.saved = true;

        this.submitBingo();
      });
  }

  //빙고팡팡 주문 내역의 액션 처리
  //mode, 상세등록(0), 미리보기(1), 승인(2), 재요청(3), 결제정보(4), 성과다운로드(5), 주문서다운로드(6), 재집행(7)
  handleBingoOrder = (mode, item) =>
  {
    if (this.state.loading)
      return;

    switch(mode)
    {
      case 0: //상세등록
        this.setLoading(true);
        BingoStoreInstance.getOrderDetail(item.idx, (resultCode, info) => {
          this.setLoading(false);

          if (resultCode !== 0)
          {
            alert(`주문 상세 정보 조회에 실패 했습니다.\n오류코드 ${resultCode}`);
            return;
          }
          this.editItem = info;
          this.stepInfo1 = null;
          this.stepInfo2 = null;
          this.generateStepInfo(0);
          this.generateStepInfo(1);
          this.setBingoStep(3);
        });
        
        return;
      case 100: //검수중 주문 수정
        this.setLoading(true);
        BingoStoreInstance.getOrderDetail(item.idx, (resultCode, info) => {
          this.setLoading(false);

          if (resultCode !== 0)
          {
            alert(`주문 상세 정보 조회에 실패 했습니다.\n오류코드 ${resultCode}`);
            return;
          }
          this.editItem = info;
          this.stepInfo1 = null;
          this.stepInfo2 = null;
          this.generateStepInfo(0);
          this.generateStepInfo(1);
          this.setBingoStep(5);
        });
        
        return;
      case 1: //미리보기
        this.setLoading(true);
        BingoStoreInstance.getOrderDetail(item.idx, (resultCode, info) => {
          this.setLoading(false);

          if (resultCode !== 0)
          {
            alert(`주문 상세 정보 조회에 실패 했습니다.\n오류코드 ${resultCode}`);
            return;
          }
          this.editItem = info;
          this.setShowBingoPlay(true);
        });
        return;
      case 2: //승인
        this.editItem = item;

        if ((item.sst & 0x1) === 0)
        {
          if (!window.confirm(`빙고를 승인처리 할까요?\n승인시 광고시작 일시에 게시 예약 되지만, 미결제 주문의 경우 게시되지 않습니다.`))
            return;
        }
        else if (!window.confirm(`빙고를 승인처리 할까요?\n승인시 희망 일시 또는 승인 다음날 10시 게시 처리됩니다.`))
          return;

        this.setLoading(true);
        BingoStoreInstance.acceptOrder(3, item.idx, this.onAcceptResult);
        return;
      case 3: //재요청
        this.editItem = item;

        if (!window.confirm(`빙고를 재작업 요청할까요?\n재작업 요청시 다시 검수 상태로 돌아갑니다.`))
          return;

        this.setLoading(true);
        BingoStoreInstance.acceptOrder(4, item.idx, this.onRejectResult);

        return;
      case 4: //결제정보, 계좌정보를 출력해야함
        this.setLoading(true);
        BingoStoreInstance.getOrderPurchaseInfo(item.idx, (resultCode, info) => {
          this.setLoading(false);

          if (resultCode !== 0)
          {
            alert(`주문 결제 정보 조회에 실패 했습니다.\n오류코드 ${resultCode}`);
            return;
          }
          this.editItem = info;
          this.setShowBankInfo(true);
        });
        return;
      case 5: //성과다운로드
        this.downloadReport(item);
        return;
      case 6: //주문서 다운로드
        this.editItem = item;
        this.downloadReceipt(item);
        return;
      case 7: //재집행
        this.editItem = item;
        this.setShowReorder(true);
        return;
      default:
        return;
    }
  }

  //빙고승인결과
  onAcceptResult = (resultCode, oidx, st, sdt) =>
  {
    this.setLoading(false);

    if (resultCode !== 0)
    {
      alert(`빙고 승인 요청에 실패 했습니다.\n오류코드 ${resultCode}`);
      return;
    }

    this.updateOrderStatus3(oidx, st, sdt);
  }

  //빙고 재작업 요청 결과
  onRejectResult = (resultCode, oidx, st) =>
  {
    this.setLoading(false);

    if (resultCode !== 0)
    {
      alert(`빙고 재작업 요청에 실패 했습니다.\n오류코드 ${resultCode}`);
      return;
    }

    this.updateOrderStatus2(oidx, st);
  }

  //성과 다운로드
  downloadReport = (item) =>
  {
    if (this.state.loading)
      return;

    if (LOG_ENABLE)
      console.log(`downloadReport`);

    this.setLoading(true);

    BingoStoreInstance.getOrderDetail(item.idx, (resultCode, orderDetail) => {
      if (resultCode !== 0 || !orderDetail)
      {
        alert(`주문 상세 정보 조회에 실패 했습니다.\n오류코드 ${resultCode}`);
        this.setLoading(false);
        return;
      }

      BingoStoreInstance.getReport(orderDetail.idx, (resultCode, report) => {
        if (resultCode !== 0)
        {
          this.setLoading(false);
          alert(`성과정보 다운로드 중 오류가 발생했습니다.`);
          return;
        }
  
        if (!report)
        {
          this.setLoading(false);
          alert(`출력할 성과 데이터가 없습니다.`);
          return;
        }
  
        printBingoReport(orderDetail, report, (blob) => {
          this.setLoading(false);
  
          if (!blob)
          {
            
            alert(`데이터 생성에 실패 했습니다.`);
            return;
          }
    
          saveAs(blob, `${orderDetail.tt}_빙고보고서.xlsx`);;
        });
      });
    });
  }

  receiptData = null;

  //주문서 다운로드
  downloadReceipt = (item) =>
  {
    if (this.state.loading)
      return;

    if (LOG_ENABLE)
      console.log(`downloadReceipt`);

    this.setLoading(true);
    BingoStoreInstance.getOrderDetail(item.idx, async (resultCode, orderDetail) => {
      if (resultCode !== 0 || !orderDetail)
      {
        alert(`주문 상세 정보 조회에 실패 했습니다.\n오류코드 ${resultCode}`);
        this.setLoading(false);
        return;
      }

      this.receiptData = [];

      if (orderDetail.imgInfo && IsValidS(orderDetail.imgInfo.bimg) && orderDetail.imgInfo.bimg !== "n")
      {
        const mainBanner = await DownloadImageBlob(getBingoImageUrl(orderDetail.imgInfo.bimg));

        if (IsValidV(mainBanner))
        {
          this.receiptData.push({name: `${orderDetail.tt}_메인배너${getFilExtension(orderDetail.imgInfo.bimg)}`, blob: mainBanner});
        }
      }
      
      if (orderDetail.imgInfo && IsValidS(orderDetail.imgInfo.simg) && orderDetail.imgInfo.simg !== "n")
      {
        const symbol = await DownloadImageBlob(getBingoImageUrl(orderDetail.imgInfo.simg));

        if (IsValidV(symbol))
        {
          this.receiptData.push({name: `${orderDetail.tt}_심볼참조${getFilExtension(orderDetail.imgInfo.simg)}`, blob: symbol});
        }
      }

      if (orderDetail.imgInfo && IsValidS(orderDetail.imgInfo.gimg) && orderDetail.imgInfo.gimg !== "n")
      {
        const banner = await DownloadImageBlob(getBingoImageUrl(orderDetail.imgInfo.gimg));

        if (IsValidV(banner))
        {
          this.receiptData.push({name: `${orderDetail.tt}_게임배너${getFilExtension(orderDetail.imgInfo.gimg)}`, blob: banner});
        }
      }

      if (orderDetail.imgInfo && IsValidS(orderDetail.imgInfo.gad) && orderDetail.imgInfo.gad !== "n")
      {
        const banner = await DownloadImageBlob(getBingoImageUrl(orderDetail.imgInfo.gad));

        if (IsValidV(banner))
        {
          this.receiptData.push({name: `${orderDetail.tt}_게임배너_첨부${getFilExtension(orderDetail.imgInfo.gad)}`, blob: banner});
        }
      }

      if (orderDetail.imgInfo && IsValidS(orderDetail.imgInfo.vdf) && orderDetail.imgInfo.vdf !== "n")
      {
        const video = await DownloadImageBlob(getBingoImageUrl(orderDetail.imgInfo.vdf));

        if (IsValidV(video))
        {
          this.receiptData.push({name: `${orderDetail.tt}_동영상${getFilExtension(orderDetail.imgInfo.vdf)}`, blob: video});
        }
      }

      printBingoOrder(orderDetail, (blob) => {

        if (!blob)
        {
          this.setLoading(false);
          alert(`주문서를 생성하지 못했습니다.`);
          return;
        }

        this.receiptData.push({name: `${orderDetail.tt}_주문서.xlsx`, blob: blob});

        SaveMultiFileToZip(this.receiptData, `${orderDetail.tt}_주문서.zip`, (success) => {
          this.setLoading(false);

          if (!success)
          {
            alert(`주문서를 저장하지 못했습니다.`);
            return;
          }
        })
      });
    });
  }

  //빙고팡팡 주문내역
  renderOrderList = () =>
  {
    let orderList = null;
    const width = this.state.contWidth - 40 - 50;

    if (IsValidS(this.state.orderList))
    {
      orderList = this.state.orderList.map(item => <BingoItem key={item.idx} item={item} handleOrder={this.handleBingoOrder} width={width} />)
    }
    return (
      <div className='bingocont colel' style={{width: this.state.contWidth, height: this.state.height}}>
        <div className='bingocont2 coltl' style={{width: width, height: this.state.height - 100}}>
          <label className='font20 wt600 color31 mgtop20'>빙고팡팡 주문내역</label>

          <div className='wd100p rowsc mgtop25'>
            <div className='rowcc'>
              <input type='text' className='bingoinp font16 wt400 color31' placeholder='광고명을 입력해 주세요.'
                value={this.state.searchWord} onChange={e => this.setSearchWord(e.target.value)}
                onKeyPress={this.handleKeyPress}
              />

              <button className='bingobtn1 font16 wt600 color20 btncur mglt10' onClick={this.handleSearch}>검색</button>
              {
                this.state.searchMode ? (<button className='bingobtn1 font16 wt600 color20 btncur mglt10' onClick={this.handleResetSearch}>목록보기</button>) : null
              }
            </div>

            <button className='bingobtn2 font16 wt600 colorw btncur mglt10' onClick={this.handleNewOrder}>빙고팡팡 주문하기</button>
          </div>
          
          <div className='wd100p coltc mgtop10'>
            <table className='bingotable'>
              <thead>
                <tr>
                  <th className="bingotbcell">
                    <label className="font15 wt500 color31">주문번호</label>
                  </th>

                  <th className="bingotbcell">
                    <label className="font15 wt500 color31">주문상태</label>
                  </th>
                  
                  <th className="bingotbcell">
                    <label className="font15 wt500 color31">광고 시작 일시</label>
                  </th>

                  <th className="bingotbcell">
                    <label className="font15 wt500 color31">광고 명</label>
                  </th>

                  <th className="bingotbcell">
                    <label className="font15 wt500 color31">경품 당첨 수</label>
                  </th>

                  <th className="bingotbcell">
                    <label className="font15 wt500 color31">빙고 플레이 수</label>
                  </th>

                  <th className="bingotbcell">
                    <label className="font15 wt500 color31">동영상 시청 수</label>
                  </th>

                  <th className="bingotbcell">
                    <label className="font15 wt500 color31">광고 성과</label>
                  </th>

                  <th className="bingotbcell">
                    <label className="font15 wt500 color31">주문서</label>
                  </th>

                  <th className="bingotbcell">
                    <label className="font15 wt500 color31">결제 수단</label>
                  </th>

                  <th className="bingotbcell">
                    <label className="font15 wt500 color31">결제 금액</label>
                  </th>

                  <th className="bingotbcell3">
                    <label className="font15 wt500 color31"></label>
                  </th>
                </tr>
              </thead>
              <tbody>
                {orderList}
              </tbody>
            </table>

            {
              this.state.pageCount > 1 && !this.state.searchMode ? (
                <Paging
                  needAfter={this.state.startPage > 1}
                  needNext={this.state.endPage < this.state.lastPage}
                  startPage={this.state.startPage}
                  endPage={this.state.endPage}
                  currentPage={this.state.currentPage}
                  pgStyle="margintop50 mgbt50"
                  handlePage={this.handlePage}
                />
              ) : (
                <div className='wd100p mgbt150'></div>
              )
            }
            
            {
              !IsValidS(orderList) ? (
                <Fragment>
                  <label className='font20 wt500 color31 mgtop150'>등록된 리워드 광고가 없어요</label>
                  <button className='bingobtn1 font16 wt600 color20 btncur mgtop10' onClick={this.handleNewOrder}>생성하기</button>
                </Fragment>
              ) : null
            }
          </div>
        </div>
        <FooterBar handleMenu={this.handleMenu}/>
      </div>
    );
  }

  renderCurrentStep = () =>
  {
    switch(this.state.step) //step: 0, //목록(0), 주문1단계(1), 주문2단계(2),상세1단계(3), 상세2단계(4), 상세3단계(5), 주문0단계(6)
    {
      case 0: //주문리스트
        return this.renderOrderList();
      case 6: //주문 0단계
        return <BingoOrder0 contWidth={this.state.contWidth} height={this.state.height}
            handleNextStep={this.handleNewOrderStep} orderInfo={this.newOrderInfo} handleMenu={this.handleMenu}
            handleClose={() => this.setBingoStep(0)}
          />
      case 1: //주문 1단계
        return <BingoOrder1 contWidth={this.state.contWidth} height={this.state.height} handleNextStep={this.handleNewOrderStep}
            orderInfo={this.newOrderInfo} handleMenu={this.handleMenu}
            handlePrevious={() => this.setBingoStep(6)}
          />
      case 2: //주문 2단계
        return <BingoOrder2 contWidth={this.state.contWidth} height={this.state.height} handleNextStep={this.handleNewOrderStep}
            handleMenu={this.handleMenu} gameType={this.newOrderInfo.gameType}
          />
      case 3: //상세 1단계
        return <BingoStep1 key={this.tempKey++} contWidth={this.state.contWidth} height={this.state.height} handleNextStep={this.handleNewOrderStep} oidx={this.editItem ? this.editItem.idx : 0} item={this.editItem} step1={this.state.stepInfo1} handleMenu={this.handleMenu} handleSaved={this.handleSaved1}/>
      case 4: //상세 2단계
        return <BingoStep2 key={this.tempKey++} contWidth={this.state.contWidth} height={this.state.height} handleNextStep={this.handleNewOrderStep} oidx={this.editItem ? this.editItem.idx : 0} item={this.editItem} step2={this.state.stepInfo2} handleMenu={this.handleMenu} handleSaved={this.handleSaved2}/>
      case 5: //상세 3단계
        return <BingoStep3 key={this.tempKey++} contWidth={this.state.contWidth} height={this.state.height} handleNextStep={this.handleNewOrderStep}
            handleMenu={this.handleMenu}
            item={this.editItem}
            step1={this.state.stepInfo1}
            step2={this.state.stepInfo2}
          />
      default:
        return null;
    }
  }

  render()
  {
    return (
      <div className='pgcont coltc'>
        {
          this.state.showLogin ? (<Login handleLoginEvent={this.handleLoginEvent}/>) : null
        }
        {
          this.state.showFindId ? (<FindId handleRegister={this.handleRegister} handleClose={() => this.showFindId(false)} handleFindPw={this.handleFindPw} handleLogin={this.handleFindIdLogin}/>) : null
        }
        {
          this.state.showFindPw ? (<FindPw handleClose={() => this.showFindPw(false)} handleRegister={this.handleRegister} />) : null
        }
        {
          this.state.showTerm ? (<Term handleClose={() => this.showTerm(false)}/>) : null
        }
        {
          this.state.showPrivacy ? (<Privacy handleClose={() => this.showPrivacy(false)}/>) : null
        }
        {
          this.state.showReorder ? (<BingoReorder handleClose={() => this.setShowReorder(false)} handleReorder={this.handleReorder} item={this.editItem}/>) : null
        }
        {
          this.state.showBannkInfo ? (<BingoBankInfo item={this.editItem} handleClose={() => this.setShowBankInfo(false)}/>) : null
        }
        {
          this.state.showPurchaseResult ? (<BingoOrderResult item={this.editItem}  handleClose={() => this.setShowPurchaseResult(false)}/>) : null
        }
        {
          this.state.showBingoPlay ? (<BingoPlay item={this.editItem} handleClose={() => this.setShowBingoPlay(false)}/>) : null
        }
        {
          this.state.loading ? (<Loading/>) : null
        }
        <TopBar handleNavi={this.handleNavi} withLine={true}/>

        <div className='pgcont2 rowlt'>
          <NaviBar width={this.state.naviWidth} height={this.state.height} handleSnsOrder={this.handleSnsOrder} handleNavi={this.handleNavi} handleMenu={this.handleMenu}/>

          {this.renderCurrentStep()}
        </div>

        <Talk handleTalk={this.handleTalk}/>
      </div>
    );
  }
}

export default withNavigation(observer(Bingo));