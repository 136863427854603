import './Talk.css';
import '../../App.css';
import { GetImgUrl, mobileOn } from '../../URL/AppUrl';

const Talk = (props) =>
{
  if (mobileOn)
  {
    return (
      <div className='talkbg_mb'>
        <img src={GetImgUrl("com/mb/kakao.png")} alt="상담" className='btncur img5050' onClick={props.handleTalk}/>
      </div>
    );
  }
  else
  {
    return (
      <div className='talkbg'>
        <img src={GetImgUrl("com/kakao.png")} alt="상담" className='btncur' onClick={props.handleTalk}/>
      </div>
    );
  }
}

export default Talk;