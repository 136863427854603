import React, { Fragment } from 'react';
import './Register.css';
import '../../App.css';
import { APP_MODE, FIRST_PAGE_INDEX, GetImgUrl, LOG_ENABLE, gotoTalkPage, handleAppNavi, mobileOn, withNavigation } from '../../URL/AppUrl';
import { GetSHA256, IsValidEmail, IsValidId, IsValidS, IsValidV, UploadFile, getShortedPathName, replaceLine } from '../../Util/Util';
import Loading from '../Loading/Loading';
import Talk from '../Talk/Talk';
import FooterBar from '../Footer/Footer';
import Term from '../Term/Term';
import Privacy from '../Term/Privacy';
import { LoginStoreInstance } from '../../Stores/LoginStore';

class Register extends React.Component
{
  state={
    loading: false,
    userId: '',
    userPw: '',
    userPw2: '',
    phone: '',
    phone2: '',
    email: '',
    userType: 0, //사업자(1), 개인(2)
    cdocFileName: '',

    errorId: '',
    errorPw: '',
    errorPw2: '',
    errorPhone: '',
    errorEmail: '',
    errorCdoc: '',
    smsSent: false, //인증번호 발송한경우 true

    showTerm: false,
    showPrivacy: false,

    checkTerm: false,
    checkPrivacy: false,
  };

  //사업자 등록증 파일
  cdocFile = null;
  phoneChecked = false; //전화번호 확인이 완료된 경우 true
  authData = null;
  authCode = null;

  componentDidMount() {
  }

  componentWillUnmount = () =>
  {
  }

  setLoading = (v) =>
  {
    this.setState((prevState) => ({...prevState, loading : v}));
  }

  showTerm = (v) =>
  {
    this.setState((prevState) => ({...prevState, showTerm: v}));
  }

  showPrivacy = (v) =>
  {
    this.setState((prevState) => ({...prevState, showPrivacy: v}));
  }

  setSmsSent = (v) =>
  {
    this.setState((prevState) => ({...prevState, smsSent: v}));
  }

  setCheckTerm = (v) =>
  {
    this.setState((prevState) => ({...prevState, checkTerm: v}));
  }

  setCheckPrivacy = (v) =>
  {
    this.setState((prevState) => ({...prevState, checkPrivacy: v}));
  }

  setUserType = (v) =>
  {
    this.setState((prevState) => ({...prevState, userType: v}));
  }

  setCdocFilename = (v) =>
  {
    this.setState((prevState) => ({...prevState, cdocFileName: v}));
  }

  handleNavi = () =>
  {
    if (this.state.loading)
      return;
    
    handleAppNavi(this.props.navigate, 100);
  }

  handleMenu = (btnIndex) =>
  {
    if (LOG_ENABLE)
      console.log(`handleMenu : ${btnIndex}`);

    switch(btnIndex)
    {
      case 6://이용약관
        this.showTerm(true);
        break;
      case 7://개인정보처리방침
        this.showPrivacy(true);
        break;
      default:
        return;
    }
  }

  handleInput = (index, v) =>
  {
    switch(index)
    {
      case 0://id
        this.setState((prevState) => ({...prevState, userId : v}));
        break;
      case 1: //pw
        this.setState((prevState) => ({...prevState, userPw : v}));
        break;
      case 2: //pw2
        this.setState((prevState) => ({...prevState, userPw2 : v}));
        break;
      case 3: //phone
        this.setState((prevState) => ({...prevState, phone : v}));
        break;
      case 4: //email
        this.setState((prevState) => ({...prevState, email : v}));
        break;
      case 5: //사업자등록증이름
        this.setState((prevState) => ({...prevState, cdocFileName : v}));
        break;
      case 6: //phone 인증코드
        this.setState((prevState) => ({...prevState, phone2 : v}));
        break;
    }
  }

  handleSendSms = () =>
  {
    if (this.state.loading)
    {
      return;
    }

    if (!IsValidS(this.state.phone))
    {
      this.setErrorMsg(3, `휴대폰 번호를 입력해 주세요.`);
      return;
    }

    this.authData = null;
    this.authCode = null;

    let temp = replaceLine(this.state.phone);

    //서버에 해당 전화 번호 인증을 요청함
    this.setLoading(true);
    LoginStoreInstance.findAccount(2, this.state.phone, null, this.onSmsResult);
  }

  onSmsResult = (resultCode, authData, authCode) =>
  {
    this.setLoading(false);

    switch(resultCode)
    {
      case 0:
        break;
      case 1:
        this.setErrorMsg(3, `잘못된 요청입니다.`);
        return;
      case 4:
        this.setErrorMsg(3, `해당 전화번호는 이미 사용중입니다. 다른번호를 입력해 주세요.`);
        return;
      case 5:
        this.setErrorMsg(3, `해당 전화번호는 당일 인증횟수를 초과 했습니다.내일 이용해 주세요.`);
        return;
      default:
        this.setErrorMsg(3, `인증번호 발송이 실패 하였습니다 : 오류코드 ${resultCode}`);
        return;
    }

    this.setSmsSent(true);
    this.authData = authData;
    this.authCode = authCode;
  }

  setErrorMsg = (index, v) =>
  {
    switch(index)
    {
      case 0://id
        this.setState((prevState) => ({...prevState, errorId : v}));
        break;
      case 1: //pw
        this.setState((prevState) => ({...prevState, errorPw : v}));
        break;
      case 2: //pw2
        this.setState((prevState) => ({...prevState, errorPw2 : v}));
        break;
      case 3: //phone
        this.setState((prevState) => ({...prevState, errorPhone : v}));
        break;
      case 4: //email
        this.setState((prevState) => ({...prevState, errorEmail : v}));
        break;
      case 5: //사업자등록증이름
        this.setState((prevState) => ({...prevState, errorCdoc : v}));
        break;
    }
  }

  clearAllError = () =>
  {
    this.setState((prevState) => ({...prevState, errorId : '', errorPw: '', errorPw2: '', errorPhone: '', errorEmail: '', errorCdoc: ''}));
  }

  registerInfo = null;

  handleRegister = () =>
  {
    if (this.state.loading)
      return;

    this.clearAllError();

    if (!IsValidS(this.state.userId))
    {
      this.setErrorMsg(0, `사용할 아이디를 입력해 주세요.`);
      return;
    }

    const id = this.state.userId.trim();

    if (!IsValidId(id))
    {
      this.setErrorMsg(0, `사용할 수 없는 아이디 입니다. 영숫자조합을 사용해 주세요.`);
      return;
    }

    if (id.length < 4 || id.length > 25)
    {
      this.setErrorMsg(0, `ID길이가 너무 짧습니다. 4 ~ 25자 범위에서 입력해 주세요.`);
      return;
    }

    if (!IsValidS(this.state.userPw))
    {
      this.setErrorMsg(1, `비밀번호를 입력해 주세요.`);
      return;
    }

    const pw = this.state.userPw.trim();

    if (pw.length < 8 || pw.length > 20)
    {
      this.setErrorMsg(1, `비밀번호는 8~20자 범위내에서 입력해 주세요.`);
      return;
    }

    if (!IsValidS(this.state.userPw2))
    {
      this.setErrorMsg(2, `비밀번호를 한번더 입력해 주세요.`);
      return;
    }

    const pw2 = this.state.userPw2.trim();

    if (pw !== pw2)
    {
      this.setErrorMsg(2, `비밀번호가 일치하지 않습니다.`);
      return;
    }

    if (!IsValidS(this.state.phone))
    {
      this.setErrorMsg(3, `휴대폰 번호를 입력해 주세요.`);
      return;
    }

    //라이브모드이고, 인증번호 발송하지 않았다면
    if (APP_MODE === 2 && !this.state.smsSent)
    {
      this.setErrorMsg(3, `휴대폰 인증 번호를 발송해 주세요.`);
      return;
    }

    let temp = replaceLine(this.state.phone);

    //라이브모드이고 인증번호 입력하지 않았다면
    if (APP_MODE === 2 && !IsValidS(this.state.phone2))
    {
      this.setErrorMsg(3, `인증번호를 입력해 주세요.`);
      return;
    }

    if (!IsValidS(this.state.email))
    {
      this.setErrorMsg(4, `이메일 주소를 입력해 주세요.`);
      return;
    }

    if (APP_MODE !== 0 && !IsValidEmail(this.state.email))
    {
      this.setErrorMsg(4, `이메일 주소가 올바르지 않습니다.`);
      return;
    }

    if (this.state.userType !== 1 && this.state.userType !== 2)
    {
      this.setErrorMsg(5, `회원 종류를 선택해 주세요.`);
      return;
    }

    if (this.state.userType === 1 && !this.cdocFile)
    {
      this.setErrorMsg(5, `사업자 등록증 이미지를 첨부해 주세요.`);
      return;
    }

    //먼저인증번호를 확인함
    //인증번호가 일치하지 않으면
    if (APP_MODE === 2 && GetSHA256(this.state.phone2) !== this.authCode)
    {
      if (APP_MODE === 0)
        console.log(`ac : ${this.authCode}, current : ${GetSHA256(this.state.phone2)}`);

      this.setErrorMsg(3, `인증번호가 올바르지 않습니다.`);

      return;
    }

    if (!this.state.checkTerm)
    {
      alert('이용약관에 동의해 주세요.');
      return;
    }

    if (!this.state.checkPrivacy)
    {
      alert('개인정보 처리방침에 동의해 주세요.');
      return;
    }

    if (!window.confirm('회원 가입을 신청할까요?'))
      return;
      
    this.registerInfo = {
      id : id,
      pw: pw,
      phone: temp,
      email: this.state.email,
      userType: this.state.userType,
      cdocFilename: '',
    };
    
    //5. 가입돈 회원데이터에 첨부된 사업자 등록증 정보를 갱신한다.

    //사용가능한 ID인지 확인한다.
    this.setLoading(true);
    LoginStoreInstance.checkUserId(id, this.onCheckIdResult);
  }

  onCheckIdResult = (resultCode) =>
  {
    switch(resultCode)
    {
      case 0: //사용가능
        break;
      case 1: //잘못된 요청
        alert(`잘못된 요청입니다.`);
        this.setLoading(false);
        return;
      case 5: //
        this.setErrorMsg(0, '이미 사용중인 ID입니다.');
        this.setLoading(false);
        return;
      case 6: //
        this.setErrorMsg(0, '사용할 수 없는 ID입니다.');
        this.setLoading(false);
        return;
    }

    if (this.registerInfo.userType === 1)
    {
       //사업자 등록증 업로드를 시도한다.
      UploadFile(0, this.cdocFile, this.onUploadDone);
    }
    else
    {
      //회원가입을 시도한다.
      LoginStoreInstance.setUserId(this.registerInfo.id);
      LoginStoreInstance.setUserPW(this.registerInfo.pw);

      LoginStoreInstance.registerUser(this.registerInfo.phone, this.registerInfo.email,
        APP_MODE ===2 ? this.authData : '0',
        APP_MODE ===2 ? this.state.phone2 : '0000', 
        this.registerInfo.userType,
        'n', //사업자등록증파일이름
        this.onRegisterResult);  
    }
  }

  onUploadDone = (success, oldFileName, newFileName) =>
  {
    if (!success)
    {
      alert(`사업자 등록증 이미지 첨부 중 오류가 발생했습니다.\n잠시 후 이용해 주세요.`);
      this.setLoading(false);
      return;
    }

    this.registerInfo.cdocFilename = newFileName;

    //회원가입을 시도한다.
    LoginStoreInstance.setUserId(this.registerInfo.id);
    LoginStoreInstance.setUserPW(this.registerInfo.pw);

    LoginStoreInstance.registerUser(this.registerInfo.phone, this.registerInfo.email, this.authData, this.state.phone2, 
      this.registerInfo.userType,
      newFileName,
      this.onRegisterResult);
  }
  
  onRegisterResult = (resultCode) =>
  {
    switch(resultCode)
    {
      case 0: //가입성공
        break;
      case 1: //잘못된요청
        this.setLoading(false);
        alert(`잘못된 요청입니다.`);
        return;
      case 2: //사용중인ID
        this.setLoading(false);
        this.setErrorMsg(0, '이미 사용중인 ID입니다.');
        return;
      case 3: //비번 비적합
        this.setLoading(false);
        this.setErrorMsg(1, '사용할 수 없는 비밀번호 입니다.');
        return;
      case 4: //ID비적합
        this.setLoading(false);
        this.setErrorMsg(1, '사용할 수 없는 ID 입니다.');
        return;
      case 6: //전화번호사용중
        this.setLoading(false);
        this.setErrorMsg(3, '해당전화번호는 이미 사용중입니다.');
        return;
      case 7: //이메일사용중
        this.setLoading(false);
        this.setErrorMsg(4, '해당이메일 주소는 이미 사용중입니다.');
        return;
      case 11: //이메일오류
        this.setLoading(false);
        this.setErrorMsg(4, '이메일 주소가 올바르지 않습니다.');
        return;
      default:
        this.setLoading(false);
        alert(`회원 가입이 실패 했습니다.\n잠시 후 이용해 주세요.`);
        return;
    }

    this.setLoading(false);
    handleAppNavi(this.props.navigate, 100);
    
    /*
    //계정에 사업자 등록증파일이름을 갱신해 준다.
    LoginStoreInstance.updateComLicense(this.registerInfo.cdocFilename, this.onUpdateCdocDone);*/
  }

  /*onUpdateCdocDone = (resultCode) =>
  {
    this.setLoading(false);
    handleAppNavi(this.props.navigate, FIRST_PAGE_INDEX); //주문내역 페이지로 이동한다.
  }*/

  handleTalk = () =>
  {
    gotoTalkPage();
  }

  renderPc = () =>
  {
    return (
      <div className='regmain coltc'>
        <Talk handleTalk={this.handleTalk}/>
        {
          this.state.showTerm ?(<Term handleClose={() => this.showTerm(false)}/>) : null
        }
        {
          this.state.showPrivacy ? (<Privacy handleClose={() => this.showPrivacy(false)}/>) : null
        }
        {
          this.state.loading ? (<Loading/>) : null
        }
        
        <div className='regtop rowcc'>

          <div className='wd50p'>
            <img src={GetImgUrl("com/toplogo.png")} alt="로고" className="mglt40 btncur menubtn" onClick={this.handleNavi}/>
          </div>

          <div className='wd50p rowlc'>

            <div className='regtoprt rowcc btncur menubtn' onClick={this.handleNavi}>
              <label className='font16 wt500 color20 marginright15 btncur' >메인페이지</label>
              <img src={GetImgUrl("register/plane.png")} alt="메인페이지"/>  
            </div>
            
          </div>
          
        </div>
        <div className='regline'></div>

        <div className='reginparea coltc'>
          <label className='font20 wt600 color31 wd91p'>머니팡애즈 회원가입을 시작합니다</label>

          <label className='font15 wt500 color31 margintop30 wd91p'>아이디</label>
          <input type="text" className='reginp font16b' maxLength={25} value={this.state.userId} placeholder='아이디를 입력해 주세요.' onChange={(e) => this.handleInput(0, e.target.value)}/>
          <label className='font14ff'>{this.state.errorId}</label>

          <label className='font15 wt500 color31 mgtop25 wd91p'>비밀번호</label>
          <input type="password" className='reginp font16b' maxLength={20} value={this.state.userPw} placeholder='8~20자 이내로 입력해 주세요.' onChange={(e) => this.handleInput(1, e.target.value)}/>
          <label className='font14ff'>{this.state.errorPw}</label>

          <label className='font15 wt500 color31 mgtop25 wd91p'>비밀번호 확인</label>
          <input type="password" className='reginp font16b' maxLength={20} value={this.state.userPw2} placeholder='비밀번호를 한번 더 입력해 주세요.' onChange={(e) => this.handleInput(2, e.target.value)}/>
          <label className='font14ff'>{this.state.errorPw2}</label>

          <label className='font15 wt500 color31 mgtop25 wd91p'>휴대폰</label>
          <div className='reginparea2 flexrowsc'>
            <input type="tel" className='reginp2 font16b' maxLength={15} value={this.state.phone} placeholder="'-'를 제외하고 입력해 주세요." onChange={(e) => this.handleInput(3, e.target.value)}/>
            <button className='regbtn font16 wt600 color20 btncur menubtn' onClick={this.handleSendSms}>인증번호</button>
          </div>

          {
            this.state.smsSent ? (
              <div className='wd91p rowlc'>
                <input type="number" className='reginp2 font16b'
                  maxLength={15}
                  value={this.state.phone2}
                  placeholder="인증번호를 입력해 주세요."
                  onChange={(e) => this.handleInput(6, e.target.value)}
                />
              </div>
            ) : null
          }              
          
          <label className='font14ff'>{this.state.errorPhone}</label>

          <label className='font15 wt500 color31 mgtop25 wd91p'>이메일</label>
          <input type="email" className='reginp font16b' maxLength={45} value={this.state.email} placeholder="이메일을 입력해 주세요." onChange={(e) => this.handleInput(4, e.target.value)}/>
          <label className='font14ff'>{this.state.errorEmail}</label>

          <label className='font15 wt500 color31 mgtop25 wd91p'>회원선택</label>
          <div className='regutbtnArea flexrowsc'>
            <div className={this.state.userType === 1 ? 'regutbtn2 flexrowcc btncur menubtn' : 'regutbtn1 flexrowcc btncur menubtn'} onClick={() => this.setUserType(1)}>
              <label className={this.state.userType === 1 ? 'font16btn3 btncur' : 'font16b btncur'}>사업자</label>
            </div>

            <div className={this.state.userType === 2 ? 'regutbtn2 flexrowcc btncur menubtn' : 'regutbtn1 flexrowcc btncur menubtn'} onClick={() => this.setUserType(2)}>
              <label className={this.state.userType === 2 ? 'font16btn3 btncur' : 'font16b btncur'}>개인</label>
            </div>
          </div>
          {
            this.state.userType === 1 ? (

              <Fragment>
                <label className='font15 wt500 color31 mgtop25 wd91p'>사업자등록증</label>
                <div className='reginparea2 flexrowsc'>
                  <div className='regbox flexrowlc' onClick={() => this.setErrorMsg(5, '첨부하기 버튼을 눌러 주세요.')}>
                    <label className='font16b'>{getShortedPathName(this.state.cdocFileName, 30)}</label>
                  </div>

                  <div className="regimgselbtn flexcenterrow  menubtn">
                    <label htmlFor="fileInputx" className="regimgselinp font16 wt600 color20 btncur menubtn">첨부하기</label>
                    <input type="file" id="fileInputx" className="regfilestyle" accept="image/png, image/jpeg"
                      onChange={(e) => {

                        if (!IsValidV(e) || !IsValidV(e.target) || !IsValidS(e.target.files))
                          return;

                        this.cdocFile = e.target.files[0];
                        this.handleInput(5, e.target.files[0].name);
                    }}/>
                  </div>
                </div>
                <label className='font15 wt400 colorb3 mgtop5 wd91p'>JPEG, PNG포맷의 파일만 업로드 가능합니다.</label>
              </Fragment>
            ) : null
          }

          <label className='font14ff wd91p'>{this.state.errorCdoc}</label>

          <div className='reginparea2 flexrowsc margintop30'>
            <div className='flexrowcc'>
              <input type="checkbox" className='regchk' checked={this.state.checkTerm} onChange={() => {this.setCheckTerm(!this.state.checkTerm)}}/>
              <label className='font15 wt500 color31 btncur'onClick={() => {this.setCheckTerm(!this.state.checkTerm)}}>이용 약관에 동의합니다.</label>
            </div>

            <label className='font15 wt500 color20 fontunderline btncur menubtn' onClick={() => this.handleMenu(6)}>약관보기</label>
          </div>

          <div className='reginparea2 flexrowsc margintop10'>
            <div className='flexrowcc'>
              <input type="checkbox" className='regchk' checked={this.state.checkPrivacy} onChange={() => {this.setCheckPrivacy(!this.state.checkPrivacy)}}/>
              <label className='font15 wt500 color31 btncur'onClick={() => {this.setCheckPrivacy(!this.state.checkPrivacy)}}>개인정보 처리 방침에 동의합니다.</label>
            </div>

            <label className='font15 wt500 color20 fontunderline btncur menubtn' onClick={() => this.handleMenu(7)}>약관보기</label>
          </div>

          <button className='regbtn2 btncur font16 wt500 colorw margintop30 menubtn' onClick={this.handleRegister}>회원가입</button>

        </div> 
      </div>
    );
  }

  renderMobie = () =>
  {
    return (
      <div className='regmain flexcoltc'>
        {
          this.state.showTerm ? (<Term handleClose={() => this.showTerm(false)}/>) : null
        }
        
        {
          this.state.showPrivacy ? (<Privacy handleClose={() => this.showPrivacy(false)}/>) : null
        }
        <Talk handleTalk={this.handleTalk}/>
        {
          this.state.loading ? (<Loading/>) : null
        }
        
        <div className='regtop_mb flexrowrc btncur menubtn menutext' onClick={this.handleNavi}>
          <label className='font14202 marginright5 btncur'>메인페이지</label>
          <img src={GetImgUrl("register/mb/plane.png")} alt="메인페이지" className='marginright20 img2514'/>
        </div>
        <div className='regline'></div>

        <div className='regright_mb flexcoltl margintop28'>
          <label className='font18b'>SNS플라이에 오신걸 환영합니다!</label>

          <label className='font14b_2 margintop30'>아이디</label>
          <input type="text" className='reginp_mb font14b_2' maxLength={25} value={this.state.userId} placeholder='아이디를 입력해 주세요.' onChange={(e) => this.handleInput(0, e.target.value)}/>
          <label className='font14ff2'>{this.state.errorId}</label>

          <label className='font14b_2 margintop30'>비밀번호</label>
          <input type="password" className='reginp_mb font14b_2' maxLength={20} value={this.state.userPw} placeholder='8~20자 이내로 입력해 주세요.' onChange={(e) => this.handleInput(1, e.target.value)}/>
          <label className='font14ff2'>{this.state.errorPw}</label>

          <label className='font14b_2 margintop30'>비밀번호 확인</label>
          <input type="password" className='reginp_mb font14b_2' maxLength={20} value={this.state.userPw2} placeholder='비밀번호를 한번 더 입력해 주세요.' onChange={(e) => this.handleInput(2, e.target.value)}/>
          <label className='font14ff2'>{this.state.errorPw2}</label>

          <label className='font14b_2 margintop30'>휴대폰</label>
          <div className='reginparea2_mb flexrowsc'>
            <input type="tel" className='reginp2_mb font14b_2' maxLength={15} value={this.state.phone} placeholder="'-'를 제외하고 입력해 주세요." onChange={(e) => this.handleInput(3, e.target.value)}/>
            <button className='regbtn_mb font13btn1 btncur menubtn' onClick={this.handleSendSms}>휴대폰인증</button>
          </div>

          {
            this.state.smsSent ? (
              <input type="number" className='reginp2_mb font14b_2'
                maxLength={15}
                value={this.state.phone2}
                placeholder="인증번호를 입력해 주세요."
                onChange={(e) => this.handleInput(6, e.target.value)}
              />
            ) : null
          }              

          <label className='font14ff2'>{this.state.errorPhone}</label>

          <label className='font14b_2 margintop30'>이메일</label>
          <input type="email" className='reginp_mb font14b_2' maxLength={45} value={this.state.email} placeholder="이메일을 입력해 주세요." onChange={(e) => this.handleInput(4, e.target.value)}/>
          <label className='font14ff2'>{this.state.errorEmail}</label>

          <label className='font14b_2 margintop30'>회원선택</label>
          <div className='regutbtnArea_mb flexrowsc'>
            <div className={this.state.userType === 1 ? 'regutbtn2_mb flexrowcc btncur' : 'regutbtn1_mb flexrowcc btncur'} onClick={() => this.setUserType(1)}>
              <label className={this.state.userType === 1 ? 'font13btn1 btncur' : 'font13b btncur'}>사업자</label>
            </div>

            <div className={this.state.userType === 2 ? 'regutbtn2_mb flexrowcc btncur' : 'regutbtn1_mb flexrowcc btncur'} onClick={() => this.setUserType(2)}>
              <label className={this.state.userType === 2 ? 'font13btn1 btncur' : 'font13b btncur'}>개인</label>
            </div>
          </div>
          <label className='font14ff2'>{this.state.errorCdoc}</label>

          <div className='reginparea2_mb flexrowsc margintop30'>
            <div className='flexrowcc'>
              <input type="checkbox" className='regchk' checked={this.state.checkTerm} onChange={() => {this.setCheckTerm(!this.state.checkTerm)}}/>
              <label className='font14b_2 btncur'onClick={() => {this.setCheckTerm(!this.state.checkTerm)}}>이용 약관에 동의합니다.</label>
            </div>

            <label className='font14202 fontunderline btncur menubtn' onClick={() => this.handleMenu(6)}>약관보기</label>
          </div>

          <div className='reginparea2_mb flexrowsc margintop10'>
            <div className='flexrowcc'>
              <input type="checkbox" className='regchk' checked={this.state.checkPrivacy} onChange={() => {this.setCheckPrivacy(!this.state.checkPrivacy)}}/>
              <label className='font14b_2 btncur'onClick={() => {this.setCheckPrivacy(!this.state.checkPrivacy)}}>개인정보 처리 방침에 동의합니다.</label>
            </div>

            <label className='font14202 fontunderline btncur menubtn' onClick={() => this.handleMenu(7)}>약관보기</label>
          </div>

          <button className='regbtn2_mb btncur font14w2 margintop30 menubtn' onClick={this.handleRegister}>회원가입</button>
        </div>

        <FooterBar handleMenu={this.handleMenu}/>
      </div>
    );
  }

  render()
  {
    /*if (mobileOn)
    {
      return this.renderMobie();
    }
    else*/
    {
      return this.renderPc();
    }
  }
}

export default withNavigation(Register);