import React from 'react';
import './Home.css';
import './HomeAni.css';
import '../../App.css';
import { GetImgUrl, LOG_ENABLE, gotoFirstPage, gotoTalkPage, handleAppNavi, mobileOn, withNavigation } from '../../URL/AppUrl';
import {  GetDataDateAndTime, GetFormattedCNum2, getShortedPathName, IsValidS, IsValidV, } from '../../Util/Util';
import TopBar from '../Top/TopBar';
import FooterBar from '../Footer/Footer';
import { LoginStoreInstance } from '../../Stores/LoginStore';
import Term from '../Term/Term';
import Privacy from '../Term/Privacy';
import Talk from '../Talk/Talk';
import NaviBar from '../Navi/Navi';
import { AdsStoreInstance } from '../../Stores/AdsStore';
import { resetAllUserData } from '../../Stores/StoreManager';
import Login from '../Login/Login';
import FindId from '../Login/FindId';
import FindPw from '../Login/FindPw';
import { observer } from 'mobx-react';
import { NoticeStoreInstance } from '../../Stores/NoticeStore';
import HomeNtcItem from './HomeNtcItem';
import HomeAdsItem from './HomeAdsItem';
import Lottie from 'react-lottie';
import * as ani3x3 from '../../LottieRes/3x3.json';
import * as aniStarter from '../../LottieRes/starter.json';

//로그인없는 홈페이지
class Home extends React.Component
{
  state = {
    loading: false,
    showTerm: false,
    showPrivacy: false,
    showLogin: false,
    showFindId: false,
    showFindPw: false,

    noticeList: null,
    alarmInfo: null,

    orderBingo: 0, //빙고주문개수
    orderInsta: 0, //인스타주문개수
    orderFb: 0, //페북 주문개수
    orderYt: 0, //유튜브 주문개수
    orderTt: 0, //틱톡 주문개수

    //사이징처리
    naviWidth: 0,
    contWidth: 0,

    height: 0,

  };

  needAlarm = true; //최근 알림 읽기가 필요한가?
  needNotice = true; //공지항목읽기가 필요한가?
  needOrder = true; //주문정보 읽기가 필요한가?
  needCompanyInfo = true; //업체 정보 필요한가?

  showTerm = (v) =>
  {
    this.setState((prevState) => ({...prevState, showTerm: v}));
  }

  showPrivacy = (v) =>
  {
    this.setState((prevState) => ({...prevState, showPrivacy: v}));
  }
  //-----------------------------------------------------------------------------------------------------------------------------
  //홈페이지용공지 목록 설정
  setNoticeList = (v) =>
  {
    this.setState((prevState) => ({...prevState, noticeList: v}));
  }

  //홈페이지 알림 정보 설정
  setAlarmInfo = (v) =>
  {
    this.setState((prevState) => ({...prevState, alarmInfo: v}));
  }
  
  //홈페이지 주문개수 정보 설정
  setOrderCount = (bingo, insta, fb, yt, tt) =>
  {
    this.setState((prevState) => ({...prevState, orderBingo: bingo, orderInsta: insta, orderFb: fb, orderYt: yt, orderTt: tt}));
  }

  //-----------------------------------------------------------------------------------------------------------------------------
  showLogin = (v) =>
  {
    this.setState((prevState) => ({...prevState, showLogin: v}));
  }

  showFindId = (v) =>
  {
    this.setState((prevState) => ({...prevState, showFindId: v}));
  }

  showFindPw = (v) =>
  {
    this.setState((prevState) => ({...prevState, showFindPw: v}));
  }

  handleLoginEvent = (eventCode) =>
  {
    if (this.state.loading)
      return;

    switch(eventCode)
    {
      case 0: //닫기
        this.showLogin(false);
        return;
      case 1: //회원가입
        this.handleRegister();
        return;
      case 2: //id찾기
        this.showLogin(false);
        this.showFindId(true);
        return;
      case 3: //비번찾기
        this.showLogin(false);
        this.showFindPw(true);
        return;
      case 100: //로그인 성공
        this.showLogin(false);
        this.needCompanyInfo = LoginStoreInstance.userType === 10;
        this.loadUserData();
        return;
      default:
        break;
    }
  }

  handleRegister = () =>
  {
    if (this.state.loading)
      return;

    this.showLogin(false);
    this.showFindId(false);
    this.showFindPw(false);
    this.handleNavi(5);
  }

  handleFindPw = () =>
  {
    if (this.state.loading)
      return;

    this.showLogin(false);
    this.showFindId(false);
    this.showFindPw(true);
  }

  handleFindIdLogin = () =>
  {
    if (this.state.loading)
      return;

    this.showLogin(true);
    this.showFindId(false);
    this.showFindPw(false);
  }
  //-----------------------------------------------------------------------------------------------------------------------------

  handleNavi = (naviIndex) =>
  {
    if (this.state.loading)
      return;

    if (LOG_ENABLE)
      console.log(`handleNavi : ${naviIndex}`);
    
    handleAppNavi(this.props.navigate, naviIndex);
  }

  handleSnsOrder = (orderType) =>
  {
    if (this.state.loading)
      return;

    if (LOG_ENABLE)
      console.log(`handleSnsOrder : ${orderType}`);

    AdsStoreInstance.setOrderType(orderType);

    handleAppNavi(this.props.navigate, 0);
  }

  handleMenu = (menuIndex) =>
  {
    if (this.state.loading)
      return;

    switch(menuIndex)
    {
      case 0: //로그인
        this.showLogin(true);
        return;
      case 6: //이용약관
        this.showTerm(true);
        return;
      case 7: //개인정보 처리방침
        this.showPrivacy(true);
        return;
      case 100: //로그아웃
        if (!window.confirm('로그아웃 할까요?'))
          return;

        this.processLogOut();
        return;
      default:
        return;
    }
  }

  processLogOut = () =>
  {
    resetAllUserData();
    gotoFirstPage();
  }

  //사이징----------------------------------------------------------------------------------------------
  setSize = (navi, cont, height) =>
  {
    this.setState((prevState) => ({...prevState, naviWidth : navi, contWidth : cont, height : height}));
  }

  resetContsize = () =>
  {
    //네비게이션 영역은 전체의 16%정도
    let naviWidth = 0.16 * window.innerWidth;

    if (naviWidth < 300) //최소크기300
      naviWidth = 300;

    //콘텐츠영역크기 계산
    let contWidth = window.innerWidth - naviWidth;

    if (contWidth < 1220)
      contWidth = 1220;

    this.setSize(naviWidth, contWidth, window.innerHeight - 70)
  }

  onResize = () => {
    this.resetContsize();
  }
  //사이징----------------------------------------------------------------------------------------------
  
  componentDidMount() {
    this.resetContsize();

    window.addEventListener("resize", this.onResize);

    //로그인되어 있지 않은경우, 혹시 새로고침했을수도 있다.
    if (!LoginStoreInstance.loggedIn)
    {
      if (LoginStoreInstance.loadToken()) //인증토큰 정보가 존재한다면
      {
        this.setLoading(true);
        LoginStoreInstance.loadProfile(this.onProfileLoadedForRefresh);
      }
    }
    else
    {
      if (!LoginStoreInstance.isProfileReady) //프로필이 준비되지 않았다면
      {
        this.setLoading(true);
        LoginStoreInstance.loadProfile(this.onProfileLoaded);
      }
      else
      {
        //마지막 포인트 조회 시점부터 현재까지 경과 시간이 1분이상 경과한경우
        if (LoginStoreInstance.getPassedPointTime() > 60)
        {
          LoginStoreInstance.catchPointRefreshTime();
          LoginStoreInstance.refreshUserPoint(null);
        }

        this.needCompanyInfo = LoginStoreInstance.userType === 10;
        this.loadUserData();
      }
    }
  }

  componentWillUnmount = () =>
  {
    window.removeEventListener("resize", this.onResize);
  }

  onProfileLoadedForRefresh = (resultCode) =>
  {
    this.setLoading(false);

    if (resultCode !== 0)
    {
      if (LOG_ENABLE)
        console.log(`invalid profile`);

      LoginStoreInstance.removeToken();

      return;
    }

    this.needCompanyInfo = LoginStoreInstance.userType === 10;

    this.loadUserData();
  }

  onProfileLoaded = (resultCode) =>
  {
    this.setLoading(false);

    if (resultCode !== 0)
    {
      alert(`프로필 읽기 중 오류가 발생했습니다.\n다시 로그인해 주세요.`);
      this.processLogOut();
      return;
    }

    this.needCompanyInfo = LoginStoreInstance.userType === 10;
    this.loadUserData();
  }

  loadUserData = () =>
  {
    //알림읽기
    if (this.needAlarm)
    {
      this.setLoading(true);
      NoticeStoreInstance.loadLastAlarm(this.onLoadLastAlarm);
      return;
    }

    //공지 읽기
    if (this.needNotice)
    {
      this.setLoading(true);
      NoticeStoreInstance.loadNoticeForHome(this.onLoadNoticeForHome);
      return;
    }

    //주문개수 읽기 필요함
    if (this.needOrder)
    {
      this.setLoading(true);
      AdsStoreInstance.loadAdsRunCount(this.onAdsRunCountLoaded);
      return;
    }

    //업체정보가 필요한가?
    if (this.needCompanyInfo)
    {
      if (IsValidV(LoginStoreInstance.userCompanyInfo))
      {
        this.needCompanyInfo = false;
        return;
      }
      else
      {
        this.setLoading(true);
        LoginStoreInstance.loadCompanyInfo(this.onLoadCompanyInfo);
      }
    }
  }

  //이용자가 마지막으로 수신한 알림 읽음
  onLoadLastAlarm = (resultCode, alarmInfo) =>
  {
    this.needAlarm = false;
    this.setLoading(false);

    if (resultCode === 0 && IsValidV(alarmInfo))
    {
      this.setAlarmInfo(alarmInfo);
    }

    //다른 데이터 읽기 필요하면 추가로 읽기 수행한다.
    this.loadUserData();
  }

  onLoadNoticeForHome = (resultCode, noticeList) =>
  {
    this.needNotice = false;
    this.setLoading(false);

    if (resultCode === 0 && IsValidS(noticeList))
    {
      this.setNoticeList(noticeList);
    }

    //다른 데이터 읽기 필요하면 추가로 읽기 수행한다.
    this.loadUserData();
  }

  //진행중 광고 주문 개수를 읽음
  onAdsRunCountLoaded = (resultCode, countInfo) =>
  {
    this.needOrder = false;
    this.setLoading(false);

    if (resultCode === 0 && IsValidV(countInfo))
    {
      this.setOrderCount(countInfo.bc, countInfo.ic, countInfo.fc, countInfo.yc, countInfo.tc);
    }

    //다른 데이터 읽기 필요하면 추가로 읽기 수행한다.
    this.loadUserData();
  }

  //업체 사업자 정보 조회됨
  onLoadCompanyInfo = (resultCode, info) =>
  {
    this.needCompanyInfo = false;
    this.setLoading(false);

    //다른 데이터 읽기 필요하면 추가로 읽기 수행한다.
    this.loadUserData();
  }

  setLoading = (v) =>
  {
    this.setState((prevState) => ({...prevState, loading : v}));
  }

  handleTalk = () =>
  {
    gotoTalkPage();
  }

  handleNotice = (noticeItem) =>
  {
    if (this.state.loading)
      return;

    if (LOG_ENABLE)
      console.log(`handleNotice : `);

    this.handleNavi(3);
  }

  renderBingo3x3 = () =>
  {
    const aniOption = {
      loop: true,
      autoPlay: true,
      animationData: ani3x3,
      rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice'
      }
    };

    return (
      <div className='home4littiecont1 rowce'>
        <Lottie options={aniOption}
          width={230}
          height={230}
          isStopped={false}
          isPaused={false}
        />
      </div>
    );
  }

  renderBingo3x1 = () =>
  {
    const aniOption = {
      loop: true,
      autoPlay: true,
      animationData: aniStarter,
      rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice'
      }
    };

    return (
      <div className='home4littiecont2 rowce'>
        <Lottie options={aniOption}
          width={220}
          height={72}
          isStopped={false}
          isPaused={false}
        />
      </div>
    );
  }

  renderHomeAny = () =>
  {
    /*return (
      <div className='homecont colel' style={{width: this.state.contWidth, height: this.state.height}}>
        <div className='homecont3 coltl' style={{width: this.state.contWidth, height: this.state.height - 100}}>
          <iframe className='homeiframe' src="https://www.bravo6.co.kr"></iframe>
        </div>
        <FooterBar handleMenu={this.handleMenu}/>
      </div>
    );*/

    return (
      <div className='homecont colel' style={{width: this.state.contWidth, height: this.state.height}}>
        <div className='homecont3 coltc' style={{width: this.state.contWidth, height: this.state.height - 100}}>
          <div className='homem1 colcc'>
            <img src={GetImgUrl("main/m1.png")} className='homem1img' alt="m1" />

            <div className='homem1text rowcc'>
              <label className='fontpd52 wt700 color00'>광고주와 고객 모두에게 가치를 더하는 광고</label>
            </div>

            <a href='#' className='scroll-down'>
              <div className='scroll'>
                <span className='scroll-dot'></span>
              </div>
            </a>
          </div>

          <div className='homem2 colcc'>
            <img src={GetImgUrl("main/m2.png")} className='homem2img' alt="m2" />
            <div className='homem2text coltl'>
              <label className='fontpd42 wt700 colorw'>리워드 앱을 활용한<br/>효과적인 마케팅, 머니팡</label>
              <label className='fontpd28 wt400 colore6 mgtop20'>머니팡은 유저에게 가변적 보상을 제공하여<br/>도파민을 자극하는 몰입도 높은 앱입니다.</label>
            </div>
          </div>

          <div className='homem3 colcc' style={{backgroundImage: `url("${GetImgUrl("main/m3.png")}")`}}>
            {/*<img src={GetImgUrl("main/m3.png")} className='homem3img' alt="m3" />*/}

            <div className='homem3text colcc'>
              <label className='fontpd52 wt700 colorw'>BRAIN STICKER</label>
              <label className='fontpd28 wt400 colore6 mgtop20 textcenter'>머니팡애즈는 일회성이 아닌,<br/>고객의 생각 속에 귀사의 서비스와<br/>상품이 각인될 수 있는 혁신적 광고 플랫폼이 되겠습니다.</label>
            </div>
          </div>

          <div className='homem4 coltc'>
            <div className='homem4in coltl mgtop150'>
              <label className='fontpd42 wt700 color31 lht52'>광고와 게임이 결합된<br/>몰입형 마케팅 경험을 제공합니다</label>

              <label className='fontpd28 wt400 color66 lht38 mgtop30'>빙고팡팡은 게이미피케이션 광고 상품으로<br/>가변적 보상, 도파민 자극, 반복 노출을 통해<br/>머니팡 유저에게 브랜드를 각인시키는 광고 상품입니다.</label>

              <div className='wd100p rowsl mgtop50'>

                <div className='coltl'>

                  <div className='home4div rowcc' style={{backgroundImage: `url("${GetImgUrl("main/m42.png")}")`}}>
                    {this.renderBingo3x3()}
                  </div>
                  

                  <label className='fontpd28 wt600 color31 mgtop30'>집중형 심볼 빙고</label>
                  <label className='fontpd20 wt400 color66 lht30 mgtop10'>상품의 키워드 심볼과 쇼츠를 이용하여 반복 노출을 하기 때문에<br/>머니팡 유저에게 높은 전환율과 확실한 인식 제고를 기대할 수 있는상품<br/>입니다.</label>
                  <label className='fontpd20 wt400 color20 btncur mgtop10' onClick={() => this.handleNavi(7)}>{'자세히 보기 >'}</label>
                </div>
                
                <div className='coltl'>
                  <div className='home4div rowcc' style={{backgroundImage: `url("${GetImgUrl("main/m52.png")}")`}}>
                    {this.renderBingo3x1()}
                  </div>

                  <label className='fontpd28 wt600 color31 mgtop30'>일반형 사진 빙고</label>
                  <label className='fontpd20 wt400 color66 lht30 mgtop10'>홍보용 상품 이미지로 심볼을 이용하여 노출을 하기 때문에<br/>다수의 머니팡 유저에게 브랜드를 노출시킬 수 있는 상품입니다.</label>
                  <label className='fontpd20 wt400 color20 btncur mgtop40' onClick={() => this.handleNavi(7)}>{'자세히 보기 >'}</label>
                </div>
              </div>

              <label className='fontpd42 wt700 color31 lht52 mgtop150'>SNS 마케팅을 통해<br/>브랜드의 성장 잠재력을 발휘해 보세요</label>
              <label className='fontpd28 wt400 color66 lht38 mgtop30'>트래픽을 프로그램으로 자동 집행하는 타 서비스와는 근본적으로 다른 방식으로<br/>한국인 머니팡 이용자들이 수작업으로 직접 미션을 수행하며<br/>완료된 미션에 따라 머니팡 유저들에게 리워드를 지급하는 SNS 미션형 광고 상품입니다.</label>

              <label className='fontpd20 wt400 color20 btncur mgtop20'onClick={() => this.handleSnsOrder(0)}>{'자세히 보기 >'}</label>

              <div className='wd100p rowcc mgtop30'>
                <img src={GetImgUrl("main/m6.png")} alt="m6"/>
              </div>
            </div>
          </div>

          <div className='home7 colcc mgtop150'>
            <label className='fontpd42 wt700 color31'>광고를 통해 브랜드의 영향력을 확대해보세요</label>
            <img src={GetImgUrl("main/m7.png")} alt="m7" className='mgtop50'/>

            <div className='home7in rowlc mgtop15'>
              <label className='fontpd28 wt400 color66 textcenter home7int1'>머니팡애즈에<br/>회원가입을 합니다</label>
              <label className='fontpd28 wt400 color66 textcenter home7int2'>광고할 서비스를<br/>선택한 후 주문합니다</label>
              <label className='fontpd28 wt400 color66 textcenter home7int3'>이미지와 문구 등<br/>심사를 합니다</label>
              <label className='fontpd28 wt400 color66 textcenter'>머니팡 유저에게<br/>노출이 시작됩니다</label>
            </div>
          </div>
        </div>
        <FooterBar handleMenu={this.handleMenu}/>
      </div>
    );
  }

  getAlarmMsg = () =>
  {
    if (!IsValidV(this.state.alarmInfo))
      return "새로운 알림이 없어요";

    if (this.state.alarmInfo.dc.length > 45)
      return getShortedPathName(this.state.alarmInfo.dc, 45);

    return this.state.alarmInfo.dc;
  }

  getAlarmDate = () =>
  {
    if (!IsValidV(this.state.alarmInfo))
      return "";

    return GetDataDateAndTime(this.state.alarmInfo.rd);
  }

  renderHomeLoggedIn = () =>
  {
    const companyInfo = LoginStoreInstance.userCompanyInfo;

    let noticeList = null;

    if (IsValidS(this.state.noticeList))
    {
      noticeList = this.state.noticeList.map(item => <HomeNtcItem key={item.idx} item={item} handleNotice={this.handleNotice}/>)
    }
    return (
      <div className='homecont colel' style={{width: this.state.contWidth, height: this.state.height}}>
        <div className='homecont2 coltl' style={{width: this.state.contWidth - 40 - 50, height: this.state.height - 100}}>
          <label className='font20 wt600 color31 mgtop20'>{`${LoginStoreInstance.getUserName}님의`}<br/>방문을 환영합니다.</label>

          <div className='homecont3 rowlt mgtop30'>
            <div className='homeleft coltl'>
              <div className='homealarm rowsc'>
                <div className='rowcc'>
                  <img src={GetImgUrl("com/alarm.png")} alt="알림"/>
                  <label className='font16 wt400 color31 mglt10'>{this.getAlarmMsg()}</label>
                </div>

                <label className='font16 colorb3 wt400'>{this.getAlarmDate()}</label>
              </div>

              <div className='homentc coltc'>
                {noticeList}
              </div>

              <label className='font16 wt600 color31 mgtop30'>진행중인 광고</label>

              <label className='font15 wt500 color31 mgtop10 mgbt10'>리워드 광고</label>

              <HomeAdsItem index={4} count={this.state.orderBingo}/>

              <label className='font15 wt500 color31 mgtop30'>SNS 주문하기</label>

              <div className='wd100p rowlc mgtop10 mgbt50'>
                <HomeAdsItem index={0} count={this.state.orderInsta}/>
                {/*<HomeAdsItem index={1} count={this.state.orderFb}/>*/}
                <HomeAdsItem index={2} count={this.state.orderYt}/>
                <HomeAdsItem index={3} count={this.state.orderTt}/>
              </div>
            </div>

            <div className='homeright rowcc mglt30'>
              <div className='homerightcont colcl'>
                <div className='wd100p rowsc'>
                  <label className='font16 wt500 color31'>사업자 정보</label>
                  <button className='font15 wt500 color20 btncur homecpinfobtn' onClick={() => this.handleNavi(6)}>자세히 보기</button>
                </div>

                <div className='wd100p rowlc mgtop10'>
                  <label className='font15 wt400 colorb3 homecpinfott'>사업자명</label>
                  <label className='font16 wt400 color31'>{companyInfo ? companyInfo.cname : "-"}</label>
                </div>

                <div className='wd100p rowlc mgtop10'>
                  <label className='font15 wt400 colorb3 homecpinfott'>사업자 등록번호</label>
                  <label className='font16 wt400 color31'>{companyInfo ? GetFormattedCNum2(companyInfo.cn) : "-"}</label>
                </div>

                <div className='wd100p rowlc mgtop10'>
                  <label className='font15 wt400 colorb3 homecpinfott'>대표자명</label>
                  <label className='font16 wt400 color31'>{companyInfo ? companyInfo.oname : "-"}</label>
                </div>

                <div className='wd100p rowlc mgtop10'>
                  <label className='font15 wt400 colorb3 homecpinfott'>발행 이메일</label>
                  <label className='font16 wt400 color31'>{companyInfo ? companyInfo.em : "-"}</label>
                </div>
              </div>
            </div>
          </div>
        </div>
        <FooterBar handleMenu={this.handleMenu}/>
      </div>
    );
  }

  render()
  {
    return (
      <div className='pgcont coltc'>
        {
          this.state.showLogin ? (<Login handleLoginEvent={this.handleLoginEvent}/>) : null
        }
        {
          this.state.showFindId ? (<FindId handleRegister={this.handleRegister} handleClose={() => this.showFindId(false)} handleFindPw={this.handleFindPw} handleLogin={this.handleFindIdLogin}/>) : null
        }
        {
          this.state.showFindPw ? (<FindPw handleClose={() => this.showFindPw(false)} handleRegister={this.handleRegister} />) : null
        }
        {
          this.state.showTerm ? (<Term handleClose={() => this.showTerm(false)}/>) : null
        }
        {
          this.state.showPrivacy ? (<Privacy handleClose={() => this.showPrivacy(false)}/>) : null
        }
        <TopBar handleNavi={this.handleNavi} withLine={true}/>

        <div className='pgcont2 rowlt'>
          <NaviBar width={this.state.naviWidth} height={this.state.height} handleSnsOrder={this.handleSnsOrder} handleNavi={this.handleNavi} handleMenu={this.handleMenu}/>

          {LoginStoreInstance.loggedIn ? (this.renderHomeLoggedIn()) : (this.renderHomeAny())}
        </div>

        <Talk handleTalk={this.handleTalk}/>
      </div>
    );
  }
}

export default withNavigation(observer(Home));