import { AdsStoreInstance } from "./AdsStore";
import { FaqStoreInstance } from "./FaqStore";
import { LoginStoreInstance } from "./LoginStore"
import { NoticeStoreInstance } from "./NoticeStore";
import { PointOrderInstance } from "./PointOrder";

const resetAllUserData = () =>
{
  LoginStoreInstance.processLogOut();
  AdsStoreInstance.clearAll();
  FaqStoreInstance.clearAll();
  NoticeStoreInstance.clearAll();
  PointOrderInstance.clearAll();

}

export {
  resetAllUserData
};