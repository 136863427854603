import './ToastMessage.css';
import '../../App.css';
import { IsValidS } from '../../Util/Util';
const ToastMessage = (props) =>
{
  let items = null;
  if (IsValidS(props.msg) && props.msg.indexOf("\n") >= 0)
  {
    let temp = props.msg.split("\n");
    items = [];

    for (let i = 0;i < temp.length; ++i)
    {
      items.push(<label key={i} className='font14 wt500 colorw'>{temp[i]}</label>);
    }
  }
  else
  {
    items = [];
    items.push(<label key={0} className='font14 wt500 colorw'>{props.msg}</label>);
  }

  return (
    <div className='toastpop colcc'>
      {items}
    </div>
  );
}

export default ToastMessage;