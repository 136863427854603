import { useState } from "react";
import './Faq.css';
import '../../App.css';
import { GetImgUrl } from "../../URL/AppUrl";

const FaqItem = (props) =>
{
  const [enabled, setEnabled] = useState(false);

  if (enabled)
  {
    return (
      <div className="faqitem3 colsc btncur" onClick={() => setEnabled(!enabled)}>
        {props.withTopLine ? (<div className="faqitemline"></div>) : null}
        
        <div className="faqitem11 rowsl mgtop5">
          <div className="rowcl">
            <label className="font1620 btncur mglt20">Q</label>
            <label className="font16b btncur mglt5 faqans_pc">{props.item.tt}</label>
          </div>

          {/*<img src={GetImgUrl(enabled ? "com/up.png" : "com/down.png")} alt="expand" className="mgrt20 mgtop5"/>*/}
        </div>

        <div className="faqitem11 rowsc mgtop15 mgbt5">
          <div className="rowcl">
            <label className="font164b btncur mglt20">A</label>
            <label className="font16b btncur mglt5 faqans_pc">{props.item.dc}</label>
          </div>
        </div>

        {props.withBottomLine ? (<div className="faqitemline"></div>) : null}
      </div>
    );
  }
  else
  {
    return (
      <div className="faqitem colcc btncur" onClick={() => setEnabled(!enabled)}>
        {props.withTopLine ? (<div className="faqitemline"></div>) : null}
        <div className="faqitem rowsc">
          <div className="rowcl">
            <label className="font1620 btncur mglt20">Q</label>
            <label className="font16b btncur mglt5 faqans_pc">{props.item.tt}</label>
          </div>

          {/*<img src={GetImgUrl(enabled ? "com/up.png" : "com/down.png")} alt="expand"  className="mgrt20"/>*/}
        </div>

        {props.withBottomLine ? (<div className="faqitemline"></div>) : null}
      </div>
    );
  }
}

export default FaqItem;