import React from 'react';

import './Login.css';
import '../../App.css';
import { GetImgUrl, LOG_ENABLE, mobileOn } from '../../URL/AppUrl';
import { observer } from 'mobx-react';
import Loading from '../Loading/Loading';
import { IsValidId, IsValidS } from '../../Util/Util';
import { LoginStoreInstance } from '../../Stores/LoginStore';

class Login extends React.Component
{
  state = {
    loading: false,
    userId: '',
    userPw: '',
    saveAccount: false,
  };

  showLoading = (v) =>
  {
    this.setState((prevState) => ({...prevState, loading: v}));
  }

  setUserId = (v) =>
  {
    this.setState((prevState) => ({...prevState, userId: v}));
  }

  setUserPw = (v) =>
  {
    this.setState((prevState) => ({...prevState, userPw: v}));
  }

  setSaveAccount = (v) =>
  {
    this.setState((prevState) => ({...prevState, saveAccount: v}));
  }

  componentDidMount() {
    LoginStoreInstance.loadAccount();

    this.setSaveAccount(LoginStoreInstance.acSave);

    if (IsValidS(LoginStoreInstance.userId))
    {
      if (LOG_ENABLE)
        console.log(`account found : ${LoginStoreInstance.userId}`);

      this.setUserId(LoginStoreInstance.userId);
    }
  }

  componentWillUnmount() {
  }

  handleLogin = () =>
  {
    if (this.state.loading)
      return;

    if (!IsValidS(this.state.userId))
    {
      alert(`아이디를 입력해 주세요.`);
      return;
    }

    if (!IsValidId(this.state.userId))
    {
      alert(`아이디를 올바르게 입력해 주세요.`);
      return;
    }

    if (!IsValidS(this.state.userPw))
    {
      alert(`비밀번호를 입력해 주세요.`);
      return;
    }

    //로그인을 시도하고 성공하는 경우, this.props.handleLoginEvent(100)
    this.showLoading(true);
    LoginStoreInstance.setSaveAccount(this.state.saveAccount);
    LoginStoreInstance.setUserId(this.state.userId);
    LoginStoreInstance.setUserPW(this.state.userPw);
    LoginStoreInstance.tryLogin(this.onLoginResult);
  }

  onLoginResult = (resultCode) =>
  {
    if (LOG_ENABLE)
      console.log(`onLoginResult : ${resultCode}`);
    
    this.showLoading(false);

    switch(resultCode)
    {
      case -1:
        alert(`네트워크 연결상태를 확인해 주세요.`);
        return;
      case 0:
        break;
      case 1:
        alert(`잘못된 요청입니다.`);
        return;
      case 2:
      case 3:
        alert(`ID 또는 비밀 번호가 올바르지 않습니다.`);
        return;
      case 4:
        alert(`프로필 정보를 읽을 수 없습니다.`);
        return;
      case 7:
        alert(`이계정은 더이상 사용 할 수 없습니다.`);
        return;
      case 8:
        alert(`이계정은 아직 허가되지 않아 이용할 수 없습니다.\n검토가 완료될때까지 기다려 주세요.`);
        return;
      case 10:
        alert(`이계정은 탈퇴가 진행중입니다.`);
        return;
      case 11:
        alert(`이미 탈퇴한 회원입니다.`);
        return;
      default:
        alert(`로그인 중 오류가 발생했습니다.\n잠시 후 이용해 주세요.\n오류코드 ${resultCode}`);
        return;
    }

    this.props.handleLoginEvent(100);
  }

  handleSaveAccount = () =>
  {
    this.setSaveAccount(!this.state.saveAccount);
  }

  render()
  {
    /*if (mobileOn)
    {
      return (
        <div className='loginbg flexcolcc'>
          {
            this.state.loading ? (<Loading/>): null
          }
          <div className='loginpop_mb flexcolcc'>
            <div className='loginconttop_mb flexrowsc'>
              <img src={GetImgUrl("com/mb/toplogo.png")} alt="snsfly" className='img9421' />
              <img src={GetImgUrl("com/mb/close.png")} alt="close" className='btncur menubtn img1414' onClick={() => this.props.handleLoginEvent(0)}/>
            </div>

            <div className='loginline'></div>

            <div className='loincont_mb flexcoltl margintop30'>
              <label className='font18b'>SNS플라이 시작하기</label>
              
              <div className='logincont2 flexcoltl margintop20'>

                <label className='font14b_2'>아이디</label>
                <input type="text" className='logininp_mb font16b' value={this.state.userId} onChange={(e) => this.setUserId(e.target.value)}
                  placeholder='아이디를 입력해 주세요.'
                />
  
                <label className='font14b_2 margintop20'>비밀번호</label>
                <input type="password" className='logininp_mb font16b' value={this.state.userPw} onChange={(e) => this.setUserPw(e.target.value)}
                  placeholder='비밀번호를 입력해 주세요.'
                />
  
                <div className='wd100p rowlc mgtop5'>
                  <input type='checkbox' checked={this.state.saveAccount} onChange={(e) => {this.handleSaveAccount()}} className='accsaveinp btncur'/>
                  <label className='font14 wt400 color31 btncur' onClick={this.handleSaveAccount}>아이디 저장</label>
                </div>

                <button className='loginbtn_mb font14btn4 btncur menubtn margintop20' onClick={this.handleLogin}>로그인</button>
  
                <div className='logincont2 flexrowcc margintop20'>
                  <label className='font14b_2'>SNS플라이 회원이 아니신가요?</label>
                  <label className='font1420 btncur menubtn' onClick={() => this.props.handleLoginEvent(1)}>&nbsp;&nbsp;&nbsp;회원가입</label>
                </div>
  
                <div className='logincont2 flexrowsc margintop20'>
                  <button className='loginbtn2_mb font14202 btncur menubtn' onClick={() => this.props.handleLoginEvent(2)}>아이디를 잃어버렸어요</button>
                  <button className='loginbtn2_mb font14202 btncur menubtn' onClick={() => this.props.handleLoginEvent(3)}>비밀번호를 잃어버렸어요</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
    else*/
    {
      return (
        <div className='loginbg flexcolcc'>
          {
            this.state.loading ? (<Loading/>): null
          }
          <div className='loginpop flexcolcc'>
            <div className='loincont flexcoltc'>
              <div className='logincont2 flexrowsc'>
                <label className='font20b2'></label>
                <img src={GetImgUrl("com/close.png")} alt="close" className='btncur menubtn' onClick={() => this.props.handleLoginEvent(0)}/>
              </div>
  
              <div className='logincont2 flexcoltc mgtop100'>
                <input type="text" className='logininp font16b' value={this.state.userId} onChange={(e) => this.setUserId(e.target.value)}
                  placeholder='아이디를 입력해 주세요.'
                />
  
                <label className='font14ff'>{this.state.errorPw2}</label>
                <input type="password" className='logininp font16b margintop15' value={this.state.userPw} onChange={(e) => this.setUserPw(e.target.value)}
                  placeholder='비밀번호를 입력해 주세요.'
                />
  
                <div className='wd100p rowlc mgtop5'>
                  <input type='checkbox' checked={this.state.saveAccount} onChange={(e) => {this.handleSaveAccount()}} className='accsaveinp btncur'/>
                  <label className='font16 wt400 color31 btncur' onClick={this.handleSaveAccount}>아이디 저장</label>
                </div>

                <button className='loginbtn font16 wt600 colorw btncur menubtn margintop20' onClick={this.handleLogin}>로그인</button>
  
                <button className='loginbtnx2 font16 wt600 color20 btncur menubtn margintop10' onClick={() => this.props.handleLoginEvent(1)}>회원가입</button>
 
                <div className='logincont2 flexrowsc margintop30'>
                  <button className='loginbtn2 font15 wt400 color31 btncur menubtn' onClick={() => this.props.handleLoginEvent(2)}>아이디를 잃어버렸어요</button>
                  <button className='loginbtn2 font15 wt400 color31 btncur menubtn' onClick={() => this.props.handleLoginEvent(3)}>비밀번호를 잃어버렸어요</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
  }
}

export default observer(Login);