import './Term.css';
import '../../App.css';
import { GetImgUrl, mobileOn } from '../../URL/AppUrl';

const Term = (props) =>
{
  if (mobileOn)
  {
    return (
      <div className='termpopbg flexcolcc'>
  
        <div className='termpop_mb flexcoltc'>

          <div className='termtitle_mb flexrowcc'>
            <div className='termtitle_in_mb flexrowsc'>
              <label className='font18b'>이용약관</label>
              <img src={GetImgUrl("com/mb/close.png")} alt="close" className='btncur menubtn img1414' onClick={props.handleClose}/>
            </div>
          </div>

          <div className='termline'></div>
  
          <div className='termcont_mb flexcoltl margintop30'>
            <br/>
            <label className='font14b_1'>제 1조 (목적)</label>
            <label className='font14b_2'>이 약관은 주식회사 브라보식스(이하 ‘회사’)가 운영하는 머니팡 애즈 서비스(링크, 이하 ‘머니팡 애즈’ 또는 ‘서비스’라 합니다.)를 이용함에 있어 회사와 회원 간의 권리, 의무 및 책임사항을 규정함을 목적으로 합니다.</label>

            <br/>
            <label className='font14b_1'>제 2조 (정의)</label>
            <label className='font14b_2'>1. ‘머니팡 애즈’란 회사가 운영하는 ‘머니팡’ 어플리케이션(mpang.io) 내의 유저가 시청 및 수행하는 형태의 온라인 마케팅 서비스(이하 ‘서비스’라 함)를 회원에게 제공하기 위하여 컴퓨터등 정보통신설비를 이용하여 서비스를 구매 및 이용 수 있도록 설정한 가상의 영업장을 말하며, 아울러 서비스를 운영하는 사업자의 의미로도 사용합니다.<br/>
            2. ‘회원’이란 ‘머니팡 애즈’에 개인정보를 제공하여 가입등록을 한 자로서, ‘머니팡 애즈’를 지속적으로 제공받으며, ‘머니팡 애즈’에서 제공하는 서비스를 계속적으로 이용할 수 있는 자나 회사를 말합니다.<br/>
            3. ‘빙고팡팡 서비스’란 ‘머니팡 애즈’에서 회원이 주문한 일반형 및 집중형, 세줄 및 한줄 빙고팡팡 을 말합니다.<br/>
            4. ‘SNS 서비스’란 ‘머니팡 애즈’에서 회원이 주문한 SNS (인스타그램, 유튜브, 틱톡 등 SNS)의 트래픽 (팔로우 등의 트래픽)을 말합니다.
            </label>

            <br/>
            <label className='font14b_1'>제 3조 (약관의 명시와 설명 및 개정)</label>
            <label className='font14b_2'>
            1. 본 약관은 서비스 화면의 게시를 통하여 공지함으로써 효력이 발생됩니다.<br/>
            2. ‘머니팡 애즈’는 약관을 언제든지 수정 및 변경할 수 있습니다.<br/>
            3. 회원은 약관에 동의하지 않을 경우 자유로운 권리로 회원 탈퇴를 요청할 수 있으며, 변경된 약관의 효력발생일 이후 7일 이내에 해지 요청을 하지 않을 경우 변경사항에 동의하는 것으로 간주됩니다.
            </label>

            <br/>
            <label className='font14b_1'>제 4조 (회원가입)</label>
            <label className='font14b_2'>
            1. 이용자는 서비스에서 정한 가입 양식에 따라 회원정보를 기입한 후 본 약관에 동의한다는 의사표시를 함으로써 회원가입을 완료합니다.<br/>
            2. 머니팡 애즈는 제1항과 같이 회원으로 가입할 것을 신청한 이용자 중 다음 각호에 해당하지 않는 한 회원으로 등록합니다.<br/>
            &nbsp;가. 가입신청자가 이 약관 제12조 제2항에 의하여 이전에 회원자격을 상실한 적이 있는 경우<br/>
            &nbsp;나. 등록 내용에 허위, 기재누락, 오기가 있는 경우<br/>
            &nbsp;다. 기타, 회원으로 등록하는 것이 머니팡 애즈의 운영상 지장이 있다고 판단되는 경우
            </label>

            <br/>
            <label className='font14b_1'>제 5조 (회원아이디 관리)</label>
            <label className='font14b_2'>
            1. 회원아이디 및 비밀번호에 대한 관리책임은 이용자에게 있습니다.<br/>
            2. 회원아이디는 다음의 경우에 한하여 이용자의 요청 또는 회사의 판단에 의하여 변경 및 회원탈퇴할 수 있습니다.<br/>
            &nbsp;가. 아이디가 회원의 전화번호나 주민등록번호등으로 되어 있어서 사생활 및 정보침해의 우려가 있는 경우<br/>
            &nbsp;나. 서비스 운영자, 직원 또는 관계자로 오인할 수 있는 경우<br/>
            &nbsp;다. 상식적으로 합리적인 사유가 있는 경우<br/>

            </label>

            <br/>
            <label className='font14b_1'>제 6조 (회사의 빙고팡팡  서비스)</label>
            <label className='font14b_2'>
            1. 본 서비스는 회사의 업무, 기술상의 장애 등 특별한 사유가 없는 한 연중무휴 24시간 서비스를 제공합니다.<br/>
            2. 회사는 광고상품의 종류, 광고영역, 등록절차, 이용요금, 위약금 여부 등을 ‘머니팡애즈’내 공지사항이나 운영정책 등을 통해 안내합니다.<br/>
            3. 본 서비스는 회원이 광고 타겟(연령 및 성별, 지역 등의 광고 타겟)을 설정할 수 있으며, ‘머니팡’ 서비스 내의 빙고팡팡 게임 형태로 노출됩니다.<br/>
            4. 본 서비스 주문 접수시 카드 또는 가상계좌, 계좌이체 결제를 통해 광고를 접수할 수 있습니다.<br/>
            5. 주문 접수 후 검수가 시작되며, 5영업일 이내에 광고가 시작됩니다.<br/>
            6. 회사가 확약한 빙고팡팡 플레이 수에 도달하게 되면 광고가 종료되며, 누적 데이터를 광고 보고서 형태로 볼 수 있습니다.
            </label>

            <br/>
            <label className='font14b_1'>제 7조 (빙고팡팡 서비스 이용의 주의사항)</label>
            <label className='font14b_2'>
            1. 제공서비스를 주문하기전 회원은 회사에게 제공하는 자료가 제3자 모두에게 공개 및 저작권에 위배되지 않는지 확인해야 합니다.<br/>
            2. ‘진행중’인 주문에 대해서 회원은 회사에게 제공한 자료(주문이행에 필요한 정보 또는 링크)의 상태를 변경 해서는 안됩니다. 또한 자료(주문이행에 필요한 정보 또는 링크) 링크를 온전히 작동하지 못하게 만드는 그 어떠한 변경도 해서는 안됩니다. 이러한 변경에는 사용자 이름/프로파일 이름 변경이 포함됩니다. ‘진행중’ 주문에 이러한 변경이 이루어진다면, 주문은 주문한 수량만큼 주문전달이 안되었을지라도 자동으로 주문완료 처리되어집니다. 이러한 모든 변경 작업은 회사가 주문을 인도한 이후에만 이루어질 수 있습니다. 작업도중 이러한 변경으로 인해 목표수치보다 덜 유입된 문제에 대해서는 회사가 책임지지 않습니다.<br/>
            3. 회원의 과실로 인해 잘못 입력된 주문에 대해서도 주문은 진행 또는 완료 될 수 있습니다.<br/>
            4. ‘회사’의 서비스를 이용함과 동시에 외부에서 추가적으로 작업이 발생되면 회사가 이행한 작업과 외부에서의 작업이 중복될 수 있습니다. 따라서, 원활한 작업을 위해 회사가 작업을 ‘완료’한 뒤 자체적으로 광고 혹은 외부서비스를 이용해 주셔야 합니다.<br/>
            5. ‘회사’는 전달되는 서비스 ‘수량’에 대해서 ‘회원’이 주문한 ‘주문수량’을 최대한 정확하게 전달할 수 있도록 최선을 다합니다. 단, 구축되어있는 시스템 특성상 ‘회사’는 ‘회원’이 주문한 ‘주문수량’만큼 정확하게 전달함을 보증하지 않습니다.
            </label>

            <br/>
            <label className='font14b_1'>제 8조 (빙고팡팡 서비스 주문 및 주문취소)</label>
            <label className='font14b_2'>
            1. ‘광고 종료’된 주문에 대해서는 주문 취소, 환불이 불가능 합니다.<br/>
            2. 주문이 접수되면 최초 주문의 주문상태는 '광고 검수 중' 입니다. 검수중인 주문은 5영업일 이내 '광고 게시중'상태가 되며, 진행중인 주문에 한해 취소가 가능합니다 . 회원 변심에 따라 주문취소 및 환불을 요청하는 경우, 광고 게시중에 지불된 금액 중 남은 금액의 20% 환불 수수료를 제하고 회원의 계좌로 환불됩니다.<br/>
            3. 회사는 주문이 기대에 비해 느리게 진행되고 있다고 하더라도 ‘진행중’ 상태인 주문에 대해서는 환불 및 주문취소를 하지 않습니다. 단 ‘광고 게시 중’ 상태에서 45일이 경과하더라도 광고 수행이 완료되지 않았을 경우에는 지불된 금액 중 남은 금액에 한해 회원의 계좌로 환불됩니다.<br/>
            4. 제7조 '빙고팡팡 서비스이용의 주의사항'에서 규정한 사항에 위배되는 행위를 한 경우 주문취소가 불가능 합니다.

            </label>

            <br/>
            <label className='font14b_1'>제 9조 (회사의 SNS 서비스)</label>
            <label className='font14b_2'>
            1. 본 서비스는 회사의 업무, 기술상의 장애 등 특별한 사유가 없는 한 연중무휴 24시간 서비스를 제공합니다.<br/>
            2. 회사는 언제든지 제공하는 서비스의 이용을 제한하고 가격변경을 할 수 있습니다.<br/>
            3. 본 서비스 사용에 동의한 회원에 한하여 더 나은 서비스 사용환경 제공을 목적으로 회원의 네트워크 및 PC 자원을 부분적으로 활용할 수 있습니다.<br/>
            4. 회사는 봇 계정, 실명계정 또는 유령계정 생성 작업을 직접하지 않으며, 매크로 프로그램, 봇 사용 등 불법적인 행위로 서비스를 제공하지 않습니다.<br/>
            5. 불법적인 서비스 (불법 도박, 성인 사이트 등) 및 악의적인 홍보는 불가능합니다. 모든 서비스는 자동으로 진행되기 때문에 구매자가 링크를 입력, 주문하여 자동으로 서비스가 시작되었을지라도 추후에 발생할 수 있는 불이익에 대한 책임을 회사는 지지 않습니다.<br/>
            6. 회사는 회원이 회사의 서비스를 이용하여 수익을 얻을 수 있다고 홍보 및 약속 하지 않습니다.<br/>
            7. 기술상의 장애등 특별한 사유가 있을 경우, 회사가 제공하는 서비스의 주문이행은 다소 ‘지연’이 될 수 있습니다.<br/>
            8. 본 서비스를 이용함으로써 일어날 수 있는 모든 책임은 회원에게 있습니다.<br/>
            9. 모든 충전은 자동으로 이루어집니다. 단, 이러한 시스템이 악용 될 경우 회사는 고발 및 고소를 취할수있으며 수사요청에 응할수있습니다.
            </label>

            <br/>
            <label className='font14b_1'>제 10조 (SNS 서비스 이용의 주의사항)</label>
            <label className='font14b_2'>
            1. 제공서비스를 주문하기전 회원은 회사에게 제공하는 자료 (주문이행에 필요한 정보 또는 링크)가 제3자 모두에게 공개될 수 있는 ‘전체공개’ 상태인지 확인을 해야 합니다. ‘전체공개’상태가 아닌 상태에서 주문을 할 경우에는 주문한 수량만큼 주문전달이 안될 수 있습니다.<br/>
            2. ‘진행중’인 주문에 대해서 회원은 회사에게 제공한 자료(주문이행에 필요한 정보 또는 링크)의 상태를 변경 해서는 안됩니다. 또한 자료(주문이행에 필요한 정보 또는 링크) 링크를 구식으로 만드는 그 어떠한 변경도 해서는 안됩니다. 이러한 변경에는 사용자 이름/프로파일 이름 변경이 포함됩니다. ‘진행중’ 주문에 이러한 변경이 이루어진다면, 주문은 주문한 수량만큼 주문전달이 안되었을지라도 자동으로 주문완료 처리되어집니다. 이러한 모든 변경 작업은 회사가 주문을 인도한 이후에만 이루어질 수 있습니다. 작업도중 이러한 변경으로 인해 목표수치보다 덜 유입된 문제에 대해서는 회사가 책임지지 않습니다.<br/>
            3. 회원의 과실로 인해 잘못 입력된 주문에 대해서도 주문은 진행 또는 완료 될 수 있습니다.<br/>
            4. ‘회사’의 서비스를 이용함과 동시에 외부에서 추가적으로 작업이 발생되면 회사가 이행한 작업과 외부에서의 작업이 중복될 수 있습니다. 따라서, 원활한 작업을 위해 회사가 작업을 ‘완료’한 뒤 자체적으로 광고 혹은 외부서비스를 이용해 주셔야 합니다.<br/>
            5. ‘회사’는 전달되는 서비스 ‘수량’에 대해서 ‘회원’이 주문한 ‘주문수량’을 최대한 정확하게 전달할 수 있도록 최선을 다합니다. 단, 구축되어있는 시스템 특성상 ‘회사’는 ‘회원’이 주문한 ‘주문수량’만큼 정확하게 전달함을 보증하지 않습니다.
            </label>

            <br/>
            <label className='font14b_1'>제 11조 (SNS 서비스 주문 및 주문취소)</label>
            <label className='font14b_2'>
            1. ‘완료’된 주문에 대해서는 주문 취소, 환불이 불가능 합니다.<br/>
            2. 시스템에 전달된 주문은 순차적으로 진행되며 모든 서비스의 완료기한은 주문했을 때의 서버 상태에 따라 모두 다르기 때문에 예측을 할 수 없으며, 평균적인 완료기한을 알고 싶다면 1:1문의를 통해 확인하시기 바랍니다. 회사는 주문이 언제 완료될 지에 대한 최선의 추정치를 제공합니다만, 그것은 보장이 아니고 항상 정확하지도 않습니다.<br/>
            3. 주문이 접수가 되면 최초 주문의 주문상태는 '준비중' 입니다. 준비중인 주문은 수분 이내 '진행'되며, 진행중인 주문 중 수행되지 않은 주문에 한해 취소가 가능합니다 . 회원의 취소요청 후, 회사가 주문을 이행하는 것이 불가능하다고 판단하면 취소 요청 시점으로 부터 최소 3분 후에 주문 취소가 완료됩니다. 구매에 지불된 금액중 남은 금액에 한해 구매자의 포인트로 환불됩니다.<br/>
            4. 제공하는 서비스는 ‘소모품’ 형식의 서비스 입니다. 서비스의 특성상 작업된 수량은 언제든지 이탈될 수 있습니다. 이탈된 완료작업 수량에 대하여 회사는 책임을 지거나, 환불을 하거나, 주문에 대한 작업을 재진행하지 않습니다.<br/>
            (하지만 회원은 회사 AS규정에 한하여 AS 신청을 할 수 있습니다.)<br/>
            5. 제10조 'SNS 서비스이용의 주의사항'에서 규정한 사항에 위배되는 행위를 한 경우 주문취소가 불가능 합니다.<br/>
            6. 회사는 주문이 기대에 비해 느리게 진행되고 있다고 하더라도 ‘진행중’ 상태인 주문에 대해서는 환불 및 주문취소를 하지 않습니다.
            </label>

            <br/>
            <label className='font14b_1'>제 12조 (SNS 서비스 포인트 [충전잔액])</label>
            <label className='font14b_2'>
            1. 회원은 플랫폼에 포인트(잔액)를 충전함으로써 머니팡 애즈의 모든 서비스를 기재된 가격으로 이용할 수 있습니다.<br/>
            2. 머니팡 애즈에서의 포인트(잔액) 충전은 다음을 통하여 할 수 있습니다.<br/>
            &nbsp;가. 계좌이체<br/>
            &nbsp;나. 가상계좌<br/>
            &nbsp;다. 신용카드<br/>
            3. 무통장입금은 정확한 입금 금액을 발급받은 계좌로 입금할 경우 자동 충전되어집니다. 자동 충전이 되지 않을 경우 회원의 요청으로 충전이 될 수 있습니다.<br/>
            4. 무상으로 적립된 포인트를 통해 서비스를 구매한 경우, 현금영수증 또는 세금계산서가 발행되지 않습니다.<br/>
            5. 유상으로 충전한 포인트(실제로 회원이 결제한 금액)는 무상으로 지급받은 포인트보다 먼저 사용됩니다.<br/>
            6. 회원이 부당 또는 부정하게 포인트를 취득한 경우 회원은 해당 포인트를 사용할 수 없으며 회사는 이를 회수할 수 있습니다.<br/>
            7. 충전된 포인트의 유효기간은 회원이 마지막 로그인한 날로부터 1년입니다. 해당 기간이 지날 경우 회원은 충전된 포인트에 대한 권리를 포기하는 것으로 간주하며 회원은 소멸된 금액을 재청구할 수 없습니다.<br/>
            8. 회사는 회원에게 포인트 소진일 30일 전에 소멸 예정 사실을 머니팡 애즈 사이트 내 화면에 통지하며 소멸된 포인트는 회사에 귀속됩니다.<br/>
            9. 회원의 변심에 의해 충전된 포인트를 환불하는 경우 20% 를 환불 수수료로 제하고 회원의 계좌로 환불됩니다.
            </label>

            <br/>
            <label className='font14b_1'>제 13조 (회원 불만형태별 처리절차 및 처리기간)</label>
            <label className='font14b_2'>
            이용자 불만형태와 처리절차 및 처리기간은 아래와 같습니다.<br/>
            &nbsp;- 불만형태 : 결제, 오류(장애), 서비스 제한 등.<br/>
            &nbsp;- 이용자는 서비스에 불만이 있을 시, 회사의 고객센터를 이용하여 언제든지 건의할 수 있습니다.<br/>
            &nbsp;- 회사는 고객센터의 이용자 불만사항에 대하여 최대한 빠른 시일내에 답변 및 조치사항을 회신합니다.
            </label>

            <br/>
            <label className='font14b_1'>제 14조 (회원 탈퇴 및 자격 상실 등)</label>
            <label className='font14b_2'>
            1. 회원은 머니팡 애즈에서 언제든지 탈퇴를 요청할 수 있으며 머니팡 애즈는 즉시 회원 탈퇴 절차를 진행합니다.<br/>
            단, 탈퇴 의사를 통지하기 전에 현재 진행 중인 모든 거래를 완료하거나 철회 또는 취소하여야 하며, 거래의 철회 또는 취소로 인한 불이익은 회원 본인이 부담하여야 합니다. 또한, 회원이 회사에 대한 채무를 전부 이행하지 않으면 회원이 해당 채무를 전부 이행할 때까지 회사는 회원의 탈퇴를 제한할 수 있으며, 회원이 이 약관과 관계 법령 등을 위반하여 이용이 정지된 경우 회사가 재발방지를 위하여 회원의 탈퇴를 제한할 수 있습니다.<br/>
            2. 회원은 다음 각 호에 해당하는 행위를 하여서는 안되며, 해당 행위를 하는 경우에 회사는 회원의 서비스 이용 제한 및 법적 조치를 포함한 제재를 가할 수 있습니다.<br/>
              &nbsp;가. 회원가입 신청 또는 회원정보 변경 시 허위 내용을 등록하는 행위<br/>
              &nbsp;나. 타인의 ID, 비밀번호, 연락처를 도용하는 행위<br/>
              &nbsp;다. 이용자 ID를 타인과 거래하는 행위<br/>
              &nbsp;라. 서비스에 위해를 가하거나 고의로 방해하는 행위<br/>
              &nbsp;마. 본 서비스를 통해 얻은 정보를 회사의 사전 승낙 없이 서비스 이용 외의 목적으로 복제하거나, 이를 출판 및 방송 등에 사용하거나, 제3자에게 제공하는 행위<br/>
              &nbsp;바. 공공질서 및 미풍양속에 위반되는 저속, 음란한 내용의 정보, 문장, 도형, 음향, 동영상을 전송, 게시, 전자우편 또는 기타의 방법으로 타인에게 유포하는 행위<br/>
              &nbsp;사. 모욕적이거나 개인 신상에 대한 내용이어서 타인의 명예나 프라이버시를 침해할 수 있는 내용을 전송, 게시, 전자우편 또는 기타의 방법으로 타인에게 유포하는 행위<br/>
              &nbsp;아. 범죄와 결부된다고 객관적으로 판단되는 행위<br/>
              &nbsp;자. 머니팡 애즈 구성원, 협력사 등에 대한 업무방해 또는 욕설 등을 하는 경우<br/>
              &nbsp;차. 본 약관을 포함하여 기타 회사가 정한 제반 규정 또는 이용 조건을 위반하는 행위<br/>
              &nbsp;카. 머니팡 애즈의 운영 정책에 동의하지 않는 경우<br/>
              &nbsp;타. 기타 관계법령에 위배되는 행위<br/>
              &nbsp;파. 제 13조 회원의 의무에서 규정한 사항에 위배되는 행위를 한 경우<br/>
            3. 머니팡 애즈가 회원자격을 상실시키는 경우에는 회원 등록을 말소합니다. 이 경우 회원에게 이를 통지합니다.<br/>
            4. 강제 탈퇴가 된 회원에 한해 재가입 방지를 위해 기본적인 회원정보가 보관되며, 포인트(잔액)는 환불되지 않습니다.<br/>
            5. 제 14 조 회원의 의무'에서 규정한 사항에 위배되는 행위를 한 경우
            </label>

            <br/>
            <label className='font14b_1'>제 15조 (회사의 의무)</label>
            <label className='font14b_2'>
            1. 회사는 법령과 이 약관이 금지하는 행위를 하지 않으며 이 약관이 정하는 바에 따라 지속적이고, 안정적으로 서비스를 제공하는데 최선을 다하여야 합니다.<br/>
            2. 회사는 서비스제공과 관련하여 취득한 이용고객의 정보를 타인에게 누설 또는 배포 할 수 없으며 상업적 목적으로 사용할 수 없습니다.<br/>
            3. 회사의 의무에 대한 면책사유는 다음과 같습니다.<br/>
              &nbsp;- 가. 회사는 통신판매자로서 시스템만 제공할 뿐, 회원이 등록한 정보 또는 거래에 관하여 분쟁이 발생한 경우 회사는 그 어떠한 분쟁에도 개입하지 않으며 그 분쟁의 결과로 인한 모든 책임은 회원이 부담합니다. 또한 이와 관련하여 회사가 제3자에게 손해를 배상하거나 기타 비용을 지출한 경우 회사는 판매회원에게 구상권을 행사할 수 있습니다.<br/>
              &nbsp;- 나. 회사는 천재지변 또는 이에 준하는 불가항력, 정보통신설비의 보수점검, 교체 또는 고장, 통신의 두절 등으로 인하여 서비스를 제공할 수 없는 경우에는 서비스 제공에 대한 책임이 면제됩니다.<br/>
              &nbsp;- 다. 회사는 기간통신 사업자가 전기통신 서비스를 중지하거나 정상적으로 제공하지 아니하여 손해가 발생한 경우 책임이 면제됩니다.<br/>
              &nbsp;- 라. 회사는 서비스용 설비의 보수, 교체, 정기점검, 공사 등 부득이한 사유로 발생한 손해에 대한 책임이 면제됩니다.<br/>
              &nbsp;- 마. 회사는 회원의 귀책사유로 인한 서비스 이용의 장애 또는 손해에 대하여 책임을 지지 않습니다.<br/>
              &nbsp;- 바. 회사는 이용자의 컴퓨터 오류에 의해 손해가 발생한 경우, 또는 회원이 신상정보 및 전자우편 주소를 부실하게 기재하여 손해가 발생한 경우 책임을 지지 않습니다.<br/>
              &nbsp;- 아. 회사는 회원이 서비스를 이용하면서 얻은 자료로 인한 손해에 대하여 책임을 지지 않습니다.<br/>
              &nbsp;- 자. 회원이 서비스 진행에 있어 주문진행이 불가능한 자료 전달에 대한 책임.<br/>
              &nbsp;- 차. 회원 상호간 또는 회원과 제3자 상호간의 서비스를 매개로 하여 발생된 손해.<br/>
              &nbsp;- 카. 회사의 서비스구매 또는 이용으로 인해 발생되는 손해.<br/>
            4. 회사는 회원 권리(서비스 안정성, 계정보호 및 악의적 이용 방지 등) 보호를 위해 동일계정의 동시접속을 제한할 수 있습니다.

            </label>

            <br/>
            <label className='font14b_1'>제 16조 (회원의 의무)</label>
            <label className='font14b_2'>
            1. 이용자는 회원가입 신청 또는 회원정보 변경 시 모든 사항을 사실과 실명(회사의 요청 시)에 근거하여 작성하여야 하며, 허위 또는 타인의 정보를 등록할 경우 일체의 권리를 주장할 수 없으며 그에 따른 민형사상 책임 처벌을 받아야 합니다.<br/>
            2. 회원은 본 약관에서 규정하는 사항과 기타 회사가 정한 제반 규정, 공지사항 등 회사가 공지하는 사항 및 관계법령을 준수하여야 하며, 기타 회사의 업무에 방해가 되는 행위, 회사의 명예를 손상시키는 행위로 회사가 피해를 입는 경우 회사가 회원에게 손해배상 및 형사고소를 취할 수 있습니다.<br/>
            3. 회원은 연락처, 이메일 주소 등 이용계약사항이 변경된 경우에 해당 절차를 거쳐 이를 회사에 즉시 알려야 합니다.<br/>
            4. 회사가 관계법령 및 '개인정보 취급방침'에 의거하여 그 책임을 지는 경우를 제외하고 회원에게 부여된 ID의 비밀번호 관리 소홀, 부정 사용에 의하여 발생하는 모든 결과에 대한 책임은 회원에게 있으며 이에 따른 피해는 머니팡 애즈에게 청구할 수 없습니다.<br/>
            5. 회원은 회사의 명시적 동의가 없는 한 서비스의 이용권한, 기타 이용계약상의 지위를 타인에게 양도, 증여할 수 없으며 이를 담보로 제공할 수 없습니다.<br/>
            6. 회원은 아이디와 비밀번호를 관리할 의무를 가지며 회원의 아이디와 비밀번호를 사용하여 발생하는 모든 결과에 대한 책임은 전적으로 회원에게 있습니다.<br/>
            7. 다른 회원의 계정을 부당하게 사용하는 행위를 하지 않아야 합니다.<br/>
            8. 회사의 저작권, 제3자의 저작권 등 기타 권리를 침해하는 행위는 하지 않아야 합니다.<br/>
            9. 회원은 아이디와 비밀번호를 제3자에게 양도 공유할 수 없으며, 이를 위반하여 발생되는 모든 결과에 대한 책임은 전적으로 회원에게 있습니다.<br/>
            10. 회원은 회사에 위해를 가하는 활동을 할 수 없으며, 해당 활동의 결과로 인하여 발생된 책임에 대하여 회사는 책임지지 않습니다. 회원은 이와 같은 활동에 대하여 회사에 대한 손해배상 의무를 집니다.<br/>
            11. 머니팡 애즈가 정한 정보 이외의 정보(컴퓨터 프로그램 등) 등의 송신 또는 게시<br/>
            12. 서비스에서 얻은 정보를 회사의 사전 승낙 없이 회원의 이용 이외의 목적으로 복제하거나 이를 출판 및 방송 등에 사용하거나 제3자에게 제공하는 행위를 하지 않아야 합니다.<br/>
            13. 머니팡 애즈 기타 제3자의 명예를 손상시키거나 업무를 방해하는 행위
            </label>

            <br/>
            <label className='font14b_1'>제 17조 (저작권의 귀속 및 이용제한)</label>
            <label className='font14b_2'>
            1. 머니팡 애즈가 작성한 저작물에 대한 저작권 기타 지적재산권은 머니팡 애즈에 귀속합니다.<br/>
            2. 회원은 머니팡 애즈를 이용함으로써 얻은 정보 중 머니팡 애즈에게 지적재산권이 귀속된 정보를 머니팡 애즈의 사전 승낙 없이 복제, 송신, 출판, 배포, 방송 기타 방법에 의하여 영리목적으로 이용하거나 제3자에게 이용하게 하여서는 안됩니다.
            </label>

            <br/>
            <label className='font14b_1'>제 18조 (약관의 해석)</label>
            <label className='font14b_2'>
            1. ‘회사’는 ‘약관’ 외에 별도의 서비스운영정책 등을 둘 수 있습니다.<br/>
            2. ‘약관’에서 정하지 않은 사항이나 해석에 대하여는 서비스운영정책, 이용안내, 공지사항, 자주묻는질문, 사이트내 모든 페이지, 관련 법령에 따르고, 본 약관과 다른 별도 합의가 있는 경우 별도 합의가 우선적용됩니다.
            </label>

            <br/>
            <label className='font14b_1'>제 19조 (손해배상 등)</label>
            <label className='font14b_2'>
            1. 회사와 회원은 일방의 귀책사유로 인하여 본 이용계약의 각 조항을 위반함으로써 상대방이 손해를 입었을 때에는 손해배상을 청구할 수 있습니다.<br/>
            2. 회원의 귀책사유로 인하여 회사가 소비자, 소비자단체, 관공서, 기타 제3자로부터 손해배상의 청구, 제소, 행정조치, 기타 법률상의 책임을 부담하게 될 경우 회원은 회사를 방어하고 면책시키며 일체의 피해, 손실에 대하여 배상책임을 집니다.
            </label>

            <br/>
            <label className='font14b_1'>제 20조 (준거법 및 재판관할)</label>
            <label className='font14b_2'>
            1. 이 약관에 명시되지 않은 사항은 전기통신사업법 등 관계법령과 상관습에 따릅니다.<br/>
            2. 서비스 이용으로 발생한 분쟁에 대해 소송이 제기되는 경우 회사의 본사 소재지를 관할하는 법원을 관할 법원으로 합니다.
            </label>

            <br/>
            <label className='font14b_1'>제 21조 (약관 외 준칙)</label>
            <label className='font14b_2'>
            1. 이 약관은 머니팡 애즈에서 제공하는 개별 서비스에 관한 이용안내와 함께 적용합니다.<br/>
            2. 이 약관에 명시되지 아니한 사항에 대해서는 관계법령 및 이용안내의 취지 또는 통상적인 관습에 따라 적용할 수 있습니다. 회원이 제공하고 등록한 정보에 대해서는 해당 회원이 그에 대한 직접적인 책임을 전적으로 부담하여야 하고 만약 재능에 손실이 발생하였을 경우 그에 따른 배상을 하거나 재능의 면책을 위해 적극 협조하여야 한다.
            </label>

            <div className='termbottom'></div>
          </div>
        </div>
      </div>
    );
  }
  else
  {
    return (
      <div className='termpopbg flexcolcc'>
  
        <div className='termpop flexcoltc'>
          <div className='termtitle flexrowsc margintop25'>
            <label className='font20b2 marginleft25'>이용약관</label>
            <img src={GetImgUrl("term/close.png")} alt="close" className='btncur marginright25 menubtn' onClick={props.handleClose}/>
          </div>
  
          <div className='termcont flexcoltl margintop30'>
            <br/>
            <label className='font14b_1'>제 1조 (목적)</label>
            <label className='font14b_2'>이 약관은 주식회사 브라보식스(이하 ‘회사’)가 운영하는 머니팡 애즈 서비스(링크, 이하 ‘머니팡 애즈’ 또는 ‘서비스’라 합니다.)를 이용함에 있어 회사와 회원 간의 권리, 의무 및 책임사항을 규정함을 목적으로 합니다.</label>

            <br/>
            <label className='font14b_1'>제 2조 (정의)</label>
            <label className='font14b_2'>1. ‘머니팡 애즈’란 회사가 운영하는 ‘머니팡’ 어플리케이션(mpang.io) 내의 유저가 시청 및 수행하는 형태의 온라인 마케팅 서비스(이하 ‘서비스’라 함)를 회원에게 제공하기 위하여 컴퓨터등 정보통신설비를 이용하여 서비스를 구매 및 이용 수 있도록 설정한 가상의 영업장을 말하며, 아울러 서비스를 운영하는 사업자의 의미로도 사용합니다.<br/>
            2. ‘회원’이란 ‘머니팡 애즈’에 개인정보를 제공하여 가입등록을 한 자로서, ‘머니팡 애즈’를 지속적으로 제공받으며, ‘머니팡 애즈’에서 제공하는 서비스를 계속적으로 이용할 수 있는 자나 회사를 말합니다.<br/>
            3. ‘빙고팡팡 서비스’란 ‘머니팡 애즈’에서 회원이 주문한 일반형 및 집중형, 세줄 및 한줄 빙고팡팡 을 말합니다.<br/>
            4. ‘SNS 서비스’란 ‘머니팡 애즈’에서 회원이 주문한 SNS (인스타그램, 유튜브, 틱톡 등 SNS)의 트래픽 (팔로우 등의 트래픽)을 말합니다.
            </label>

            <br/>
            <label className='font14b_1'>제 3조 (약관의 명시와 설명 및 개정)</label>
            <label className='font14b_2'>
            1. 본 약관은 서비스 화면의 게시를 통하여 공지함으로써 효력이 발생됩니다.<br/>
            2. ‘머니팡 애즈’는 약관을 언제든지 수정 및 변경할 수 있습니다.<br/>
            3. 회원은 약관에 동의하지 않을 경우 자유로운 권리로 회원 탈퇴를 요청할 수 있으며, 변경된 약관의 효력발생일 이후 7일 이내에 해지 요청을 하지 않을 경우 변경사항에 동의하는 것으로 간주됩니다.
            </label>

            <br/>
            <label className='font14b_1'>제 4조 (회원가입)</label>
            <label className='font14b_2'>
            1. 이용자는 서비스에서 정한 가입 양식에 따라 회원정보를 기입한 후 본 약관에 동의한다는 의사표시를 함으로써 회원가입을 완료합니다.<br/>
            2. 머니팡 애즈는 제1항과 같이 회원으로 가입할 것을 신청한 이용자 중 다음 각호에 해당하지 않는 한 회원으로 등록합니다.<br/>
            &nbsp;가. 가입신청자가 이 약관 제12조 제2항에 의하여 이전에 회원자격을 상실한 적이 있는 경우<br/>
            &nbsp;나. 등록 내용에 허위, 기재누락, 오기가 있는 경우<br/>
            &nbsp;다. 기타, 회원으로 등록하는 것이 머니팡 애즈의 운영상 지장이 있다고 판단되는 경우
            </label>

            <br/>
            <label className='font14b_1'>제 5조 (회원아이디 관리)</label>
            <label className='font14b_2'>
            1. 회원아이디 및 비밀번호에 대한 관리책임은 이용자에게 있습니다.<br/>
            2. 회원아이디는 다음의 경우에 한하여 이용자의 요청 또는 회사의 판단에 의하여 변경 및 회원탈퇴할 수 있습니다.<br/>
            &nbsp;가. 아이디가 회원의 전화번호나 주민등록번호등으로 되어 있어서 사생활 및 정보침해의 우려가 있는 경우<br/>
            &nbsp;나. 서비스 운영자, 직원 또는 관계자로 오인할 수 있는 경우<br/>
            &nbsp;다. 상식적으로 합리적인 사유가 있는 경우<br/>

            </label>

            <br/>
            <label className='font14b_1'>제 6조 (회사의 빙고팡팡  서비스)</label>
            <label className='font14b_2'>
            1. 본 서비스는 회사의 업무, 기술상의 장애 등 특별한 사유가 없는 한 연중무휴 24시간 서비스를 제공합니다.<br/>
            2. 회사는 광고상품의 종류, 광고영역, 등록절차, 이용요금, 위약금 여부 등을 ‘머니팡애즈’내 공지사항이나 운영정책 등을 통해 안내합니다.<br/>
            3. 본 서비스는 회원이 광고 타겟(연령 및 성별, 지역 등의 광고 타겟)을 설정할 수 있으며, ‘머니팡’ 서비스 내의 빙고팡팡 게임 형태로 노출됩니다.<br/>
            4. 본 서비스 주문 접수시 카드 또는 가상계좌, 계좌이체 결제를 통해 광고를 접수할 수 있습니다.<br/>
            5. 주문 접수 후 검수가 시작되며, 5영업일 이내에 광고가 시작됩니다.<br/>
            6. 회사가 확약한 빙고팡팡 플레이 수에 도달하게 되면 광고가 종료되며, 누적 데이터를 광고 보고서 형태로 볼 수 있습니다.
            </label>

            <br/>
            <label className='font14b_1'>제 7조 (빙고팡팡 서비스 이용의 주의사항)</label>
            <label className='font14b_2'>
            1. 제공서비스를 주문하기전 회원은 회사에게 제공하는 자료가 제3자 모두에게 공개 및 저작권에 위배되지 않는지 확인해야 합니다.<br/>
            2. ‘진행중’인 주문에 대해서 회원은 회사에게 제공한 자료(주문이행에 필요한 정보 또는 링크)의 상태를 변경 해서는 안됩니다. 또한 자료(주문이행에 필요한 정보 또는 링크) 링크를 온전히 작동하지 못하게 만드는 그 어떠한 변경도 해서는 안됩니다. 이러한 변경에는 사용자 이름/프로파일 이름 변경이 포함됩니다. ‘진행중’ 주문에 이러한 변경이 이루어진다면, 주문은 주문한 수량만큼 주문전달이 안되었을지라도 자동으로 주문완료 처리되어집니다. 이러한 모든 변경 작업은 회사가 주문을 인도한 이후에만 이루어질 수 있습니다. 작업도중 이러한 변경으로 인해 목표수치보다 덜 유입된 문제에 대해서는 회사가 책임지지 않습니다.<br/>
            3. 회원의 과실로 인해 잘못 입력된 주문에 대해서도 주문은 진행 또는 완료 될 수 있습니다.<br/>
            4. ‘회사’의 서비스를 이용함과 동시에 외부에서 추가적으로 작업이 발생되면 회사가 이행한 작업과 외부에서의 작업이 중복될 수 있습니다. 따라서, 원활한 작업을 위해 회사가 작업을 ‘완료’한 뒤 자체적으로 광고 혹은 외부서비스를 이용해 주셔야 합니다.<br/>
            5. ‘회사’는 전달되는 서비스 ‘수량’에 대해서 ‘회원’이 주문한 ‘주문수량’을 최대한 정확하게 전달할 수 있도록 최선을 다합니다. 단, 구축되어있는 시스템 특성상 ‘회사’는 ‘회원’이 주문한 ‘주문수량’만큼 정확하게 전달함을 보증하지 않습니다.
            </label>

            <br/>
            <label className='font14b_1'>제 8조 (빙고팡팡 서비스 주문 및 주문취소)</label>
            <label className='font14b_2'>
            1. ‘광고 종료’된 주문에 대해서는 주문 취소, 환불이 불가능 합니다.<br/>
            2. 주문이 접수되면 최초 주문의 주문상태는 '광고 검수 중' 입니다. 검수중인 주문은 5영업일 이내 '광고 게시중'상태가 되며, 진행중인 주문에 한해 취소가 가능합니다 . 회원 변심에 따라 주문취소 및 환불을 요청하는 경우, 광고 게시중에 지불된 금액 중 남은 금액의 20% 환불 수수료를 제하고 회원의 계좌로 환불됩니다.<br/>
            3. 회사는 주문이 기대에 비해 느리게 진행되고 있다고 하더라도 ‘진행중’ 상태인 주문에 대해서는 환불 및 주문취소를 하지 않습니다. 단 ‘광고 게시 중’ 상태에서 45일이 경과하더라도 광고 수행이 완료되지 않았을 경우에는 지불된 금액 중 남은 금액에 한해 회원의 계좌로 환불됩니다.<br/>
            4. 제7조 '빙고팡팡 서비스이용의 주의사항'에서 규정한 사항에 위배되는 행위를 한 경우 주문취소가 불가능 합니다.

            </label>

            <br/>
            <label className='font14b_1'>제 9조 (회사의 SNS 서비스)</label>
            <label className='font14b_2'>
            1. 본 서비스는 회사의 업무, 기술상의 장애 등 특별한 사유가 없는 한 연중무휴 24시간 서비스를 제공합니다.<br/>
            2. 회사는 언제든지 제공하는 서비스의 이용을 제한하고 가격변경을 할 수 있습니다.<br/>
            3. 본 서비스 사용에 동의한 회원에 한하여 더 나은 서비스 사용환경 제공을 목적으로 회원의 네트워크 및 PC 자원을 부분적으로 활용할 수 있습니다.<br/>
            4. 회사는 봇 계정, 실명계정 또는 유령계정 생성 작업을 직접하지 않으며, 매크로 프로그램, 봇 사용 등 불법적인 행위로 서비스를 제공하지 않습니다.<br/>
            5. 불법적인 서비스 (불법 도박, 성인 사이트 등) 및 악의적인 홍보는 불가능합니다. 모든 서비스는 자동으로 진행되기 때문에 구매자가 링크를 입력, 주문하여 자동으로 서비스가 시작되었을지라도 추후에 발생할 수 있는 불이익에 대한 책임을 회사는 지지 않습니다.<br/>
            6. 회사는 회원이 회사의 서비스를 이용하여 수익을 얻을 수 있다고 홍보 및 약속 하지 않습니다.<br/>
            7. 기술상의 장애등 특별한 사유가 있을 경우, 회사가 제공하는 서비스의 주문이행은 다소 ‘지연’이 될 수 있습니다.<br/>
            8. 본 서비스를 이용함으로써 일어날 수 있는 모든 책임은 회원에게 있습니다.<br/>
            9. 모든 충전은 자동으로 이루어집니다. 단, 이러한 시스템이 악용 될 경우 회사는 고발 및 고소를 취할수있으며 수사요청에 응할수있습니다.
            </label>

            <br/>
            <label className='font14b_1'>제 10조 (SNS 서비스 이용의 주의사항)</label>
            <label className='font14b_2'>
            1. 제공서비스를 주문하기전 회원은 회사에게 제공하는 자료 (주문이행에 필요한 정보 또는 링크)가 제3자 모두에게 공개될 수 있는 ‘전체공개’ 상태인지 확인을 해야 합니다. ‘전체공개’상태가 아닌 상태에서 주문을 할 경우에는 주문한 수량만큼 주문전달이 안될 수 있습니다.<br/>
            2. ‘진행중’인 주문에 대해서 회원은 회사에게 제공한 자료(주문이행에 필요한 정보 또는 링크)의 상태를 변경 해서는 안됩니다. 또한 자료(주문이행에 필요한 정보 또는 링크) 링크를 구식으로 만드는 그 어떠한 변경도 해서는 안됩니다. 이러한 변경에는 사용자 이름/프로파일 이름 변경이 포함됩니다. ‘진행중’ 주문에 이러한 변경이 이루어진다면, 주문은 주문한 수량만큼 주문전달이 안되었을지라도 자동으로 주문완료 처리되어집니다. 이러한 모든 변경 작업은 회사가 주문을 인도한 이후에만 이루어질 수 있습니다. 작업도중 이러한 변경으로 인해 목표수치보다 덜 유입된 문제에 대해서는 회사가 책임지지 않습니다.<br/>
            3. 회원의 과실로 인해 잘못 입력된 주문에 대해서도 주문은 진행 또는 완료 될 수 있습니다.<br/>
            4. ‘회사’의 서비스를 이용함과 동시에 외부에서 추가적으로 작업이 발생되면 회사가 이행한 작업과 외부에서의 작업이 중복될 수 있습니다. 따라서, 원활한 작업을 위해 회사가 작업을 ‘완료’한 뒤 자체적으로 광고 혹은 외부서비스를 이용해 주셔야 합니다.<br/>
            5. ‘회사’는 전달되는 서비스 ‘수량’에 대해서 ‘회원’이 주문한 ‘주문수량’을 최대한 정확하게 전달할 수 있도록 최선을 다합니다. 단, 구축되어있는 시스템 특성상 ‘회사’는 ‘회원’이 주문한 ‘주문수량’만큼 정확하게 전달함을 보증하지 않습니다.
            </label>

            <br/>
            <label className='font14b_1'>제 11조 (SNS 서비스 주문 및 주문취소)</label>
            <label className='font14b_2'>
            1. ‘완료’된 주문에 대해서는 주문 취소, 환불이 불가능 합니다.<br/>
            2. 시스템에 전달된 주문은 순차적으로 진행되며 모든 서비스의 완료기한은 주문했을 때의 서버 상태에 따라 모두 다르기 때문에 예측을 할 수 없으며, 평균적인 완료기한을 알고 싶다면 1:1문의를 통해 확인하시기 바랍니다. 회사는 주문이 언제 완료될 지에 대한 최선의 추정치를 제공합니다만, 그것은 보장이 아니고 항상 정확하지도 않습니다.<br/>
            3. 주문이 접수가 되면 최초 주문의 주문상태는 '준비중' 입니다. 준비중인 주문은 수분 이내 '진행'되며, 진행중인 주문 중 수행되지 않은 주문에 한해 취소가 가능합니다 . 회원의 취소요청 후, 회사가 주문을 이행하는 것이 불가능하다고 판단하면 취소 요청 시점으로 부터 최소 3분 후에 주문 취소가 완료됩니다. 구매에 지불된 금액중 남은 금액에 한해 구매자의 포인트로 환불됩니다.<br/>
            4. 제공하는 서비스는 ‘소모품’ 형식의 서비스 입니다. 서비스의 특성상 작업된 수량은 언제든지 이탈될 수 있습니다. 이탈된 완료작업 수량에 대하여 회사는 책임을 지거나, 환불을 하거나, 주문에 대한 작업을 재진행하지 않습니다.<br/>
            (하지만 회원은 회사 AS규정에 한하여 AS 신청을 할 수 있습니다.)<br/>
            5. 제10조 'SNS 서비스이용의 주의사항'에서 규정한 사항에 위배되는 행위를 한 경우 주문취소가 불가능 합니다.<br/>
            6. 회사는 주문이 기대에 비해 느리게 진행되고 있다고 하더라도 ‘진행중’ 상태인 주문에 대해서는 환불 및 주문취소를 하지 않습니다.
            </label>

            <br/>
            <label className='font14b_1'>제 12조 (SNS 서비스 포인트 [충전잔액])</label>
            <label className='font14b_2'>
            1. 회원은 플랫폼에 포인트(잔액)를 충전함으로써 머니팡 애즈의 모든 서비스를 기재된 가격으로 이용할 수 있습니다.<br/>
            2. 머니팡 애즈에서의 포인트(잔액) 충전은 다음을 통하여 할 수 있습니다.<br/>
            &nbsp;가. 계좌이체<br/>
            &nbsp;나. 가상계좌<br/>
            &nbsp;다. 신용카드<br/>
            3. 무통장입금은 정확한 입금 금액을 발급받은 계좌로 입금할 경우 자동 충전되어집니다. 자동 충전이 되지 않을 경우 회원의 요청으로 충전이 될 수 있습니다.<br/>
            4. 무상으로 적립된 포인트를 통해 서비스를 구매한 경우, 현금영수증 또는 세금계산서가 발행되지 않습니다.<br/>
            5. 유상으로 충전한 포인트(실제로 회원이 결제한 금액)는 무상으로 지급받은 포인트보다 먼저 사용됩니다.<br/>
            6. 회원이 부당 또는 부정하게 포인트를 취득한 경우 회원은 해당 포인트를 사용할 수 없으며 회사는 이를 회수할 수 있습니다.<br/>
            7. 충전된 포인트의 유효기간은 회원이 마지막 로그인한 날로부터 1년입니다. 해당 기간이 지날 경우 회원은 충전된 포인트에 대한 권리를 포기하는 것으로 간주하며 회원은 소멸된 금액을 재청구할 수 없습니다.<br/>
            8. 회사는 회원에게 포인트 소진일 30일 전에 소멸 예정 사실을 머니팡 애즈 사이트 내 화면에 통지하며 소멸된 포인트는 회사에 귀속됩니다.<br/>
            9. 회원의 변심에 의해 충전된 포인트를 환불하는 경우 20% 를 환불 수수료로 제하고 회원의 계좌로 환불됩니다.
            </label>

            <br/>
            <label className='font14b_1'>제 13조 (회원 불만형태별 처리절차 및 처리기간)</label>
            <label className='font14b_2'>
            이용자 불만형태와 처리절차 및 처리기간은 아래와 같습니다.<br/>
            &nbsp;- 불만형태 : 결제, 오류(장애), 서비스 제한 등.<br/>
            &nbsp;- 이용자는 서비스에 불만이 있을 시, 회사의 고객센터를 이용하여 언제든지 건의할 수 있습니다.<br/>
            &nbsp;- 회사는 고객센터의 이용자 불만사항에 대하여 최대한 빠른 시일내에 답변 및 조치사항을 회신합니다.
            </label>

            <br/>
            <label className='font14b_1'>제 14조 (회원 탈퇴 및 자격 상실 등)</label>
            <label className='font14b_2'>
            1. 회원은 머니팡 애즈에서 언제든지 탈퇴를 요청할 수 있으며 머니팡 애즈는 즉시 회원 탈퇴 절차를 진행합니다.<br/>
            단, 탈퇴 의사를 통지하기 전에 현재 진행 중인 모든 거래를 완료하거나 철회 또는 취소하여야 하며, 거래의 철회 또는 취소로 인한 불이익은 회원 본인이 부담하여야 합니다. 또한, 회원이 회사에 대한 채무를 전부 이행하지 않으면 회원이 해당 채무를 전부 이행할 때까지 회사는 회원의 탈퇴를 제한할 수 있으며, 회원이 이 약관과 관계 법령 등을 위반하여 이용이 정지된 경우 회사가 재발방지를 위하여 회원의 탈퇴를 제한할 수 있습니다.<br/>
            2. 회원은 다음 각 호에 해당하는 행위를 하여서는 안되며, 해당 행위를 하는 경우에 회사는 회원의 서비스 이용 제한 및 법적 조치를 포함한 제재를 가할 수 있습니다.<br/>
              &nbsp;가. 회원가입 신청 또는 회원정보 변경 시 허위 내용을 등록하는 행위<br/>
              &nbsp;나. 타인의 ID, 비밀번호, 연락처를 도용하는 행위<br/>
              &nbsp;다. 이용자 ID를 타인과 거래하는 행위<br/>
              &nbsp;라. 서비스에 위해를 가하거나 고의로 방해하는 행위<br/>
              &nbsp;마. 본 서비스를 통해 얻은 정보를 회사의 사전 승낙 없이 서비스 이용 외의 목적으로 복제하거나, 이를 출판 및 방송 등에 사용하거나, 제3자에게 제공하는 행위<br/>
              &nbsp;바. 공공질서 및 미풍양속에 위반되는 저속, 음란한 내용의 정보, 문장, 도형, 음향, 동영상을 전송, 게시, 전자우편 또는 기타의 방법으로 타인에게 유포하는 행위<br/>
              &nbsp;사. 모욕적이거나 개인 신상에 대한 내용이어서 타인의 명예나 프라이버시를 침해할 수 있는 내용을 전송, 게시, 전자우편 또는 기타의 방법으로 타인에게 유포하는 행위<br/>
              &nbsp;아. 범죄와 결부된다고 객관적으로 판단되는 행위<br/>
              &nbsp;자. 머니팡 애즈 구성원, 협력사 등에 대한 업무방해 또는 욕설 등을 하는 경우<br/>
              &nbsp;차. 본 약관을 포함하여 기타 회사가 정한 제반 규정 또는 이용 조건을 위반하는 행위<br/>
              &nbsp;카. 머니팡 애즈의 운영 정책에 동의하지 않는 경우<br/>
              &nbsp;타. 기타 관계법령에 위배되는 행위<br/>
              &nbsp;파. 제 13조 회원의 의무에서 규정한 사항에 위배되는 행위를 한 경우<br/>
            3. 머니팡 애즈가 회원자격을 상실시키는 경우에는 회원 등록을 말소합니다. 이 경우 회원에게 이를 통지합니다.<br/>
            4. 강제 탈퇴가 된 회원에 한해 재가입 방지를 위해 기본적인 회원정보가 보관되며, 포인트(잔액)는 환불되지 않습니다.<br/>
            5. 제 14 조 회원의 의무'에서 규정한 사항에 위배되는 행위를 한 경우
            </label>

            <br/>
            <label className='font14b_1'>제 15조 (회사의 의무)</label>
            <label className='font14b_2'>
            1. 회사는 법령과 이 약관이 금지하는 행위를 하지 않으며 이 약관이 정하는 바에 따라 지속적이고, 안정적으로 서비스를 제공하는데 최선을 다하여야 합니다.<br/>
            2. 회사는 서비스제공과 관련하여 취득한 이용고객의 정보를 타인에게 누설 또는 배포 할 수 없으며 상업적 목적으로 사용할 수 없습니다.<br/>
            3. 회사의 의무에 대한 면책사유는 다음과 같습니다.<br/>
              &nbsp;- 가. 회사는 통신판매자로서 시스템만 제공할 뿐, 회원이 등록한 정보 또는 거래에 관하여 분쟁이 발생한 경우 회사는 그 어떠한 분쟁에도 개입하지 않으며 그 분쟁의 결과로 인한 모든 책임은 회원이 부담합니다. 또한 이와 관련하여 회사가 제3자에게 손해를 배상하거나 기타 비용을 지출한 경우 회사는 판매회원에게 구상권을 행사할 수 있습니다.<br/>
              &nbsp;- 나. 회사는 천재지변 또는 이에 준하는 불가항력, 정보통신설비의 보수점검, 교체 또는 고장, 통신의 두절 등으로 인하여 서비스를 제공할 수 없는 경우에는 서비스 제공에 대한 책임이 면제됩니다.<br/>
              &nbsp;- 다. 회사는 기간통신 사업자가 전기통신 서비스를 중지하거나 정상적으로 제공하지 아니하여 손해가 발생한 경우 책임이 면제됩니다.<br/>
              &nbsp;- 라. 회사는 서비스용 설비의 보수, 교체, 정기점검, 공사 등 부득이한 사유로 발생한 손해에 대한 책임이 면제됩니다.<br/>
              &nbsp;- 마. 회사는 회원의 귀책사유로 인한 서비스 이용의 장애 또는 손해에 대하여 책임을 지지 않습니다.<br/>
              &nbsp;- 바. 회사는 이용자의 컴퓨터 오류에 의해 손해가 발생한 경우, 또는 회원이 신상정보 및 전자우편 주소를 부실하게 기재하여 손해가 발생한 경우 책임을 지지 않습니다.<br/>
              &nbsp;- 아. 회사는 회원이 서비스를 이용하면서 얻은 자료로 인한 손해에 대하여 책임을 지지 않습니다.<br/>
              &nbsp;- 자. 회원이 서비스 진행에 있어 주문진행이 불가능한 자료 전달에 대한 책임.<br/>
              &nbsp;- 차. 회원 상호간 또는 회원과 제3자 상호간의 서비스를 매개로 하여 발생된 손해.<br/>
              &nbsp;- 카. 회사의 서비스구매 또는 이용으로 인해 발생되는 손해.<br/>
            4. 회사는 회원 권리(서비스 안정성, 계정보호 및 악의적 이용 방지 등) 보호를 위해 동일계정의 동시접속을 제한할 수 있습니다.

            </label>

            <br/>
            <label className='font14b_1'>제 16조 (회원의 의무)</label>
            <label className='font14b_2'>
            1. 이용자는 회원가입 신청 또는 회원정보 변경 시 모든 사항을 사실과 실명(회사의 요청 시)에 근거하여 작성하여야 하며, 허위 또는 타인의 정보를 등록할 경우 일체의 권리를 주장할 수 없으며 그에 따른 민형사상 책임 처벌을 받아야 합니다.<br/>
            2. 회원은 본 약관에서 규정하는 사항과 기타 회사가 정한 제반 규정, 공지사항 등 회사가 공지하는 사항 및 관계법령을 준수하여야 하며, 기타 회사의 업무에 방해가 되는 행위, 회사의 명예를 손상시키는 행위로 회사가 피해를 입는 경우 회사가 회원에게 손해배상 및 형사고소를 취할 수 있습니다.<br/>
            3. 회원은 연락처, 이메일 주소 등 이용계약사항이 변경된 경우에 해당 절차를 거쳐 이를 회사에 즉시 알려야 합니다.<br/>
            4. 회사가 관계법령 및 '개인정보 취급방침'에 의거하여 그 책임을 지는 경우를 제외하고 회원에게 부여된 ID의 비밀번호 관리 소홀, 부정 사용에 의하여 발생하는 모든 결과에 대한 책임은 회원에게 있으며 이에 따른 피해는 머니팡 애즈에게 청구할 수 없습니다.<br/>
            5. 회원은 회사의 명시적 동의가 없는 한 서비스의 이용권한, 기타 이용계약상의 지위를 타인에게 양도, 증여할 수 없으며 이를 담보로 제공할 수 없습니다.<br/>
            6. 회원은 아이디와 비밀번호를 관리할 의무를 가지며 회원의 아이디와 비밀번호를 사용하여 발생하는 모든 결과에 대한 책임은 전적으로 회원에게 있습니다.<br/>
            7. 다른 회원의 계정을 부당하게 사용하는 행위를 하지 않아야 합니다.<br/>
            8. 회사의 저작권, 제3자의 저작권 등 기타 권리를 침해하는 행위는 하지 않아야 합니다.<br/>
            9. 회원은 아이디와 비밀번호를 제3자에게 양도 공유할 수 없으며, 이를 위반하여 발생되는 모든 결과에 대한 책임은 전적으로 회원에게 있습니다.<br/>
            10. 회원은 회사에 위해를 가하는 활동을 할 수 없으며, 해당 활동의 결과로 인하여 발생된 책임에 대하여 회사는 책임지지 않습니다. 회원은 이와 같은 활동에 대하여 회사에 대한 손해배상 의무를 집니다.<br/>
            11. 머니팡 애즈가 정한 정보 이외의 정보(컴퓨터 프로그램 등) 등의 송신 또는 게시<br/>
            12. 서비스에서 얻은 정보를 회사의 사전 승낙 없이 회원의 이용 이외의 목적으로 복제하거나 이를 출판 및 방송 등에 사용하거나 제3자에게 제공하는 행위를 하지 않아야 합니다.<br/>
            13. 머니팡 애즈 기타 제3자의 명예를 손상시키거나 업무를 방해하는 행위
            </label>

            <br/>
            <label className='font14b_1'>제 17조 (저작권의 귀속 및 이용제한)</label>
            <label className='font14b_2'>
            1. 머니팡 애즈가 작성한 저작물에 대한 저작권 기타 지적재산권은 머니팡 애즈에 귀속합니다.<br/>
            2. 회원은 머니팡 애즈를 이용함으로써 얻은 정보 중 머니팡 애즈에게 지적재산권이 귀속된 정보를 머니팡 애즈의 사전 승낙 없이 복제, 송신, 출판, 배포, 방송 기타 방법에 의하여 영리목적으로 이용하거나 제3자에게 이용하게 하여서는 안됩니다.
            </label>

            <br/>
            <label className='font14b_1'>제 18조 (약관의 해석)</label>
            <label className='font14b_2'>
            1. ‘회사’는 ‘약관’ 외에 별도의 서비스운영정책 등을 둘 수 있습니다.<br/>
            2. ‘약관’에서 정하지 않은 사항이나 해석에 대하여는 서비스운영정책, 이용안내, 공지사항, 자주묻는질문, 사이트내 모든 페이지, 관련 법령에 따르고, 본 약관과 다른 별도 합의가 있는 경우 별도 합의가 우선적용됩니다.
            </label>

            <br/>
            <label className='font14b_1'>제 19조 (손해배상 등)</label>
            <label className='font14b_2'>
            1. 회사와 회원은 일방의 귀책사유로 인하여 본 이용계약의 각 조항을 위반함으로써 상대방이 손해를 입었을 때에는 손해배상을 청구할 수 있습니다.<br/>
            2. 회원의 귀책사유로 인하여 회사가 소비자, 소비자단체, 관공서, 기타 제3자로부터 손해배상의 청구, 제소, 행정조치, 기타 법률상의 책임을 부담하게 될 경우 회원은 회사를 방어하고 면책시키며 일체의 피해, 손실에 대하여 배상책임을 집니다.
            </label>

            <br/>
            <label className='font14b_1'>제 20조 (준거법 및 재판관할)</label>
            <label className='font14b_2'>
            1. 이 약관에 명시되지 않은 사항은 전기통신사업법 등 관계법령과 상관습에 따릅니다.<br/>
            2. 서비스 이용으로 발생한 분쟁에 대해 소송이 제기되는 경우 회사의 본사 소재지를 관할하는 법원을 관할 법원으로 합니다.
            </label>

            <br/>
            <label className='font14b_1'>제 21조 (약관 외 준칙)</label>
            <label className='font14b_2'>
            1. 이 약관은 머니팡 애즈에서 제공하는 개별 서비스에 관한 이용안내와 함께 적용합니다.<br/>
            2. 이 약관에 명시되지 아니한 사항에 대해서는 관계법령 및 이용안내의 취지 또는 통상적인 관습에 따라 적용할 수 있습니다. 회원이 제공하고 등록한 정보에 대해서는 해당 회원이 그에 대한 직접적인 책임을 전적으로 부담하여야 하고 만약 재능에 손실이 발생하였을 경우 그에 따른 배상을 하거나 재능의 면책을 위해 적극 협조하여야 한다.
            </label>

            <div className='termbottom'></div>
          </div>
        </div>
      </div>
    );
  }
  
}

export default Term;