import { GetImgUrl, mobileOn } from "../../URL/AppUrl";

const getInstaDesc = (adsType) =>
{
  switch(adsType)
  {
    case 0:
      if (mobileOn)
        return <label className='font12b lineheight20 margintop5 odleftlh'>게시물에 좋아요를 늘려주는 서비스입니다.<br/>좋아요의 개수가 많아질수록 인기 게시물에 선정될 확률이 증가하며, 한국인들로만 작업되기 때문에<br/>인기 게시물 작업을 원하시는 회원님들에게 추천하는 서비스입니다.</label>
      else
        return <label className='font15 wt400 color31 margintop5 odleftlh'>게시물에 좋아요를 늘려주는 서비스입니다.<br/>좋아요의 개수가 많아질수록 인기 게시물에 선정될 확률이 증가하며, 한국인들로만 작업되기 때문에<br/>인기 게시물 작업을 원하시는 회원님들에게 추천하는 서비스입니다.</label>
    case 1:
      if (mobileOn)
        return <label className='font12b lineheight20 margintop5 odleftlh'>계정에 팔로우를 늘려주는 서비스입니다.<br/>많은 팔로우를 보유할수록 계정의 가치 및 브랜드의 이미지가 높게 평가됩니다.</label>
      else
        return <label className='font15 wt400 color31 margintop5 odleftlh'>계정에 팔로우를 늘려주는 서비스입니다.<br/>많은 팔로우를 보유할수록 계정의 가치 및 브랜드의 이미지가 높게 평가됩니다.</label>
    case 2:
      if (mobileOn)
        return <label className='font12b lineheight20 margintop5 odleftlh'>게시물을 직접 본 후에 자연스러운 댓글을 작성하는 서비스입니다.<br/>게시물에 좋아요는 많으나 댓글이 적다면<br/>댓글 서비스를 이용하면 자연스럽게 보일 수 있고 인기있는 게시물로 인식될 수도 있습니다.</label>
      else
        return <label className='font15 wt400 color31 margintop5 odleftlh'>게시물을 직접 본 후에 자연스러운 댓글을 작성하는 서비스입니다.<br/>게시물에 좋아요는 많으나 댓글이 적다면<br/>댓글 서비스를 이용하면 자연스럽게 보일 수 있고인기있는 게시물로 인식될 수도 있습니다.</label>
    default:
      return null;
  }
}

const getFacebookDesc = (adsType) =>
{
  switch(adsType)
  {
    case 0:
      if (mobileOn)
        return <label className='font12b lineheight20 margintop5 odleftlh'>게시물에 좋아요를 늘려주는 서비스입니다.<br/>댓글은 많으나 좋아요가 적다면 좋아요 늘리기 서비스를<br/>이용하면 인기 있는 게시물로 인식될 수 있습니다.</label>
      else
        return <label className='font15 wt400 color31 margintop5 odleftlh'>게시물에 좋아요를 늘려주는 서비스입니다.<br/>댓글은 많으나 좋아요가 적다면 좋아요 늘리기 서비스를<br/>이용하면 인기 있는 게시물로 인식될 수 있습니다.</label>
    case 1:
      if (mobileOn)
        return <label className='font12b lineheight20 margintop5 odleftlh'>개인 계정(프로페셔널 계정만 이용 가능)의 팔로우를 늘려주는 서비스입니다.<br/>많은 팔로우를 보유할수록 계정의 가치 및 브랜드의 이미지가 높게 평가됩니다.</label>
      else
        return <label className='font15 wt400 color31 margintop5 odleftlh'>개인 계정(프로페셔널 계정만 이용 가능)의 팔로우를 늘려주는 서비스입니다.<br/>많은 팔로우를 보유할수록 계정의 가치 및 브랜드의 이미지가 높게 평가됩니다.</label>
    case 2:
      if (mobileOn)
        return <label className='font12b lineheight20 margintop5 odleftlh'>게시물을 직접 본 후에 자연스러운 댓글을 작성하는 서비스입니다.<br/>게시물에 좋아요는 많으나 댓글이 적다면 댓글 서비스를 이용하면 자연스럽게 보일 수 있고인기있는 게시물로 인식될 수도 있습니다.</label>
      else
        return <label className='font15 wt400 color31 margintop5 odleftlh'>게시물을 직접 본 후에 자연스러운 댓글을 작성하는 서비스입니다.<br/>게시물에 좋아요는 많으나 댓글이 적다면 댓글 서비스를 이용하면 자연스럽게 보일 수 있고인기있는 게시물로 인식될 수도 있습니다.</label>
    case 3: //페이지팔로우
      if (mobileOn)
        return <label className='font12b lineheight20 margintop5 odleftlh'>페이지 팔로우(좋아요)를 늘려주는 서비스입니다.<br/>많은 팔로우를 보유할수록 계정의 가치 및 브랜드의 이미지가 높게 평가됩니다.</label>
      else
        return <label className='font15 wt400 color31 margintop5 odleftlh'>페이지 팔로우(좋아요)를 늘려주는 서비스입니다.<br/>많은 팔로우를 보유할수록 계정의 가치 및 브랜드의 이미지가 높게 평가됩니다.</label>
    default:
      return null;
  }
}


const getYoutubeDesc = (adsType) =>
{
  switch(adsType)
  {
    case 0:
      if (mobileOn)
        return <label className='font12b lineheight20 margintop5 odleftlh'>영상 게시물에 좋아요를 즐려주는 서비스입니다.<br/>영상 조회 수는 많으나 좋아요가 적다면 좋아요 늘리기 서비스를 이용하면 자연스럽게 보일 수 있으며,좋아요가 많을수록 인기 있는 영상으로 인식이 될 수 있습니다.</label>
      else
        return <label className='font15 wt400 color31 margintop5 odleftlh'>영상 게시물에 좋아요를 즐려주는 서비스입니다.<br/>영상 조회 수는 많으나 좋아요가 적다면 좋아요 늘리기 서비스를 이용하면 자연스럽게 보일 수 있으며,좋아요가 많을수록 인기 있는 영상으로 인식이 될 수 있습니다.</label>
    case 1:
      if (mobileOn)
        return <label className='font12b lineheight20 margintop5 odleftlh'>개인계정의 구독자를 늘려주는 서비스입니다.<br/>많은 구독자 보유할수록 계정의 가치 및 브랜드의 이미지가 높게 평가됩니다.</label>
      else
        return <label className='font15 wt400 color31 margintop5 odleftlh'>개인계정의 구독자를 늘려주는 서비스입니다.<br/>많은 구독자를 보유할수록 계정의 가치 및 브랜드의 이미지가 높게 평가됩니다.</label>
    case 2:
      if (mobileOn)
        return <label className='font12b lineheight20 margintop5 odleftlh'>영상을 직접 본 후에 자연스러운 댓글을 작성하는 서비스입니다.<br/>게시물에 댓글이 많을수록 인기 및 신뢰도 있는 영상으로 인식될 수 있으며, 영상 조회 수가 어느 정도 있는 상태에서 댓글 늘리기 서비스를 이용하면 자연스럽게 보일 수 있고인기 있는 영상 게시물로 인식될 수도 있습니다.</label>
      else
        return <label className='font15 wt400 color31 margintop5 odleftlh'>영상을 직접 본 후에 자연스러운 댓글을 작성하는 서비스입니다.<br/>게시물에 댓글이 많을수록 인기 및 신뢰도 있는 영상으로 인식될 수 있으며, 영상 조회 수가 어느 정도 있는 상태에서 댓글 늘리기 서비스를 이용하면 자연스럽게 보일 수 있고인기 있는 영상 게시물로 인식될 수도 있습니다.</label>
    default:
      return null;
  }
}

const getTiktokDesc = (adsType) =>
{
  switch(adsType)
  {
    case 0:
      if (mobileOn)
        return <label className='font12b lineheight20 margintop5 odleftlh'>게시된 동영상에 좋아요를 늘려주는 서비스입니다.<br/>좋아요의 개수가 많아질수록 게시물의 인기 게시물에 선정될 확률이 증가하기 때문에,인기 있는 동영상 작업을 원하는 회원님들에게 추천하는 서비스입니다.</label>
      else
        return <label className='font15 wt400 color31 margintop5 odleftlh'>게시된 동영상에 좋아요를 늘려주는 서비스입니다.<br/>좋아요의 개수가 많아질수록 게시물의 인기 게시물에 선정될 확률이 증가하기 때문에,인기 있는 동영상 작업을 원하는 회원님들에게 추천하는 서비스입니다.</label>
    case 1:
      if (mobileOn)
        return <label className='font12b lineheight20 margintop5 odleftlh'>계정에 팔로우를 늘려주는 서비스입니다.<br/>많은 팔로우를 보유할수록 브랜드 인지도 및 계정 활성화에 영향을 미칩니다.</label>
      else
        return <label className='font15 wt400 color31 margintop5 odleftlh'>계정에 팔로우를 늘려주는 서비스입니다.<br/>많은 팔로우를 보유할수록 브랜드 인지도 및 계정 활성화에 영향을 미칩니다.</label>
    case 2:
      if (mobileOn)
        return <label className='font12b lineheight20 margintop5 odleftlh'>게시물을 직접 본 후에 자연스러운 댓글을 작성하는 서비스입니다.<br/>게시물에 좋아요는 많으나 댓글이 적다면 댓글 서비스를 이용하면 자연스럽게 보일 수 있고 인기있는 동영상으로 인식될 수도 있습니다.</label>
      else
        return <label className='font15 wt400 color31 margintop5 odleftlh'>게시물을 직접 본 후에 자연스러운 댓글을 작성하는 서비스입니다.<br/>게시물에 좋아요는 많으나 댓글이 적다면 댓글 서비스를 이용하면 자연스럽게 보일 수 있고 인기있는 동영상으로 인식될 수도 있습니다.</label>
    default:
      return null;
  }
}

const getDesc = (platform, adsType) =>
{
  switch(platform)
  {
    case 0:
      return getInstaDesc(adsType);
    case 1:
      return getFacebookDesc(adsType);
    case 2:
      return getYoutubeDesc(adsType);
    case 3:
      return getTiktokDesc(adsType);
    default:
      return null;
  }
}

const getInstaDescTitle = (adsType) =>
{
  switch(adsType)
  {
    case 0:
      return "인스타그램 좋아요 늘리기 상품설명";
    case 1:
      return "인스타그램 팔로우 늘리기 상품설명";
    case 2:
      return "인스타그램 댓글 늘리기 상품설명";
    default:
      return "";
  }
}

const getFacebookDescTitle = (adsType) =>
{
  switch(adsType)
  {
    case 0:
      return "페이스북 좋아요 늘리기 상품설명";
    case 1:
      return "페이스북 프로필 팔로우 늘리기 상품설명";
    case 2:
      return "페이스북 댓글 늘리기 상품설명";
    case 3:
      return "페이스북 페이지 팔로우 늘리기 상품설명";
    default:
      return "";
  }
}


const getYoutubeDescTitle = (adsType) =>
{
  switch(adsType)
  {
    case 0:
      return "유튜브 좋아요 늘리기 상품설명";
    case 1:
      return "유튜브 구독자 늘리기 상품설명";
    case 2:
      return "유튜브 댓글 늘리기 상품설명";
    default:
      return "";
  }
}

const getTiktokDescTitle = (adsType) =>
{
  switch(adsType)
  {
    case 0:
      return "틱톡 좋아요 늘리기 상품설명";
    case 1:
      return "틱톡 팔로우 늘리기 상품설명";
    case 2:
      return "틱톡 댓글 늘리기 상품설명";
    default:
      return "";
  }
}

const getDescTitle = (platform, adsType) =>
{
  switch(platform)
  {
    case 0:
      return getInstaDescTitle(adsType);
    case 1:
      return getFacebookDescTitle(adsType);
    case 2:
      return getYoutubeDescTitle(adsType);
    case 3:
      return getTiktokDescTitle(adsType);
    default:
      return 0;
  }
}

const getCurrentPlatform = (platformCode) =>
{
  switch(platformCode)
  {
    case 0:
      return "인스타그램 상품목록";
    case 1:
      return "페이스북 상품목록";
    case 2:
      return "유튜브 상품목록";
    case 3:
      return "틱톡 상품목록";
    default:
      return '';
  }
}

const getAdsTypeCode = (platform, adsType) =>
{
  if (platform === 0) //인스타
  {
    switch(adsType)
    {
      case 0: //게시물 좋아요
        return 0;
      case 1: //프로필팔로우
        return 1;
      case 2: //댓글
        return 2;
      default:
        return -1;
    }
  }
  else if (platform === 1) //페이스북
  {
    switch(adsType)
    {
      case 0: //게시물 좋아요
        return 10;
      case 1: //프로필팔로우
        return 11;
      case 2: //댓글
        return 12;
      case 3: //페이지팔로우
        return 14;
      default:
        return -1;
    }
  }
  else if (platform === 2)
  {
    switch(adsType)
    {
      case 0: //게시물 좋아요
        return 20;
      case 1: //프로필팔로우
        return 21;
      case 2: //댓글
        return 22;
      default:
        return -1;
    }
  }
  else if (platform === 3)
  {
    switch(adsType)
    {
      case 0: //게시물 좋아요
        return 30;
      case 1: //프로필팔로우
        return 31;
      case 2: //댓글
        return 32;
      default:
        return -1;
    }
  }

  return -1;
}

const checkAdsSystemInsta = (adsConfig, adsType) =>
{
  switch(adsType)
  {
    case 0://좋아요
    case 2: //댓글
      return (adsConfig & 0x1) === 0;

    case 1: //팔로우
      return (adsConfig & 0x2) === 0;

    default:
      return false;
  }
}

const checkAdsSystemFb = (adsConfig, adsType) =>
{
  switch(adsType)
  {
    case 0://좋아요
    case 2: //댓글
      return (adsConfig & 0x4) === 0;

    case 1: //팔로우
    case 3: //페이지
      return (adsConfig & 0x8) === 0;

    default:
      return false;
  }
}

const checkAdsSystemYt = (adsConfig, adsType) =>
{
  switch(adsType)
  {
    case 0://좋아요
    case 2: //댓글
      return (adsConfig & 0x10) === 0;

    case 1: //구독
      return (adsConfig & 0x20) === 0;

    default:
      return false;
  }
}

const checkAdsSystemTt = (adsConfig, adsType) =>
{
  switch(adsType)
  {
    case 0://좋아요
    case 2: //댓글
      return (adsConfig & 0x40) === 0;

    case 1: //구독
      return (adsConfig & 0x80) === 0;

    default:
      return false;
  }
}

const checkAdsSystem = (adsConfig, platform, adsType) =>
{
  if (adsConfig === 0)
    return true;

  switch(platform)
  {
    case 0:
      return checkAdsSystemInsta(adsConfig, adsType);
    case 1:
      return checkAdsSystemFb(adsConfig, adsType);
    case 2:
      return checkAdsSystemYt(adsConfig, adsType);
    case 3:
      return checkAdsSystemTt(adsConfig, adsType);
    default:
      return true;
  }
}

//광고시스템 점검중 메시지 얻기
const getAdsSystemMaintMsg = (platform, adsType) =>
{
  let snsName = "";

  switch(platform)
  {
    case 0:
      snsName = "인스타그램";
      break;
    case 1:
      snsName = "페이스북";
      break;
    case 2:
      snsName = "유튜브";
      break;
    case 3:
      snsName = "틱톡";
      break;
    default:
      break;
  }

  switch(adsType)
  {
    case 0:
    case 2:
      return `${snsName} 게시물 광고 시스템 점검으로 인해 신규 주문이 일시적으로 중지되었습니다.\n자세한 내용은 공지사항을 참조해 주세요.`;
    case 1:
    case 3:
      return `${snsName} 프로필/페이지 광고 시스템 점검으로 인해 신규 주문이 일시적으로 중지되었습니다.\n자세한 내용은 공지사항을 참조해 주세요.`;

    default:
      return `${snsName} 광고 시스템 점검으로 인해 신규 주문이 일시적으로 중지되었습니다.\n자세한 내용은 공지사항을 참조해 주세요.`;
  }
}

//인스타 광고 설명이미지
const getInsAdsDescImage = (adsType) =>
{
  switch(adsType)
  {
    case 0://게시물좋아요
      return mobileOn ? GetImgUrl("tut/mb/ins0.png") : GetImgUrl("tut/ins0.png");
    case 1://팔로우
      return mobileOn ? GetImgUrl("tut/mb/ins1.png") : GetImgUrl("tut/ins1.png");
    case 2://게시물댓글
      return mobileOn ? GetImgUrl("tut/mb/ins2.png") : GetImgUrl("tut/ins2.png");
    default:
      return mobileOn ? GetImgUrl("tut/mb/ins0.png") : GetImgUrl("tut/ins0.png");
  }
}

//페북 광고 설명이미지
const getFbAdsDescImage = (adsType) =>
{
  switch(adsType)
  {
    case 0://게시물좋아요
      return mobileOn ? GetImgUrl("tut/mb/fb10.png") : GetImgUrl("tut/fb10.png");
    case 1://팔로우
      return mobileOn ? GetImgUrl("tut/mb/fb11.png") : GetImgUrl("tut/fb11.png");
    case 2://게시물댓글
      return mobileOn ? GetImgUrl("tut/mb/fb12.png") : GetImgUrl("tut/fb12.png");
    case 3://페이지 좋아요
      return mobileOn ? GetImgUrl("tut/mb/fb14.png") : GetImgUrl("tut/fb14.png");
    default:
      return mobileOn ? GetImgUrl("tut/mb/fb10.png") : GetImgUrl("tut/fb10.png");
  }
}

//유튜브 광고 설명이미지
const getYtAdsDescImage = (adsType) =>
{
  switch(adsType)
  {
    case 0://게시물좋아요
      return mobileOn ? GetImgUrl("tut/mb/yt20.png") : GetImgUrl("tut/yt20.png");
    case 1://구독
      return mobileOn ? GetImgUrl("tut/mb/yt21.png") : GetImgUrl("tut/yt21.png");
    case 2://게시물댓글
      return mobileOn ? GetImgUrl("tut/mb/yt22.png") : GetImgUrl("tut/yt22.png");
    default:
      return mobileOn ? GetImgUrl("tut/mb/yt20.png") : GetImgUrl("tut/yt20.png");
  }
}

//틱톡 광고 설명이미지
const getTtAdsDescImage = (adsType) =>
{
  switch(adsType)
  {
    case 0://게시물좋아요
      return mobileOn ? GetImgUrl("tut/mb/tt30.png") : GetImgUrl("tut/tt30.png");
    case 1://팔로우
      return mobileOn ? GetImgUrl("tut/mb/tt31.png") : GetImgUrl("tut/tt31.png");
    case 2://게시물댓글
      return mobileOn ? GetImgUrl("tut/mb/tt32.png") : GetImgUrl("tut/tt32.png");
    default:
      return mobileOn ? GetImgUrl("tut/mb/tt30.png") : GetImgUrl("tut/tt30.png");
  }
}

//광고 설명이미지 파일이름 얻기
const getAdsDescImage = (platform, adsType) =>
{

  switch(platform)
  {
    case 0:
      return getInsAdsDescImage(adsType);
    case 1:
      return getFbAdsDescImage(adsType);
    case 2:
      return getYtAdsDescImage(adsType);
    case 3:
      return getTtAdsDescImage(adsType);
    default:
      return getInsAdsDescImage(adsType);
  }
}

export {
  getDesc,
  getDescTitle,
  getCurrentPlatform,
  getAdsTypeCode,
  checkAdsSystem,
  getAdsSystemMaintMsg,
  getAdsDescImage
};