import './Order.css';
import '../../App.css';
import { getAdsDescImage, getAdsTypeCode } from './OrderDescUtil';
import { GetImgUrl } from '../../URL/AppUrl';
import { Fragment } from 'react';


const getInsFollowImg = () =>
{
  return (
    <Fragment>
      <img src={GetImgUrl("tut/mb/inssub1.png")} alt="설명" className='mgtop45 odmgdcimg'/>
      <img src={GetImgUrl("tut/mb/inssub2.png")} alt="설명" className='odmgdcimg'/>
      <img src={GetImgUrl("tut/mb/inssub3.png")} alt="설명" className='odmgdcimg'/>
    </Fragment>
  );
}

const getInsLikeImg = () =>
{
  return (
    <Fragment>
      <img src={GetImgUrl("tut/mb/inslike1.png")} alt="설명" className='mgtop45 odmgdcimg'/>
      <img src={GetImgUrl("tut/mb/inslike2.png")} alt="설명" className='odmgdcimg'/>
    </Fragment>
  );
}

const getInsCommentImg = () =>
{
  return (
    <Fragment>
      <img src={GetImgUrl("tut/mb/inscom1.png")} alt="설명" className='mgtop45 odmgdcimg'/>
      <img src={GetImgUrl("tut/mb/inscom2.png")} alt="설명" className='odmgdcimg'/>
    </Fragment>
  );
}

const getFbPgFollowImg = () =>
{
  return (
    <Fragment>
      <img src={GetImgUrl("tut/mb/fbpgsub1.png")} alt="설명" className='mgtop45 odmgdcimg'/>
      <img src={GetImgUrl("tut/mb/fbpgsub2.png")} alt="설명" className='odmgdcimg'/>
      <img src={GetImgUrl("tut/mb/fbpgsub3.png")} alt="설명" className='odmgdcimg'/>
    </Fragment>
  );
}

const getFbPfFollowImg = () =>
{
  return (
    <Fragment>
      <img src={GetImgUrl("tut/mb/fbpfsub1.png")} alt="설명" className='mgtop45 odmgdcimg'/>
      <img src={GetImgUrl("tut/mb/fbpfsub2.png")} alt="설명" className='odmgdcimg'/>
    </Fragment>
  );
}
  
const getFbLikeImg = () =>
{
  return (
    <Fragment>
      <img src={GetImgUrl("tut/mb/fblike1.png")} alt="설명" className='mgtop45 odmgdcimg'/>
      <img src={GetImgUrl("tut/mb/fblike2.png")} alt="설명" className='odmgdcimg'/>
    </Fragment>
  );
}

const getFbCommentImg = () =>
{
  return (
    <Fragment>
      <img src={GetImgUrl("tut/mb/fbcom1.png")} alt="설명" className='mgtop45 odmgdcimg'/>
      <img src={GetImgUrl("tut/mb/fbcom2.png")} alt="설명" className='odmgdcimg'/>
    </Fragment>
  );
}

const getYtFollowImg = () =>
{
  return (
    <Fragment>
      <img src={GetImgUrl("tut/mb/ytsub1.png")} alt="설명" className='mgtop45 odmgdcimg'/>
      <img src={GetImgUrl("tut/mb/ytsub2.png")} alt="설명" className='odmgdcimg'/>
      <img src={GetImgUrl("tut/mb/ytsub3.png")} alt="설명" className='odmgdcimg'/>
    </Fragment>
  );
}

const getYtLikeImg = () =>
{
  return (
    <Fragment>
      <img src={GetImgUrl("tut/mb/ytlike1.png")} alt="설명" className='mgtop45 odmgdcimg'/>
      <img src={GetImgUrl("tut/mb/ytlike2.png")} alt="설명" className='odmgdcimg'/>
    </Fragment>
  );
}

const getYtCommentImg = () =>
{
  return (
    <Fragment>
      <img src={GetImgUrl("tut/mb/ytcom1.png")} alt="설명" className='mgtop45 odmgdcimg'/>
      <img src={GetImgUrl("tut/mb/ytcom2.png")} alt="설명" className='odmgdcimg'/>
    </Fragment>
  );
}

const getTtFollowImg = () =>
{
  return (
    <Fragment>
      <img src={GetImgUrl("tut/mb/ttsub1.png")} alt="설명" className='mgtop45 odmgdcimg'/>
      <img src={GetImgUrl("tut/mb/ttsub2.png")} alt="설명" className='odmgdcimg'/>
    </Fragment>
  );
}

const getTtLikeImg = () =>
{
  return (
    <Fragment>
      <img src={GetImgUrl("tut/mb/ttlike1.png")} alt="설명" className='mgtop45 odmgdcimg'/>
      <img src={GetImgUrl("tut/mb/ttlike2.png")} alt="설명" className='odmgdcimg'/>
    </Fragment>
  );
}

const getTtCommentImg = () =>
{
  return (
    <Fragment>
      <img src={GetImgUrl("tut/mb/ttcom1.png")} alt="설명" className='mgtop45 odmgdcimg'/>
      <img src={GetImgUrl("tut/mb/ttcom2.png")} alt="설명" className='odmgdcimg'/>
    </Fragment>
  );
}

const getDescImage = (adsType) =>
{
  switch(adsType)
  {
    case 0: //인스타좋아요
      return getInsLikeImg();
    case 1: //인스타팔로우
      return getInsFollowImg();
    case 2: //인스타댓글
      return getInsCommentImg();
    case 10: //페북좋아요
      return getFbLikeImg();
    case 11: //페북프로필팔로우
      return getFbPfFollowImg();
    case 12: //페북댓글
      return getFbCommentImg();
    case 14: //페북페이지팔로우
      return getFbPgFollowImg();
    case 20: //유튜브좋아요
      return getYtLikeImg();
    case 21: //유튜브구독
      return getYtFollowImg();
    case 22: //유튜브댓글
      return getYtCommentImg();
    case 30: //틱톡좋아요
      return getTtLikeImg();
    case 31: //틱톡팔로우
      return getTtFollowImg();
    case 32: //틱톡댓글
      return getTtCommentImg();
    default:
      return null;
  }
}

//모바일 주문 가이드
const OrderDescPop = (props) =>
{
  return (
    <div className='odguidepop coltc'>
      <div className='odguidepoptop rowcc'>
        <div className='odguidepoptopin rowrc'>
          <img src={GetImgUrl("com/mb/close.png")} alt="닫기" className='btncur menubtn img1414' onClick={props.handleClose}/>
        </div>
      </div>
      <div className='odmgdccont coltc'>
        {getDescImage(getAdsTypeCode(props.platform, props.adsType))}
      </div>
    </div>
  );
}

export default OrderDescPop;