
import {GetApiUrlBase, GetSNSApiUrl, LOG_ENABLE, mobileOn} from '../URL/AppUrl';
import axios from 'axios';
import { FromBase64, IsValidS, IsValidV, ToBase64, replaceLine } from '../Util/Util';

/*
sns플랫폼별 결과값 참조 : https://github.com/jaehodes/bravo6-snsfly-scraping-api

*/

class SNSQuery {
  //observable변수

  constructor() {
  }

  //-------------------------------------------------------------------------------------------------------
  //유튜브관련 조회
  //queryType : 채널(0), 비디오(1)
  youtubeQuery = (queryType, url, callback) => {

    const apiUrl = queryType === 0 ? `api/youtube/channel?url=${encodeURI(url)}` : `api/youtube/video?url=${encodeURI(url)}`;
    axios({
      method:"GET",
      url: GetSNSApiUrl(apiUrl),
      headers: {
        //Accept: 'application/json',
        Accept: 'application/text',
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
      },
      responseType: 'json', // 기본 값, [ 'arraybuffer', 'blob', 'document', 'json', 'text', 'stream' ]
      responseEncoding: 'utf8', // 기본 값
    }).then((res)=>{
        this.parseYoutubeQueryResult(res.data, callback);
    }).catch(error=>{
        console.log(error);
        this.parseYoutubeQueryResult(null, callback);
    });
  }

  parseYoutubeQueryResult = (result, callback) => 
  {
    if (!IsValidV(result) || !result.success)
    {
      if (LOG_ENABLE)
        console.log("request failed.");

      if (callback)
      {
        callback(false, null);
      }

      return;
    }
    
    if (LOG_ENABLE)
      console.log(JSON.stringify(result));

    if (callback)
    {
      callback(true, result);
    }
  }
  
  //-------------------------------------------------------------------------------------------------------
  //인스타그램 조회
  //queryType : 프로필(0), 포스트(1)
  instagramQuery = (queryType, url, callback) => {

    const apiUrl = queryType === 0 ? `api/instagram/profile?url=${encodeURI(url)}` : `api/instagram/post?url=${encodeURI(url)}`;
    axios({
      method:"GET",
      url: GetSNSApiUrl(apiUrl),
      headers: {
        //Accept: 'application/json',
        Accept: 'application/text',
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
      },
      responseType: 'json', // 기본 값, [ 'arraybuffer', 'blob', 'document', 'json', 'text', 'stream' ]
      responseEncoding: 'utf8', // 기본 값
    }).then((res)=>{
        this.parseInstagramQueryResult(res.data, callback);
    }).catch(error=>{
        console.log(error);
        this.parseInstagramQueryResult(null, callback);
    });
  }

  parseInstagramQueryResult = (result, callback) => 
  {
    if (!IsValidV(result) || !result.success)
    {
      if (LOG_ENABLE)
        console.log("request failed.");

      if (callback)
      {
        callback(false, null);
      }

      return;
    }
    
    if (LOG_ENABLE)
      console.log(JSON.stringify(result));

    if (callback)
    {
      callback(true, result);
    }
  }

  //-------------------------------------------------------------------------------------------------------
  //틱톡 조회
  //queryType : 프로필(0), 포스트(1)
  tiktokQuery = (queryType, url, callback) => {

    const apiUrl = queryType === 0 ? `api/tiktok/profile?url=${encodeURI(url)}` : `api/tiktok/post?url=${encodeURI(url)}`;
    axios({
      method:"GET",
      url: GetSNSApiUrl(apiUrl),
      headers: {
        //Accept: 'application/json',
        Accept: 'application/text',
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
      },
      responseType: 'json', // 기본 값, [ 'arraybuffer', 'blob', 'document', 'json', 'text', 'stream' ]
      responseEncoding: 'utf8', // 기본 값
    }).then((res)=>{
        this.parseTiktokQueryResult(res.data, callback);
    }).catch(error=>{
        console.log(error);
        this.parseTiktokQueryResult(null, callback);
    });
  }

  parseTiktokQueryResult = (result, callback) => 
  {
    if (!IsValidV(result) || !result.success)
    {
      if (LOG_ENABLE)
        console.log("request failed.");

      if (callback)
      {
        callback(false, null);
      }

      return;
    }
    
    if (LOG_ENABLE)
      console.log(JSON.stringify(result));

    if (callback)
    {
      callback(true, result);
    }
  }
  //-------------------------------------------------------------------------------------------------------
  //페이스북 조회
  //queryType : 프로필(0), 포스트(1)
  facebookQuery = (queryType, url, callback) => {

    const apiUrl = queryType === 0 ? `api/facebook/profile?url=${encodeURI(url)}` : `api/facebook/post?url=${encodeURI(url)}`;
    axios({
      method:"GET",
      url: GetSNSApiUrl(apiUrl),
      headers: {
        //Accept: 'application/json',
        Accept: 'application/text',
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
      },
      responseType: 'json', // 기본 값, [ 'arraybuffer', 'blob', 'document', 'json', 'text', 'stream' ]
      responseEncoding: 'utf8', // 기본 값
    }).then((res)=>{
        this.parseFacebookQueryResult(res.data, callback);
    }).catch(error=>{
        console.log(error);
        this.parseFacebookQueryResult(null, callback);
    });
  }

  parseFacebookQueryResult = (result, callback) => 
  {
    if (!IsValidV(result) || !result.success)
    {
      if (LOG_ENABLE)
        console.log("request failed.");

      if (callback)
      {
        callback(false, null);
      }

      return;
    }
    
    if (LOG_ENABLE)
      console.log(JSON.stringify(result));

    if (callback)
    {
      callback(true, result);
    }
  }
}

const SNSQueryInstance = new SNSQuery();

export {SNSQueryInstance};
