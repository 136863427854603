import './Home.css';
import '../../App.css';
import { GetImgUrl } from '../../URL/AppUrl';

const getAdsTitleImage = (index) =>
{
  switch(index)
  {
    case 0: //인스타
      return GetImgUrl("com/insta.png");
    case 1: //페북
      return GetImgUrl("com/fb.png");
    case 2: //유튜브
      return GetImgUrl("com/yt.png");
    case 3: //틱톡
      return GetImgUrl("com/tt.png");
    case 4: //빙고
    default:
      return GetImgUrl("com/bingo.png");
  }
}

const getAdsTitleName = (index) =>
{
  switch(index)
  {
    case 0: //인스타
      return "인스타그램";
    case 1: //페북
      return "페이스북";
    case 2: //유튜브
      return "유튜브";
    case 3: //틱톡
      return "틱톡";
    case 4: //빙고
    default:
      return "빙고팡팡";
  }
}

const HomeAdsItem = (props) =>
{
  return (
    <div className='homeadscont colcc mgrt10'>
      <img src={getAdsTitleImage(props.index)} alt="광고"/>
      <label className='font15 wt400 color31 mgtop3'>{getAdsTitleName(props.index)}</label>
      <label className='font20 wt500 colorb3 mgtop5'>{props.count}</label>
    </div>
  );
}

export default HomeAdsItem;