import { APP_VERSION, GetImgUrl, mobileOn } from "../../URL/AppUrl";
import './Footer.css';
import '../../App.css';
const FooterBar = (props) =>
{
  return (
    <div className="ft_cont rowlc">
      <label className="mglt40 font15 wt400 color31 lht23">주식회사 브라보식스 · 대표 조용완 · 사업자번호 675-86-02368 · 이메일 info@bravo6.kr<br/>부산광역시 연제구 월드컵대로 160-1 3F · 통신 판매업 신고 2022-부산수영-0236</label>
      <label className="mglt40 font15 wt400 color31 lht23">Cpyright 2024.브라보식스 inc. all rights reserved. {`v${APP_VERSION}`}<br/><span className="fontunderline btncur" onClick={() => props.handleMenu(6)}>이용약관</span>&nbsp;&nbsp;&nbsp;<span className="fontunderline btncur" onClick={() => props.handleMenu(7)}>개인정보취급방침</span></label>
    </div>
  );
}

export default FooterBar;