import './TopBar.css';
import '../../App.css';
import { GetImgUrl } from '../../URL/AppUrl';
import { GetFormattedNum } from '../../Util/Util';

const MobileMenu = (props) =>
{
  return (
    <div className='mmbg flexcoltc'>
      <div className='mmtop flexrowcc'>
        <div className='mmtop_in flexrowsc'>
          <img src={GetImgUrl("com/mb/toplogo.png")} alt="snsfly" className='img9421'/>

          <div className='flexrowcc'>
            <div className="pointbox_mb flexrowcc marginright20">
              <label className="font12202">{`보유 포인트 ${GetFormattedNum(props.userPoint)}P`}</label>
            </div>

            <img src={GetImgUrl("com/mb/close.png")} alt="close" className='btncur menubtn img1414' onClick={() => props.handleMenu(101)}/>
          </div>
        </div>
      </div>

      <div className='topbarline'></div>

      <div className='mmmenu flexrowcc'>
        <div className='mmmenu_in flexrowsc'>
          <div className='flexcolcc btncur menubtn menutext' onClick={() => props.handleNavi(0)}>
            <img src={GetImgUrl("com/mb/order.png")} alt="order" className='img4545'/>
            <label className='font12b'>주문하기</label>
          </div>

          <div className='flexcolcc btncur menubtn menutext' onClick={() => props.handleNavi(1)}>
            <img src={GetImgUrl("com/mb/ol.png")} alt="order" className='img4545'/>
            <label className='font12b'>주문내역</label>
          </div>

          <div className='flexcolcc btncur menubtn menutext' onClick={() => props.handleNavi(2)}>
            <img src={GetImgUrl("com/mb/faq.png")} alt="order" className='img4545'/>
            <label className='font12b'>자주묻는질문</label>
          </div>

          <div className='flexcolcc btncur menubtn menutext' onClick={() => props.handleNavi(3)}>
            <img src={GetImgUrl("com/mb/notice.png")} alt="order" className='img4545'/>
            <label className='font12b'>공지사항</label>
          </div>

          <div className='flexcolcc btncur menubtn menutext' onClick={() => props.handleNavi(4)}>
            <img src={GetImgUrl("com/mb/charge.png")} alt="order" className='img4545'/>
            <label className='font12b'>충전하기</label>
          </div>
        </div>
      </div>

      <div className='mmmenu2 flexrowcc'>
        <div className='flexrowcc marginright70 btncur menubtn menutext' onClick={() => props.handleNavi(6)}>
          <img src={GetImgUrl("com/mb/set.png")} alt="setting" className='img1414'/>
          <label className='font12w'>&nbsp;계정설정</label>
        </div>

        <div className='flexrowcc btncur menubtn menutext' onClick={() => props.handleMenu(1)}>
          <img src={GetImgUrl("com/mb/lo.png")} alt="logout" className='img1414'/>
          <label className='font12w'>&nbsp;로그아웃</label>
        </div>
      </div>
    </div>
  );
}

export default MobileMenu;