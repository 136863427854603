import {
  action,
  makeObservable,
  /*makeAutoObservable,*/ observable,
  computed,
} from 'mobx';
import React from 'react';
import {APP_MODE, GetApiUrl, GetApiUrlBase, LOG_ENABLE, mobileOn} from '../URL/AppUrl';
import axios from 'axios';
import { delCookie, getCookie, setCookie } from '../Util/CookieManager';
import { FromBase64, IsValidS, IsValidV, ToBase64, replaceLine } from '../Util/Util';
import CryptoJS from 'crypto-js';
import TimeUtil from '../Util/TimeUtil';

//로그인 및 계정
class LoginStore {
  //observable변수
  init = false; //초기화 되었나?
  userId = ""; //사용자 계정
  userPW = ""; //비번
  acSave = true; //계정정보 저장하기
  loggedIn = false; //서버에로그인되었나?
  login = false; //로그인시도중인가?
  profile = null; //사용자의 프로필 정보
  userCompanyInfo = null; //사용자의 사업자 정보
  companyName = null; //사업자 회원인경우 사업체명

  //비 observable
  sid = null; //세션id
  token = null; //토큰

  userIdx = -1; //사용자 고유번호
  userType = -1; //로그인 유저의 타입
  errorCode = 0; //로그인에러코드
  sessionRefreshing = false;
  sessionError = false; //세션 오류가 생기는경우 true로 설정됨
  pointRefreshTime = null; //이용자 보유 포인트가 리프리시될때의 시간을 기록함
  pointRefreshed = false; //포인트 갱신중인가?

  constructor() {
    //makeAutoObservable(this, {
    makeObservable(this, {
      init: observable,
      userId: observable,
      userPW: observable,
      acSave: observable,
      loggedIn: observable,
      login: observable,
      profile: observable,
      userCompanyInfo: observable,
      companyName: observable,

      setUserId: action,
      setUserPW: action,
      toggleSaveAccount : action,
      setSaveAccount : action,
      setLoggedIn: action,
      saveAccount: action,
      loadAccount: action,
      clearAccountInfo: action,

      tryLogin: action,
      logOut: action,
      setLoginResult: action,

      parseProfile: action,
      registerUser: action,
      parseRegisterResult: action,
      parseChangePwResult: action,
      processLogOut: action,
      parseSessionRefreshResult: action,
      parseRefreshUPResult: action,

      parseCompanyInfoResult: action,

      setUserPoint: action,
      getUserId : computed,
      getUserPw : computed,
      getUserName : computed,
      getPhone : computed,
      getEmail : computed,
      getUserPoint : computed,
      getUserPoint1 : computed,
      getUserPoint2 : computed,
      isProfileReady : computed,
    });
  }

  //마지막으로 포인트를 갱신한 시점의 시간을 기록함
  catchPointRefreshTime = () =>
  {
    if (!this.pointRefreshTime)
      this.pointRefreshTime = new TimeUtil();

    this.pointRefreshTime.catch();
  }

  //마지막으로 포인트가 갱신된 시점부터 현재까지의 경과 시간을 초단위로 구함
  getPassedPointTime = () =>
  {
    if (!this.pointRefreshTime)
    {
      this.catchPointRefreshTime();
    }

    return this.pointRefreshTime.getPassedTime2();
  }

  //모든 계정 정보가 유효한가
  isAccountValid = () => {
    if (IsValidS(this.userId) && IsValidS(this.userPW))
      return true;

      return false;
  };

  setUserId = (id) => {
    this.userId = id;
  };

  setUserPW = (pw) => {
    this.userPW = pw;
  };

  toggleSaveAccount = () => {
    this.acSave = !this.acSave;
  }

  setSaveAccount = (sa) =>
  {
    this.acSave = sa;
  }

  setLoggedIn = (isLoggedIn) => {
    this.loggedIn = isLoggedIn;
  };

  //현재의 계정정보를 저장소에 저장함
  saveAccount = () => {
    let accountInfo = {};
    
    if (this.acSave)
    {
      if (IsValidS(this.userId))
        accountInfo.id = this.userId;
    }

    //계정저장
    accountInfo.asv = this.acSave;

    if (LOG_ENABLE)
      console.log("save account info : " + JSON.stringify(accountInfo));

    if (Object.keys(accountInfo).length > 0)
    {
      setCookie("uaix2", CryptoJS.AES.encrypt(JSON.stringify(accountInfo), "fjFcdsoQfwfGeGh*&46$lEf2dr&^1$#H").toString());
    }    
  };

  //스토어에 저장된 계정정보를 읽어옴
  loadAccount = () => {
    this.init = true;

    let temp = getCookie("uaix2");

    if (IsValidS(temp))
    {
      if (LOG_ENABLE)
        console.log("user account found");

      const bytes = CryptoJS.AES.decrypt(temp, 'fjFcdsoQfwfGeGh*&46$lEf2dr&^1$#H');

      if (IsValidV(bytes))
      {
        let t = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));

        if (IsValidV(t.asv))
        {
          this.setSaveAccount(t.asv);

          if (t.asv)
          {
            if (IsValidS(t.id))
              this.setUserId(t.id);
          }
        }
      }
      else
        this.clearAccountInfo();
    }
    else
    {
      this.clearAccountInfo();

      if (LOG_ENABLE)
      console.log("user account not found");
    }
  };

  //계정정보 클리어
  clearAccountInfo = () => {
    this.userId = "";
    this.userPW = "";
    this.acSave = true;
  };

  //로그인 시도하기
  tryLogin = (callback) => {

    this.login = true;
    this.errorCode = 0;

    if (LOG_ENABLE)
      console.log("try login : ");

    this.validateAccount();

    const params = new URLSearchParams();
   
    if (IsValidS(this.userId))
      params.append("id", this.userId);
    
    if (IsValidS(this.userPW))
      params.append("pw", ToBase64(this.userPW));

    params.append("rt", "1");
    params.append("el", "0");

    axios({
      method:"POST",
      url: GetApiUrl("user/login/"),
      headers: {
        //Accept: 'application/json',
        Accept: 'application/text',
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
      },
      responseType: 'json', // 기본 값, [ 'arraybuffer', 'blob', 'document', 'json', 'text', 'stream' ]
      responseEncoding: 'utf8', // 기본 값
      data: params
    }).then((res)=>{
        this.setLoginResult(res.data, callback);
    }).catch(error=>{
        if (LOG_ENABLE)
          console.log(error);
        this.setLoginResult(null, callback);
    });
  }

  //서버로 부터 수신된 로그인 결과를 바탕으로 로그인 데이터 설정
  setLoginResult = (result, callback) => 
  {
    this.login = false;

    if (LOG_ENABLE)
      console.log(`result ${result ? JSON.stringify(result) : 'failed'}`);

    if (!result || !IsValidV(result.ret))
    {
      if (LOG_ENABLE)
        console.log(`request failed.`);

      this.errorCode = -1;

      if (callback)
      {
        callback(this.errorCode);
      }

      return;
    }
    
    if (LOG_ENABLE)
      console.log(JSON.stringify(result));

    this.errorCode = result.ret;

    switch(result.ret)
    {
      case 0:
        {
          if (LOG_ENABLE)
            console.log("login succeeded");

          this.loggedIn = true;
          this.sessionError = false;

          this.validateProfile(result.pf);

          this.profile = result.pf; //프로필 정보를 저장한다.
          
          if (IsValidV(this.profile) && IsValidV(this.profile.idx))
          {
            this.userIdx = this.profile.idx;
            this.userType = this.profile.ut;

            if (LOG_ENABLE)
              console.log("user idx : " + this.userIdx);
          }

          if (IsValidS(result.cn) && result.cn !== "n")
          {
            this.companyName = FromBase64(result.cn);
          }

          this.sid = result.uidx;
          this.token = result.tk;

          if (LOG_ENABLE)
              console.log(`sid : ${this.sid} user token : ${this.token}`);

          this.saveToken();

          //계정저장 설정되어 있으면 계정정보도 저장함
          if (this.acSave)
          {
            this.saveAccount();
          }

          this.catchPointRefreshTime();

          if (callback)
          {
            callback(0);
          }

          return;
        }
      default:
        if (callback)
        {
          callback(this.errorCode);
        }
        break;
    }
  }
  
  logOut = () => {
    
    //observable변수
    this.loggedIn = false; //서버에로그인되었나?
    this.login = false; //로그인시도중인가?
    this.profile = null; //사용자의 프로필 정보
    this.userCompanyInfo = null; //사용자의 사업자 정보
    this.sessionError = false;

    //비 observable
    this.errorCode = 0; //로그인에러코드
    this.token = null;
    this.sid = null;

    this.removeToken();
  }

  validateProfile(profileData) {
    
    if (profileData == null)
      return;

    if (IsValidS(profileData.nm))
      profileData.nm = FromBase64(profileData.nm);
  }

  //----------------------------------------------------------------------------------------------------------------------------------------
  //---------------------------------------------------------------------------------------------------
  //세션 리프레시
  refreshSession = () => {
    //이미 주문이 진행중인경우
    if (this.sessionRefreshing || !this.loggedIn || !IsValidS(this.token) || !IsValidV(this.sid))
      return;

    this.sessionRefreshing = true;

    const params = new URLSearchParams();
    params.append("rt", "1"); //요청타입

    axios({
      method:"POST",
      url: GetApiUrl("user/refs"),
      headers: {
        //Accept: 'application/json',
        Accept: 'application/text',
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
      },
      responseType: 'json', // 기본 값
      responseEncoding: 'utf8', // 기본 값
      data: params
    }).then((res)=>{

      if (LOG_ENABLE)
        console.log("session refresh : " + JSON.stringify(res.data));

      this.parseSessionRefreshResult(res.data);
    }).catch(error=>{
      console.log(error);
      
      this.parseSessionRefreshResult(null);
    });
  }

  parseSessionRefreshResult = (result) =>
  {
    this.sessionRefreshing = false;

    if (!result)
      return;

    if (result.ret !== 0)
    {
      this.profile = null;
      this.loggedIn = false;
      this.sessionError = true;
    }
  }
  //---------------------------------------------------------------------------------------------------

  //프로필 정보 다시 읽기
  loadProfile = (callback) => {

    //토큰 정보가 없으면 로컬에서 읽기
    if (!IsValidS(this.token))
    {
      this.loadAccount();
      this.loadToken();

      if (!IsValidS(this.token) || !IsValidV(this.sid))
      {
        if (callback)
        {
          callback(-2);
          return;
        }
      }
    }

    axios({
      method:"POST",
      url: GetApiUrl("user/profile"),
      headers: {
        //Accept: 'application/json',
        Accept: 'application/text',
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
      },
      responseType: 'json', // 기본 값
      responseEncoding: 'utf8', // 기본 값
    }).then((res)=>{

      if (LOG_ENABLE)
        console.log("loadProfile : " + JSON.stringify(res.data));

      this.parseProfile(res.data, callback);

    }).catch(error=>{
      console.log(error);
      
      this.parseProfile(null, callback);
    });
  }

  parseProfile = (result, callback) =>
  {

    if (LOG_ENABLE)
      console.log(`parseProfile : ${JSON.stringify(result)}`);

    if (!result)
    {
      if (callback)
        callback(-2);

      return;
    }
    else if (result.ret !== 0)
    {
      if (result.ret === 100)
      {
        this.sessionError = true;
      }

      if (callback)
        callback(result.ret);

      return;
    }

    this.loggedIn = true;
    this.profile = result.pf;
    
    if (IsValidS(result.cn) && result.cn !== "n")
    {
      this.companyName = FromBase64(result.cn);
    }

    if (IsValidV(this.profile))
    {
      this.validateProfile(this.profile);
      this.userIdx = this.profile.idx;
      this.userType = this.profile.ut;
    }
    else
    {
      this.profile = null;
    }

    if (!IsValidS(this.userId))
    {
      this.loadAccount();
    }

    this.catchPointRefreshTime();

    if (callback)
      callback(0);
  }
  //-------------------------------------------------------------------------------------------------------
  validateAccount = () =>
  {
    if (IsValidS(this.userId))
      this.userId = this.userId.trim();

    if (IsValidS(this.userPW))
      this.userPW = this.userPW.trim();
  }

  //회원가입시도
  //userType : 회원종류, 사업자(1), 일반(2)
  //cdocFilename: 사업자 등록증 첨부파일이름
  registerUser = (phone, email, authData, authCode, userType, cdocFilename, callback) => {

    this.login = true;
    this.loggedIn = false;
    this.errorCode = 0;

    this.validateAccount();

    const params = new URLSearchParams();

    params.append("rt", "1");
    params.append("el", "0");

    if (IsValidS(this.userId))
      params.append("id", this.userId);
    
    if (IsValidS(this.userPW))
      params.append("pw", ToBase64(this.userPW));

    params.append("ph", replaceLine(phone));
    params.append("em", email.trim());

    params.append("ad", authData);
    params.append("ac", authCode);

    params.append("ut", userType.toString());
    params.append("cdoc", cdocFilename);
    params.append("ufm", "1"); //가입경로, mpang애즈(1)

    axios({
      method:"POST",
      url: GetApiUrl("user/reg/"),
      headers: {
        //Accept: 'application/json',
        Accept: 'application/text',
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
      },
      responseType: 'json', // 기본 값, [ 'arraybuffer', 'blob', 'document', 'json', 'text', 'stream' ]
      responseEncoding: 'utf8', // 기본 값
      data: params
    }).then((res)=>{
        this.parseRegisterResult(res.data, callback);
    }).catch(error=>{
        console.log(error);
        this.parseRegisterResult(null, callback);
    });
  }

  //서버로 부터 수신된 로그인 결과를 바탕으로 로그인 데이터 설정
  parseRegisterResult = (result, callback) => 
  {
    this.login = false;

    if (!IsValidV(result) || !IsValidV(result.ret))
    {
      if (LOG_ENABLE)
        console.log("request failed.");

      this.errorCode = -1;

      if (callback)
      {
        callback(this.errorCode);
      }

      return;
    }
    
    if (LOG_ENABLE)
      console.log(JSON.stringify(result));

    this.errorCode = result.ret;

    switch(result.ret)
    {
      case 0:
        {
          if (LOG_ENABLE)
            console.log("register succeeded");

          this.loggedIn = true;

          this.validateProfile(result.pf);

          this.profile = result.pf; //프로필 정보를 저장한다.
          
          if (IsValidV(this.profile) && IsValidV(this.profile.idx))
          {
            this.userIdx = this.profile.idx;
            this.userType = this.profile.ut;

            if (LOG_ENABLE)
              console.log("user idx : " + this.userIdx);
          }

          this.sid = result.uidx;
          this.token = result.tk;

          if (LOG_ENABLE)
              console.log(`sid : ${this.sid} user token : ${this.token}`);

          this.saveToken();

          //계정저장 설정되어 있으면 계정정보도 저장함
          if (this.acSave)
          {
            this.saveAccount();
          }

          this.catchPointRefreshTime();

          if (callback)
          {
            callback(0);
          }

          return;
        }
      default:
        if (callback)
        {
          callback(this.errorCode);
        }
        break;
    }
  }

  //-------------------------------------------------------------------------------------------------------
  //ID/비번찾기 또는 전화번호 확인
  //requestType : ID찾기(0), 비번찾기(1), 전화번호 확인(2)
  //phone : 인증에 사용할 전화번호
  //userId : 비번찾기시 유저ID, 나머지 경우에는 무시
  findAccount = (requestType, phone, userId, callback) => {

    const params = new URLSearchParams();

    if (requestType === 2)
      params.append("rt", "3"); //SNS플라이 전화번호 확인
    else
      params.append("rt", "1"); //sns플라이 id 또는 비번찾기

    params.append("at", "0"); //인증타입전화

    params.append("ft", requestType === 1 ? "1" : "0"); //아이디찾기(0), 비번찾기(1)

    params.append("av", replaceLine(phone)); //전화번호
    
    if (IsValidS(userId))
      params.append("id", userId); //비번찾기시 유저ID

    axios({
      method:"POST",
      url: GetApiUrl("user/find/"),
      headers: {
        //Accept: 'application/json',
        Accept: 'application/text',
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
      },
      responseType: 'json', // 기본 값, [ 'arraybuffer', 'blob', 'document', 'json', 'text', 'stream' ]
      responseEncoding: 'utf8', // 기본 값
      data: params
    }).then((res)=>{
        this.parseFindResult(res.data, callback);
    }).catch(error=>{
        console.log(error);
        this.parseFindResult(null, callback);
    });
  }

  parseFindResult = (result, callback) => 
  {
    if (!IsValidV(result) || !IsValidV(result.ret))
    {
      if (LOG_ENABLE)
        console.log("request failed.");

      if (callback)
      {
        callback(-2);
      }

      return;
    }
    
    if (LOG_ENABLE)
      console.log(JSON.stringify(result));

    if (result.ret === 0) //성공인경우
    {
      if (callback)
      {
        callback(result.ret, 
          result.ai, //인증데이터
          result.ac //인증코드
          );
      }
    }
    else //실패인경우
    {
      if (callback)
      {
        callback(result.ret, 
          null,
          null
          );
      }
    }
  }
  //-------------------------------------------------------------------------------------------------------
  //ID/비번찾기 또는 전화번호 확인의 인증요청
  //requestType : ID찾기(0), 비번찾기(1), 전화번호 확인(2)
  //phone : 인증에 사용할 전화번호
  //ai : 인증데이터, 서버에서 인증 요청시에 전달받음
  //ac : 인증코드
  checkFindAccount = (requestType, phone, ai, ac, callback) => {

    const params = new URLSearchParams();

    if (requestType === 2)
      params.append("rt", "3"); //SNS플라이 전화번호 확인
    else
      params.append("rt", "1"); //sns플라이 id 또는 비번찾기

    params.append("at", "0"); //인증타입전화

    params.append("ft", requestType === 1 ? "1" : "0"); //아이디찾기(0), 비번찾기(1)

    params.append("av", replaceLine(phone)); //전화번호
    
    params.append("ai", ai); //인증데이터
    params.append("ac", ac); //인증코드
    

    axios({
      method:"POST",
      url: GetApiUrl("user/auth/"),
      headers: {
        //Accept: 'application/json',
        Accept: 'application/text',
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
      },
      responseType: 'json', // 기본 값, [ 'arraybuffer', 'blob', 'document', 'json', 'text', 'stream' ]
      responseEncoding: 'utf8', // 기본 값
      data: params
    }).then((res)=>{
        this.parseCheckFindResult(res.data, callback);
    }).catch(error=>{
        console.log(error);
        this.parseCheckFindResult(null, callback);
    });
  }

  parseCheckFindResult = (result, callback) => 
  {
    if (!IsValidV(result) || !IsValidV(result.ret))
    {
      if (LOG_ENABLE)
        console.log("request failed.");

      if (callback)
      {
        callback(-2);
      }

      return;
    }
    
    if (LOG_ENABLE)
      console.log(JSON.stringify(result));

    if (result.ret === 0) //성공인경우
    {
      if (callback)
      {
        callback(result.ret, 
          result.id, //id찾기인경우 찾은 ID
          result.ai, //비번찾기인경우 추가 인증데이터
          );
      }
    }
    else //실패인경우
    {
      if (callback)
      {
        callback(result.ret, 
          null,
          null
          );
      }
    }
  }
  //-------------------------------------------------------------------------------------------------------
  //비번변경
  //requestType : 인증에의한변경(0), 앱내변경(1)
  //ai : 인증데이터, 서버에서 인증 요청시에 전달받음
  //npw : 새비번
  //opw : 이전비번, 앱내변경
  newPassword = null;
  changePw = (requestType, ai, npw, opw, callback) => {

    this.newPassword = npw;

    const params = new URLSearchParams();

    params.append("rt", requestType.toString());

    if (IsValidS(ai))
      params.append("ai", ai); //인증데이터

    params.append("npw", ToBase64(npw)); //새비번

    if (IsValidS(opw))
      params.append("opw", ToBase64(opw)); //이전비번
    
    axios({
      method:"POST",
      url: GetApiUrl("user/chgpw/"),
      headers: {
        //Accept: 'application/json',
        Accept: 'application/text',
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
      },
      responseType: 'json', // 기본 값, [ 'arraybuffer', 'blob', 'document', 'json', 'text', 'stream' ]
      responseEncoding: 'utf8', // 기본 값
      data: params
    }).then((res)=>{
        this.parseChangePwResult(res.data, callback);
    }).catch(error=>{
        console.log(error);
        this.parseChangePwResult(null, callback);
    });
  }

  parseChangePwResult = (result, callback) => 
  {
    if (!IsValidV(result) || !IsValidV(result.ret))
    {
      if (LOG_ENABLE)
        console.log("request failed.");

      if (callback)
      {
        callback(-2);
      }

      return;
    }
    
    if (LOG_ENABLE)
      console.log(JSON.stringify(result));

    if (result.ret === 0) //성공인경우
    {
      this.userPW = this.newPassword;
      this.newPassword = null;
    }

    if (callback)
      callback(result.ret);
  }
  //-------------------------------------------------------------------------------------------------------
  /*회원의 사업자 등록증 사본 파일 정보를 갱신함
  회원 가입시 1차로 계정등록이 되고 성공시에 2차로 사업자 등록증을 서버에 업로드 및
  업로드된 파일이름을 수신하여, 계정에 갱신해줘야함
  */
  updateComLicense = (cdoc, callback) => {

    const params = new URLSearchParams();

    params.append("cdoc", cdoc);

    axios({
      method:"POST",
      url: GetApiUrl("user/cdoc/"),
      headers: {
        //Accept: 'application/json',
        Accept: 'application/text',
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
      },
      responseType: 'json', // 기본 값, [ 'arraybuffer', 'blob', 'document', 'json', 'text', 'stream' ]
      responseEncoding: 'utf8', // 기본 값
      data: params
    }).then((res)=>{
        this.parseUpdateCDocResult(res.data, callback);
    }).catch(error=>{
        console.log(error);
        this.parseUpdateCDocResult(null, callback);
    });
  }

  parseUpdateCDocResult = (result, callback) => 
  {
    if (!IsValidV(result) || !IsValidV(result.ret))
    {
      if (LOG_ENABLE)
        console.log("request failed.");

      if (callback)
      {
        callback(-2);
      }

      return;
    }
    
    if (LOG_ENABLE)
      console.log(`parseUpdateCDocResult : ${JSON.stringify(result)}`);

    if (callback)
      callback(result.ret);
  }
  //-------------------------------------------------------------------------------------------------------
  //사용가능한 ID인지 확인하기
  checkUserId = (userId, callback) => {

    const params = new URLSearchParams();

    params.append("rt", "3");
    params.append("el", "0");
    params.append("id", userId);

    axios({
      method:"POST",
      url: GetApiUrl("user/login/"),
      headers: {
        //Accept: 'application/json',
        Accept: 'application/text',
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
      },
      responseType: 'json', // 기본 값, [ 'arraybuffer', 'blob', 'document', 'json', 'text', 'stream' ]
      responseEncoding: 'utf8', // 기본 값
      data: params
    }).then((res)=>{
        this.parseCheckIdResult(res.data, callback);
    }).catch(error=>{
        console.log(error);
        this.parseCheckIdResult(null, callback);
    });
  }

  parseCheckIdResult = (result, callback) => 
  {
    if (!IsValidV(result) || !IsValidV(result.ret))
    {
      if (LOG_ENABLE)
        console.log("request failed.");

      if (callback)
      {
        callback(-2);
      }

      return;
    }
    
    if (LOG_ENABLE)
      console.log(JSON.stringify(result));

    if (callback)
    {
      callback(result.ret);
    }
  }
  //-------------------------------------------------------------------------------------------------------
  //이용자의 보유포인트 정보만 갱신한다.
  refreshUserPoint = (callback) => {
    if (this.pointRefreshed)
      return;

    this.pointRefreshed = true;

    axios({
      method:"POST",
      url: GetApiUrl("user/point/"),
      headers: {
        //Accept: 'application/json',
        Accept: 'application/text',
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
      },
      responseType: 'json', // 기본 값, [ 'arraybuffer', 'blob', 'document', 'json', 'text', 'stream' ]
      responseEncoding: 'utf8', // 기본 값
    }).then((res)=>{
        this.parseRefreshUPResult(res.data, callback);
    }).catch(error=>{
        console.log(error);
        this.parseRefreshUPResult(null, callback);
    });
  }

  parseRefreshUPResult = (result, callback) => 
  {
    this.pointRefreshed = false;

    if (!IsValidV(result) || !IsValidV(result.ret))
    {
      if (LOG_ENABLE)
        console.log("request failed.");

      if (callback)
      {
        callback(-2);
      }

      return;
    }
    
    if (LOG_ENABLE)
      console.log(`parseRefreshUPResult : ${JSON.stringify(result)}`);

    if (result.ret === 0)
    {
      this.profile = {
        ...this.profile,
        pt1: result.pnt1,
        pt2: result.pnt2,
      };

      this.catchPointRefreshTime();
    }

    if (callback)
    {
      callback(result.ret);
    }
  }
  //-------------------------------------------------------------------------------------------------------
  //회원탈퇴 신청
  requestUnregister = (callback) => {

    const params = new URLSearchParams();

    params.append("rt", "1");
    params.append("res", "0");

    axios({
      method:"POST",
      url: GetApiUrl("user/unregister/"),
      headers: {
        //Accept: 'application/json',
        Accept: 'application/text',
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
      },
      responseType: 'json',
      responseEncoding: 'utf8',
      data: params,
    }).then((res)=>{
        this.parseUnregisterResult(res.data, callback);
    }).catch(error=>{
        console.log(error);
        this.parseUnregisterResult(null, callback);
    });
  }

  parseUnregisterResult = (result, callback) => 
  {
    if (!IsValidV(result) || !IsValidV(result.ret))
    {
      if (LOG_ENABLE)
        console.log("request failed.");

      if (callback)
      {
        callback(-2);
      }

      return;
    }
    
    if (LOG_ENABLE)
      console.log(`parseUnregisterResult : ${JSON.stringify(result)}`);

    if (callback)
    {
      callback(result.ret);
    }
  }
  //-------------------------------------------------------------------------------------------------------
  //자신의 사업자 정보 조회
  loadCompanyInfo = (callback) => {

    axios({
      method:"POST",
      url: GetApiUrl("user/cif/"),
      headers: {
        //Accept: 'application/json',
        Accept: 'application/text',
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
      },
      responseType: 'json',
      responseEncoding: 'utf8',
    }).then((res)=>{
        this.parseCompanyInfoResult(res.data, callback);
    }).catch(error=>{
        console.log(error);
        this.parseCompanyInfoResult(null, callback);
    });
  }

  parseCompanyInfoResult = (result, callback) => 
  {
    if (!IsValidV(result) || !IsValidV(result.ret))
    {
      if (LOG_ENABLE)
        console.log("request failed.");

      if (callback)
      {
        callback(-2);
      }

      return;
    }
    
    if (LOG_ENABLE)
      console.log(`parseCompanyInfoResult : ${JSON.stringify(result)}`);

    if (result.ret === 0 && IsValidV(result.ci))
    {
      this.decodeCompanyInfo(result.ci);
      this.userCompanyInfo = result.ci;
    }
    else
    {
      this.userCompanyInfo = null;
    }

    if (callback)
    {
      callback(result.ret, result.ci);
    }
  }

  decodeCompanyInfo = (item) =>
  {
    if (IsValidS(item.cname))
      item.cname = FromBase64(item.cname);

    if (IsValidS(item.oname))
      item.oname = FromBase64(item.oname);

    if (IsValidS(item.ut1))
      item.ut1 = FromBase64(item.ut1);

    if (IsValidS(item.ut2))
      item.ut2 = FromBase64(item.ut2);

    if (IsValidS(item.adr))
      item.adr = FromBase64(item.adr);
  }
  //----------------------------------------------------------------------------------------------------------------------------------------
  //---------------------------------------------------------------------------------------------------

  setUserPoint = (pt1, pt2) =>
  {
    this.profile = {
      ...this.profile,
      pt1: pt1,
      pt2: pt2,
    };
  }

  //사용자 계정 얻기
  get getUserId() {
    return this.userId;
  }

  get getUserPw() {
    return this.userPW;
  }

  get getUserName()
  {
    if (IsValidS(this.companyName) && this.companyName !== "n")
      return this.companyName;

    return this.userId;
  }

  get getPhone()
  {
    if (!IsValidV(this.profile))
      return "";

    return this.profile.ph;
  }

  get getEmail()
  {
    if (!IsValidV(this.profile))
      return "";

    return this.profile.em;
  }

  get getUserPoint()
  {
    if (!IsValidV(this.profile))
      return 0;

    return (this.profile.pt1 + this.profile.pt2);
  }

  get getUserPoint1()
  {
    if (!IsValidV(this.profile))
      return 0;

    return this.profile.pt1;
  }

  get getUserPoint2()
  {
    if (!IsValidV(this.profile))
      return 0;

    return this.profile.pt2;
  }

  get isProfileReady()
  {
    return IsValidV(this.profile);
  }

  removeToken = () =>
  {
    delCookie("mpadstk");
  }

  saveToken = () =>
  {
    let temp = {
      sid : this.sid,
      token : this.token
    };

    setCookie("mpadstk", CryptoJS.AES.encrypt(JSON.stringify(temp), "ajecdsoFEwfdeuh*&56$lzpidr&^1$#n").toString());

    if (LOG_ENABLE)
      console.log("save token");
  }

  loadToken = () =>
  {
    this.token = null;
    this.sid = null;

    let v = getCookie("mpadstk");

    if (!IsValidS(v))
    {
      if (LOG_ENABLE)
        console.log("session info invalid");

      return false;
    }

    try
    {
      const bytes = CryptoJS.AES.decrypt(v, 'ajecdsoFEwfdeuh*&56$lzpidr&^1$#n');

      let t = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));

      this.sid = t.sid;
      this.token = t.token;

      if (LOG_ENABLE)
      {
        console.log("token found : id = " + this.sid + "/ tk = " + this.token);
      }

      return true;
    }
    catch(e)
    {
      console.log(e.toString());

      return false;
    }
  }

  processLogOut = () =>
  {
    this.removeToken();
    this.userId = null;
    this.userPW = null;
    this.loggedIn = false; //서버에로그인되었나?
    this.login = false; //로그인시도중인가?
    this.profile = null; //사용자의 프로필 정보
    this.userCompanyInfo = null; //사용자 사업자 정보
    this.companyName = null; //사업자 회원인경우 사업체명

    //비 observable
    this.sid = null; //세션id
    this.token = null; //토큰

    this.userIdx = -1; //사용자 고유번호
    this.userType = -1; //로그인 유저의 타입
    this.errorCode = 0; //로그인에러코드
    this.sessionRefreshing = false;
    this.sessionError = false; //세션 오류가 생기는경우 true로 설정됨
  }

  //axios config--------------------------------------------------------------------------
  isApiUrl = (url) =>
  {
    if (!IsValidS(url))
      return false;

    if (url.indexOf(GetApiUrlBase()) >= 0)
      return true;

    return false;
  }

  setHeaderToken = async (config) =>
  {
    if (IsValidS(this.token) && IsValidV(this.sid))
    {
      /*if (APP_MODE === 0)
        console.log(`request url : ${config.url}`);*/

      if (this.isApiUrl(config.url))
      {
        /*if (APP_MODE === 0)
          console.log(`set default header : ${config.url}, ${this.sid}, ${this.token}`);*/

        config.headers["tk"] = this.token;
        config.headers["uidx"] = this.sid.toString();
      }      
    }
    
    return config;
  }
}

const LoginStoreInstance = new LoginStore();
const LoginStoreContext = React.createContext(LoginStoreInstance);

const UseLoginStore = () => React.useContext(LoginStoreContext);

export {UseLoginStore, LoginStoreInstance};
