import ExcelJS from 'exceljs';
import { adjustColumnWidth, convertBingoDateInfo2, getBingoAgeInfo, getBingoBankInfo, getBingoButtonTypeName, getBingoDesignName, getBingoGameTypeName, getBingoGenderInfo, getBingoOrderStatus, getBingoProductType, getBingoPurchaseMode, GetDataDateAndTime, GetFormattedNum, GetFormattedPhone2, IsValidS } from '../../Util/Util';

const printBingoOrder = (data, callback) =>
{
  const workBook = new ExcelJS.Workbook();
  workBook.creator = 'Bravo6';
  workBook.created = new Date();
  workBook.modified = new Date();

  let sheet1 = workBook.addWorksheet('빙고 주문 상세 정보', {properties:{tabColor:{argb:'FFFC0000'}, outlineLevelCol:1}});

  makeSheetBingoOrder(data, sheet1);

  workBook.xlsx.writeBuffer().then(function (buffer){
    if (callback)
      callback(new Blob([buffer]));
  });
}

const makeSheetBingoOrder = (data, workSheet) =>
{
  workSheet.columns = [
    { header: '타입', key: 'c1'},
    { header: '내용', key: 'c2'},
  ];
  

  let border = {
    top: {style:'thin'},
    left: {style:'thin'},
    bottom: {style:'thin'},
    right: {style:'thin'}
  };

  let rowIndex = 1;
  let row = workSheet.getRow(rowIndex++);

  let fill1 = {
    type: 'pattern',
    pattern:'solid',
    fgColor:{argb:'1AFF3636'},
  };

  row.font = {bold : true, color : {argb: 'ffffffff'}};
  row.alignment = {vertical: 'center', horizontal: 'center'};
  setBingoRowStyle(row, border, fill1);

  let fill2 = {
    type: 'pattern',
    pattern:'solid',
    fgColor:{argb:'ffffff'},
  };


  for (let i = 0;i < 32; ++i)
  {
    row = workSheet.getRow(rowIndex++);
    row.values = getPrintDataForBingo(i, data);
    row.alignment = {vertical: 'center', horizontal: 'center'};
    setBingoRowStyle(row, border, fill2);
  }

  adjustColumnWidth(workSheet);
}

const getPrintDataForBingo = (index, item) =>
{
  let temp = [];

  switch(index)
  {
    case 0:
      temp.push("주문번호");
      temp.push(item.idx);
      break;
    case 1:
      temp.push("주문타입");
      temp.push((item.sst & 0x8) !== 0 ? "재집행" : "신규");
      break;
    case 2:
      temp.push("광고상태");
      temp.push(getBingoOrderStatus(item.st, item.sst));
      break;
    case 3:
      temp.push("빙고게임타입");
      temp.push(`${getBingoGameTypeName(item.gt)}`);
      break;
    case 4:
      temp.push("광고제목");
      temp.push(item.tt);
      break;
    case 5:
      temp.push("게임 타이틀");
      temp.push(item.gti);
      break;
    case 6:
      temp.push("ID 및 사업자");
      temp.push(`${item.uid}${IsValidS(item.cn) && item.cn !== "n" ? `(${item.cn})` : ""}`);
      break;
    case 7:
      temp.push("연령");
      temp.push(getBingoAgeInfo(item));
      break;
    case 8:
      temp.push("성별");
      temp.push(getBingoGenderInfo(item.gtt));
      break;
    case 9:
      temp.push("주문일자");
      temp.push(GetDataDateAndTime(item.odt));
      break;
    case 10:
      temp.push("시작희망일시");
      temp.push(convertBingoDateInfo2(item.rdt));
      break;
    case 11:
      temp.push("승인요청일자");
      temp.push(item.st === 4 ? GetDataDateAndTime(item.rvt) : "-");
      break;
    case 12:
      temp.push("광고시작일");
      temp.push(item.st >= 5 ? convertBingoDateInfo2(item.sdt) : "-");
      break;
    case 13:
      temp.push(item.st === 8  ? "광고취소일" : "광고종료일");
      temp.push(item.st > 7 ? GetDataDateAndTime(item.edt) : "-");
      break;
    case 14:
      temp.push("담당자명");
      temp.push(IsValidS(item.userInfo.un) && item.userInfo.un !== "n" ? item.userInfo.un : "-");
      break;
    case 15:
      temp.push("전화번호");
      temp.push(IsValidS(item.userInfo.uph) && item.userInfo.uph !== "n" ? GetFormattedPhone2(item.userInfo.uph) : "-");
      break;
    case 16:
      temp.push("이메일주소");
      temp.push(IsValidS(item.userInfo.em) && item.userInfo.em !== "n" ? item.userInfo.em : "-");
      break;
    case 17:
      temp.push("구매개수");
      temp.push(GetFormattedNum(item.bc));
      break;
    case 18:
      temp.push("결제방식");
      temp.push(getBingoPurchaseMode(item.pm));
      break;
    case 19:
      temp.push("입금계좌");
      temp.push(getBingoBankInfo(item));
      break;
    case 20:
      temp.push("결제금액");
      temp.push(GetFormattedNum(item.pamt));
      break;
    case 21:
      temp.push("결제상태");
      temp.push((item.sst & 0x1) !== 0 ? "결제완료" : "결제대기");
      break;
    case 22:
      temp.push("상품분류");
      temp.push(getBingoProductType(item.pt));
      break;
    case 23:
      temp.push("왼버튼");
      temp.push(getBingoButtonTypeName(item.lbt));
      break;
    case 24:
      temp.push("왼버튼링크");
      temp.push(IsValidS(item.llk) && item.llk !== "n" ? item.llk : "-");
      break;
    case 25:
      temp.push("오른버튼");
      temp.push(getBingoButtonTypeName(item.rbt));
      break;
    case 26:
      temp.push("오른버튼링크");
      temp.push(IsValidS(item.rlk) && item.rlk !== "n" ? item.rlk : "-");
      break;
    case 27:
      temp.push("빙고디자인");
      temp.push(getBingoDesignName(item.dn));
      break;
    case 28:
      temp.push("총게임횟수");
      temp.push(GetFormattedNum(item.tgc));
      break;
    case 29:
      temp.push("총경품개수");
      temp.push(item.gfc > 0 ? GetFormattedNum(item.gfc) : "-");
      break;
    case 30:
      temp.push("재검수요청");
      temp.push((item.sst & 0x2) !== 0 ? "진행중" : "-");
      break;
    case 31:
      temp.push("일시정지");
      if (item.st === 6)
        temp.push((item.sst & 0x4) !== 0 ? "일시정지중" : "광고게시중");
      else
        temp.push("-");
      break;
    default:
      temp.push("");
      temp.push("");
      break;
  }

  return temp;
}

const setBingoRowStyle = (row, border, fill) =>
{
  row.getCell('c1').border = border;
  row.getCell('c2').border = border;
  
  if (fill)
  {
    row.getCell('c1').fill = fill;
    row.getCell('c2').fill = fill;
  }
}

export {
  printBingoOrder,
};