import React from 'react';
import './Setting.css';
import '../../App.css';
import { APP_MODE, LOG_ENABLE, gotoFirstPage, gotoTalkPage, handleAppNavi, mobileOn, withNavigation } from '../../URL/AppUrl';
import {  GetFormattedCNum2, IsValidS, IsValidV, } from '../../Util/Util';
import Loading from '../Loading/Loading';
import TopBar from '../Top/TopBar';
import FooterBar from '../Footer/Footer';
import { LoginStoreInstance } from '../../Stores/LoginStore';
import Term from '../Term/Term';
import Privacy from '../Term/Privacy';
import Talk from '../Talk/Talk';
import MobileMenu from '../Top/MobileMenu';
import { AdsStoreInstance } from '../../Stores/AdsStore';
import { resetAllUserData } from '../../Stores/StoreManager';
import Login from '../Login/Login';
import FindId from '../Login/FindId';
import FindPw from '../Login/FindPw';
import NaviBar from '../Navi/Navi';
import { observer } from 'mobx-react';

const getTestErrorMsg = () =>
{
  return '';
}

class Setting extends React.Component
{
  state = {
    loading: false,
    userId : '',
    oldPw: '',
    newPw1: '',
    newPw2: '',

    errorOldPw: APP_MODE ===0 ? getTestErrorMsg() : '',
    errorNewPw1: APP_MODE ===0 ? getTestErrorMsg() : '',
    errorNewPw2: APP_MODE ===0 ? getTestErrorMsg() : '',
    showTerm: false,
    showPrivacy: false,
    showMobileMenu: false,
    showLogin: false,
    showFindId: false,
    showFindPw: false,

    //사이징처리
    naviWidth: 0,
    contWidth: 0,

    height: 0,

    companyInfo: null,
  };
  
  intervalId = -1;
  needCompanyInfo = true;

  update = () =>
  {
    if (LoginStoreInstance.sessionError && LoginStoreInstance.loggedIn)
    {
      LoginStoreInstance.sessionError = false;
      alert(`인증 세션이 만료되었습니다.`);
      this.processLogOut();
    }
  }

  showMobileMenu = (v) =>
  {
    this.setState((prevState) => ({...prevState, showMobileMenu: v}));
  }

  showTerm = (v) =>
  {
    this.setState((prevState) => ({...prevState, showTerm: v}));
  }

  showPrivacy = (v) =>
  {
    this.setState((prevState) => ({...prevState, showPrivacy: v}));
  }

  //홈페이지 업체 정보 설정
  setCompanyInfo = (v) =>
  {
    this.setState((prevState) => ({...prevState, companyInfo: v}));
  }
  //-----------------------------------------------------------------------------------------------------------------------------
  showLogin = (v) =>
  {
    this.setState((prevState) => ({...prevState, showLogin: v}));
  }

  showFindId = (v) =>
  {
    this.setState((prevState) => ({...prevState, showFindId: v}));
  }

  showFindPw = (v) =>
  {
    this.setState((prevState) => ({...prevState, showFindPw: v}));
  }

  handleLoginEvent = (eventCode) =>
  {
    if (this.state.loading)
      return;

    switch(eventCode)
    {
      case 0: //닫기
        this.showLogin(false);
        return;
      case 1: //회원가입
        this.handleRegister();
        return;
      case 2: //id찾기
        this.showLogin(false);
        this.showFindId(true);
        return;
      case 3: //비번찾기
        this.showLogin(false);
        this.showFindPw(true);
        return;
      case 100: //로그인 성공
        this.showLogin(false);
        this.setValue(0, LoginStoreInstance.userId);
        this.needCompanyInfo = LoginStoreInstance.userType === 10;
        this.loadUserData();
        return;
    }
  }

  handleRegister = () =>
  {
    if (this.state.loading)
      return;

    this.showLogin(false);
    this.showFindId(false);
    this.showFindPw(false);
    this.handleNavi(5);
  }

  handleFindPw = () =>
  {
    if (this.state.loading)
      return;

    this.showLogin(false);
    this.showFindId(false);
    this.showFindPw(true);
  }

  handleFindIdLogin = () =>
  {
    if (this.state.loading)
      return;

    this.showLogin(true);
    this.showFindId(false);
    this.showFindPw(false);
  }
  //-----------------------------------------------------------------------------------------------------------------------------

  handleNavi = (naviIndex) =>
  {
    if (this.state.loading)
      return;

    if (LOG_ENABLE)
      console.log(`handleNavi : ${naviIndex}`);

    //console.log(`${this.props.navigate}`);
    this.showMobileMenu(false);
    handleAppNavi(this.props.navigate, naviIndex);
  }

  handleSnsOrder = (orderType) =>
  {
    if (this.state.loading)
      return;

    if (LOG_ENABLE)
      console.log(`handleSnsOrder : ${orderType}`);

    AdsStoreInstance.setOrderType(orderType);

    handleAppNavi(this.props.navigate, 0);
  }

  handleMenu = (menuIndex) =>
  {
    if (this.state.loading)
      return;

    switch(menuIndex)
    {
      case 0: //로그인
        this.showLogin(true);
        return;
      case 6: //이용약관
        this.showTerm(true);
        return;
      case 7: //개인정보 처리방침
        this.showPrivacy(true);
        return;
      case 100: //로그아웃
        if (!window.confirm('로그아웃 할까요?'))
          return;

        this.processLogOut();
        return;
    }
  }

  processLogOut = () =>
  {
    resetAllUserData();
    gotoFirstPage();
  }

  //사이징----------------------------------------------------------------------------------------------
  setSize = (navi, cont, height) =>
  {
    this.setState((prevState) => ({...prevState, naviWidth : navi, contWidth : cont, height : height}));
  }

  resetContsize = () =>
  {
    //네비게이션 영역은 전체의 16%정도
    let naviWidth = 0.16 * window.innerWidth;

    if (naviWidth < 300) //최소크기300
      naviWidth = 300;

    //콘텐츠영역크기 계산
    let contWidth = window.innerWidth - naviWidth;

    if (contWidth < 1220)
      contWidth = 1220;

    this.setSize(naviWidth, contWidth, window.innerHeight - 70)
  }

  onResize = () => {
    this.resetContsize();
  }
  //사이징----------------------------------------------------------------------------------------------

  componentDidMount() {
    this.resetContsize();

    window.addEventListener("resize", this.onResize);

    this.intervalId = setInterval(this.update, 1000);

    //로그인되어 있지 않은경우, 혹시 새로고침했을수도 있다.
    if (!LoginStoreInstance.loggedIn)
    {
      if (LoginStoreInstance.loadToken()) //인증토큰 정보가 존재한다면
      {
        this.setLoading(true);
        LoginStoreInstance.loadProfile(this.onProfileLoadedForRefresh);
      }
    }
    else
    {
      if (!LoginStoreInstance.isProfileReady) //프로필이 준비되지 않았다면
      {
        this.setLoading(true);
        LoginStoreInstance.loadProfile(this.onProfileLoaded);
      }
      else
      {
        //마지막 포인트 조회 시점부터 현재까지 경과 시간이 1분이상 경과한경우
        if (LoginStoreInstance.getPassedPointTime() > 60)
        {
          LoginStoreInstance.catchPointRefreshTime();
          LoginStoreInstance.refreshUserPoint(null);
        }

        this.loadUserData();
      }
    
      this.setValue(0, LoginStoreInstance.userId);
    }
  }

  componentWillUnmount = () =>
  {
    if (this.intervalId !== -1)
    {
      clearInterval(this.intervalId);
      this.intervalId = -1;
    }

    window.removeEventListener("resize", this.onResize);
  }

  onProfileLoadedForRefresh = (resultCode) =>
  {
    this.setLoading(false);

    if (resultCode !== 0)
    {
      if (LOG_ENABLE)
        console.log(`invalid profile`);

      LoginStoreInstance.removeToken();

      return;
    }

    LoginStoreInstance.loadAccount();
    this.needCompanyInfo = LoginStoreInstance.userType === 10;

    this.loadUserData();
  }

  onProfileLoaded = (resultCode) =>
  {
    this.setLoading(false);

    if (resultCode !== 0)
    {
      alert(`프로필 읽기 중 오류가 발생했습니다.\n다시 로그인해 주세요.`);
      this.processLogOut();
      return;
    }

    this.needCompanyInfo = LoginStoreInstance.userType === 10;
    this.loadUserData();
  }

  setLoading = (v) =>
  {
    this.setState((prevState) => ({...prevState, loading : v}));
  }

  setValue = (index, v) =>
  {
    switch(index)
    {
      case 0:
        this.setState((prevState) => ({...prevState, userId : v}));
        break;
      case 1:
        this.setState((prevState) => ({...prevState, oldPw : v}));
        break;
      case 2:
        this.setState((prevState) => ({...prevState, newPw1 : v}));
        break;
      case 3:
        this.setState((prevState) => ({...prevState, newPw2 : v}));
        break;
      default:
        break;
    }
  }

  clearError = () =>
  {
    this.setState((prevState) => ({...prevState, errorOldPw : '', errorNewPw1: '', errorNewPw2: ''}));
  }

  setError = (index, v) =>
  {
    switch(index)
    {
      case 0:
        this.setState((prevState) => ({...prevState, errorOldPw : v}));
        break;
      case 1:
        this.setState((prevState) => ({...prevState, errorNewPw1 : v}));
        break;
      case 2:
        this.setState((prevState) => ({...prevState, errorNewPw2 : v}));
        break;
      default:
        break;
    }
  }

  isInputValid = () =>
  {
    return IsValidS(this.state.oldPw) && IsValidS(this.state.newPw1) && IsValidS(this.state.newPw2) && this.state.newPw1 === this.state.newPw2 &&
      this.state.newPw1.length > 7 && this.state.newPw1.length < 21;
  }

  handleChangePw = () =>
  {
    if (this.state.loading || !LoginStoreInstance.loggedIn)
      return;

    this.clearError();

    if (!IsValidS(this.state.oldPw))
    {
      this.setError(0, `이전 비밀 번호를 입력해 주세요.`);
      return;
    }

    if (!IsValidS(this.state.newPw1))
    {
      this.setError(1, `변경 할 비밀 번호를 입력해 주세요.`);
      return;
    }

    if (!IsValidS(this.state.newPw2))
    {
      this.setError(2, `변경 할 비밀 번호를 한번 더 입력해 주세요.`);
      return;
    }

    if (this.state.newPw1 !== this.state.newPw2)
    {
      this.setError(2, `비밀 번호가 일치하지 않습니다.`);
      return;
    }

    if (this.state.newPw1.length < 8 || this.state.newPw1.length > 20)
    {
      this.setError(1, `비밀번호 길이가 올바르지 않습니다. 8~20자 범위에서 입력해 주세요.`);
      return;
    }

    if (!window.confirm(`비밀 번호를 변경 할까요?`))
      return;

    //비밀번호 변경루틴을 시작함
    this.setLoading(true);

    LoginStoreInstance.changePw(1, null, this.state.newPw1, this.state.oldPw, this.onChangePwResult);
  }

  onChangePwResult = (resultCode) =>
  {
    this.setLoading(false);

    switch(resultCode)
    {
      case 0: //정상
        break;
      case 1: //잘못된 요청
        this.setError(2, `잘못된 요청입니다.`);
        return;
      case 3: //올바르지 않은 비밀번호
        this.setError(2, `새로운비밀번호가 올바르지 않습니다. 8~20자 범위에서 입력해 주세요.`);
        return;
      case 4: //회원정보 오류
        this.setError(2, `회원정보 읽기 오류입니다.\n나중에 다시 시도해 주세요.`);
        return;
      case 5: //이전비밀번호 불일치
        this.setError(0, `이전 비밀 번호가 올바르지 않습니다.`);
        return;
      default:
        this.setError(2, `비밀번호 변경 중 오류가 발생했습니다. 나중에 시도해 주세요.`);
        return;
    }

    alert(`비밀 번호가 변경되었습니다.`);
    LoginStoreInstance.loadAccount();
    LoginStoreInstance.setUserPW(this.state.newPw1);

    if (LoginStoreInstance.acSave && LoginStoreInstance.isAccountValid())
    {
      LoginStoreInstance.saveAccount();
    }
  }

  loadUserData = () =>
  {
    //this.setLoading(this.needCompanyInfo);

    if (this.needCompanyInfo)
    {
      if (!IsValidV(LoginStoreInstance.userCompanyInfo))
      {
        this.setLoading(true);
        LoginStoreInstance.loadCompanyInfo(this.onLoadCompanyInfo);
      }
      else
      {
        this.needCompanyInfo = false;
      }
    }
  }

  onLoadCompanyInfo = (resultCode, info) =>
  {
    this.needCompanyInfo = false;
    this.setLoading(false);
  }

  handleTalk = () =>
  {
    gotoTalkPage();
  }

  //회원탈퇴신청
  handleUnregister = () =>
  {
    /*if (mobileOn)
    {
      alert(`모바일에서 지원하지 않는 기능입니다.\nPC에서 이용해 주세요.`);
      return;
    }
    else*/
      this.handleNavi(101);
  }

  render()
  {
    const companyInfo = LoginStoreInstance.userCompanyInfo;

    /*if (mobileOn)
    {
      return (
        <div className='setbg flexcoltc'>
          <Talk handleTalk={this.handleTalk}/>
          <TopBar handleNavi={this.handleNavi} handleMenu={this.handleMenu} selected={5} withLine={true}/>
          {
            this.state.showMobileMenu ? (<MobileMenu handleNavi={this.handleNavi} handleMenu={this.handleMenu} userPoint={LoginStoreInstance.getUserPoint} />) : null
          }
          {
            this.state.showTerm ?(<Term handleClose={() => this.showTerm(false)}/>) : null
          }
          {
            this.state.showPrivacy ? (<Privacy handleClose={() => this.showPrivacy(false)}/>) : null
          }
          {
            this.state.loading ? (<Loading/>) : null
          }
          <div className='setcont_mb flexcoltl'>
  
            <label className='font18b margintop30'>비밀번호를 변경합니다.</label>
  
            <label className='font14b_2 margintop15'>현재 사용중인 아이디</label>
            <div className='setbox_mb flexrowlc margintop5'>
              <label className='font16b'>{this.state.userId}</label>
            </div>
  
            <label className='font14b_2 margintop30'>현재비밀번호</label>
            <input type='password' value={this.state.oldPw} onChange={(e) => {this.setValue(1, e.target.value)}} className='setinp_mb font14b_2 margintop5'
              placeholder='현재 사용중인 비밀번호를 입력해 주세요.'
            />
  
            {
              IsValidS(this.state.errorOldPw) ? (<label className='font14ff2 margintop5'>{this.state.errorOldPw}</label>) : null
            }
  
            <label className='font14b_2 margintop30'>변경할 비밀번호</label>
            <input type='password' value={this.state.newPw1} onChange={(e) => {this.setValue(2, e.target.value)}} className='setinp_mb font14b_2 margintop5'
              placeholder='비밀번호를 입력해 주세요.'
            />
  
            {
              IsValidS(this.state.errorNewPw1) ? (<label className='font14ff2 margintop5'>{this.state.errorNewPw1}</label>) : null
            }
  
            <label className='font14b_2 margintop30'>변경할 비밀번호 확인</label>
            <input type='password' value={this.state.newPw2} onChange={(e) => {this.setValue(3, e.target.value)}} className='setinp_mb font14b_2 margintop5'
              placeholder='비밀번호를 한번 더 입력해 주세요.'
            />
  
            {
              IsValidS(this.state.errorNewPw2) ? (<label className='font14ff2 margintop5'>{this.state.errorNewPw2}</label>) : null
            }
  
            <button className='setbtn_mb font14btn1 btncur margintop50' onClick={this.handleChangePw}>변경하기</button>

          </div>

          <UserQuery />

          <div className='flexrowrc unregbtnarea'>
            <label className='marginbottom200 font14b3 fontunderline margintop20 btncur' onClick={this.handleUnregister}>회원탈퇴</label>
          </div>
          <FooterBar handleMenu={this.handleMenu}/>
        </div>
        
      );
    }
    else*/
    {
      return (
        <div className='pgcont coltc'>
          {
            this.state.showLogin ? (<Login handleLoginEvent={this.handleLoginEvent}/>) : null
          }
          {
            this.state.showFindId ? (<FindId handleRegister={this.handleRegister} handleClose={() => this.showFindId(false)} handleFindPw={this.handleFindPw} handleLogin={this.handleFindIdLogin}/>) : null
          }
          {
            this.state.showFindPw ? (<FindPw handleClose={() => this.showFindPw(false)} handleRegister={this.handleRegister} />) : null
          }
          {
            this.state.showMobileMenu ? (<MobileMenu handleNavi={this.handleNavi} handleMenu={this.handleMenu} />) : null
          }
          {
            this.state.showTerm ?(<Term handleClose={() => this.showTerm(false)}/>) : null
          }
          {
            this.state.showPrivacy ? (<Privacy handleClose={() => this.showPrivacy(false)}/>) : null
          }
          {
            this.state.loading ? (<Loading/>) : null
          }
          
          <TopBar handleNavi={this.handleNavi} withLine={true}/>
          <Talk handleTalk={this.handleTalk}/>

          <div className='pgcont2 rowlt'>
            <NaviBar width={this.state.naviWidth} height={this.state.height} handleSnsOrder={this.handleSnsOrder} handleNavi={this.handleNavi} handleMenu={this.handleMenu}/>

            <div className='homecont colel' style={{width: this.state.contWidth, height: this.state.height}}>
              <div className='homecont2 coltl' style={{width: this.state.contWidth - 40 - 50, height: this.state.height - 100}}>
              
              <label className='font20 wt600 color31 mgtop20'>계정 설정</label>

                <div className='wd100p rowlt mgtop20 mgbt50'>
                  <div className='setcont coltc mgrt40'>
                    <div className='setcont2 coltc'>
                      <label className='font20 wt500 color20 wd91p'>기본정보</label>

                      <label className='font15 wt500 color31 mgtop10 wd91p'>아이디</label>
                      <div className='setbox flexrowlc margintop10'>
                        <label className='font16b'>{this.state.userId}</label>
                      </div>

                      <label className='font15 wt500 color31 mgtop20 wd91p'>현재 비밀번호</label>
                      <input type='password' value={this.state.oldPw} onChange={(e) => {this.setValue(1, e.target.value)}} className='setinp font16b margintop10'
                        placeholder='현재 사용중인 비밀번호를 입력해 주세요.'
                      />

                      {
                        IsValidS(this.state.errorOldPw) ? (<label className='font14ff2 margintop5 wd91p'>{this.state.errorOldPw}</label>) : null
                      }

                      <label className='font15 wt500 color31 mgtop20 wd91p'>변경할 비밀번호</label>
                      <input type='password' value={this.state.newPw1} onChange={(e) => {this.setValue(2, e.target.value)}} className='setinp font16b margintop10'
                        placeholder='비밀번호를 입력해 주세요.'
                      />

                      {
                        IsValidS(this.state.errorNewPw1) ? (<label className='font14ff2 margintop5 wd91p'>{this.state.errorNewPw1}</label>) : null
                      }

                      <label className='font15 wt500 color31 mgtop20 wd91p'>변경할 비밀번호 확인</label>
                      <input type='password' value={this.state.newPw2} onChange={(e) => {this.setValue(3, e.target.value)}} className='setinp font16b margintop10'
                        placeholder='비밀번호를 한번 더 입력해 주세요.'
                      />

                      {
                        IsValidS(this.state.errorNewPw2) ? (<label className='font14ff2 margintop5 wd91p'>{this.state.errorNewPw2}</label>) : null
                      }

                      <div className='wd91p rowrc'>
                        <button className={this.isInputValid() ? 'setbtn font16 wt600 colorw btncur margintop30 bgcolor20' : 'setbtn font16 wt600 colorw btncur margintop30 bgcolorb3'} onClick={this.handleChangePw}>수정 완료</button>
                      </div>
                    </div>

                    <div className='wd100p rowlc mgbt50'>
                      {
                        LoginStoreInstance.loggedIn ? (
                          <label className='font15 wt400 colorb3 fontunderline mgtop10 btncur' onClick={this.handleUnregister}>회원탈퇴</label>
                        ) : null
                      }
                    </div>
                  </div>

                  <div className='setcont coltc'>
                    <div className='setcont2 coltc'>
                      <label className='font20 wt500 color20 wd91p'>사업자 정보</label>

                      <label className='font15 wt500 color31 mgtop10 wd91p'>사업자 등록번호</label>
                      <div className='setbox2 flexrowlc margintop10'>
                        <label className='font16b'>{IsValidV(companyInfo) ? GetFormattedCNum2(companyInfo.cn) : "-"}</label>
                      </div>

                      <label className='font15 wt500 color31 mgtop10 wd91p'>사업자명</label>
                      <div className='setbox2 flexrowlc margintop10'>
                        <label className='font16b'>{IsValidV(companyInfo) ? companyInfo.cname : "-"}</label>
                      </div>

                      <label className='font15 wt500 color31 mgtop10 wd91p'>대표자명</label>
                      <div className='setbox2 flexrowlc margintop10'>
                        <label className='font16b'>{IsValidV(companyInfo) ? companyInfo.oname : "-"}</label>
                      </div>

                      <label className='font15 wt500 color31 mgtop10 wd91p'>업태</label>
                      <div className='setbox2 flexrowlc margintop10'>
                        <label className='font16b'>{IsValidV(companyInfo) ? companyInfo.ut1 : "-"}</label>
                      </div>

                      <label className='font15 wt500 color31 mgtop10 wd91p'>업종</label>
                      <div className='setbox2 flexrowlc margintop10'>
                        <label className='font16b'>{IsValidV(companyInfo) ? companyInfo.ut2 : "-"}</label>
                      </div>

                      <label className='font15 wt500 color31 mgtop10 wd91p'>주소</label>
                      <div className='setbox2 flexrowlc margintop10'>
                        <label className='font16b'>{IsValidV(companyInfo) ? companyInfo.adr : "-"}</label>
                      </div>

                      <label className='font15 wt500 color31 mgtop10 wd91p'>이메일</label>
                      <div className='setbox2 flexrowlc margintop10'>
                        <label className='font16b'>{IsValidV(companyInfo) ? companyInfo.em : "-"}</label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            <FooterBar handleMenu={this.handleMenu}/>
            </div>
          </div>
          
        </div>
      );
    }
  }
}

export default withNavigation(observer(Setting));