import './Term.css';
import '../../App.css';
import { GetImgUrl, mobileOn } from '../../URL/AppUrl';

const Privacy = (props) =>
{
  if (mobileOn)
  {
    return (
      <div className='termpopbg flexcolcc'>
  
        <div className='termpop_mb flexcoltc'>

          <div className='termtitle_mb flexrowcc'>
            <div className='termtitle_in_mb flexrowsc'>
              <label className='font18b'>개인정보처리방침</label>
              <img src={GetImgUrl("com/mb/close.png")} alt="close" className='btncur menubtn img1414' onClick={props.handleClose}/>
            </div>
          </div>

          <div className='termline'></div>
  
          <div className='termcont_mb flexcoltl margintop30'>
          <label className='font14b_2'>
            주식회사 브라보식스 (이하 “회사”)는 머니팡 애즈 서비스(이하 "서비스")를 제공하는 데 있어서, 이용자의 개인정보를 중요시하며,<br/>
            ‘정보통신망 이용촉진 및 정보보호 등에 관한 법률’ 등 관계 법령에 의거하여 개인정보를 관리 및 보호하고 있습니다.<br/>
            회사의 머니팡 애즈 서비스에서는 개인정보의 수집, 이용 등 처리에 있어 아래의 사항을 정보주체에게 안내합니다.
            </label>
            <br/>

            <label className='font14b_1'>1. 개인정보의 수집·이용목적</label>
            <label className='font14b_2'>
            회사는 수집한 개인정보를 다음의 목적을 위해 이용합니다.<br/><br/>

            가. 서비스 이용관리<br/>
            SNS 좋아요, 팔로우, 댓글, 구독 등 SNS 마케팅 주문 서비스<br/>
            주문 발주를 위한 광고비 충전 서비스<br/><br/>

            나. 서비스 제공에 관한 계약 이행 및 서비스 이용에 따른 재화 정산<br/>
            콘텐츠 제공, 특정 맞춤 서비스 제공, 물품배송 또는 청구서 등 발송, 재화 지급 및 이에 따른 세금 등의 신고<br/><br/>

            다. 회원관리<br/>
            회원제 서비스 이용 및 본인확인, 불량회원의 부정 이용방지와 비인가 사용방지, 가입의사 확인, 가입 및 가입횟수 제한, 만14세 미만 아동 개인정보 수집 시 법정 대리인 동의여부 확인, 추후 법정 대리인 본인확인, 분쟁 조정을 위한 기록보존, 불만처리 등 민원처리, 고지사항 전달<br/><br/>

            라. 신규 서비스 개발 및 마케팅•광고에 활용<br/>
            신규 서비스 개발 및 맞춤 서비스 제공, 이벤트 및 광고성 정보 제공 및 참여기회 제공
            </label>
            
            <br/>
            <label className='font14b_1'>2. 수집하는 개인정보의 항목</label>
            <label className='font14b_2'>
            “회사”는 회원가입, 상담, 서비스 신청 등을 위해 아래와 같은 개인정보를 수집하고 있습니다.<br/><br/>

            서비스명 개인정보 항목<br/>
            머니팡 애즈 (일반 회원)<br/>
            - 필수항목: 이메일주소(아이디), 휴대전화번호, 아이디, 비밀번호<br/><br/>

            머니팡 애즈 (사업자 검토 회원)<br/>
            - 필수항목: 이메일주소(아이디), 휴대전화번호, 아이디, 비밀번호, 사업자등록증<br/><br/>

            머니팡 애즈 (사업자 회원)<br/>
            - 필수항목: 이메일주소(아이디), 휴대전화번호, 아이디, 비밀번호, 사업자등록증<br/><br/>

            또한 서비스 이용 과정이나 사업처리 과정에서 아래와 같은 정보들이 생성되어 수집될 수 있습니다.<br/>
            - 서비스 이용기록, 접속로그, 쿠키, 접속 IP 정보, 포인트(현금) 충전 기록, 이용정지 기록
            </label>
            
            <br/>
            <label className='font14b_1'>3. 개인정보의 보유 및 이용기간</label>
            <label className='font14b_2'>
            원칙적으로 회원탈퇴 등 개인정보의 수집 및 이용목적이 달성된 후에는 개인정보를 지체 없이 파기하나, 일부 개인정보(이메일주소, 휴대전화번호)에 한해<br/>
            회원탈퇴 후 30일 이내 재가입 불가 정책에 따라 30일간 보관됩니다.<br/>
            또한 이용약관에 따른 광고주 직권해지(회원탈퇴) 시,머니팡 애즈 포인트 미환급 이용자에 한해 개인정보의 수집 및 이용목적이 달성되지 않았다고 보아<br/>
            환급 처리 완료 시까지 개인정보를 보관합니다.<br/>
            아래의 정보에 대해서는 명시한 보관이유 및 기간에 따라 회원탈퇴 이후에도 개인정보를 보관하고 있습니다.<br/><br/>

            가. 회사 내부방침에 의한 정보보유 사유<br/>
            보관하는 정보 : 부정이용 기록 (이메일주소, 휴대전화번호)<br/><br/>

            보존 이유 : 부정 가입 및 이용 방지<br/>
            ※ ‘부정이용기록’이란 머니팡 애즈 서비스 내에서의 부정이용에 따라 운영 정책에 의해 회사로부터 이용 제한 등을 당한 기록을 말합니다.<br/>
            보존 기간 : 6개월<br/><br/>

            나. 관련법령에 의한 정보보유 사유<br/>
            상법, 전자상거래 등에서의 소비자보호에 관한 법률 등 관계법령의 규정에 의하여 보존할 필요가 있는 경우 회사는 관계법령에서 정한 일정한 기간 동안 회원정보를 보관합니다.<br/>
            이 경우 회사는 보관하는 정보를 그 보관의 목적으로만 이용하며 보존기간은 아래의 예시와 같습니다.
            </label>

            <table className='termtable'>
              <thead>
                  <tr>
                    <th className="termtablehead1">
                      <label className="font14b_1">보관하는 정보</label>
                    </th>
  
                    <th className="termtablehead2">
                      <label className="font14b_1">보존 이유</label>
                    </th>

                    <th className="termtablehead3">
                      <label className="font14b_1">보존 기간</label>
                    </th>
                  </tr>
              </thead>
              <tbody>
                <tr>
                  <td className='termtabledata1'>
                    <label className="font14b_2">계약 또는 청약철회 등에 관한 기록</label>
                  </td>

                  <td className='termtabledata2'>
                    <label className="font14b_2">전자상거래 등에서의 소비자보호에 관한 법률</label>
                  </td>

                  <td className='termtabledata3'>
                    <label className="font14b_2">5년</label>
                  </td>
                </tr>

                <tr>
                  <td className='termtabledata1'>
                    <label className="font14b_2">대금결제 및 재화 등의 공급에 관한 기록</label>
                  </td>

                  <td className='termtabledata2'>
                    <label className="font14b_2">전자상거래 등에서의 소비자보호에 관한 법률</label>
                  </td>

                  <td className='termtabledata3'>
                    <label className="font14b_2">5년</label>
                  </td>
                </tr>

                <tr>
                  <td className='termtabledata1'>
                    <label className="font14b_2">소비자의 불만 또는 분쟁처리에 관한 기록</label>
                  </td>

                  <td className='termtabledata2'>
                    <label className="font14b_2">전자상거래 등에서의 소비자보호에 관한 법률</label>
                  </td>

                  <td className='termtabledata3'>
                    <label className="font14b_2">3년</label>
                  </td>
                </tr>

                <tr>
                  <td className='termtabledata1'>
                    <label className="font14b_2">표시/광고에 관한 기록</label>
                  </td>

                  <td className='termtabledata2'>
                    <label className="font14b_2">전자상거래 등에서의 소비자보호에 관한 법률</label>
                  </td>

                  <td className='termtabledata3'>
                    <label className="font14b_2">6개월</label>
                  </td>
                </tr>

                <tr>
                  <td className='termtabledata1'>
                    <label className="font14b_2">세법이 규정하는 모든 거래에 관한 장부 및 증빙서류</label>
                  </td>

                  <td className='termtabledata2'>
                    <label className="font14b_2">국세기본법, 법인세법</label>
                  </td>

                  <td className='termtabledata3'>
                    <label className="font14b_2">5년</label>
                  </td>
                </tr>

                <tr>
                  <td className='termtabledata1'>
                    <label className="font14b_2">전자금융 거래에 관한 기록</label>
                  </td>

                  <td className='termtabledata1'>
                    <label className="font14b_2">전자금융거래법</label>
                  </td>

                  <td className='termtabledata3'>
                    <label className="font14b_2">5년</label>
                  </td>
                </tr>
                
                <tr>
                  <td className='termtabledata1'>
                    <label className="font14b_2">웹사이트 방문에 관한 기록</label>
                  </td>

                  <td className='termtabledata2'>
                    <label className="font14b_2">통신비밀보호법</label>
                  </td>

                  <td className='termtabledata3'>
                    <label className="font14b_2">3개월</label>
                  </td>
                </tr>
              </tbody>
            </table>
            <br/>
            <label className='font14b_2'>그 밖의 사항은 머니팡 애즈 회원 개인정보 처리방침에 따릅니다.</label>
          </div>
        </div>
      </div>
    );
  }
  else
  {
    return (
      <div className='termpopbg flexcolcc'>

        <div className='termpop flexcoltc'>
          <div className='termtitle flexrowsc margintop25'>
            <label className='font20b2 marginleft25'>개인정보처리방침</label>
            <img src={GetImgUrl("term/close.png")} alt="close" className='btncur marginright25 menubtn' onClick={props.handleClose}/>
          </div>

          <div className='termcont flexcoltl margintop30'>
            <label className='font14b_2'>
            주식회사 브라보식스 (이하 “회사”)는 머니팡 애즈 서비스(이하 "서비스")를 제공하는 데 있어서, 이용자의 개인정보를 중요시하며,<br/>
            ‘정보통신망 이용촉진 및 정보보호 등에 관한 법률’ 등 관계 법령에 의거하여 개인정보를 관리 및 보호하고 있습니다.<br/>
            회사의 머니팡 애즈 서비스에서는 개인정보의 수집, 이용 등 처리에 있어 아래의 사항을 정보주체에게 안내합니다.
            </label>
            <br/>

            <label className='font14b_1'>1. 개인정보의 수집·이용목적</label>
            <label className='font14b_2'>
            회사는 수집한 개인정보를 다음의 목적을 위해 이용합니다.<br/><br/>

            가. 서비스 이용관리<br/>
            SNS 좋아요, 팔로우, 댓글, 구독 등 SNS 마케팅 주문 서비스<br/>
            주문 발주를 위한 광고비 충전 서비스<br/><br/>

            나. 서비스 제공에 관한 계약 이행 및 서비스 이용에 따른 재화 정산<br/>
            콘텐츠 제공, 특정 맞춤 서비스 제공, 물품배송 또는 청구서 등 발송, 재화 지급 및 이에 따른 세금 등의 신고<br/><br/>

            다. 회원관리<br/>
            회원제 서비스 이용 및 본인확인, 불량회원의 부정 이용방지와 비인가 사용방지, 가입의사 확인, 가입 및 가입횟수 제한, 만14세 미만 아동 개인정보 수집 시 법정 대리인 동의여부 확인, 추후 법정 대리인 본인확인, 분쟁 조정을 위한 기록보존, 불만처리 등 민원처리, 고지사항 전달<br/><br/>

            라. 신규 서비스 개발 및 마케팅•광고에 활용<br/>
            신규 서비스 개발 및 맞춤 서비스 제공, 이벤트 및 광고성 정보 제공 및 참여기회 제공
            </label>
            
            <br/>
            <label className='font14b_1'>2. 수집하는 개인정보의 항목</label>
            <label className='font14b_2'>
            “회사”는 회원가입, 상담, 서비스 신청 등을 위해 아래와 같은 개인정보를 수집하고 있습니다.<br/><br/>

            서비스명 개인정보 항목<br/>
            머니팡 애즈 (일반 회원)<br/>
            - 필수항목: 이메일주소(아이디), 휴대전화번호, 아이디, 비밀번호<br/><br/>

            머니팡 애즈 (사업자 검토 회원)<br/>
            - 필수항목: 이메일주소(아이디), 휴대전화번호, 아이디, 비밀번호, 사업자등록증<br/><br/>

            머니팡 애즈 (사업자 회원)<br/>
            - 필수항목: 이메일주소(아이디), 휴대전화번호, 아이디, 비밀번호, 사업자등록증<br/><br/>

            또한 서비스 이용 과정이나 사업처리 과정에서 아래와 같은 정보들이 생성되어 수집될 수 있습니다.<br/>
            - 서비스 이용기록, 접속로그, 쿠키, 접속 IP 정보, 포인트(현금) 충전 기록, 이용정지 기록
            </label>
            
            <br/>
            <label className='font14b_1'>3. 개인정보의 보유 및 이용기간</label>
            <label className='font14b_2'>
            원칙적으로 회원탈퇴 등 개인정보의 수집 및 이용목적이 달성된 후에는 개인정보를 지체 없이 파기하나, 일부 개인정보(이메일주소, 휴대전화번호)에 한해<br/>
            회원탈퇴 후 30일 이내 재가입 불가 정책에 따라 30일간 보관됩니다.<br/>
            또한 이용약관에 따른 광고주 직권해지(회원탈퇴) 시,머니팡 애즈 포인트 미환급 이용자에 한해 개인정보의 수집 및 이용목적이 달성되지 않았다고 보아<br/>
            환급 처리 완료 시까지 개인정보를 보관합니다.<br/>
            아래의 정보에 대해서는 명시한 보관이유 및 기간에 따라 회원탈퇴 이후에도 개인정보를 보관하고 있습니다.<br/><br/>

            가. 회사 내부방침에 의한 정보보유 사유<br/>
            보관하는 정보 : 부정이용 기록 (이메일주소, 휴대전화번호)<br/><br/>

            보존 이유 : 부정 가입 및 이용 방지<br/>
            ※ ‘부정이용기록’이란 머니팡 애즈 서비스 내에서의 부정이용에 따라 운영 정책에 의해 회사로부터 이용 제한 등을 당한 기록을 말합니다.<br/>
            보존 기간 : 6개월<br/><br/>

            나. 관련법령에 의한 정보보유 사유<br/>
            상법, 전자상거래 등에서의 소비자보호에 관한 법률 등 관계법령의 규정에 의하여 보존할 필요가 있는 경우 회사는 관계법령에서 정한 일정한 기간 동안 회원정보를 보관합니다.<br/>
            이 경우 회사는 보관하는 정보를 그 보관의 목적으로만 이용하며 보존기간은 아래의 예시와 같습니다.
            </label>

            <table className='termtable'>
              <thead>
                  <tr>
                    <th className="termtablehead1">
                      <label className="font14b_1">보관하는 정보</label>
                    </th>
  
                    <th className="termtablehead2">
                      <label className="font14b_1">보존 이유</label>
                    </th>

                    <th className="termtablehead3">
                      <label className="font14b_1">보존 기간</label>
                    </th>
                  </tr>
              </thead>
              <tbody>
                <tr>
                  <td className='termtabledata1'>
                    <label className="font14b_2">계약 또는 청약철회 등에 관한 기록</label>
                  </td>

                  <td className='termtabledata2'>
                    <label className="font14b_2">전자상거래 등에서의 소비자보호에 관한 법률</label>
                  </td>

                  <td className='termtabledata3'>
                    <label className="font14b_2">5년</label>
                  </td>
                </tr>

                <tr>
                  <td className='termtabledata1'>
                    <label className="font14b_2">대금결제 및 재화 등의 공급에 관한 기록</label>
                  </td>

                  <td className='termtabledata2'>
                    <label className="font14b_2">전자상거래 등에서의 소비자보호에 관한 법률</label>
                  </td>

                  <td className='termtabledata3'>
                    <label className="font14b_2">5년</label>
                  </td>
                </tr>

                <tr>
                  <td className='termtabledata1'>
                    <label className="font14b_2">소비자의 불만 또는 분쟁처리에 관한 기록</label>
                  </td>

                  <td className='termtabledata2'>
                    <label className="font14b_2">전자상거래 등에서의 소비자보호에 관한 법률</label>
                  </td>

                  <td className='termtabledata3'>
                    <label className="font14b_2">3년</label>
                  </td>
                </tr>

                <tr>
                  <td className='termtabledata1'>
                    <label className="font14b_2">표시/광고에 관한 기록</label>
                  </td>

                  <td className='termtabledata2'>
                    <label className="font14b_2">전자상거래 등에서의 소비자보호에 관한 법률</label>
                  </td>

                  <td className='termtabledata3'>
                    <label className="font14b_2">6개월</label>
                  </td>
                </tr>

                <tr>
                  <td className='termtabledata1'>
                    <label className="font14b_2">세법이 규정하는 모든 거래에 관한 장부 및 증빙서류</label>
                  </td>

                  <td className='termtabledata2'>
                    <label className="font14b_2">국세기본법, 법인세법</label>
                  </td>

                  <td className='termtabledata3'>
                    <label className="font14b_2">5년</label>
                  </td>
                </tr>

                <tr>
                  <td className='termtabledata1'>
                    <label className="font14b_2">전자금융 거래에 관한 기록</label>
                  </td>

                  <td className='termtabledata1'>
                    <label className="font14b_2">전자금융거래법</label>
                  </td>

                  <td className='termtabledata3'>
                    <label className="font14b_2">5년</label>
                  </td>
                </tr>
                
                <tr>
                  <td className='termtabledata1'>
                    <label className="font14b_2">웹사이트 방문에 관한 기록</label>
                  </td>

                  <td className='termtabledata2'>
                    <label className="font14b_2">통신비밀보호법</label>
                  </td>

                  <td className='termtabledata3'>
                    <label className="font14b_2">3개월</label>
                  </td>
                </tr>
              </tbody>
            </table>
            <br/>
            <label className='font14b_2'>그 밖의 사항은 머니팡 애즈 회원 개인정보 처리방침에 따릅니다.</label>
          </div>
        </div>
      </div>
    );
  }
}

export default Privacy;