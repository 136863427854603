import { observer } from "mobx-react";
import { GetImgUrl, mobileOn } from "../../URL/AppUrl";
import { UseLoginStore } from "../../Stores/LoginStore";
import { Fragment } from "react";

import './TopBar.css';
import '../../App.css';
import { GetFormattedNum } from "../../Util/Util";

const getFontStyle = (selectedIndex, index, needMargin) =>
{
  if (selectedIndex === index)
    return `font16203 menutext btncur menubtn ${needMargin ? "marginright20" : ''}`;

    return `font16b menutext btncur menubtn ${needMargin ? "marginright20" : ''}`;
}

const TopBar = observer((props) =>
{
  const loginStore = UseLoginStore();

  {/*if (mobileOn)
  {
    return (
      <div className="top_cont flexcolcc">
        <div className="top_bar_mb flexrowsc">
          
          <img src={GetImgUrl("com/mb/toplogo.png")} alt="로고" className='img9421'/>
  
          <div className="flexrowcc">
            {
              loginStore.loggedIn ? (
                <Fragment>
                  <div className="pointbox_mb flexrowcc marginright20">
                    <label className="font12202">{`보유 포인트 ${GetFormattedNum(loginStore.getUserPoint)}P`}</label>
                  </div>

                  <img src={GetImgUrl("com/mb/topmenu.png")} alt="메뉴" className="menubtn btncur img1713" onClick={() => props.handleMenu(100)}/>
                </Fragment>
              ) : (
                <Fragment>
                  <label className="font14b_2 menutext marginright20 btncur menubtn" onClick={() => props.handleMenu(2)}>로그인</label>
                  <label className="font14b_2 menutext btncur menubtn" onClick={() => props.handleMenu(3)}>회원가입</label>
                </Fragment>
              )
            }
            
          </div>
        </div>
        {
          props.withLine ? (<div className="topbarline"></div>) : null
        }
      </div>
    );
  }
  else*/}
  {
    return (
      <div className="top_cont flexcolcc">
        <div className="top_bar flexrowsc">
          
          <div className="flexrowcc btncur menubtn" onClick={() => {
            if (props.handleNavi)
              props.handleNavi(100);
          }}>
            <img src={GetImgUrl("com/toplogo.png")} alt="로고" className="mglt40"/>
          </div>
  
          <div className="flexrowcc">
          </div>
        </div>
        {
          props.withLine ? (<div className="topbarline"></div>) : null
        }
      </div>
    );
  }  
});

export default TopBar;