import './Bingo.css';
import '../../App.css';
import { GetImgUrl } from '../../URL/AppUrl';

const BingoDescDesign = (props) =>
{
  return (
    <div className='wd100p coltl mgtop10'>
      <label className='font15 wt400 color31 lht23'>빙고 디자인은 "자연" 컨셉으로만 구성되어 있으며, 디자인은 다음과 같습니다.<br/><br/>① 빙고 이름 : 빙고 이름이 표시됩니다.<br/>② 빙고팡팡 게임 배너 : 게임 배너 이미지가 노출됩니다.<br/>③ 머니팡 회원은 버튼을 클릭하면 리워드를 지급받기 때문에, 클릭하도록 유도하는 표시가 되어 있습니다.</label>

      <div className='wd100p rowcc'>
        <img src={GetImgUrl("bingo/stp21n.png")} alt="설명" className='bgst2img1'/>
      </div>
    </div>
  );
}

export default BingoDescDesign;