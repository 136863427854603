
import React from 'react';
import {GetApiUrl, GetApiUrlBase, GetSNSApiUrl, LOG_ENABLE, mobileOn} from '../URL/AppUrl';
import axios from 'axios';
import { FromBase64, IsValidS, IsValidV, ToBase64, replaceLine } from '../Util/Util';
import { LoginStoreInstance } from './LoginStore';

//자주묻는질문관련
class FaqStore {
  //observable변수

  constructor() {
  }

  clearAll = () =>
  {

  }
  //-------------------------------------------------------------------------------------------------------
  //faq 목록을 조회함
  //faqType: faq타입, 전체(-1), 일반(0), 취소환불(1), 서비스(2)
  //pos : 읽을 위치
  loadFaq = (faqType, pos, callback) => {

    const params = new URLSearchParams();

    params.append("nt", faqType.toString());
    params.append("pos", pos.toString());

    axios({
      method:"POST",
      url: GetApiUrl('faq/list/'),
      headers: {
        //Accept: 'application/json',
        Accept: 'application/text',
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
      },
      responseType: 'json', // 기본 값, [ 'arraybuffer', 'blob', 'document', 'json', 'text', 'stream' ]
      responseEncoding: 'utf8', // 기본 값
      data: params
    }).then((res)=>{
        this.parseFaqResult(res.data, callback);
    }).catch(error=>{
        console.log(error);
        this.parseFaqResult(null, callback);
    });
  }

  parseFaqResult = (result, callback) => 
  {
    if (LOG_ENABLE)
      console.log(`parseFaqResult ${result ? JSON.stringify(result) : 'unknown'}`);

    if (!IsValidV(result))
    {
      if (LOG_ENABLE)
        console.log("request failed.");

      if (callback)
      {
        callback(-2, null, null, null);
      }

      return;
    }
    
    if (callback)
    {
      if (IsValidS(result.list))
      {
        result.list.forEach(element => {
          this.decodeFaqItem(element);
        });
      }

      if (result.ret === 100)
      {
        LoginStoreInstance.sessionError = true;
      }

      callback(result.ret, result.ft, result.tc, result.list);
    }
  }

  decodeFaqItem = (item) =>
  {
    if (IsValidS(item.tt))
      item.tt = FromBase64(item.tt);

    if (IsValidS(item.dc))
      item.dc = FromBase64(item.dc);
  }
  //-------------------------------------------------------------------------------------------------------
  //faq를 검색함
  //faqType: faq타입, 전체(-1), 일반(0), 취소환불(1), 서비스(2)
  //sw : 검색어
  searchFaq = (faqType, sw, callback) => {

    const params = new URLSearchParams();

    params.append("nt", faqType.toString());
    params.append("sw", ToBase64(sw));

    axios({
      method:"POST",
      url: GetApiUrl('faq/search/'),
      headers: {
        //Accept: 'application/json',
        Accept: 'application/text',
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
      },
      responseType: 'json', // 기본 값, [ 'arraybuffer', 'blob', 'document', 'json', 'text', 'stream' ]
      responseEncoding: 'utf8', // 기본 값
      data: params
    }).then((res)=>{
        this.parseFaqSearchResult(res.data, callback);
    }).catch(error=>{
        console.log(error);
        this.parseFaqSearchResult(null, callback);
    });
  }

  parseFaqSearchResult = (result, callback) => 
  {
    if (LOG_ENABLE)
      console.log(`parseFaqSearchResult ${result ? JSON.stringify(result) : 'unknown'}`);

    if (!IsValidV(result))
    {
      if (LOG_ENABLE)
        console.log("request failed.");

      if (callback)
      {
        callback(-2, null);
      }

      return;
    }
    
    if (callback)
    {
      if (IsValidS(result.list))
      {
        result.list.forEach(element => {
          this.decodeFaqItem(element);
        });
      }

      if (result.ret === 100)
      {
        LoginStoreInstance.sessionError = true;
      }

      callback(result.ret, result.list);
    }
  }
  //-------------------------------------------------------------------------------------------------------
  //문의하기
  //aidx 광고 고유번호
  //dc : 문의 내용
  sendQuestion = (aidx, dc, callback) => {

    const params = new URLSearchParams();

    params.append("aidx", aidx.toString());
    params.append("dc", ToBase64(dc));

    axios({
      method:"POST",
      url: GetApiUrl('faq/ssqr/'),
      headers: {
        //Accept: 'application/json',
        Accept: 'application/text',
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
      },
      responseType: 'json', // 기본 값, [ 'arraybuffer', 'blob', 'document', 'json', 'text', 'stream' ]
      responseEncoding: 'utf8', // 기본 값
      data: params
    }).then((res)=>{
        this.parseSSQueryResult(res.data, callback);
    }).catch(error=>{
        console.log(error);
        this.parseSSQueryResult(null, callback);
    });
  }

  parseSSQueryResult = (result, callback) => 
  {
    if (LOG_ENABLE)
      console.log(`parseSSQueryResult ${result ? JSON.stringify(result) : 'unknown'}`);

    if (!IsValidV(result))
    {
      if (LOG_ENABLE)
        console.log("request failed.");

      if (callback)
      {
        callback(-2);
      }

      return;
    }
    
    if (callback)
    {
      if (result.ret === 100)
      {
        LoginStoreInstance.sessionError = true;
      }

      callback(result.ret, result.aidx);
    }
  }
  //-------------------------------------------------------------------------------------------------------
  //나의 문의 내역조회하기
  loadQuestion = (pos, callback) => {

    const params = new URLSearchParams();

    params.append("rt", "0");
    params.append("pos", pos.toString());

    axios({
      method:"POST",
      url: GetApiUrl('faq/ssqrls/'),
      headers: {
        //Accept: 'application/json',
        Accept: 'application/text',
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
      },
      responseType: 'json', // 기본 값, [ 'arraybuffer', 'blob', 'document', 'json', 'text', 'stream' ]
      responseEncoding: 'utf8', // 기본 값
      data: params
    }).then((res)=>{
        this.parseSSQueryLoadResult(res.data, callback);
    }).catch(error=>{
        console.log(error);
        this.parseSSQueryLoadResult(null, callback);
    });
  }

  parseSSQueryLoadResult = (result, callback) => 
  {
    if (LOG_ENABLE)
      console.log(`parseSSQueryLoadResult ${result ? JSON.stringify(result) : 'unknown'}`);

    if (!IsValidV(result))
    {
      if (LOG_ENABLE)
        console.log("request failed.");

      if (callback)
      {
        callback(-2, null, null, null);
      }

      return;
    }
    
    if (callback)
    {
      if (result.ret === 100)
      {
        LoginStoreInstance.sessionError = true;
      }

      if (result.ret === 0)
      {
        if (IsValidS(result.list))
        {
          result.list.forEach(item => this.decodeUserQuery(item));
        }
      }

      callback(result.ret, result.list, result.ft === 1, result.tc);
    }
  }

  decodeUserQuery = (item) =>
  {
    if (IsValidS(item.qr))
      item.qr = FromBase64(item.qr);

    if (IsValidS(item.ans))
      item.ans = FromBase64(item.ans);
  }
  //-------------------------------------------------------------------------------------------------------
  //광고 고유번호에 해당하는 문의 내역 한건조회
  loadQuestionOneByAidx = (aidx, callback) => {

    const params = new URLSearchParams();

    params.append("rt", "3");
    params.append("pos", "0");
    params.append("aidx", aidx.toString());

    axios({
      method:"POST",
      url: GetApiUrl('faq/ssqrls/'),
      headers: {
        //Accept: 'application/json',
        Accept: 'application/text',
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
      },
      responseType: 'json', // 기본 값, [ 'arraybuffer', 'blob', 'document', 'json', 'text', 'stream' ]
      responseEncoding: 'utf8', // 기본 값
      data: params
    }).then((res)=>{
        this.parseSSQueryLoadOneResult(res.data, callback);
    }).catch(error=>{
        console.log(error);
        this.parseSSQueryLoadOneResult(null, callback);
    });
  }

  parseSSQueryLoadOneResult = (result, callback) => 
  {
    if (LOG_ENABLE)
      console.log(`parseSSQueryLoadOneResult ${result ? JSON.stringify(result) : 'unknown'}`);

    if (!IsValidV(result))
    {
      if (LOG_ENABLE)
        console.log("request failed.");

      if (callback)
      {
        callback(-2, null);
      }

      return;
    }
    
    if (callback)
    {
      if (result.ret === 100)
      {
        LoginStoreInstance.sessionError = true;
      }

      if (result.ret === 0)
      {
        if (IsValidV(result.info))
        {
          this.decodeUserQuery(result.info);
        }
      }

      callback(result.ret, result.info);
    }
  }
}

const FaqStoreInstance = new FaqStore();
const FaqStoreContext = React.createContext(FaqStoreInstance);

const UseFaqStore = () => React.useContext(FaqStoreContext);

export {UseFaqStore, FaqStoreInstance};
