import '../Purchase/Purchase.css';
import '../../App.css';
import { GetImgUrl, LOG_ENABLE } from '../../URL/AppUrl';
import { convertBingoDateInfo, GetFormattedNum } from '../../Util/Util';

const BingoOrderResult = (props) =>
{
  if (LOG_ENABLE)
    console.log(`item : ${JSON.stringify(props.item)}`);

  return (
    <div className='prpopbg2 colcc'>
      <div className='prpop2 colcc'>
        <img src={GetImgUrl("com/pr.png")} alt="신청완료"/>

        <label className='font20 wt500 color31'>신청 완료</label>
        <label className='font16 wt400 color31'>이용해 주셔서 감사합니다.</label>

        <div className='prpop2in mgtop20 colcl'>
          <div className='prpop2line'></div>

          <label className='font15 wt500 color31 mgtop10'>입금계좌 정보</label>

          <label className='font16 wt400 color31 mgtop5'>예금주명&nbsp;&nbsp;&nbsp;주식회사 브라보식스</label>
          <label className='font16 wt400 color31 mgtop5'>거래은행&nbsp;&nbsp;&nbsp;하나은행</label>
          <label className='font16 wt400 color31 mgtop5'>계좌번호&nbsp;&nbsp;&nbsp;321-910042-08605</label>

          <div className='prpop2line mgtop10'></div>

          <label className='font15 wt500 color31 mgtop10'>입금자 정보</label>

          <label className='font16 wt400 color31 mgtop5'>입금자명&nbsp;&nbsp;&nbsp;{props.item.wun}</label>
          <label className='font16 wt400 color31 mgtop5'>입금금액&nbsp;&nbsp;&nbsp;{GetFormattedNum(props.item.pamt)}원 (VAT포함)</label>
          <label className='font16 wt400 color31 mgtop5'>입금기한&nbsp;&nbsp;&nbsp;{convertBingoDateInfo(props.item.bankInfo.ldt)}</label>

          <div className='prpop2line mgtop10'></div>

          <label className='font15 wt400 color31 mgtop10'><span className='colorff36'>· 주문은 접수되었으나, 결제가 확인되어야 광고를 게시할 수 있습니다.</span><br/>· 신청 후 7일 이내 입금하지 않으면 주문이 자동으로 취소됩니다.<br/>· 입금이 완료되었으나 결제 대기 상태로 보일 경우, 문의해 주시기 바랍니다. 입금 확인은 1~3 영업일이 소요될 수 있습니다.</label>

          <button className='prpop2btn font14 wt700 colorw mgtop50 btncur' onClick={props.handleClose}>확인</button>
        </div>

      </div>
    </div>
  );
}

export default BingoOrderResult;