import './Purchase.css';
import '../../App.css';
import { GetImgUrl } from '../../URL/AppUrl';
import { GetFormattedNum } from '../../Util/Util';

const PurchaseResult3 = (props) =>
{
  return (
    <div className='prpopbg2 colcc'>
      <div className='prpop2 colcc'>
        <img src={GetImgUrl("com/pr.png")} alt="신청완료"/>

        <label className='font20 wt500 color31'>신청 완료</label>
        <label className='font16 wt400 color31'>이용해 주셔서 감사합니다.</label>

        <div className='prpop2in mgtop20 colcl'>
          <div className='prpop2line'></div>

          <label className='font15 wt500 color31 mgtop10'>입금계좌 정보</label>

          <label className='font16 wt400 color31 mgtop5'>예금주명&nbsp;&nbsp;&nbsp;주식회사 브라보식스</label>
          <label className='font16 wt400 color31 mgtop5'>거래은행&nbsp;&nbsp;&nbsp;하나은행</label>
          <label className='font16 wt400 color31 mgtop5'>계좌번호&nbsp;&nbsp;&nbsp;321-910042-08605</label>

          <div className='prpop2line mgtop10'></div>

          <label className='font15 wt500 color31 mgtop10'>입금자 정보</label>

          <label className='font16 wt400 color31 mgtop5'>입금자명&nbsp;&nbsp;&nbsp;{props.uname}</label>
          <label className='font16 wt400 color31 mgtop5'>입금금액&nbsp;&nbsp;&nbsp;{GetFormattedNum(props.totalCost + Math.floor(props.totalCost * 0.1))}원 (VAT포함)</label>

          <div className='prpop2line mgtop10'></div>

          <label className='font15 wt400 color31 mgtop10'>· 결제금액과 임금자명이 일치해야 충전이 완료됩니다.<br/>· 입금이 완료되더라도 포인트 충전에는 시간이 소요될 수 있으며, 영업시간 외에 입금한 경우 충전이 &nbsp;&nbsp;&nbsp;지연될 수 있습니다.<br/>· 현재 계좌이체로 충전한 내역은 표시되지 않습니다.</label>

          <button className='prpop2btn font14 wt700 colorw mgtop50 btncur' onClick={props.handleClose}>확인</button>
        </div>

      </div>
    </div>
  );
}

export default PurchaseResult3;