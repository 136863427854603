import {Cookies} from "react-cookie";
import { APP_MODE } from "../URL/AppUrl";

const cookies = new Cookies();

const getSiteDomain = () =>
{
  if (APP_MODE === 0)
    return "localhost";
  else if (APP_MODE === 1)
    return ".snsfly.kr";
  else
    return "ads.mpang.io";
}

export const setCookie = (name, value) =>
{
  try
  {
    return cookies.set(name, value, {
      path : '',
      maxAge : 604800,
      domain:  getSiteDomain()
    });
  }
  catch(e)
  {
    console.log(e.toString());
    return false;
  }
}

export const getCookie = (name) =>
{
  try
  {
    return cookies.get(name);
  }
  catch(e)
  {
    console.log(e.toString());
    return null;
  }
}

export const delCookie = (name) =>
{
  try
  {
    cookies.remove(name, {path: "", domain: getSiteDomain()});
  }
  catch(e)
  {
    console.log(e.toString());
  }
}