import { GetImgUrl, mobileOn } from "../../URL/AppUrl";
import { UseLoginStore } from "../../Stores/LoginStore";
import './Navi.css';
import '../../App.css';
import { Fragment } from "react";
import { GetFormattedNum } from "../../Util/Util";
import { observer } from "mobx-react";

const NaviBar = observer((props) =>
{
  const loginStore = UseLoginStore();

  return (
    <div className="navibar colsl" style={{width: props.width, height: props.height}}>
      <div className="navimenu coltl mglt40" style={{height: props.height - 100}}>
        {
          loginStore.loggedIn ? (
            <Fragment>
              <label className="font15 wt500 color31 mgtop30">{`${loginStore.getUserName}님`}</label>
              <div className="rowcc mgtop15 btncur" onClick={() => props.handleNavi(100)}>
                <img src={GetImgUrl("navi/home.png")} alt="홈"/>
                <label className="font16 wt400 color31 mglt10 btncur">홈</label>
              </div>
            </Fragment>
          ) : (
            <Fragment>
              <label className="font15 wt500 color31 mgtop30">로그인 후 이용해 주세요.</label>
              <button className="nvloginbtn font15 wt600 colorw btncur mgtop15" onClick={() => props.handleMenu(0)}>로그인</button>
            </Fragment>
          )
        }

        <div className="nvline mgtop30"></div>

        <label className="font16 wt500 color20 mgtop15">리워드 광고 주문하기</label>

        <div className="rowcc mgtop15 btncur" onClick={() => props.handleNavi(7)}>
          <img src={GetImgUrl("navi/bingo.png")} alt="빙고팡팡"/>
          <label className="font16 wt400 color31 mglt10 btncur">빙고팡팡 주문</label>
        </div>

        <div className="nvline mgtop30"></div>

        <label className="font16 wt500 color20 mgtop15">SNS 주문하기</label>
        {
          loginStore.loggedIn ? (
            <div className="nvptbox rowsc btncur mgtop10" onClick={() => props.handleNavi(4)}>
              <label className="font15 wt400 colorw btncur">{`${GetFormattedNum(loginStore.getUserPoint)}`}P</label>
              <label className="font15 wt400 colorw btncur">+충전</label>
            </div>
          ) : null
        }
        <div className="rowcc mgtop20 btncur" onClick={() => props.handleSnsOrder(0)}>
          <img src={GetImgUrl("navi/insta.png")} alt="인스타"/>
          <label className="font16 wt400 color31 mglt10 btncur">인스타그램 주문</label>
        </div>

        {/*<div className="rowcc mgtop15 btncur" onClick={() => props.handleSnsOrder(1)}>
          <img src={GetImgUrl("navi/fb.png")} alt="페북"/>
          <label className="font16 wt400 color31 mglt10 btncur">페이스북 주문</label>
        </div>*/}

        <div className="rowcc mgtop15 btncur" onClick={() => props.handleSnsOrder(2)}>
          <img src={GetImgUrl("navi/youtube.png")} alt="유튜브"/>
          <label className="font16 wt400 color31 mglt10 btncur">유튜브 주문</label>
        </div>

        <div className="rowcc mgtop15 btncur" onClick={() => props.handleSnsOrder(3)}>
          <img src={GetImgUrl("navi/tiktok.png")} alt="틱톡"/>
          <label className="font16 wt400 color31 mglt10 btncur">틱톡 주문</label>
        </div>

        <div className="rowcc mgtop30 btncur" onClick={() => props.handleNavi(1)}>
          <img src={GetImgUrl("navi/order.png")} alt="주문내역"/>
          <label className="font16 wt400 color31 mglt10 btncur">SNS 광고 주문내역</label>
        </div>

        <div className="nvline mgtop30"></div>

        <div className="rowcc mgtop15 btncur" onClick={() => props.handleNavi(3)}>
          <img src={GetImgUrl("navi/notice.png")} alt="공지"/>
          <label className="font16 wt400 color31 mglt10 btncur">공지사항</label>
        </div>

        <div className="rowcc mgtop15 btncur" onClick={() => props.handleNavi(2)}>
          <img src={GetImgUrl("navi/faq.png")} alt="faq"/>
          <label className="font16 wt400 color31 mglt10 btncur">자주 묻는 질문</label>
        </div>

        <div className="rowcc mgtop15 btncur" onClick={() => props.handleNavi(6)}>
          <img src={GetImgUrl("navi/setting.png")} alt="faq"/>
          <label className="font16 wt400 color31 mglt10 btncur">계정 설정</label>
        </div>

        {
          loginStore.loggedIn ? (
            <div className="rowcc mgtop60 btncur mgbt50" onClick={() => props.handleMenu(100)}>
              <img src={GetImgUrl("navi/logout.png")} alt="faq"/>
              <label className="font16 wt400 color31 mglt10 btncur">로그아웃</label>
            </div>
          ) : null
        }
        
      </div>

      <div className="navidesc colcl">
        <div className="navidesccont mglt40 coltl">
          <label className="font15 color31 wt400 lht23"><span className="wt500">상담 가능 시간</span><br/>평일 09:00~18:00 (주말, 공휴일 제외)</label>
        </div>
      </div>
    </div>
  );
});

export default NaviBar;