import React, { useState } from 'react';
import './Bingo.css';
import '../../App.css';
import { APP_MODE, GetImgUrl, gotoTalkPage } from '../../URL/AppUrl';
import FooterBar from '../Footer/Footer';
import { IsValidV } from '../../Util/Util';
import BingoCardAni from './BingoCardAni';

//광고유형, 일반형, 쇼츠형
const BingoCategoryMenu1 = (props) =>
{
  const descColor1 = props.index === props.selected ? 'bingood0menudesc font15 wt500 color20' : 'bingood0menudesc font15 wt500 color31';
  const descColor2 = props.index === props.selected ? 'font15 wt400 color31' : 'font15 wt400 color80';

  return (
    <div className={props.index === props.selected ? 'bingood0menubg1 bingood0menubght1 colcc btncur mgbt10' : 'bingood0menubg2 bingood0menubght1 colcc btncur mgbt10'}
      onClick={() => props.handleSelectMenu(props.index)}
    >
      <div className='bingood0menuin colevl'>
        <div className='wd100p rowsc'>
          <label className='font16 wt500 color31'>{props.title}</label>
          <img src={GetImgUrl(props.index === props.selected ? "bingo/sel.png" : "bingo/sel2.png")} alt="선택"/>
        </div>

        <div className='rowcc'>
          <label className={descColor1}>{props.desc1.title}</label>
          <label className={descColor2}>{props.desc1.desc}</label>
        </div>

        <div className='rowcc'>
          <label className={descColor1}>{props.desc2.title}</label>
          <label className={descColor2}>{props.desc2.desc}</label>
        </div>

        <div className='rowcc'>
          <label className={descColor1}>{props.desc3.title}</label>
          <label className={descColor2}>{props.desc3.desc}</label>
        </div>

      </div>
    </div>
  );
}

//빙고유형, 한줄, 세줄
const BingoCategoryMenu2 = (props) =>
{
  const descColor1 = props.index === props.selected ? 'bingood0menudesc font15 wt500 color20' : 'bingood0menudesc font15 wt500 color31';
  const descColor2 = props.index === props.selected ? 'font15 wt400 color31' : 'font15 wt400 color80';

  return (
    <div className={props.index === props.selected ? 'bingood0menubg1 bingood0menubght2 colcc btncur mgbt10' : 'bingood0menubg2 bingood0menubght2 colcc btncur mgbt10'}
      onClick={() => props.handleSelectMenu(props.index)}
    >
      <div className='bingood0menuin colevl'>
        <div className='wd100p rowsc'>
          <label className='font16 wt500 color31'>{props.title}</label>
          <img src={GetImgUrl(props.index === props.selected ? "bingo/sel.png" : "bingo/sel2.png")} alt="선택"/>
        </div>

        <div className='rowcc'>
          <label className={descColor1}>{props.desc1.title}</label>
          <label className={descColor2}>{props.desc1.desc}</label>
        </div>

        <div className='rowcc'>
          <label className={descColor1}>{props.desc2.title}</label>
          <label className={descColor2}>{props.desc2.desc}</label>
        </div>
      </div>
    </div>
  );
}

//bingoType: 한줄(0), 세줄(1)
const getCurrentDesc = (bingoType) =>
{
  if (bingoType === 0)
  {
    return (
      <div className='bingooddescsamplebg colcc'>

        <div className='bingooddescsamplebgin colcl'>

          <label className='font20 wt500 color31'>한줄 빙고 특징</label>

          <div className='rowcc mgtop10'>
            <img src={GetImgUrl("bingo/bdogimg.png")} alt=""/>
            <label className='font15 wt400 color80 mglt5'>브랜드 광고 이미지 배치형</label>
          </div>

          <div className='bingooddescsamplebg2 rowcc mgtop10'>
            <BingoCardAni gameCode={5} selected={true}/>
          </div>

          <label className='font15 wt500 color31 mgtop10'>강력한 브랜딩 효과를 제공합니다.</label>
          <label className='font15 wt400 color31 mgtop5 lht23'>브랜드 광고 이미지를 크게 배치해 메시지를 효과적으로 전달할 수 있으며, 게임배경으로 광고 이미지가 노출돼요</label>
        </div>
        
      </div>
    );
  }
  else if (bingoType === 1)
  {
    return (
      <div className='bingooddescsamplebg colcc'>

        <div className='bingooddescsamplebgin colcl'>

          <label className='font20 wt500 color31'>세줄 빙고 특징</label>

          <div className='rowcc mgtop10'>
            <img src={GetImgUrl("bingo/bdogimg2.png")} alt=""/>
            <label className='font15 wt400 color80 mglt5'>게임 심볼 배치형</label>
          </div>

          <div className='bingooddescsamplebg2 rowcc mgtop10'>
            <BingoCardAni gameCode={1} selected={true}/>
          </div>

          <label className='font15 wt500 color31 mgtop10'>서비스 각인 효과를 제공합니다.</label>
          <label className='font15 wt400 color31 mgtop5 lht23'>상품 이미지와 키워드를 활용해 게임과 어우러지도록 9개의 심볼을 제작하여 게임 내 배치함으로써 브랜드 각인 효과를 만들어냅니다.</label>
        </div>
        
      </div>
    );
  }

  return null;
}
//빙고 주문하기 0단계, 빙고분류 선택, UI가 나중에 수정되어 0단계를 추가했습니다.
const BingoOrder0 = (props) =>
{
  const [bingoCategory, setBingoCategory] = useState(props.orderInfo ? props.orderInfo.bingoCategory : 0);
  const [subCategory, setBingoSubCategory] = useState(props.orderInfo ? props.orderInfo.bingoSubCategory : 0);

  return (
    <div className='bingocont colel' style={{width: props.contWidth, height: props.height}}>
      <div className='bingocont2 coltl' style={{width: props.contWidth - 40 - 50, height: props.height - 100}}>
        <label className='font20 wt600 color31 mgtop20'>빙고팡팡 광고</label>

        <div className='bingood0box rowsc mgtop20'>
          <div className='rowcc'>
            <img src={GetImgUrl("bingo/bgodsm.png")} alt="sm"/>
            <label className='font16 wt400 color31 mglt5'>선택하신 유형에 따라 적절한 빙고팡팡 광고를 추천해 드리겠습니다.</label>
          </div>

          <button className='bingood0btn1 font16 wt500 colorw btncur' onClick={() => gotoTalkPage()}>광고 상담</button>
        </div>
        
        <label className='font15 color31 wt500 mgbt10  mgtop40'>광고 유형</label>

        <div className='wd100p rowsc'>
          <div className='bingood0left coltl'>

            <BingoCategoryMenu1
              index={0}
              selected={bingoCategory}
              title="일반형 광고"
              desc1={{title: "최대 다수 노출", desc: "브랜드 인지도 확대"}}
              desc2={{title: "효율적 도달", desc: "다수의 유저에게 유저당 최대 5회 빙고팡팡 플레이 제공"}}
              desc3={{title: "저비용 노출비", desc: "비용 대비 효과 높은 광고"}}
              handleSelectMenu={(index) => {
                setBingoCategory(index);
                setBingoSubCategory(0);
              }}
            />

            <BingoCategoryMenu1
              index={1}
              selected={bingoCategory}
              title="쇼츠형 광고"
              desc1={{title: "높은 몰입도", desc: "게임형 광고로 확실한 브랜드 각인"}}
              desc2={{title: "반복 노출 각인", desc: "유저당 최대 25회 빙고팡팡 플레이 제공"}}
              desc3={{title: "구매 고려 증가", desc: "쇼츠 영상 2회 노출로 높은 전환율"}}
              handleSelectMenu={(index) => {
                setBingoCategory(index);
                setBingoSubCategory(APP_MODE === 2 ? 1 : 0); //한줄빙고 출시할때 0으로 고정하기**********************************
              }}
            />

            <label className='font15 color31 wt500 mgbt10 mgtop20'>빙고 유형</label>

            {//아래한줄빙고 출시할때 APP_MODE에 따른 처리 업애기************************************
              bingoCategory === 0 | (bingoCategory === 1 && APP_MODE !== 2) ? (
                <BingoCategoryMenu2
                  index={0}
                  selected={subCategory}
                  title="한줄 빙고"
                  desc1={{title: "계속 노출", desc: "게임 배경으로 광고 이미지 노출"}}
                  desc2={{title: "광고 노출 방식", desc: "1개의 큰 광고 이미지를 유저에게 노출"}}
                  handleSelectMenu={(index) => setBingoSubCategory(index)}
                />
              ) : null
            }

            {
              bingoCategory === 1 ? (
                <BingoCategoryMenu2
                  index={1}
                  selected={subCategory}
                  title="세줄 빙고"
                  desc1={{title: "몰입 노출", desc: "이미지와 키워드를 활용해 제작, 게임 속 심볼을 통한 노출"}}
                  desc2={{title: "광고 노출 방식", desc: "9개의 다양한 심볼을 머니팡 유저에게 노출"}}
                  handleSelectMenu={(index) => setBingoSubCategory(index)}
                />
              ) : null
            }
            
            <div className='rowcc mgbt50 mgtop40'>
              <button className='bingood0btn2 font16 wt500 colorw btncur mgrt10' onClick={props.handleClose}>생성 취소</button>
              <button className='bingood0btn3 font16 wt500 colorw btncur'
                onClick={() => props.handleNextStep(100, {bingoCategory : bingoCategory, bingoSubCategory: subCategory})}
              >다음</button>
            </div>
          </div>

          <div className='coltc' style={{width: props.contWidth - 650, height: "100%"}}>
            {getCurrentDesc(subCategory)}
          </div>
        </div>
        
      </div>
      <FooterBar handleMenu={props.handleMenu}/>
    </div>
  );
};



export default BingoOrder0;