import './Bingo.css';
import '../../App.css';
import { GetImgUrl } from '../../URL/AppUrl';

const BingoDescAllBingo = (props) =>
{
  return (
    <div className='wd100p coltl mgtop10'>
      <label className='font15 wt400 color31 lht23'>올빙고 심볼은 1회 플레이마다 게임 시작 전과 진행 중 반복 노출되어 고객의 기억에 각인되는 심볼이며, 머니팡애즈에서 제작합니다.</label>

      <div className='wd100p rowcc mgtop30'>
        <img src={GetImgUrl("bingo/stp51.png")} alt="설명" className='bgst5img1'/>
      </div>

      <label className='font15 wt400 color31 lht23 mgtop30'>올빙고 심볼은 광고 소재의 핵심 키워드로 구성되어 제작됩니다.<br/>로고,슬로건,상품 특징 등 광고주가 원하는 형태로 제작 가능하며,키워드 구성에는 두가지 방식이 있습니다.<br/>정확한 전달을 위해 한 줄당 최대 4자 입력을 권장드립니다.</label>

      <label className='font15 wt400 color20 lht23 mgtop30'>1. 각각의 심볼에 키워드를 삽입하여 총 3가지 키워드 제작할 수 있습니다.[최대 3줄]</label>

      <label className='font15 wt400 color80 lht23 mgtop20'>[예시 심볼]</label>

      <div className='wd100p rowcc mgtop5'>
        <img src={GetImgUrl("bingo/stp52.png")} alt="설명" className='bgst5img2'/>
      </div>

      <label className='font15 wt400 color20 lht23 mgtop50'>2. 3개의 심볼이 동시에 표시될 때, 키워드가 맞춰지도록 제작할 수 있습니다. [최대 3줄]</label>

      <label className='font15 wt400 color80 lht23 mgtop20'>[예시 심볼]</label>

      <div className='wd100p rowcc mgtop5'>
        <img src={GetImgUrl("bingo/stp53.png")} alt="설명" className='bgst5img3'/>
      </div>
    </div>
  );
}

export default BingoDescAllBingo;