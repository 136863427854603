
import './OrderList.css';
import '../../App.css';
import { GetImgUrl, mobileOn } from '../../URL/AppUrl';
import { useState } from 'react';

const OrderAs = (props) =>
{
  const [msg, setMsg] = useState('');

  if (mobileOn)
  {
    return (
      <div className='odasbgmb flexcoltc'>
        <div className='odasttmb flexrowcc'>
          <div className='odasttmbin flexrowsc'>
            <img src={GetImgUrl("com/mb/toplogo.png")} alt="logo" className='img9421'/>
            <img src={GetImgUrl("com/mb/close.png")} alt="close" className='btncur menubtn img1414' onClick={props.handleClose}/>
          </div>
        </div>

        <div className='odaslinemb'></div>

        <div className='odaspopin_mb flexcoltl'>
          <label className='font18b'>AS문의하기</label>

          <label className='font14b_2 margintop5'>문의 내용을 자세히 입력해 주세요.</label>

          <textarea className='odasinp margintop5 font14b_2' value={msg} onChange={(e) => setMsg(e.target.value)} placeholder='문의 내용을 입력해 주세요.(최대 300자까지 입력 가능합니다.)'/>

          <label className='font1620 margintop10'>고객센터 운영 시간</label>

          <label className='font14b_2 margintop5'>공휴일 제외 평일 09:00~18:00 (점심시간 12:00~13:00)</label>

          <button className='odasbtnmb font14btn4 btncur menubtn' onClick={() => props.handleAs(props.item, msg)}>문의하기</button>
        </div>
      </div>
    );
  }
  else
  {
    return (
      <div className='odasbg flexrowcc'>
        <div className='odaspop flexcoltc'>
          <div className='odaspopin flexcoltl'>
            <div className='fullwidth flexrowsc'>
              <label className='font20b2'>AS문의하기</label>
              <img src={GetImgUrl("com/close.png")} alt="닫기" className='btncur menubtn' onClick={() => props.handleClose()}/>
            </div>
  
            <label className='font16b margintop10'>문의 내용을 자세히 입력해 주세요.</label>
  
            <textarea className='odasinp margintop5 font16b' value={msg} onChange={(e) => setMsg(e.target.value)} placeholder='문의 내용을 입력해 주세요.(최대 300자까지 입력 가능합니다.)'/>
  
            <label className='font1820 margintop10'>고객센터 운영 시간</label>
  
            <label className='font16b margintop5'>공휴일 제외 평일 09:00~18:00 (점심시간 12:00~13:00)</label>
  
            <button className='odasbtn font16btn btncur menubtn' onClick={() => props.handleAs(props.item, msg)}>문의하기</button>
          </div>
        </div>
      </div>
    );
  }
  
}

export default OrderAs;