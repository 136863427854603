import React from 'react';
import {GetApiUrl, GetApiUrlBase, GetSNSApiUrl, LOG_ENABLE, mobileOn} from '../URL/AppUrl';
import axios from 'axios';
import { FromBase64, IsValidS, IsValidV, ToBase64, replaceLine } from '../Util/Util';
import { LoginStoreInstance } from './LoginStore';

//공지관련
class NoticeStore {
  //observable변수

  constructor() {
  }

  clearAll = () =>
  {

  }
  //-------------------------------------------------------------------------------------------------------
  //공지 목록을 조회함
  //noticeType: 공지타입, 전체(-1), 일반(0), 이벤트(1)
  //pos : 읽을 위치
  loadNotice = (noticeType, pos, callback) => {

    const params = new URLSearchParams();

    params.append("nt", noticeType.toString());
    params.append("pos", pos.toString());

    axios({
      method:"POST",
      url: GetApiUrl('notice/list/'),
      headers: {
        //Accept: 'application/json',
        Accept: 'application/text',
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
      },
      responseType: 'json', // 기본 값, [ 'arraybuffer', 'blob', 'document', 'json', 'text', 'stream' ]
      responseEncoding: 'utf8', // 기본 값
      data: params
    }).then((res)=>{
        this.parseNoticeResult(res.data, callback);
    }).catch(error=>{
        console.log(error);
        this.parseNoticeResult(null, callback);
    });
  }

  parseNoticeResult = (result, callback) => 
  {
    if (LOG_ENABLE)
      console.log(`parseNoticeResult ${result ? JSON.stringify(result) : 'unknown'}`);

    if (!IsValidV(result))
    {
      if (LOG_ENABLE)
        console.log("request failed.");

      if (callback)
      {
        callback(-2, null, null, null);
      }

      return;
    }

    if (callback)
    {
      if (IsValidS(result.list))
      {
        result.list.forEach(element => {
          this.decodeNoticeItem(element);
        });
      }

      if (result.ret === 100)
      {
        LoginStoreInstance.sessionError = true;
      }

      callback(result.ret, result.ft, result.tc, result.list);
    }
  }

  decodeNoticeItem = (item) =>
  {
    if (IsValidS(item.tt))
      item.tt = FromBase64(item.tt);

    if (IsValidS(item.dc))
      item.dc = FromBase64(item.dc);
  }
  //-------------------------------------------------------------------------------------------------------
  //홈페이지용 공지를 조회함
  loadNoticeForHome = (callback) => {

    const params = new URLSearchParams();

    params.append("nt", "200");
    params.append("pos", "0");

    axios({
      method:"POST",
      url: GetApiUrl('notice/list/'),
      headers: {
        //Accept: 'application/json',
        Accept: 'application/text',
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
      },
      responseType: 'json', // 기본 값, [ 'arraybuffer', 'blob', 'document', 'json', 'text', 'stream' ]
      responseEncoding: 'utf8', // 기본 값
      data: params
    }).then((res)=>{
        this.parseNoticeForHomeResult(res.data, callback);
    }).catch(error=>{
        console.log(error);
        this.parseNoticeForHomeResult(null, callback);
    });
  }

  parseNoticeForHomeResult = (result, callback) => 
  {
    if (LOG_ENABLE)
      console.log(`parseNoticeForHomeResult ${result ? JSON.stringify(result) : 'unknown'}`);

    if (!IsValidV(result))
    {
      if (LOG_ENABLE)
        console.log("request failed.");

      if (callback)
      {
        callback(-2, null, null, null);
      }

      return;
    }

    if (callback)
    {
      if (IsValidS(result.list))
      {
        result.list.forEach(element => {
          this.decodeNoticeItem(element);
        });
      }

      if (result.ret === 100)
      {
        LoginStoreInstance.sessionError = true;
      }

      callback(result.ret, result.list);
    }
  }
  //-------------------------------------------------------------------------------------------------------
  //공지를 검색함
  //noticeType: 공지타입, 전체(-1), 일반(0), 이벤트(1)
  //sw : 검색어
  searchNotice = (noticeType, sw, callback) => {

    const params = new URLSearchParams();

    params.append("nt", noticeType.toString());
    params.append("sw", ToBase64(sw));

    axios({
      method:"POST",
      url: GetApiUrl('notice/search/'),
      headers: {
        //Accept: 'application/json',
        Accept: 'application/text',
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
      },
      responseType: 'json', // 기본 값, [ 'arraybuffer', 'blob', 'document', 'json', 'text', 'stream' ]
      responseEncoding: 'utf8', // 기본 값
      data: params
    }).then((res)=>{
        this.parseNoticeSearchResult(res.data, callback);
    }).catch(error=>{
        console.log(error);
        this.parseNoticeSearchResult(null, callback);
    });
  }

  parseNoticeSearchResult = (result, callback) => 
  {
    if (LOG_ENABLE)
      console.log(`parseNoticeSearchResult ${result ? JSON.stringify(result) : 'unknown'}`);

    if (!IsValidV(result))
    {
      if (LOG_ENABLE)
        console.log("request failed.");

      if (callback)
      {
        callback(-2, null);
      }

      return;
    }

    if (callback)
    {
      if (IsValidS(result.list))
      {
        result.list.forEach(element => {
          this.decodeNoticeItem(element);
        });
      }

      if (result.ret === 100)
      {
        LoginStoreInstance.sessionError = true;
      }

      callback(result.ret, result.list);
    }
  }
  //-------------------------------------------------------------------------------------------------------
  //마지막으로 수신한 알림 목록 한개를 읽음
  loadLastAlarm = (callback) => {

    const params = new URLSearchParams();

    params.append("rt", "1");
    params.append("pos", "0");

    axios({
      method:"POST",
      url: GetApiUrl('notice/alarm/'),
      headers: {
        //Accept: 'application/json',
        Accept: 'application/text',
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
      },
      responseType: 'json', // 기본 값, [ 'arraybuffer', 'blob', 'document', 'json', 'text', 'stream' ]
      responseEncoding: 'utf8', // 기본 값
      data: params
    }).then((res)=>{
        this.parseLastAlarmResult(res.data, callback);
    }).catch(error=>{
        console.log(error);
        this.parseLastAlarmResult(null, callback);
    });
  }

  parseLastAlarmResult = (result, callback) => 
  {
    if (LOG_ENABLE)
      console.log(`parseLastAlarmResult ${result ? JSON.stringify(result) : 'unknown'}`);

    if (!IsValidV(result))
    {
      if (LOG_ENABLE)
        console.log("request failed.");

      if (callback)
      {
        callback(-2, null);
      }

      return;
    }

    if (callback)
    {
      if (IsValidV(result.info))
      {
        this.decodeAlarmItem(result.info);
      }

      if (result.ret === 100)
      {
        LoginStoreInstance.sessionError = true;
      }

      callback(result.ret, result.info);
    }
  }

  decodeAlarmItem = (item) =>
  {
    if (IsValidS(item.dc))
      item.dc = FromBase64(item.dc);
  }
}

const NoticeStoreInstance = new NoticeStore();
const NoticeStoreContext = React.createContext(NoticeStoreInstance);

const UseNoticeStore = () => React.useContext(NoticeStoreContext);

export {UseNoticeStore, NoticeStoreInstance};
