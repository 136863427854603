import React from 'react';

import './Login.css';
import '../../App.css';
import { APP_MODE, GetImgUrl, mobileOn } from '../../URL/AppUrl';
import Loading from '../Loading/Loading';
import { GetSHA256, IsValidS, replaceLine } from '../../Util/Util';
import { LoginStoreInstance } from '../../Stores/LoginStore';
import TimeUtil from '../../Util/TimeUtil';

class FindPw extends React.Component
{
  state = {
    loading: false,
    authStep: 0, //인증 단계, 번호인증(0), 인증성공(1)
    userId: '', //찾은 ID
    phone: '', //전화번호
    authNum: '', //인증번호

    errorMsg1: '', //오류메시지
    errorMsg2: '', //오류메시지

    smsSent: false, //sms발송되었나?

    newPw1: '', //변경할 비밀번호
    newPw2: '', //변경할 비밀번호
  };

  smsSentCount = 0;
  smsTime = null; //sms발송한 시점의 시간이 기록됨
  authData = null;
  authCode = null;
  authDisabled = false; //당일 인증이 더이상 불가능함

  showLoading = (v) =>
  {
    this.setState((prevState) => ({...prevState, loading: v}));
  }

  setAuthStep = (v) =>
  {
    this.setState((prevState) => ({...prevState, authStep: v}));
  }

  setUserId = (v) =>
  {
    this.setState((prevState) => ({...prevState, userId: v}));
  }

  setPhone = (v) =>
  {
    this.setState((prevState) => ({...prevState, phone: v}));
  }

  setAuthNumber = (v) =>
  {
    this.setState((prevState) => ({...prevState, authNum: v}));
  }

  setErrorMsg1 = (v) =>
  {
    this.setState((prevState) => ({...prevState, errorMsg1: v}));
  }

  setErrorMsg2 = (v) =>
  {
    this.setState((prevState) => ({...prevState, errorMsg2: v}));
  }

  setSmsSent = (v) =>
  {
    this.setState((prevState) => ({...prevState, smsSent: v}));
  }

  setNewPw1 = (v) =>
  {
    this.setState((prevState) => ({...prevState, newPw1: v}));
  }

  setNewPw2 = (v) =>
  {
    this.setState((prevState) => ({...prevState, newPw2: v}));
  }

  componentDidMount() {
  }

  componentWillUnmount() {
  }

  handleSendSms = () =>
  {
    if (this.state.loading)
      return;

    this.setErrorMsg1('');
    this.setErrorMsg2('');

    if (this.state.smsSent)
    {
      this.setErrorMsg2('인증번호가 발송되었습니다.');
      return;
    }

    if (this.authDisabled || this.smsSentCount > 10)
    {
      this.setErrorMsg2('당일 인증 횟수를 초과 했습니다. 내일 이용해 주세요.');
      return;
    }

    if (!IsValidS(this.state.userId))
    {
      this.setErrorMsg1('아이디를 입력해 주세요.');
      return;
    }

    if (!IsValidS(this.state.phone))
    {
      this.setErrorMsg2('휴대폰 번호를 입력해 주세요.');
      return;
    }

    ++this.smsSentCount;
    this.setErrorMsg1('');
    this.setErrorMsg2('');

    const phone = replaceLine(this.state.phone);

    this.showLoading(true);
    LoginStoreInstance.findAccount(1, phone, this.state.userId, this.onFindPwResult1);
  }

  onFindPwResult1 = (resultCode, authData, authCode) =>
  {
    this.showLoading(false);

    switch(resultCode)
    {
      case 0: //정상
        this.authData = authData;
        this.authCode = authCode;

        if (this.smsTime === null)
          this.smsTime = new TimeUtil();

        this.smsTime.catch();
        this.setSmsSent(true);
        this.setErrorMsg1('');
        this.setErrorMsg2('');
        this.setAuthNumber('');
        break;
      case 1: //잘못된 요청
        this.setErrorMsg2('잘못된 요청입니다.');
        return;
      case 2: //전화 또는 이메일 없음
        this.setErrorMsg2('해당 번호로 가입된 계정이 없습니다.');
        return;
      case 5: //당일 인증가능횟수 초과
        this.authDisabled = true;
        this.setErrorMsg2('당일 인증 횟수를 초과 했습니다. 내일 이용해 주세요.');
        return;
      case 6: //전화번호와 계정이 불일치
        this.setErrorMsg2('아이디 또는 전화번호가 올바르지 않습니다.');
        return;
      default:
        this.setErrorMsg2('인증 요청 중 오류가 발생했습니다. 잠시 후 이용해 주세요.');
        return;
    }
  }

  handleFindPw = () =>
  {
    if (this.state.loading)
      return;

    this.setErrorMsg1('');
    this.setErrorMsg2('');

    if (this.authDisabled)
    {
      this.setErrorMsg2('당일 인증 횟수를 초과 했습니다. 내일 이용해 주세요.');
      return;
    }

    //sms발송 후 3분이상 경과한경우
    if (this.smsTime && this.smsTime.getPassedTime2() > (60 * 3))
    {
      this.setErrorMsg2('인증시간이 경과 되었습니다. 인증번호를 재발송해 주세요.');
      this.setSmsSent(false);
      return;
    }

    if (!this.state.smsSent)
    {
      this.setErrorMsg2('인증번호 버튼을 눌러 인증번호를 발송해 주세요.');
      return;
    }

    if (!IsValidS(this.state.authNum))
    {
      this.setErrorMsg2(`인증번호를 입력해 주세요.`);
      return;
    }

    //인증번호가 일치하지 않으면
    if (GetSHA256(this.state.authNum) !== this.authCode)
    {
      if (APP_MODE === 0)
        console.log(`ac : ${this.authCode}, current : ${GetSHA256(this.state.authNum)}`);

      this.setErrorMsg2(`인증번호가 올바르지 않습니다.`);
      return;
    }

    this.showLoading(true);
    LoginStoreInstance.checkFindAccount(1, this.state.phone, this.authData, this.state.authNum, this.onFindPwResult2);
  }

  onFindPwResult2 = (resultCode, rv, authData) =>
  {
    this.showLoading(false);

    switch(resultCode)
    {
      case 0: //정상
        break;
      case 1: //잘못된 요청
        this.setErrorMsg2('잘못된 요청입니다.');
        return;
      case 2: //인증정보 없음
        this.setErrorMsg2('인증 정보가 존재하지 않습니다.');
        return;
      case 3: //인증코드 불일치
        this.setErrorMsg2('인증번호가 올바르지 않습니다.');
        return;
      case 4: //인증시간경과
        this.setErrorMsg2('인증시간이 경과 되었습니다. 인증번호를 재발송해 주세요.');
        this.setSmsSent(false);
        return;
      default:
        this.setErrorMsg2('인증오류가 발생했습니다.\n잠시 후 이용해 주세요.');
        return;
    }

    this.authData = authData;
    this.setAuthStep(1);
  }

  handleChangePw = () =>
  {
    if (this.state.loading)
      return;

    if (!IsValidS(this.state.newPw1))
    {
      this.setErrorMsg1('변경할 비밀번호를 입력해 주세요.')
      return;
    }

    if (!IsValidS(this.state.newPw2))
    {
      this.setErrorMsg2('변경할 비밀번호를 한번더 입력해 주세요.')
      return;
    }

    if (this.state.newPw1 !== this.state.newPw2)
    {
      this.setErrorMsg2('비밀번호가 일치하지 않습니다.')
      return;
    }

    if (this.state.newPw1.length < 8)
    {
      this.setErrorMsg1('비밀번호를 8자 이상 입력해 주세요.')
      return;
    }

    if (this.state.newPw1.length > 20)
    {
      this.setErrorMsg1('비밀번호는 20자를 초과할 수 없습니다.')
      return;
    }

    this.showLoading(true);
    LoginStoreInstance.changePw(0, this.authData, this.state.newPw1, null, this.onChangePwResult);
  }

  onChangePwResult = (resultCode) =>
  {
    this.showLoading(false);

    switch(resultCode)
    {
      case 0:
        alert(`비밀번호가 변경되었습니다.`);
        this.props.handleClose();
        return;
      case 1:
        this.setErrorMsg2('잘못된 요청입니다.');
        return;
      case 2:
        this.setErrorMsg2('인증정보가 올바르지 않습니다.');
        return;
      case 3:
        this.setErrorMsg2('사용할 수 없는 비밀번호 입니다.');
        return;
      case 4:
        this.setErrorMsg2('회원정보가 올바르지 않습니다.');
        return;
      default:
        this.setErrorMsg2('비밀번호변경실패 했습니다.잠시 후 이용해 주세요.');
        return;
    }
  }

  renderAuthStepPc1 = () =>
  {
    return (
      <div className='findidbg flexcolcc'>
        {
          this.state.loading ? (<Loading/>): null
        }
        <div className='findidpop flexcoltc'>
            <div className='findidpop_in flexcoltl'>
              <div className='fullwidth flexrowsc'>
                <label className='font20b2'>비밀번호 찾기</label>
                <img src={GetImgUrl("com/close.png")} alt="닫기" className='btncur menubtn' onClick={this.props.handleClose}/>
              </div>

              <label className='font16b margintop5'>회원가입시 입력했던 휴대폰 번호로 휴대폰 인증을 진행해 주세요.</label>


              <input type="text" value={this.state.userId} onChange={(e) => this.setUserId(e.target.value)}
                className='findidinp2 font14b_2 margintop30'
                placeholder='아이디를 입력해 주세요.'
              />

              {
                IsValidS(this.state.errorMsg1) ? (<label className='font14ff margintop5'>{this.state.errorMsg1}</label>) : null
              }

              <div className='fullwidth flexrowsc margintop20'>
                <input type="number" value={this.state.phone} onChange={(e) => this.setPhone(e.target.value)} className='findidinp1 font14b_2'
                  placeholder='휴대폰 번호를 입력해 주세요.'
                />

                <button className='findidbtn1 font14btn5 btncur menubtn' onClick={this.handleSendSms}>인증번호</button>
              </div>

              {
                this.state.smsSent ? (
                  <input type="number" value={this.state.authNum} onChange={(e) => this.setAuthNumber(e.target.value)}
                    className='findidinp2 font14b_2 margintop30'
                    placeholder='인증번호를 입력해 주세요.'
                  />
                ) : null
              }
              

              {
                IsValidS(this.state.errorMsg2) ? (<label className='font14ff margintop5'>{this.state.errorMsg2}</label>) : null
              }

              <button
                className={this.state.smsSent ? 'findidbtn2 font16btn btncur margintop50 menubtn' : 'findidbtn3 font16btn btncur margintop50'}
                onClick={this.handleFindPw}>확인</button>

              <div className='fullwidth flexrowcc margintop15'>
                <label className='font16 wt400 colorb3 btncur menubtn fontunderline' onClick={this.props.handleRegister}>회원가입</label>
              </div>
            </div>
        </div>
      </div>
    );
  }

  renderAuthStepPc2 = () =>
  {
    return (
      <div className='findidbg flexcolcc'>
        {
          this.state.loading ? (<Loading/>): null
        }
        <div className='findidpop flexcoltc'>
            <div className='findidpop_in flexcoltl'>
              <div className='fullwidth flexrowsc'>
                <label className='font20b2'>비밀번호 변경</label>
                <img src={GetImgUrl("com/close.png")} alt="닫기" className='btncur menubtn' onClick={this.props.handleClose}/>
              </div>


              <input type="password" value={this.state.newPw1} onChange={(e) => this.setNewPw1(e.target.value)}
                className='findidinp2 font14b_2 mgtop100'
                placeholder='신규 비밀 번호를 입력해 주세요.'
              />

              {
                IsValidS(this.state.errorMsg1) ? (<label className='font14ff margintop5'>{this.state.errorMsg1}</label>) : null
              }

              <input type="password" value={this.state.newPw2} onChange={(e) => this.setNewPw2(e.target.value)}
                className='findidinp2 font14b_2 margintop20'
                placeholder='신규 비밀 번호를 한번 더 입력해 주세요.'
              />

              {
                IsValidS(this.state.errorMsg2) ? (<label className='font14ff margintop5'>{this.state.errorMsg2}</label>) : null
              }
              
              <button
                className='findidbtn2 font16btn btncur margintop50 menubtn'
                onClick={this.handleChangePw}>비밀번호 변경하기</button>
            </div>
        </div>
      </div>
    );
  }

  renderAuthStepMobile1 = () =>
  {
    return (
      <div className='findidbg flexcolcc'>
        {
          this.state.loading ? (<Loading/>): null
        }
        <div className='findidpop_mb flexcoltc'>
            <div className='loginconttop_mb flexrowsc'>
              <img src={GetImgUrl("com/mb/toplogo.png")} alt="snsfly" className='img9421' />
              <img src={GetImgUrl("com/mb/close.png")} alt="close" className='btncur menubtn img1414' onClick={this.props.handleClose}/>
            </div>
            <div className='loginline'></div>

            <div className='findidpop_in_mb flexcoltl'>
              <label className='font18b'>비밀번호 찾기</label>

              <label className='font14b_2 margintop5'>회원가입시 입력했던 휴대폰 번호로 휴대폰 인증을 진행해 주세요.</label>

              <input type="text" value={this.state.userId} onChange={(e) => this.setUserId(e.target.value)} className='findidinp2_mb font14b_2 margintop50'
                placeholder='아이디를 입력해 주세요.'
              />

              {
                IsValidS(this.state.errorMsg1) ? (<label className='font14ff margintop5'>{this.state.errorMsg1}</label>) : null
              }

              <div className='fullwidth flexrowsc margintop20'>
                <input type="number" value={this.state.phone} onChange={(e) => this.setPhone(e.target.value)} className='findidinp1_mb font14b_2'
                  placeholder='휴대폰 번호를 입력해 주세요.'
                />

                <button className='findidbtn1_mb font14btn5 btncur menubtn' onClick={this.handleSendSms}>인증번호</button>
              </div>

              {
                this.state.smsSent ? (
                  <input type="number" value={this.state.authNum} onChange={(e) => this.setAuthNumber(e.target.value)}
                    className='findidinp2_mb font14b_2 margintop30'
                    placeholder='인증번호를 입력해 주세요.'
                  />
                ) : null
              }
              

              {
                IsValidS(this.state.errorMsg2) ? (<label className='font14ff margintop5'>{this.state.errorMsg2}</label>) : null
              }

              <button
                className={this.state.smsSent ? 'findidbtn2_mb font14btn4 btncur margintop50 menubtn' : 'findidbtn3_mb font14btn4 btncur margintop50'}
                onClick={this.handleFindPw}>확인</button>

              <div className='fullwidth flexrowcc margintop15'>
                <label className='font14b_2'>SNS플라이 회원이 아니신가요?</label>
                <label className='font1420 btncur menubtn' onClick={this.props.handleRegister}>&nbsp;&nbsp;&nbsp;회원가입</label>
              </div>
            </div>
        </div>
      </div>
    );
  }

  renderAuthStepMobile2 = () =>
  {
    return (
      <div className='findidbg flexcolcc'>
        {
          this.state.loading ? (<Loading/>): null
        }
        <div className='findidpop_mb flexcoltc'>
            <div className='loginconttop_mb flexrowsc'>
              <img src={GetImgUrl("com/mb/toplogo.png")} alt="snsfly" className='img9421' />
              <img src={GetImgUrl("com/mb/close.png")} alt="close" className='btncur menubtn img1414' onClick={this.props.handleClose}/>
            </div>
            <div className='loginline'></div>

            <div className='findidpop_in_mb flexcoltl'>
              <label className='font18b'>비밀번호 변경</label>

              <label className='font14b_2 margintop5'>비밀번호를 변경해 주세요.</label>

              <input type="password" value={this.state.newPw1} onChange={(e) => this.setNewPw1(e.target.value)}
                className='findidinp2_mb font14b_2 margintop30'
                placeholder='신규 비밀 번호를 입력해 주세요.'
              />

              {
                IsValidS(this.state.errorMsg1) ? (<label className='font14ff margintop5'>{this.state.errorMsg1}</label>) : null
              }

              <input type="password" value={this.state.newPw2} onChange={(e) => this.setNewPw2(e.target.value)}
                className='findidinp2_mb font14b_2 margintop20'
                placeholder='신규 비밀 번호를 한번 더 입력해 주세요.'
              />

              {
                IsValidS(this.state.errorMsg2) ? (<label className='font14ff margintop5'>{this.state.errorMsg2}</label>) : null
              }

              <button
                className='findidbtn2_mb font14btn4 btncur margintop15 menubtn'
                onClick={this.handleChangePw}>비밀번호 변경</button>
            </div>
        </div>
      </div>
    );
  }

  render()
  {
    /*if (mobileOn)
    {
      if (this.state.authStep === 0)
        return this.renderAuthStepMobile1();
      else
        return this.renderAuthStepMobile2();
    }
    else*/
    {
      if (this.state.authStep === 0)
        return this.renderAuthStepPc1();
      else
        return this.renderAuthStepPc2();
    }
  }
}

export default FindPw;