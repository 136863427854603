import React from 'react';
import './Purchase.css';
import '../../App.css';
import { APP_MODE, LOG_ENABLE, gotoFirstPage, gotoTalkPage, handleAppNavi, mobileOn, withNavigation } from '../../URL/AppUrl';
import Loading from '../Loading/Loading';
import TopBar from '../Top/TopBar';
import FooterBar from '../Footer/Footer';
import { LoginStoreInstance } from '../../Stores/LoginStore';
import Term from '../Term/Term';
import Privacy from '../Term/Privacy';
import Talk from '../Talk/Talk';
import PurchaseBank from './PurchaseBank';
import PurchaseList from './PurchaseList';
import PurchaseResult from './PurchaseResult';
import PurchaseInfo from './PurchaseInfo';
import MobileMenu from '../Top/MobileMenu';
import { PointOrderInstance } from '../../Stores/PointOrder';
import { AdsStoreInstance } from '../../Stores/AdsStore';
import { resetAllUserData } from '../../Stores/StoreManager';
import { observer } from 'mobx-react';
import Login from '../Login/Login';
import FindId from '../Login/FindId';
import FindPw from '../Login/FindPw';
import NaviBar from '../Navi/Navi';
import PurchaseResult2 from './PurchaseResult2';
import PurchaseResult3 from './PurchaseResult3';
import { IsValidS, SNSPM_TRANS, SNSPM_VBANK, SNSPM_VBANK_PONE } from '../../Util/Util';
import PurchaseBankPg from './PurchaseBankPg';


class Purchase extends React.Component
{
  state = {
    loading: false,
    showTerm: false,
    showMobileMenu: false,
    showPrivacy: false,
    showLogin: false,
    showFindId: false,
    showFindPw: false,
    purchaseStep: 0, //충전금액선택(0), 완료(1)
    showBankInfo: false,

    //사이징처리
    naviWidth: 0,
    contWidth: 0,

    height: 0,

    showResultTemp: false,
  };

  //임시 계좌입금 방식 처리를 위한변수
  bankUserName = null;
  purchasePrice = 0; //충전포인트
  
  orderItem = null;
  intervalId = -1;
  lastOrderIdx = -1; //충전 진행중인 주문의 고유번호
  lastOrderInfo = null;
  popupIntervald = -1;
  currentPopup = null;

  update = () =>
  {
    if (LoginStoreInstance.sessionError && LoginStoreInstance.loggedIn)
    {
      LoginStoreInstance.sessionError = false;
      alert(`인증 세션이 만료되었습니다.`);
      this.processLogOut();
    }
  }

  showMobileMenu = (v) =>
  {
    this.setState((prevState) => ({...prevState, showMobileMenu: v}));
  }

  setPurchaseStep = (v) =>
  {
    this.setState((prevState) => ({...prevState, purchaseStep : v}));
  }

  setShowBankInfo = (v) =>
  {
    this.setState((prevState) => ({...prevState, showBankInfo : v}));
  }

  setLoading = (v) =>
  {
    this.setState((prevState) => ({...prevState, loading : v}));
  }

  setShowTempResult = (v) =>
  {
    this.setState((prevState) => ({...prevState, showResultTemp : v}));
  }

  //사이징----------------------------------------------------------------------------------------------
  setSize = (navi, cont, height) =>
  {
    this.setState((prevState) => ({...prevState, naviWidth : navi, contWidth : cont, height : height}));
  }

  resetContsize = () =>
  {
    //네비게이션 영역은 전체의 16%정도
    let naviWidth = 0.16 * window.innerWidth;

    if (naviWidth < 300) //최소크기300
      naviWidth = 300;

    //콘텐츠영역크기 계산
    let contWidth = window.innerWidth - naviWidth;

    if (contWidth < 1220)
      contWidth = 1220;

    this.setSize(naviWidth, contWidth, window.innerHeight - 70)
  }

  onResize = () => {
    this.resetContsize();
  }
  //사이징----------------------------------------------------------------------------------------------
  
  componentDidMount() {
    this.resetContsize();

    window.addEventListener("resize", this.onResize);

    this.intervalId = setInterval(this.update, 1000);

    //로그인되어 있지 않은경우, 혹시 새로고침했을수도 있다.
    if (!LoginStoreInstance.loggedIn)
    {
      if (LoginStoreInstance.loadToken()) //인증토큰 정보가 존재한다면
      {
        this.setLoading(true);
        LoginStoreInstance.loadProfile(this.onProfileLoadedForRefresh);
      }
    }
    else
    {
      if (!LoginStoreInstance.isProfileReady) //프로필이 준비되지 않았다면
      {
        this.setLoading(true);
        LoginStoreInstance.loadProfile(this.onProfileLoaded);
      }
      else
      {
        //마지막 포인트 조회 시점부터 현재까지 경과 시간이 1분이상 경과한경우
        if (LoginStoreInstance.getPassedPointTime() > 60)
        {
          LoginStoreInstance.catchPointRefreshTime();
          LoginStoreInstance.refreshUserPoint(null);
        }
      }
    }
  }

  componentWillUnmount = () =>
  {
    window.removeEventListener("resize", this.onResize);

    if (this.intervalId !== -1)
    {
      clearInterval(this.intervalId);
      this.intervalId = -1;
    }

    this.currentPopup = null;
    
    if (this.popupIntervald !== -1)
    {
      clearInterval(this.popupIntervald);
      this.popupIntervald = -1;
    }
  }

  clearCurrentOrder = () =>
  {
    this.currentPopup = null;
    this.lastOrderIdx = -1; //충전 진행중인 주문의 고유번호
    this.lastOrderInfo = null;

    if (this.popupIntervald !== -1)
    {
      clearInterval(this.popupIntervald);
      this.popupIntervald = -1;
    }
  }

  onProfileLoadedForRefresh = (resultCode) =>
  {
    this.setLoading(false);

    if (resultCode !== 0)
    {
      if (LOG_ENABLE)
        console.log(`invalid profile`);

      LoginStoreInstance.removeToken();

      return;
    }
  }

  onProfileLoaded = (resultCode) =>
  {
    this.setLoading(false);

    if (resultCode !== 0)
    {
      alert(`프로필 읽기 중 오류가 발생했습니다.\n다시 로그인해 주세요.`);
      this.processLogOut();
      return;
    }
  }
  
  //수동계좌이체 주문 처리
  handlePurchase = (payMode, price, bankUserName, taxMode, contact) =>
  {
    if (this.state.loading)
      return;

    if (this.currentPopup && !this.currentPopup.closed)
    {
      alert(`먼저 진행중인 주문을 완료해 주세요.`);
      return;
    }

    if (!IsValidS(bankUserName))
    {
      alert(`입금자명을 입력해 주세요.`);
      return;
    }

    this.bankUserName = bankUserName;
    this.purchasePrice = price;

    this.setLoading(true);
    PointOrderInstance.pointTempOrder(bankUserName, price, price + Math.floor(price * 0.1), taxMode, contact, (resultCode) => {

      this.setLoading(false);

      switch(resultCode)
      {
        case 0:
          this.setShowTempResult(true);
          break;
        case 1:
          alert(`잘못된 요청입니다.`);
          return;
        default:
          alert(`포인트 충전 신청이 실패 했습니다.\n잠시 후 이용해 주세요.\n오류코드 ${resultCode}`);
          return;
      }
    });
  }

  handlePurchasePg = (payMode, price, bankUserName, taxMode, contact) =>
  {
    if (this.state.loading)
      return;

    if (this.currentPopup && !this.currentPopup.closed)
    {
      alert(`먼저 진행중인 주문을 완료해 주세요.`);
      return;
    }

    this.bankUserName = "n";
    this.purchasePrice = price;

    let currentPayMode = -1;

    switch(payMode)
    {
      case 0: //가상계좌
        currentPayMode = 2;
        this.bankUserName = "n";
        break;
      case 1: //카드
        currentPayMode = 1;
        this.bankUserName = "n";
        break;
      case 2: //계좌이체
        currentPayMode = 0;
        this.bankUserName = bankUserName;
        break;
      default:
        alert(`결제 수단을 올바르게 선택해 주세요.`);
        return;
    }
    this.clearCurrentOrder();
    this.setLoading(true);
    PointOrderInstance.pointOrder(!mobileOn, currentPayMode, price, this.bankUserName, taxMode, contact, this.onPurchaseResult);
  }

  onPurchaseResult = (resultCode, payMode, orderIdx, authUrl) =>
  {
    switch(resultCode)
    {
      case 0: //정상
        break;
      case 1:
        this.setLoading(false);
        alert(`잘못된 요청입니다.`);
        return;
      case 2:
        this.setLoading(false);
        alert(`지원되지 않는 결제 수단입니다.`);
        return;
      case 5:
        this.setLoading(false);
        alert(`프로필 정보 읽기 중 오류가 발생했습니다.\n잠시 후 이용해 주세요.`);
        return;
      case 6:
        this.setLoading(false);
        alert(`이계정은 현재 충전 주문이 불가능한 상태입니다.`);
        return;
      default:
        this.setLoading(false);
        alert(`충전 주문 등록 중 오류가 발생했습니다.\n잠시 후 이용해 주세요.\n오류코드 ${resultCode}`);
        return;
    }

    this.lastOrderIdx = orderIdx;

    if (LOG_ENABLE)
      console.log(`last order info : ${this.lastOrderIdx}`);

    if (payMode === 0) //계좌이체 주문인경우
    {
      this.handleAuthPopupClose();
    }
    else //pg결제인경우
    {
      //팝업띄우기
      if (mobileOn)
        this.currentPopup = window.open(authUrl, "SNSFly 충전주문", "width = 100%, height = 100%, top = 0, left = 0, location = no");
      else
        this.currentPopup = window.open(authUrl, "SNSFly 충전주문", "width = 500, height = 700, top = 50, left = 50, location = no");

      if (this.popupIntervald === -1)
      {
        this.popupIntervald = setInterval(this.checkPopupClosed, 300);
      }
    }
  }

  checkPopupClosed = () =>
  {
    if (this.currentPopup && this.currentPopup.closed)
    {
      this.setLoading(false);
      this.currentPopup = null;
      clearInterval(this.popupIntervald);
      this.popupIntervald = -1;

      this.handleAuthPopupClose();
    }
  }

  handleAuthPopupClose = () =>
  {
    if (LOG_ENABLE)
      console.log('팝업창 닫힘');

    if (this.lastOrderIdx > 0)
    {
      this.setLoading(true);
      PointOrderInstance.getOrder(this.lastOrderIdx, this.onOrderCheckResult);
    }
  }

  //주문한개의 상태 조회 결과를 수신함
  onOrderCheckResult = (resultCode, orderInfo) =>
  {
    this.setLoading(false);

    if (resultCode !== 0 || !orderInfo)
    {
      alert(`주문정보 조회 중 오류가 발생했습니다.\n인터넷 연결 상태를 확인해 주세요.\n오류코드 ${resultCode}`);
      return;
    }

    if (orderInfo.st > 0)
    {
      this.lastOrderInfo = orderInfo;

      this.setPurchaseStep(1);
    }
  }

  handleCloseResult = () =>
  {
    this.setPurchaseStep(0);

    LoginStoreInstance.refreshUserPoint(null);
  }

  handleOrder = (item) =>
  {
    if (LOG_ENABLE)
      console.log(`handleOrder`);

    if (!item || (item.pmd !== SNSPM_TRANS && item.pmd !== SNSPM_VBANK && item.pmd !== SNSPM_VBANK_PONE))
      return;

    this.orderItem = item;

    this.setShowBankInfo(true);
  }

  showTerm = (v) =>
  {
    this.setState((prevState) => ({...prevState, showTerm: v}));
  }

  showPrivacy = (v) =>
  {
    this.setState((prevState) => ({...prevState, showPrivacy: v}));
  }

  //-----------------------------------------------------------------------------------------------------------------------------
  showLogin = (v) =>
  {
    this.setState((prevState) => ({...prevState, showLogin: v}));
  }

  showFindId = (v) =>
  {
    this.setState((prevState) => ({...prevState, showFindId: v}));
  }

  showFindPw = (v) =>
  {
    this.setState((prevState) => ({...prevState, showFindPw: v}));
  }

  handleLoginEvent = (eventCode) =>
  {
    if (this.state.loading)
      return;

    switch(eventCode)
    {
      case 0: //닫기
        this.showLogin(false);
        return;
      case 1: //회원가입
        this.handleRegister();
        return;
      case 2: //id찾기
        this.showLogin(false);
        this.showFindId(true);
        return;
      case 3: //비번찾기
        this.showLogin(false);
        this.showFindPw(true);
        return;
      case 100: //로그인 성공
        this.showLogin(false);
        return;
    }
  }

  handleRegister = () =>
  {
    if (this.state.loading)
      return;

    this.showLogin(false);
    this.showFindId(false);
    this.showFindPw(false);
    this.handleNavi(5);
  }

  handleFindPw = () =>
  {
    if (this.state.loading)
      return;

    this.showLogin(false);
    this.showFindId(false);
    this.showFindPw(true);
  }

  handleFindIdLogin = () =>
  {
    if (this.state.loading)
      return;

    this.showLogin(true);
    this.showFindId(false);
    this.showFindPw(false);
  }
  //-----------------------------------------------------------------------------------------------------------------------------

  handleNavi = (naviIndex) =>
  {
    if (this.state.loading)
      return;

    if (LOG_ENABLE)
      console.log(`handleNavi : ${naviIndex}`);

    //console.log(`${this.props.navigate}`);
    this.showMobileMenu(false);
    handleAppNavi(this.props.navigate, naviIndex);
  }

  handleSnsOrder = (orderType) =>
  {
    if (this.state.loading)
      return;

    if (LOG_ENABLE)
      console.log(`handleSnsOrder : ${orderType}`);

    AdsStoreInstance.setOrderType(orderType);

    handleAppNavi(this.props.navigate, 0);
  }

  handleMenu = (menuIndex) =>
  {
    if (this.state.loading)
      return;

    switch(menuIndex)
    {
      case 0: //로그인
        this.showLogin(true);
        return;
      case 6: //이용약관
        this.showTerm(true);
        return;
      case 7: //개인정보 처리방침
        this.showPrivacy(true);
        return;
      case 100: //로그아웃
        if (!window.confirm('로그아웃 할까요?'))
          return;

        this.processLogOut();
        return;
    }
  }

  processLogOut = () =>
  {
    resetAllUserData();
    gotoFirstPage();
  }

  handleTalk = () =>
  {
    gotoTalkPage();
  }
  
  renderPc = () =>
  {
    return (
      <div className='pgcont coltc'>
        {
          this.state.showLogin ? (<Login handleLoginEvent={this.handleLoginEvent}/>) : null
        }
        {
          this.state.showFindId ? (<FindId handleRegister={this.handleRegister} handleClose={() => this.showFindId(false)} handleFindPw={this.handleFindPw} handleLogin={this.handleFindIdLogin}/>) : null
        }
        {
          this.state.showFindPw ? (<FindPw handleClose={() => this.showFindPw(false)} handleRegister={this.handleRegister} />) : null
        }
        {
          this.state.showMobileMenu ? (<MobileMenu handleNavi={this.handleNavi} handleMenu={this.handleMenu} />) : null
        }
        {
          this.state.showBankInfo ? (
            <PurchaseInfo item={this.orderItem} handleClose={() => this.setShowBankInfo(false)}/>
          ) : null
        }
        {
          this.state.showResultTemp ? (<PurchaseResult3 handleClose={() => this.setShowTempResult(false)} uname={this.bankUserName} totalCost={this.purchasePrice}/>) : null
        }
        {
          this.state.showTerm ? (<Term handleClose={() => this.showTerm(false)}/>) : null
        }
        
        {
          this.state.showPrivacy ? (<Privacy handleClose={() => this.showPrivacy(false)}/>) : null
        }
        {
          this.state.loading ? (<Loading withbg={true}/>) : null
        }
        <Talk handleTalk={this.handleTalk}/>
        <TopBar handleNavi={this.handleNavi} handleMenu={this.handleMenu} selected={4} withLine={true}/>

        <div className='pgcont2 rowlt'>
          <NaviBar width={this.state.naviWidth} height={this.state.height} handleSnsOrder={this.handleSnsOrder} handleNavi={this.handleNavi} handleMenu={this.handleMenu}/>

          <div className='homecont colel' style={{width: this.state.contWidth, height: this.state.height}}>
            <div className='homecont2 coltl' style={{width: this.state.contWidth - 40 - 50, height: this.state.height - 100}}>
              {
                this.state.purchaseStep === 0 ? (
                  <div className='purcont2 rowlt margintop20'>
                    <PurchaseBankPg handlePurchase={this.handlePurchasePg}/>
                    <PurchaseList handleOrder={this.handleOrder} loggedIn={LoginStoreInstance.loggedIn}/>
                  </div>
                ) : (
                  <div className='purcont2 flexrowcc margintop50'>
                    <PurchaseResult handleCloseResult = {this.handleCloseResult} orderInfo={this.lastOrderInfo}/>
                  </div>
                )
              }
            </div>

            <FooterBar handleMenu={this.handleMenu} />
          </div>
        </div>
      </div>
    );
  }

  renderMobile = () =>
  {
    return (
      <div className='purbg flexcoltc'>
        {
          this.state.showMobileMenu ? (<MobileMenu handleNavi={this.handleNavi} handleMenu={this.handleMenu} userPoint={LoginStoreInstance.getUserPoint} />) : null
        }
        {
          this.state.showBankInfo ? (
            <PurchaseInfo item={this.orderItem} handleClose={() => this.setShowBankInfo(false)}/>
          ) : null
        }
        {
          this.state.showTerm ? (<Term handleClose={() => this.showTerm(false)}/>) : null
        }
        
        {
          this.state.showPrivacy ? (<Privacy handleClose={() => this.showPrivacy(false)}/>) : null
        }
        {
          this.state.loading ? (<Loading withbg={true}/>) : null
        }
        <Talk handleTalk={this.handleTalk}/>
        <div className='purcont_mb flexcoltc'>
          <TopBar handleNavi={this.handleNavi} handleMenu={this.handleMenu} selected={4} withLine={true}/>

          {
            this.state.purchaseStep === 0 ? (
              <div className='purcont2 flexcoltl margintop50'>
                <PurchaseBankPg handlePurchase={this.handlePurchasePg}/>
                <PurchaseList handleOrder={this.handleOrder} loggedIn={LoginStoreInstance.loggedIn}/>
              </div>
            ) : (
              <div className='purcont2 flexrowcc margintop50'>
                <PurchaseResult handleCloseResult = {this.handleCloseResult} orderInfo={this.lastOrderInfo}/>
              </div>
            )
          }
        </div>

        <FooterBar handleMenu={this.handleMenu} />
      </div>
    );
  }

  render()
  {
    /*if (mobileOn)
    {
      return this.renderMobile();
    }
    else*/
    {
      return this.renderPc();
    }
  }
}

export default withNavigation(observer(Purchase));