import './Bingo.css';
import '../../App.css';
import { BPM_TRANS, BPM_VBANK, BPM_VBANK_PONE, ConvertDateInfo, getBingoGameTypeName, getBingoOrderStatus, getBingoPurchaseMode, GetFormattedNum } from '../../Util/Util';

const getAdsStatusBtn = (props) =>
{
  if (props.item.st === 4) //승인대기상태일경우
  {
    return <button className='bingobtn14 font15 wt600 colorw btncur mgtop5' onClick={() => props.handleOrder(1, props.item)}>미리보기</button>
  }
  else
  {
    switch(props.item.st)
    {
      case 1: //등록대기
        return <button className='bingobtn12 font15 wt600 colorw btncur mgtop5' onClick={() => props.handleOrder(0, props.item)}>상세등록</button>
      case 2: //검수대기
      case 3: //검수중
        if ((props.item.sst & 0x2) !== 0)
          return <button className='bingobtn12 font15 wt600 colorw btncur mgtop5' onClick={() => props.handleOrder(100, props.item)}>주문수정</button>
        return null;
      default:
        return null;
    }
  }
}

const getAdsStartInfo = (props) =>
{
  if (props.item.st === 4) //승인대기
  {
    const temp = [];

    temp.push(<button key={1} className='bingobtn12 font15 wt600 colorw btncur' onClick={() => props.handleOrder(2, props.item)}>승인</button>);
    temp.push(<button key={2} className='bingobtn13 font15 wt600 colorw btncur mgtop5' onClick={() => props.handleOrder(3, props.item)}>재요청</button>);

    return temp;
  }
  else
  {
    switch(props.item.st)
    {
      case 5:
      case 6:
      case 7:
        return <label className="font16 wt400 color31 btncur">{ConvertDateInfo(props.item.sdt)}</label>
  
      default:
        return null;
    }
  }
}

const getPurchaseErrorMsg = (item) =>
{
  if (item.st === 1 && ((item.sst & 0x1) != 0)) //결제완료한 등록대기 주문
    return "주문이 접수되지 않았습니다. 주문서 작성을 완료해 주세요.";
  else if (item.st === 5) //게시대기중
    return "주문이 게시대기 중 이지만, 기간 내 입금하지 않으면 자동 취소됩니다.";
  else if (item.st === 8) //취소
    return "미결제로 취소된 주문입니다.";
  return "주문이 등록되었지만 결제가 완료되지 않았습니다. 기간 내 입금하지 않으면 자동 취소됩니다.";
}

const BingoItem = (props) =>
{
  const notCharged = (props.item.sst & 0x1) === 0 || (props.item.st === 1 && ((props.item.sst & 0x1) != 0));
  const divStyle = notCharged ? "bingorowdiv coltc posrel"  : "bingorowdiv colcc";
  const divStyle2 = notCharged ? "bingorowdiv coltl posrel"  : "bingorowdiv colcl";

  return (
    <tr className="ntctbrow">

      {/*주문번호*/}
      <td className="odltbrowitem">
        <div className={divStyle} style={{paddingBottom: notCharged ? 60 : 10}}>
          <label className="font16 wt400 color31">{props.item.idx}</label>
          {
            notCharged ? (
              <div className='bgpurchasedesc rowcc' style={{width: props.width - 20}}>
                <label className='font15 wt500 colorff36'>{getPurchaseErrorMsg(props.item)}</label>
              </div>
            ) : null
          }
        </div>
      </td>

      {/*주문상태*/}
      <td className="odltbrowitem2">
        <div className={divStyle} style={{paddingBottom: notCharged ? 60 : 10}}>
          <label className="font16 wt400 color31">{getBingoOrderStatus(props.item.st, props.item.sst)}</label>
          {getAdsStatusBtn(props)}
        </div>
      </td>

      {/*광고시작일시*/}
      <td className="odltbrowitem2">
        <div className={divStyle} style={{paddingBottom: notCharged ? 60 : 10}}>
          {getAdsStartInfo(props)}
        </div>
      </td>

      {/*광고명*/}
      <td className="odltbrowitem2">
        <div className={divStyle2} style={{paddingBottom: notCharged ? 60 : 10}}>
          <div className='bingonamebox mglt10'>
            <label className="font15 wt400 color20">{getBingoGameTypeName(props.item.gt)}</label>
          </div>
          
          <label className="font16 wt400 color31 mglt10 mgtop5">{props.item.tt}</label>
        </div>
      </td>

      {/*경품당첨수*/}
      <td className="odltbrowitem2">
        <div className={divStyle} style={{paddingBottom: notCharged ? 60 : 10}}>
          <label className="font16 wt400 color31">{props.item.st1 > 0 ? GetFormattedNum(props.item.st1) : "-"}</label>
        </div>
      </td>

      {/*빙고플레이수*/}
      <td className="odltbrowitem2">
        <div className={divStyle} style={{paddingBottom: notCharged ? 60 : 10}}>
          <label className="font16 wt400 color31">{props.item.st2 > 0 ? GetFormattedNum(props.item.st2) : "-"}</label>
        </div>
      </td>

      {/*동영상시청수*/}
      <td className="odltbrowitem2">
        <div className={divStyle} style={{paddingBottom: notCharged ? 60 : 10}}>
          <label className="font16 wt400 color31">{props.item.st3 > 0 ? GetFormattedNum(props.item.st3) : "-"}</label>
        </div>
      </td>

      {/*광고성과*/}
      <td className="odltbrowitem2">
        <div className={divStyle} style={{paddingBottom: notCharged ? 60 : 10}}>
          {
            props.item.st === 6 || props.item.st === 7 ? (
              <button className='bingobtn14 font15 wt600 colorw btncur mgtop5' onClick={() => props.handleOrder(5, props.item)}>다운로드</button>
            ) : null
          }
        </div>
      </td>

      {/*주문서*/}
      <td className="odltbrowitem2">
        <div className={divStyle} style={{paddingBottom: notCharged ? 60 : 10}}>
          {
            props.item.st === 5 || props.item.st === 6 || props.item.st === 7 ? (
              <button className='bingobtn14 font15 wt600 colorw btncur mgtop5' onClick={() => props.handleOrder(6, props.item)}>다운로드</button>
            ) : null
          }
        </div>
      </td>

      {/*결제수단*/}
      <td className="odltbrowitem2">
        <div className={divStyle} style={{paddingBottom: notCharged ? 60 : 10}}>
          <label className="font16 wt400 color31">{getBingoPurchaseMode(props.item.pm)}</label>
          {
            (props.item.pm === BPM_VBANK || props.item.pm === BPM_TRANS || props.item.pm === BPM_VBANK_PONE) && (props.item.sst & 0x1) === 0 && props.item.st !== 8 ? (
              <button className='bingobtn15 font15 wt600 colorw btncur mgtop5' onClick={() => props.handleOrder(4, props.item)}>결제대기</button>
            ) : null
          }
        </div>
      </td>

      {/*결제금액*/}
      <td className="odltbrowitem2">
        <div className={divStyle} style={{paddingBottom: notCharged ? 60 : 10}}>
          <label className="font16 wt400 color31">{GetFormattedNum(props.item.pamt)}</label>
        </div>
      </td>

      {/* */}
      <td className="odltbrowitem3">
        <div className={divStyle} style={{paddingBottom: notCharged ? 60 : 10}}>
          {
            props.item.st === 7 && props.item.gt !== 5 ? (
              <button className='bingobtn15 font15 wt600 colorw btncur mgtop5' onClick={() => props.handleOrder(7, props.item)}>재집행</button>
            ) : null
          }
        </div>
      </td>
    </tr>
  );
}

export default BingoItem;