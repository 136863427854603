
import React from 'react';
import {GetApiUrl, GetApiUrlBase, GetSNSApiUrl, LOG_ENABLE, mobileOn} from '../URL/AppUrl';
import axios from 'axios';
import { FromBase64, IsValidS, IsValidV, ToBase64, replaceLine } from '../Util/Util';
import { LoginStoreInstance } from './LoginStore';
import { makeObservable, action, observable } from 'mobx';

//광고 주문관련
class AdsStore {
  //observable변수
  orderType = 0; //인스타(0), 페북(1), 유튜브(2), 틱톡(3)

  //비 옵저버 변수
  priceList = null;

  constructor() {
    makeObservable(this, {
      orderType : observable,

      clearAll: action,
      setOrderType: action,
    });
  }

  clearAll = () =>
  {
    this.priceList = null;
  }

  setOrderType = (orderType) =>
  {
    this.orderType = orderType;
  }
  //-------------------------------------------------------------------------------------------------------
  isPriceValid = () =>
  {
    return IsValidS(this.priceList);
  }

  findPriceInfo = (adsType) =>
  {
    if (!IsValidS(this.priceList))
      return null;

    for (let i = 0;i < this.priceList.length; ++i)
    {
      if (this.priceList[i].adt === adsType)
        return this.priceList[i];
    }

    return null;
  }
  //-------------------------------------------------------------------------------------------------------
  //나의 단가 정보를 조회함
  loadPrice = (callback) => {
    this.priceList = null;
    const params = new URLSearchParams();

    params.append("rt", "3");

    axios({
      method:"POST",
      url: GetApiUrl('ssad/price/'),
      headers: {
        //Accept: 'application/json',
        Accept: 'application/text',
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
      },
      responseType: 'json', // 기본 값, [ 'arraybuffer', 'blob', 'document', 'json', 'text', 'stream' ]
      responseEncoding: 'utf8', // 기본 값
      data: params
    }).then((res)=>{
        this.parsePriceResult(res.data, callback);
    }).catch(error=>{
        console.log(error);
        this.parsePriceResult(null, callback);
    });
  }

  parsePriceResult = (result, callback) => 
  {
    if (LOG_ENABLE)
      console.log(`parsePriceResult ${result ? JSON.stringify(result) : 'unknown'}`);

    if (!IsValidV(result))
    {
      if (LOG_ENABLE)
        console.log("request failed.");

      if (callback)
      {
        callback(-2);
      }

      return;
    }
    
    if (result.ret === 0)
    {
      if (IsValidS(result.list))
      {
        this.priceList = result.list;
      }
    }
    else if (result.ret === 100)
    {
      LoginStoreInstance.sessionError = true;
    }

    if (callback)
    {
      callback(result.ret);
    }
  }

  //-------------------------------------------------------------------------------------------------------
  //조건없이 나의 광고 목록을 조회함
  loadAds = (pos, callback) => {

    const params = new URLSearchParams();

    params.append("rt", "0");
    params.append("pos", pos.toString());

    if (LOG_ENABLE)
      console.log(`loadAds : ${params.toString()}`);

    axios({
      method:"POST",
      url: GetApiUrl('ssad/list2/'),
      headers: {
        //Accept: 'application/json',
        Accept: 'application/text',
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
      },
      responseType: 'json', // 기본 값, [ 'arraybuffer', 'blob', 'document', 'json', 'text', 'stream' ]
      responseEncoding: 'utf8', // 기본 값
      data: params
    }).then((res)=>{
        this.parseAdsListResult(res.data, callback);
    }).catch(error=>{
        console.log(error);
        this.parseAdsListResult(null, callback);
    });
  }

  //특정 상품타입의 광고 목록을 조회함
  loadAdsCond1 = (adt, pos, callback) => {

    const params = new URLSearchParams();

    params.append("rt", "1");
    params.append("adt", adt.toString());
    params.append("pos", pos.toString());

    if (LOG_ENABLE)
      console.log(`loadAdsCond1 : ${params.toString()}`);

    axios({
      method:"POST",
      url: GetApiUrl('ssad/list2/'),
      headers: {
        //Accept: 'application/json',
        Accept: 'application/text',
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
      },
      responseType: 'json', // 기본 값, [ 'arraybuffer', 'blob', 'document', 'json', 'text', 'stream' ]
      responseEncoding: 'utf8', // 기본 값
      data: params
    }).then((res)=>{
        this.parseAdsListResult(res.data, callback);
    }).catch(error=>{
        console.log(error);
        this.parseAdsListResult(null, callback);
    });
  }

  //이용자의 특정 조건 타입조회(주문상태, 성별, 연령)
  loadAdsCond2 = (ost, gt, age, pos, callback) => {

    const params = new URLSearchParams();

    params.append("rt", "2");
    params.append("ost", ost.toString());
    params.append("gt", gt.toString());
    params.append("age", age.toString());
    params.append("pos", pos.toString());

    if (LOG_ENABLE)
      console.log(`loadAdsCond2 : ${params.toString()}`);

    axios({
      method:"POST",
      url: GetApiUrl('ssad/list2/'),
      headers: {
        //Accept: 'application/json',
        Accept: 'application/text',
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
      },
      responseType: 'json', // 기본 값, [ 'arraybuffer', 'blob', 'document', 'json', 'text', 'stream' ]
      responseEncoding: 'utf8', // 기본 값
      data: params
    }).then((res)=>{
        this.parseAdsListResult(res.data, callback);
    }).catch(error=>{
        console.log(error);
        this.parseAdsListResult(null, callback);
    });
  }

  //이용자의 특정 조건 타입조회(상품종류, 주문상태, 성별, 연령)
  loadAdsCond3 = (adt, ost, gt, age, pos, callback) => {

    const params = new URLSearchParams();

    params.append("rt", "3");
    params.append("adt", adt.toString());
    params.append("ost", ost.toString());
    params.append("gt", gt.toString());
    params.append("age", age.toString());
    params.append("pos", pos.toString());

    if (LOG_ENABLE)
      console.log(`loadAdsCond3 : ${params.toString()}`);

    axios({
      method:"POST",
      url: GetApiUrl('ssad/list2/'),
      headers: {
        //Accept: 'application/json',
        Accept: 'application/text',
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
      },
      responseType: 'json', // 기본 값, [ 'arraybuffer', 'blob', 'document', 'json', 'text', 'stream' ]
      responseEncoding: 'utf8', // 기본 값
      data: params
    }).then((res)=>{
        this.parseAdsListResult(res.data, callback);
    }).catch(error=>{
        console.log(error);
        this.parseAdsListResult(null, callback);
    });
  }

  //이용자의 특정 주소에 해당하는 조회
  loadAdsCond4 = (hidx, snsLink, pos, callback) => {

    const params = new URLSearchParams();

    params.append("rt", "4");
    params.append("lk", ToBase64(snsLink));
    params.append("pos", pos.toString());
    params.append("hidx", hidx.toString());

    if (LOG_ENABLE)
      console.log(`loadAdsCond4 : ${params.toString()}`);

    axios({
      method:"POST",
      url: GetApiUrl('ssad/list2/'),
      headers: {
        //Accept: 'application/json',
        Accept: 'application/text',
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
      },
      responseType: 'json', // 기본 값, [ 'arraybuffer', 'blob', 'document', 'json', 'text', 'stream' ]
      responseEncoding: 'utf8', // 기본 값
      data: params
    }).then((res)=>{
        this.parseAdsListResult(res.data, callback);
    }).catch(error=>{
        console.log(error);
        this.parseAdsListResult(null, callback);
    });
  }

  //이용자의 특정 주소와 조건에 해당하는 조회(주문상태, 성별, 연령)
  loadAdsCond5 = (hidx, snsLink, ost, gt, age, pos, callback) => {

    const params = new URLSearchParams();

    params.append("rt", "5");
    params.append("lk", ToBase64(snsLink));
    params.append("ost", ost.toString());
    params.append("gt", gt.toString());
    params.append("age", age.toString());
    params.append("pos", pos.toString());
    params.append("hidx", hidx.toString());

    if (LOG_ENABLE)
      console.log(`loadAdsCond5 : ${params.toString()}`);

    axios({
      method:"POST",
      url: GetApiUrl('ssad/list2/'),
      headers: {
        //Accept: 'application/json',
        Accept: 'application/text',
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
      },
      responseType: 'json', // 기본 값, [ 'arraybuffer', 'blob', 'document', 'json', 'text', 'stream' ]
      responseEncoding: 'utf8', // 기본 값
      data: params
    }).then((res)=>{
        this.parseAdsListResult(res.data, callback);
    }).catch(error=>{
        console.log(error);
        this.parseAdsListResult(null, callback);
    });
  }

  //이용자의 특정 주소와 조건에 해당하는 조회(상품종류, 주문상태, 성별, 연령)
  loadAdsCond6 = (hidx, snsLink, adt, ost, gt, age, pos, callback) => {

    const params = new URLSearchParams();

    params.append("rt", "6");
    params.append("lk", ToBase64(snsLink));
    params.append("adt", adt.toString());
    params.append("ost", ost.toString());
    params.append("gt", gt.toString());
    params.append("age", age.toString());
    params.append("pos", pos.toString());
    params.append("hidx", hidx.toString());

    if (LOG_ENABLE)
      console.log(`loadAdsCond6 : ${params.toString()}`);

    axios({
      method:"POST",
      url: GetApiUrl('ssad/list2/'),
      headers: {
        //Accept: 'application/json',
        Accept: 'application/text',
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
      },
      responseType: 'json', // 기본 값, [ 'arraybuffer', 'blob', 'document', 'json', 'text', 'stream' ]
      responseEncoding: 'utf8', // 기본 값
      data: params
    }).then((res)=>{
        this.parseAdsListResult(res.data, callback);
    }).catch(error=>{
        console.log(error);
        this.parseAdsListResult(null, callback);
    });
  }

  //이용자의 특정 주소와 상품종류가 일치하는 광고
  loadAdsCond7 = (hidx, snsLink, adt, pos, callback) => {

    const params = new URLSearchParams();

    params.append("rt", "7");
    params.append("lk", ToBase64(snsLink));
    params.append("adt", adt.toString());
    params.append("pos", pos.toString());
    params.append("hidx", hidx.toString());

    if (LOG_ENABLE)
      console.log(`loadAdsCond7 : ${params.toString()}`);

    axios({
      method:"POST",
      url: GetApiUrl('ssad/list2/'),
      headers: {
        //Accept: 'application/json',
        Accept: 'application/text',
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
      },
      responseType: 'json', // 기본 값, [ 'arraybuffer', 'blob', 'document', 'json', 'text', 'stream' ]
      responseEncoding: 'utf8', // 기본 값
      data: params
    }).then((res)=>{
        this.parseAdsListResult(res.data, callback);
    }).catch(error=>{
        console.log(error);
        this.parseAdsListResult(null, callback);
    });
  }

  parseAdsListResult = (result, callback) => 
  {
    if (LOG_ENABLE)
      console.log(`parseAdsListResult ${result ? JSON.stringify(result) : 'unknown'}`);

    if (!IsValidV(result))
    {
      if (LOG_ENABLE)
        console.log("request failed.");

      if (callback)
      {
        callback(-2, 0, null, false, 0, 0);
      }

      return;
    }
    
    if (result.ret === 100)
    {
      LoginStoreInstance.sessionError = true;
    }

    if (callback)
    {
      callback(result.ret, result.rt, result.list, result.ft === 1, result.tc, result.hidx);
    }
  }
  //-------------------------------------------------------------------------------------------------------
  /*
  광고 주문을 발주한다.
  platform : 인스타(0), 페이스북(1), 유튜브(2), 틱톡(3)
  adsType : 광고 타입코드
  genderType : 전체(0), 남성(1), 여성(2)
  ageType : 전체(0), 20대(1), 30대(2)
  lk : 링크 주소
  count : 구매수량
  delay : 반복 지연시간
  repeatCount : 반복횟수
  price : 단가
  userPoint : 유저참여보상
  partnerPoint : 파트너 보상
  img : 썸네일 이미지
  */
  requestAds = (platform, adsType, genderType, ageType, lk, count, delay, repeatCount,
    price, userPoint, partnerPoint, img, callback) => {

    const params = new URLSearchParams();

    params.append("pf", platform.toString());
    params.append("adt", adsType.toString());
    params.append("gt", genderType.toString());
    params.append("agt", ageType.toString());
    params.append("lk", ToBase64(lk));
    params.append("cnt", count.toString());
    params.append("tm", delay.toString());
    params.append("rpc", repeatCount.toString());
    params.append("pnt", price.toString());
    params.append("upt", userPoint.toString());
    params.append("pp", partnerPoint.toString());
    params.append("img", img);

    if (LOG_ENABLE)
      console.log(`requestAds : ${params.toString()}`);

    axios({
      method:"POST",
      url: GetApiUrl('ssad/add/'),
      headers: {
        //Accept: 'application/json',
        Accept: 'application/text',
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
      },
      responseType: 'json', // 기본 값, [ 'arraybuffer', 'blob', 'document', 'json', 'text', 'stream' ]
      responseEncoding: 'utf8', // 기본 값
      data: params
    }).then((res)=>{
        this.parseRequestAdsResult(res.data, callback);
    }).catch(error=>{
        console.log(error);
        this.parseRequestAdsResult(null, callback);
    });
  }

  parseRequestAdsResult = (result, callback) => 
  {
    if (LOG_ENABLE)
      console.log(`parseRequestAdsResult ${result ? JSON.stringify(result) : 'unknown'}`);

    if (!IsValidV(result))
    {
      if (LOG_ENABLE)
        console.log("request failed.");

      if (callback)
      {
        callback(-2);
      }

      return;
    }
    
    if (result.ret === 100)
    {
      LoginStoreInstance.sessionError = true;
    }

    if (callback)
    {
      if (result.ret === 0)
      {
        LoginStoreInstance.setUserPoint(result.pt1, result.pt2);
      }

      callback(result.ret);
    }
  }

  //-------------------------------------------------------------------------------------------------------
  /*
  광고 취소
  aidx : 광고 고유번호
  */
  requestCancel = (aidx, callback) => {

    const params = new URLSearchParams();

    params.append("rt", "0");
    params.append("aidx", aidx.toString());

    axios({
      method:"POST",
      url: GetApiUrl('ssad/cancel/'),
      headers: {
        //Accept: 'application/json',
        Accept: 'application/text',
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
      },
      responseType: 'json', // 기본 값, [ 'arraybuffer', 'blob', 'document', 'json', 'text', 'stream' ]
      responseEncoding: 'utf8', // 기본 값
      data: params
    }).then((res)=>{
        this.parseCancelResult(res.data, callback);
    }).catch(error=>{
        console.log(error);
        this.parseCancelResult(null, callback);
    });
  }

  parseCancelResult = (result, callback) => 
  {
    if (LOG_ENABLE)
      console.log(`parseCancelResult ${result ? JSON.stringify(result) : 'unknown'}`);

    if (!IsValidV(result))
    {
      if (LOG_ENABLE)
        console.log("request failed.");

      if (callback)
      {
        callback(-2, 0, 0);
      }

      return;
    }
    
    if (result.ret === 100)
    {
      LoginStoreInstance.sessionError = true;
    }

    if (callback)
    {
      callback(result.ret, result.aidx, result.st);
    }
  }
  //-------------------------------------------------------------------------------------------------------
  /*
  광고시스템 환경설정정보를 읽음
  */
  loadAdsConfig = (callback) => {

    const params = new URLSearchParams();

    params.append("rt", "0");

    axios({
      method:"POST",
      url: GetApiUrl('ssad/adsset/'),
      headers: {
        //Accept: 'application/json',
        Accept: 'application/text',
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
      },
      responseType: 'json', // 기본 값, [ 'arraybuffer', 'blob', 'document', 'json', 'text', 'stream' ]
      responseEncoding: 'utf8', // 기본 값
      data: params
    }).then((res)=>{
        this.parseAdsConfigResult(res.data, callback);
    }).catch(error=>{
        console.log(error);
        this.parseAdsConfigResult(null, callback);
    });
  }

  parseAdsConfigResult = (result, callback) => 
  {
    if (LOG_ENABLE)
      console.log(`parseAdsConfigResult ${result ? JSON.stringify(result) : 'unknown'}`);

    if (!IsValidV(result))
    {
      if (LOG_ENABLE)
        console.log("request failed.");

      if (callback)
      {
        callback(-2, 0);
      }

      return;
    }
    
    if (result.ret === 100)
    {
      LoginStoreInstance.sessionError = true;
    }

    if (callback)
    {
      callback(result.ret, result.rdt);
    }
  }
  //-------------------------------------------------------------------------------------------------------
  //현재 진행중인 광고의 개수를 구함
  loadAdsRunCount = (callback) => {

    const params = new URLSearchParams();

    params.append("rt", "4");

    axios({
      method:"POST",
      url: GetApiUrl('ssad/list/'),
      headers: {
        //Accept: 'application/json',
        Accept: 'application/text',
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
      },
      responseType: 'json', // 기본 값, [ 'arraybuffer', 'blob', 'document', 'json', 'text', 'stream' ]
      responseEncoding: 'utf8', // 기본 값
      data: params
    }).then((res)=>{
        this.parseAdsRunCountResult(res.data, callback);
    }).catch(error=>{
        console.log(error);
        this.parseAdsRunCountResult(null, callback);
    });
  }

  parseAdsRunCountResult = (result, callback) => 
  {
    if (LOG_ENABLE)
      console.log(`parseAdsRunCountResult ${result ? JSON.stringify(result) : 'unknown'}`);

    if (!IsValidV(result))
    {
      if (LOG_ENABLE)
        console.log("request failed.");

      if (callback)
      {
        callback(-2, null);
      }

      return;
    }
    
    if (result.ret === 100)
    {
      LoginStoreInstance.sessionError = true;
    }

    if (callback)
    {
      callback(result.ret, result.ci);
    }
  }
}

const AdsStoreInstance = new AdsStore();
const AdsStoreContext = React.createContext(AdsStoreInstance);

const UseAdsStore = () => React.useContext(AdsStoreContext);

export {UseAdsStore, AdsStoreInstance};
