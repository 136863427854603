import './Home.css';
import '../../App.css';

const HomeNtcItem = (props) =>
{
  return (
    <div className='homentcitem rowsc btncur' onClick={() => props.handleNotice(props.item)}>
      <div className='rowcc'>
        <div className='homentcitemtag rowcc'>
          <label className='font15 wt500 colorw btncur'>공지</label>
        </div>

        <label className='font16 wt400 color31 btncur'>{props.item.tt}</label>
      </div>
      
      <label className='font16 wt400 colorb3 btncur'>{props.item.rd}</label>
    </div>
  );
}

export default HomeNtcItem;