import './Order.css';
import '../../App.css';
import { GetImgUrl, LOG_ENABLE } from '../../URL/AppUrl';
import { checkAdsSystem, getCurrentPlatform } from './OrderDescUtil';
import { GetFormattedNum } from '../../Util/Util';

const getPlatformImg = (platform, enabled) =>
{
  switch(platform)
  {
    case 0:
      return GetImgUrl(enabled ? "order/instaen.png" : "order/insta.png");
    case 1:
      return GetImgUrl(enabled ? "order/facebooken.png" : "order/facebook.png");
    case 2:
      return GetImgUrl(enabled ? "order/youtubeen.png" : "order/youtube.png");
    case 3:
      return GetImgUrl(enabled ? "order/ticktocken.png" : "order/ticktock.png");
    default:
      return "";
  }
}

const PlatformBtn = (props) =>
{
  if (props.enabled)
  {
    return (
      <div className='orderrightpfbtn1 btncur flexcolevenc mgrt10' onClick={() => props.handlePlatform(props.platform)}>
        <img src={getPlatformImg(props.platform, true)} alt="광고선택" />
        <label className='font16 wt500 color20 btncur'>{props.caption}</label>
      </div>
    );
  }
  else
  {
    return (
      <div className='orderrightpfbtn2  btncur flexcolevenc mgrt10' onClick={() => props.handlePlatform(props.platform)}>
        <img src={getPlatformImg(props.platform, false)} alt="광고선택" />
        <label className='font16 wt500 colorb3 btncur'>{props.caption}</label>
      </div>
    );
  }
}

const getAdsImg = (adsType, enabled) =>
{
  switch(adsType)
  {
    case 0: //좋아요
      return GetImgUrl(enabled ? "order/likeen.png" : "order/like.png");
    case 1: //팔로우
      return GetImgUrl(enabled ? "order/followen.png" : "order/follow.png");
    case 2: //댓글
      return GetImgUrl(enabled ? "order/commenten.png" : "order/comment.png");
    case 3: //페이지, 페이스북전용
      return GetImgUrl(enabled ? "order/pageen.png" : "order/page.png");
    default:
      return "";
  }
}

const AdsTypeBtn = (props) =>
{
  if (props.enabled)
  {
    return (
      <div className='orderrightadstypebtn1 flexrowevenc marginright10 btncur margintop10' onClick={() => props.handleAdsType(props.adsType)}>
        <img src={getAdsImg(props.adsType, true)} alt="광고타입"/>
        <label className='font16 wt500 color20 btncur'>&nbsp;{props.caption}</label>
      </div>
    );
  }
  else
  {
    return (
      <div className='orderrightadstypebtn2 flexrowevenc marginright10 btncur margintop10' onClick={() => props.handleAdsType(props.adsType)}>
        <img src={getAdsImg(props.adsType, false)} alt="광고타입"/>
        <label className='font16 wt500 colorb3 btncur'>&nbsp;{props.caption}</label>
      </div>
    );
  }
}

const handleKeyPress = (props, e) =>
{
  if (LOG_ENABLE)
    console.log("handleKeyPress");

  if (e.key === "Enter")
  {
    props.handleEnter();
  }
}

const getBuyCount = (count) =>
{
  if (!count || count < 1)
    return '';

  return count.toString();
}

const getAdsTypeFllowerCaption = (platform) =>
{
  switch(platform)
  {
    case 0: //인스타
      return "팔로우 늘리기";
    case 1: //페이스북
      return "프로필 팔로우 늘리기";
    case 2: //유튜브
      return "구독자 늘리기";
    case 3: //틱톡
      return "팔로우 늘리기";
    default:
      return "";
  }
}

const getSnsLinkDesc = (platform, adsType) =>
{
  switch(adsType)
  {
    case 0: //게시물 좋아요
    case 2: //게시물 댓글

      if (platform === 2)
        return "동영상 주소를 정확히 입력해 주세요.";
      else if (platform === 3)
        return "게시물 주소를 정확히 입력해 주세요.";
      else
        return "게시물 주소를 정확히 입력해 주세요.";
    case 1: //프로필 팔로우
      if (platform === 2)
        return "유튜브 채널 주소를 정확히 입력해 주세요.";
      else
        return "프로필 주소를 정확히 입력해 주세요.";
    case 3: //페이지팔로우, 페이스북 전용
      return "페이지 주소를 정확히 입력해 주세요."
    default:
      return "";
  }
}

const getGenderCost = (props) =>
{
  if (props.genderCost < 1)
    return '';

  return ` (+${props.genderCost})`;
}

const getAgeCost = (props) =>
{
  if (props.ageCost < 1)
    return '';

  return ` (+${props.ageCost})`;
}

const getOrderTite = (props) =>
{
  if (props.resultCost < 1)
    return "주문하기";

  return `${GetFormattedNum(props.resultCost)}원 주문하기`;
}

const getCostInfo = (props, adt, title) =>
{
  const cost = props.getBaseCost(props.platform, adt);

  if (cost < 1)
    return title;

  if (!checkAdsSystem(props.adsConfig, props.platform, adt))
    return `${title}(점검중)`;
  else
    return `${title}(+${cost}P)`;
}

const OrderRight = (props) =>
{
  return (
    <div className='orderright coltc'>

      <div className='orderrightcont flexcoltl'>
        <label className='font20 wt500 color31 mgtop15'>주문을 생성하기 위해 주문서를 작성해 주세요</label>

        <label className='font15 wt500 color31 margintop10'>플랫폼</label>
        <div className='fullwidth rowlc margintop5'>
          <PlatformBtn platform={0} enabled={props.platform === 0} handlePlatform={props.handlePlatform} caption="인스타그램"/>
          {/*<PlatformBtn platform={1} enabled={props.platform === 1} handlePlatform={props.handlePlatform} caption="페이스북"/>*/}
          <PlatformBtn platform={2} enabled={props.platform === 2} handlePlatform={props.handlePlatform} caption="유튜브"/>
          <PlatformBtn platform={3} enabled={props.platform === 3} handlePlatform={props.handlePlatform} caption="틱톡"/>
        </div>

        {/*
        <label className='font15 wt500 color31 margintop20'>{getCurrentPlatform(props.platform)}</label>

        <div className='orderrightadstypecont flexrowlc'>
          <AdsTypeBtn adsType={0} enabled={props.adsType === 0} handleAdsType={props.handleAdsType} caption={getCostInfo(props, 0, `좋아요 늘리기`)}/>
          <AdsTypeBtn adsType={1} enabled={props.adsType === 1} handleAdsType={props.handleAdsType} caption={getCostInfo(props, 1, getAdsTypeFllowerCaption(props.platform))}/>
          {
            props.platform === 1 ? (
              <AdsTypeBtn adsType={3} enabled={props.adsType === 3} handleAdsType={props.handleAdsType} caption={getCostInfo(props, 3, "페이지 팔로우[좋아요] 늘리기")}/>
            ) : null
          }
          <AdsTypeBtn adsType={2} enabled={props.adsType === 2} handleAdsType={props.handleAdsType} caption={getCostInfo(props, 2, "댓글 늘리기")}/>
        </div>
        */}

        <label className='font15 wt500 color31 margintop25'>추가옵션</label>
        <div className='orderrightadstypecont flexrowlc margintop5'>
          <select className='odrtsel font16 wt400 color31 marginright10' value={props.ageType.toString()} onChange={(e) => props.handleAgeType(parseInt(e.target.value))}>
            <option value="0">연령무관</option>
            <option value="1">{`20대${getAgeCost(props)}`}</option>
            <option value="2">{`30대${getAgeCost(props)}`}</option>
          </select>

          <select className='odrtsel font16 wt400 color31' value={props.genderType.toString()} onChange={(e) => props.handleGenderType(parseInt(e.target.value))}>
            <option value="0">성별무관</option>
            <option value="1">{`남성${getGenderCost(props)}`}</option>
            <option value="2">{`여성${getGenderCost(props)}`}</option>
          </select>
        </div>

        <label className='font15 wt500 color31 mgtop20'>게시할 SNS 주소</label>
        
        <div className='flexrowsc fullwidth margintop5'>
          <input type='text' className='odrtinp3 font16 wt400 colorb3' value={props.link} onChange={e => props.handleLink(e.target.value)} maxLength={700}
            placeholder='URL을 입력후 확인 버튼을 눌러주세요.'
            onKeyUp={e => handleKeyPress(props, e)}
          />

          <button className='odrchkbtn btncur font14btn2' onClick={() => props.handleCheckSns()}>확인</button>
        </div>

        {
          props.snsLinkChecked ? (
            <label className="font15 wt400 color31 margintop5">SNS주소가 확인되었습니다.</label>
          ) : (
            <label className="font15 wt400 colorff36 margintop5">SNS주소가 확인되지 않았습니다.</label>
          )
        }
        

        <label className='font15 wt500 color31 mgtop20'>구매수량</label>
        
        {
          props.repeat === 0 ? (
            <input type='text' className='odrtinp font16 wt400 color31 mgtop5' value={getBuyCount(props.count)} onChange={e => props.handleCount(e.target.value)} maxLength={10}
              placeholder='최소 5개부터 최대 3만 개 까지 주문 가능합니다.'
            />
          ) : (
            <div className='odrptarea margintop10 flexrowsc'>
              <select className='odrptsel font16 wt400 color31' value={props.repeatDelay.toString()} onChange={(e) => props.handleRepeatData(8, parseInt(e.target.value))}>
                <option value="0">시간 선택</option>
                <option value="30">30분</option>
                <option value="60">60분</option>
                <option value="90">90분</option>
                <option value="120">120분</option>
                <option value="150">150분</option>
                <option value="180">180분</option>
                <option value="210">210분</option>
                <option value="240">240분</option>
                <option value="270">270분</option>
                <option value="300">300분</option>
              </select>
              <label className='font16 wt400 color31'>마다</label>

              <select className='odrptsel font16 wt400 color31' value={props.count.toString()} onChange={(e) => props.handleRepeatData(9, parseInt(e.target.value))}>
                <option value="0">반복 개수</option>
                <option value="10">10개</option>
                <option value="20">20개</option>
                <option value="30">30개</option>
                <option value="40">40개</option>
                <option value="50">50개</option>
                <option value="60">60개</option>
                <option value="70">70개</option>
                <option value="80">80개</option>
                <option value="90">90개</option>
                <option value="100">100개</option>
              </select>
              <label className='font16 wt400 color31'>씩</label>

              <select className='odrptsel font16 wt400 color31' value={props.repeatCount.toString()} onChange={(e) => props.handleRepeatData(7, parseInt(e.target.value))}>
                <option value="0">총 횟수</option>
                <option value="2">2번</option>
                <option value="3">3번</option>
                <option value="4">4번</option>
                <option value="5">5번</option>
                <option value="6">6번</option>
                <option value="7">7번</option>
                <option value="8">8번</option>
                <option value="9">9번</option>
                <option value="10">10번</option>
              </select>
              <label className='font16 wt400 color31'>작업</label>
            </div>
          )
        }

        {/*
          props.repeat === 0 ? (
            <button className='odrptbtn2 font16 wt600 color20 btncur margintop10' onClick={() => props.handleRepeat(1)}>반복 주문</button>
          ) : (
            <button className='odrptbtn1 font16 wt600 color20 btncur margintop10' onClick={() => props.handleRepeat(0)}>반복 주문</button>
          )
        */}
      </div>

      <div className='odrdescmsg rowcc mgtop50'>
        <label className='font16 wt400 color31'>머니팡 이용자들이 수작업으로 직접 수행하기 때문에 작업 속도가 다소 느릴 수 있어요</label>
      </div>

      <button className={props.snsChecked ? 'odrtodbtn font14btn1 btncur mgbt20 mgtop10 mgbt20' : 'odrtodbtn_dis font14btn1 btncur mgbt20 mgtop10'} onClick={props.handleOrder}>{getOrderTite(props)}</button>
    </div>
  );

}

export default OrderRight;