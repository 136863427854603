import './Order.css';
import '../../App.css';
import { getDesc, getDescTitle } from './OrderDescUtil';

const OrderLeftMb = (props) =>
{
  return (
    <div className="orderleft_mb flexcolcc margintop10">
      <div className='orderleft_in_mb flexcoltl'>
        <label className="font12202">{getDescTitle(props.platform, props.adsType)}</label>
        {getDesc(props.platform, props.adsType)}

        <label className="font12202 margintop15">취소 및 환불 불가에 대한 주의사항 안내</label>

        <label className="font12b lineheight20 margintop5 odleftlh">· 비공개 계정은 작업이 불가능합니다.<br/>· 진행 중인 주문 건 추가 주문 시 진행 중인 주문 건이 진행 완료된 후 주문해 주세요.<br/>· 작업 중 아이디 변경 및 비공개 계정 (게시물) 전환 시 작업이 불가능합니다.<br/>· URL을 정확히 확인 후 입력해 주세요. 잘못 입력하거나 실수로 인한 주문은 취소 및 환불이 불가능합니다. </label>
      </div>
    </div>
  );
}

export default OrderLeftMb;