import '../Purchase/Purchase.css';
import '../../App.css';
import { GetImgUrl, LOG_ENABLE, mobileOn } from '../../URL/AppUrl';
import { BPM_TRANS, BPM_VBANK, BPM_VBANK_PONE, convertBingoDateInfo, GetFormattedNum } from '../../Util/Util';

const BingoBankInfo = (props) =>
{
  if (LOG_ENABLE)
    console.log(`bank info :${JSON.stringify(props.item)}`);

  if (mobileOn)
  {
    return (
      <div className='purinfobg flexrowcc'>
        <div className='purinfopop_mb flexcoltc'>
          <div className='purinfocont_mb flexrowsc margintop15'>
            <label className='font20 wt600 color31'>{(props.item.pm === BPM_VBANK || props.item.pm === BPM_VBANK_PONE) ? "가상계좌 입금안내" : "계좌이체 입금안내"}</label>
          </div>
  
          <div className='purinfocont_mb flexrowlc margintop20'>
            <label className='font15 wt500 color31'>입금계좌 정보</label>
          </div>
  
          <div className='purinfobox_mb flexcolevenl margintop3'>
            <label className='font16 wt400 color31'>예금주명<span className='mglt10'>{`${props.item.bankInfo.bun}`}</span></label>
            <label className='font16 wt400 color31'>거래은행<span className='mglt10'>{`${props.item.bankInfo.bn}`}</span></label>
            <label className='font16 wt400 color31'>계좌번호<span className='mglt10'>{`${props.item.bankInfo.ba}`}</span></label>
          </div>
  
          <div className='purinfocont_mb flexrowlc margintop20'>
            <label className='font15 wt500 color31'>입금자 정보</label>
          </div>
  
          <div className='purinfobox_mb flexcolevenl margintop3'>
            {
              props.item.pm === BPM_TRANS ? (
                <label className='font16 wt400 color31'>입금자명<span className='mglt10'>{`${props.item.wun}`}</span></label>
              ) : null
            }
            
            <label className='font16 wt400 color31'>입금금액<span className='mglt10'>{`${GetFormattedNum(props.item.pamt)}원`}</span></label>
            <label className='font16 wt400 color31'>입금기한<span className='mglt10'>{convertBingoDateInfo(props.item.bankInfo.ldt)}</span></label>
          </div>
  
          <button className='purinfobtn_mb btncur font16 wt600 colorw margintop15' onClick={props.handleClose}>확인</button>
        </div>
      </div>
    );
  }
  else
  {
    return (
      <div className='purinfobg flexrowcc'>
        <div className='purinfopop flexcoltc'>
          <div className='purinfocont flexrowsc margintop22'>
            <label className='font20 wt600 color31'>{(props.item.pm === BPM_VBANK || props.item.pm === BPM_VBANK_PONE) ? "가상계좌 입금안내" : "계좌이체 입금안내"}</label>
            <img src={GetImgUrl("com/close.png")} alt="닫기" className='btncur' onClick={props.handleClose}/>
          </div>
  
          <div className='purinfocont flexrowlc margintop35'>
            <label className='font15 wt500 color31'>입금계좌 정보</label>
          </div>
  
          <div className='purinfobox flexcolevenl margintop8'>
            <label className='font16 wt600 color31'>예금주명 <span className='wt400 mglt10'>{`${props.item.bankInfo.bun}`}</span></label>
            <label className='font16 wt600 color31'>거래은행 <span className='wt400 mglt10'>{`${props.item.bankInfo.bn}`}</span></label>
            <label className='font16 wt600 color31'>계좌번호 <span className='wt400 mglt10'>{`${props.item.bankInfo.ba}`}</span></label>
          </div>
  
          <div className='purinfocont flexrowlc margintop30'>
            <label className='font15 wt500 color31'>입금자 정보</label>
          </div>
  
          <div className='purinfobox flexcolevenl margintop8'>
            {
              props.item.pm === BPM_TRANS ? (
                <label className='font16 wt600 color31'>입금자명 <span className='wt400 mglt10'>{`${props.item.wun}`}</span></label>
              ) : null
            }
            
            <label className='font16 wt600 color31'>입금금액 <span className='wt400 mglt10'>{`${GetFormattedNum(props.item.pamt)}`}원</span></label>
            <label className='font16 wt600 color31'>입금기한 <span className='wt400 mglt10'>{`${convertBingoDateInfo(props.item.bankInfo.ldt)}`}</span></label>
          </div>
  
          <button className='purinfobtn btncur font16 wt600 colorw margintop35' onClick={props.handleClose}>확인</button>
        </div>
      </div>
    );
  }
}

export default BingoBankInfo;