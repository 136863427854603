import React from 'react';
import './UnregPage.css';
import '../../App.css';
import { LOG_ENABLE, gotoFirstPage, gotoTalkPage, handleAppNavi, mobileOn, withNavigation } from '../../URL/AppUrl';
import {  GetFormattedNum, IsValidS, } from '../../Util/Util';
import Loading from '../Loading/Loading';
import TopBar from '../Top/TopBar';
import FooterBar from '../Footer/Footer';
import { LoginStoreInstance } from '../../Stores/LoginStore';
import Term from '../Term/Term';
import Privacy from '../Term/Privacy';
import Talk from '../Talk/Talk';
import MobileMenu from '../Top/MobileMenu';
import { AdsStoreInstance } from '../../Stores/AdsStore';
import { resetAllUserData } from '../../Stores/StoreManager';
import Login from '../Login/Login';
import FindId from '../Login/FindId';
import FindPw from '../Login/FindPw';
import NaviBar from '../Navi/Navi';

//회원탈퇴신청페이지
class UnregPage extends React.Component
{
  state = {
    loading: false,
    showTerm: false,
    showPrivacy: false,
    showMobileMenu: false,
    showLogin: false,
    showFindId: false,
    showFindPw: false,

    checkTerm: false,

    //사이징처리
    naviWidth: 0,
    contWidth: 0,

    height: 0,
  };
  
  intervalId = -1;

  update = () =>
  {
    if (LoginStoreInstance.sessionError && LoginStoreInstance.loggedIn)
    {
      LoginStoreInstance.sessionError = false;
      alert(`인증 세션이 만료되었습니다.`);
      this.processLogOut();
    }
  }

  showMobileMenu = (v) =>
  {
    this.setState((prevState) => ({...prevState, showMobileMenu: v}));
  }

  showTerm = (v) =>
  {
    this.setState((prevState) => ({...prevState, showTerm: v}));
  }

  showPrivacy = (v) =>
  {
    this.setState((prevState) => ({...prevState, showPrivacy: v}));
  }

  setCheckTerm = (v) =>
  {
    this.setState((prevState) => ({...prevState, checkTerm: v}));
  }

  //-----------------------------------------------------------------------------------------------------------------------------
  showLogin = (v) =>
  {
    this.setState((prevState) => ({...prevState, showLogin: v}));
  }

  showFindId = (v) =>
  {
    this.setState((prevState) => ({...prevState, showFindId: v}));
  }

  showFindPw = (v) =>
  {
    this.setState((prevState) => ({...prevState, showFindPw: v}));
  }

  handleLoginEvent = (eventCode) =>
  {
    if (this.state.loading)
      return;

    switch(eventCode)
    {
      case 0: //닫기
        this.showLogin(false);
        return;
      case 1: //회원가입
        this.handleRegister();
        return;
      case 2: //id찾기
        this.showLogin(false);
        this.showFindId(true);
        return;
      case 3: //비번찾기
        this.showLogin(false);
        this.showFindPw(true);
        return;
      case 100: //로그인 성공
        this.showLogin(false);
        this.needCompanyInfo = LoginStoreInstance.userType === 10;
        this.loadUserData();
        return;
    }
  }

  handleRegister = () =>
  {
    if (this.state.loading)
      return;

    this.showLogin(false);
    this.showFindId(false);
    this.showFindPw(false);
    this.handleNavi(5);
  }

  handleFindPw = () =>
  {
    if (this.state.loading)
      return;

    this.showLogin(false);
    this.showFindId(false);
    this.showFindPw(true);
  }

  handleFindIdLogin = () =>
  {
    if (this.state.loading)
      return;

    this.showLogin(true);
    this.showFindId(false);
    this.showFindPw(false);
  }
  //-----------------------------------------------------------------------------------------------------------------------------

  handleNavi = (naviIndex) =>
  {
    if (this.state.loading)
      return;

    if (LOG_ENABLE)
      console.log(`handleNavi : ${naviIndex}`);

    //console.log(`${this.props.navigate}`);
    this.showMobileMenu(false);
    handleAppNavi(this.props.navigate, naviIndex);
  }

  handleSnsOrder = (orderType) =>
  {
    if (this.state.loading)
      return;

    if (LOG_ENABLE)
      console.log(`handleSnsOrder : ${orderType}`);

    AdsStoreInstance.setOrderType(orderType);

    handleAppNavi(this.props.navigate, 0);
  }

  handleMenu = (menuIndex) =>
  {
    if (this.state.loading)
      return;

    switch(menuIndex)
    {
      case 0: //로그인
        this.showLogin(true);
        return;
      case 6: //이용약관
        this.showTerm(true);
        return;
      case 7: //개인정보 처리방침
        this.showPrivacy(true);
        return;
      case 100: //로그아웃
        if (!window.confirm('로그아웃 할까요?'))
          return;

        this.processLogOut();
        return;
    }
  }

  handleMenu = (btnIndex) =>
  {
    if (this.state.loading)
      return;

    if (LOG_ENABLE)
      console.log(`handleMenu : ${btnIndex}`);

    switch(btnIndex)
    {
      case 0: //계정설정
        this.showMobileMenu(false);
        break;
      case 1: //로그아웃
        this.showMobileMenu(false);
        this.processLogOut();
        return;
      
      case 6://이용약관
        this.showTerm(true);
        break;
      case 7://개인정보처리방침
        this.showPrivacy(true);
        break;
      case 100: //탑 햄버거 메뉴
        this.showMobileMenu(true);
        break;
      case 101: //탑 햄버거 메뉴 닫기
        this.showMobileMenu(false);
        break;
      default:
        return;
    }
  }

  processLogOut = () =>
  {
    resetAllUserData();
    gotoFirstPage();
  }

  //사이징----------------------------------------------------------------------------------------------
  setSize = (navi, cont, height) =>
  {
    this.setState((prevState) => ({...prevState, naviWidth : navi, contWidth : cont, height : height}));
  }

  resetContsize = () =>
  {
    //네비게이션 영역은 전체의 16%정도
    let naviWidth = 0.16 * window.innerWidth;

    if (naviWidth < 300) //최소크기300
      naviWidth = 300;

    //콘텐츠영역크기 계산
    let contWidth = window.innerWidth - naviWidth;

    if (contWidth < 1220)
      contWidth = 1220;

    this.setSize(naviWidth, contWidth, window.innerHeight - 70)
  }

  onResize = () => {
    this.resetContsize();
  }
  //사이징----------------------------------------------------------------------------------------------

  componentDidMount() {
    this.resetContsize();

    window.addEventListener("resize", this.onResize);

    this.intervalId = setInterval(this.update, 1000);

    //로그인되어 있지 않은경우, 혹시 새로고침했을수도 있다.
    if (!LoginStoreInstance.loggedIn)
    {
      if (LoginStoreInstance.loadToken()) //인증토큰 정보가 존재한다면
      {
        this.setLoading(true);
        LoginStoreInstance.loadProfile(this.onProfileLoadedForRefresh);
      }
    }
    else
    {
      if (!LoginStoreInstance.isProfileReady) //프로필이 준비되지 않았다면
      {
        this.setLoading(true);
        LoginStoreInstance.loadProfile(this.onProfileLoaded);
      }
      else
      {
        //마지막 포인트 조회 시점부터 현재까지 경과 시간이 1분이상 경과한경우
        if (LoginStoreInstance.getPassedPointTime() > 60)
        {
          LoginStoreInstance.catchPointRefreshTime();
          LoginStoreInstance.refreshUserPoint(null);
        }
      }
    }
  }

  componentWillUnmount = () =>
  {
    if (this.intervalId !== -1)
    {
      clearInterval(this.intervalId);
      this.intervalId = -1;
    }

    window.removeEventListener("resize", this.onResize);
  }

  onProfileLoadedForRefresh = (resultCode) =>
  {
    this.setLoading(false);

    if (resultCode !== 0)
    {
      if (LOG_ENABLE)
        console.log(`invalid profile`);

      LoginStoreInstance.removeToken();

      return;
    }
  }

  onProfileLoaded = (resultCode) =>
  {
    this.setLoading(false);

    if (resultCode !== 0)
    {
      alert(`프로필 읽기 중 오류가 발생했습니다.\n다시 로그인해 주세요.`);
      this.processLogOut();
      return;
    }
  }

  setLoading = (v) =>
  {
    this.setState((prevState) => ({...prevState, loading : v}));
  }

  handleTalk = () =>
  {
    gotoTalkPage();
  }

  //회원탈퇴신청
  handleUnregister = () =>
  {
    if (this.state.loading || !LoginStoreInstance.loggedIn)
      return;

    if (!this.state.checkTerm)
    {
      alert(`탈퇴 약관을 잘 읽고 이에 동의해 주세요.`);
      return;
    }

    if (!window.confirm(`정말 회원탈퇴를 진행할까요?\n신청 즉시 사이트 이용이 불가능해집니다.`))
      return;
   
    this.setLoading(true);
    LoginStoreInstance.requestUnregister(this.onUnregisterDone);
  }

  onUnregisterDone = (resultCode) =>
  {
    this.setLoading(false);
    
    switch(resultCode)
    {
      case 0:
        alert(`정상처리되었습니다.`);
        this.processLogOut();
        return;
      case 1:
        alert(`잘못된 요청입니다.`);
        return;
      case 3:
        alert(`이미 탈되 신청 중이거나 탈퇴된 계정입니다`);
        return;
      default:
        alert(`탈퇴 신청 중 오류가 발생했습니다.\n잠시 후 이용해 주세요.\n오류코드 ${resultCode}`);
        return;
    }
  }

  handleClose = () =>
  {
    if (this.state.loading)
      return;

    this.handleNavi(6);
  }

  render()
  {
    return (
      <div className='pgcont coltc'>
        {
          this.state.showLogin ? (<Login handleLoginEvent={this.handleLoginEvent}/>) : null
        }
        {
          this.state.showFindId ? (<FindId handleRegister={this.handleRegister} handleClose={() => this.showFindId(false)} handleFindPw={this.handleFindPw} handleLogin={this.handleFindIdLogin}/>) : null
        }
        {
          this.state.showFindPw ? (<FindPw handleClose={() => this.showFindPw(false)} handleRegister={this.handleRegister} />) : null
        }
        {
          this.state.showMobileMenu ? (<MobileMenu handleNavi={this.handleNavi} handleMenu={this.handleMenu} />) : null
        }
        {
          this.state.showTerm ?(<Term handleClose={() => this.showTerm(false)}/>) : null
        }
        {
          this.state.showPrivacy ? (<Privacy handleClose={() => this.showPrivacy(false)}/>) : null
        }
        {
          this.state.loading ? (<Loading/>) : null
        }
        <TopBar handleNavi={this.handleNavi} withLine={true}/>
        <Talk handleTalk={this.handleTalk}/>

        <div className='pgcont2 rowlt'>
          <NaviBar width={this.state.naviWidth} height={this.state.height} handleSnsOrder={this.handleSnsOrder} handleNavi={this.handleNavi} handleMenu={this.handleMenu}/>

          <div className='homecont colel' style={{width: this.state.contWidth, height: this.state.height}}>
            <div className='homecont2 coltl' style={{width: this.state.contWidth - 40 - 50, height: this.state.height - 100}}>
              <label className='font20 wt600 color31 mgtop20'>회원탈퇴 신청</label>
      
              <label className='font15 wt400 color31 lht23 margintop10'>1. 회원 탈퇴를 신청하는 경우 즉시 접수되며, SNSFLY 로그인이 불가능해집니다.<br/>
                2. 아이디는 탈퇴할 경우 본인과 타인 모두 재사용 및 복구가 불가능하니 신중하게 선택하시기 바랍니다.<br/>
                3. 안전한 회원 탈퇴 처리를 위해, 회원 탈퇴 신청 시 신청 접수일로부터 7일 경과 후 완전한 회원 탈퇴 처리가 되며, 철회를 원하는 경우 탈퇴 신청 접수일로부터 7일이 경과하기 전까지 카카오톡 문의해 주세요.<br/>
                4. 진행 중인 주문이 있는 상태에서 회원 탈퇴를 진행하게 될 경우 주문 취소가 되지 않고 계속 진행이 되며, 환불이 불가능합니다. 중지를 원할 경우 회원 탈퇴 신청 전에 주문을 중지하고 회원 탈퇴를 진행해 주세요.<br/>
                5. 회원 탈퇴가 완료되기 전까지 동일한 사업자 번호로 재가입이 불가능하며, 회원 정보 및 개인 서비스 이용 기록은 모두 삭제되어 복구가 불가능합니다.
              </label>
              
              <label className='font16 wt500 color20 margintop30'>탈퇴 완료 시 삭제 되는 정보는 아래와 같습니다.</label>

              <label className='font15 wt400 color31 lht23 margintop10'>
                1. 회원정보 : 사업자등록번호(사업자명, 대표자명, 업태, 종목, 사업자 주소, 이메일), 휴대폰 번호, 이메일<br/>
                2. 개인 서비스 이용 기록 : 광고 주문 내역, 포인트
              </label>

              <label className='font16 wt500 color20 margintop30'>탈퇴 후에도 일정기간 보유하는 개인 정보 및 근거는 아래와 같습니다.</label>

              <label className='font15 wt400 color31 lht23 margintop10'>
                1. 대금결제 및 재화공급에 관한 기록 : 각종 결제 내역 (전자상거래 등에서의 소비자보호에 관한 법률, 5년)<br/>
                2. 소비자의 불만 또는 분쟁처리에 관한 기록 : AS 문의 (전자상거래 등에서의 소비자보호에 관한 법률, 3년)<br/>
                3. 웹사이트 방문기록 : 통신비밀보호법, 3개월<br/>
                4. 전자금융 거래에 관한 기록 : 전자금융거래법, 5년
              </label>

              <label className='font16 wt500 color20 margintop30'>포인트 환급 안내</label>

              <label className='font15 wt400 color31 lht23 margintop10'>
                1. 보유하신 포인트 중 충전을 통해 획득한 유료 포인트에 한해서 환급 신청이 가능합니다.<br/>
                2. 각종 이벤트등을 통해 획득하신 무료포인트는 환급 대상이 아니며 회원 탈퇴 완료 시 모두 소멸 됩니다.<br/>
                3. 회원 탈퇴를 신청하시기 전에 사이트내의 문의 하기를 통해서 포인트 환급 신청을 하셔야 합니다.<br/>
                4. 포인트 환급 신청을 하지 않고 회원 탈퇴가 완료되는 경우 개인정보 삭제에 따라 보유하신 포인트는 모두 소멸되어, 포인트 환불 처리가 불가능합니다.
              </label>

              <label className='font16 wt500 color20 margintop30'>보유하신 포인트는 아래와 같습니다.</label>

              <label className='font15 wt400 color31 lht23 margintop10'>
                {`1. 유료 포인트 : ${GetFormattedNum(LoginStoreInstance.getUserPoint1)}P`}<br/>
                {`2. 무료 포인트 : ${GetFormattedNum(LoginStoreInstance.getUserPoint2)}P`}
              </label>

              <div className='flexrowcc margintop40' >
                <input type='checkbox' className='unregchk btncur' value={this.state.checkTerm} onChange={() => this.setCheckTerm(!this.state.checkTerm)} />
                <label className='font15 wt400 color31'>&nbsp;해당 내용을 모두 확인 했으며, 회원탈퇴에 동의 합니다.</label>
              </div>

              <div className='flexrowcc marginbottom150 margintop30'>
                <button className='unregbtn1 font16 wt600 colorw btncur' onClick={this.handleUnregister}>회원탈퇴</button>
                <button className='unregbtn2 font16 wt600 colorw btncur' onClick={this.handleClose}>닫기</button>
              </div>

            </div>
            <FooterBar handleMenu={this.handleMenu}/>
          </div>
        </div>
      </div>
    );
  }
}

export default withNavigation(UnregPage);