import './Setting.css';
import '../../App.css';
import { GetImgUrl, mobileOn } from '../../URL/AppUrl';
import { IsValidS } from '../../Util/Util';

const getMultilineText = (text) =>
{
  if (text.indexOf("\n") < 1)
  {
    if (mobileOn)
      return <label className='font12b'>{text}</label>
    else
      return <label className='font16b'>{text}</label>
  }
  else
  {
    let temp = text.split("\n");

    if (!IsValidS(temp))
    {
      if (mobileOn)
        return <label className='font12b'>{text}</label>
      else
        return <label className='font16b'>{text}</label>
    }
    else
    {
      let index = 0;

      let list = temp.map(item => {
        if (mobileOn)
        {
          return <label key={index++} className='font12b'>{item}</label>;
        }
        else
        {
          return <label key={index++} className='font16b'>{item}</label>;
        }
      });

      return list;
    }
  }
}

const UserQueryDetail = (props) =>
{
  /*if (mobileOn)
  {
    return (
      <div className='uqdetbg_mb flexcoltc'>
        <div className='uqdettt flexcolcc'>
          <div className='uqdetttin flexrowsc'>
            <img src={GetImgUrl("com/mb/toplogo.png")} alt="logo" className='img9421'/>
            <img src={GetImgUrl("com/mb/close.png")} alt="close" className='btncur menubtn img1414' onClick={props.handleClose}/>
          </div>

          <div className='uqitemlinemb'></div>
        </div>

        <div className='uqdetpopmb flexcoltl margintop20'>
          <label className='font14b_1'>문의 내용</label>
  
          <div className='uqdetqtmb margintop5 flexcoltl'>
            {getMultilineText(props.item.qr)}
          </div>

          <label className='font14b_1 margintop20'>문의 답변</label>
  
          <div className='uqdetansmb margintop5 flexcoltl'>
            {
              IsValidS(props.item.ans) && props.item.ans !== "n" ? getMultilineText(props.item.ans) : null
            }
          </div>
        </div>
      </div>
    );
  }
  else*/
  {
    return (
      <div className='uqdetbg flexrowcc'>
        <div className='uqdetpop flexcoltc'>
          <div className='uqdetpopin flexrowrc'>
            <img src={GetImgUrl("com/close.png")} alt="닫기" className='btncur menubtn' onClick={() => props.handleClose()}/>
          </div>
  
          <div className='uqdetpopin flexrowlc margintop15'>
            <label className='font16b'>문의 내용</label>
          </div>
  
          <div className='uqdetqt margintop5 flexcoltl'>
            {getMultilineText(props.item.qr)}
          </div>
  
          <div className='uqdetpopin flexrowlc margintop15'>
            <label className='font16b'>문의 답변</label>
          </div>
  
          <div className='uqdetans margintop5 flexcoltl'>
            {
              IsValidS(props.item.ans) && props.item.ans !== "n" ? getMultilineText(props.item.ans) : null
            }
          </div>
        </div>
      </div>
    );
  }
}

export default UserQueryDetail;