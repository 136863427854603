import React from 'react';
import './Faq.css';
import '../../App.css';
import { GetImgUrl, LOG_ENABLE, gotoFirstPage, gotoTalkPage, handleAppNavi, mobileOn, withNavigation } from '../../URL/AppUrl';
import {  IsValidS, } from '../../Util/Util';
import Loading from '../Loading/Loading';
import TopBar from '../Top/TopBar';
import FooterBar from '../Footer/Footer';
import { LoginStoreInstance } from '../../Stores/LoginStore';
import Term from '../Term/Term';
import Privacy from '../Term/Privacy';
import FaqItem from './FaqItem';
import Paging from '../Paging/Paging';
import Talk from '../Talk/Talk';
import MobileMenu from '../Top/MobileMenu';
import FaqItemMb from './FaqItemMb';
import { FaqStoreInstance } from '../../Stores/FaqStore';
import { resetAllUserData } from '../../Stores/StoreManager';
import { AdsStoreInstance } from '../../Stores/AdsStore';
import Login from '../Login/Login';
import FindId from '../Login/FindId';
import FindPw from '../Login/FindPw';
import NaviBar from '../Navi/Navi';

class Faq extends React.Component
{
  state = {
    loading: false,
    showTerm: false,
    showPrivacy: false,
    showMobileMenu: false,
    showLogin: false,
    showFindId: false,
    showFindPw: false,

    searchWord: '',
    searchMode: false,
    faqMode: -1, //전체(-1), 취소/환불(1), 서비스(2)
    faqList:  null,
    totalFaqCount: 0,

    startPage: 1, //현재의 첫페이지
    endPage: 1, //현재의 끝페이지
    lastPage: 1, //마지막 페이지번호
    pageCount: 0, //전체 페이지의 개수
    currentPage: 1, //현재 선택된 페이지

    //사이징처리
    naviWidth: 0,
    contWidth: 0,

    height: 0,
  };

  faqMode = -1; //전체(-1), 취소/환불(1), 서비스(2)
  intervalId = -1;
  maxPageNum = 5;

  update = () =>
  {
    if (LoginStoreInstance.sessionError && LoginStoreInstance.loggedIn)
    {
      LoginStoreInstance.sessionError = false;
      alert(`인증 세션이 만료되었습니다.`);
      this.processLogOut();
    }
  }

  showMobileMenu = (v) =>
  {
    this.setState((prevState) => ({...prevState, showMobileMenu: v}));
  }

  setCurrentPage = (v) =>
  {
    this.setState((prevState) => ({...prevState, currentPage: v}));
  }

  setPageInfo = (start, end, lastPage, resetCurrentPage) =>
  {
    if (resetCurrentPage)
      this.setState((prevState) => ({...prevState, startPage: start, endPage: end, lastPage: lastPage, currentPage: start}));
    else
      this.setState((prevState) => ({...prevState, startPage: start, endPage: end, lastPage: lastPage}));
  }

  setFaqMode = (v) =>
  {
    if (v === this.faqMode)
      return;

    if (LOG_ENABLE)
      console.log(`setFaqMode : ${v}`);
    this.faqMode = v;
    this.setState((prevState) => ({...prevState, faqMode: v}));
  }

  setFaqList = (v) =>
  {
    this.setState((prevState) => ({...prevState, faqList: v}));
  }

  setFaqCount = (v) =>
  {
    this.setState((prevState) => ({...prevState, totalFaqCount: v}));
  }

  setSearchWord = (v) =>
  {
    this.setState((prevState) => ({...prevState, searchWord: v}));
  }

  setSearchMode = (v) =>
  {
    this.setState((prevState) => ({...prevState, searchMode: v}));
  }

  showTerm = (v) =>
  {
    this.setState((prevState) => ({...prevState, showTerm: v}));
  }

  showPrivacy = (v) =>
  {
    this.setState((prevState) => ({...prevState, showPrivacy: v}));
  }
  //-----------------------------------------------------------------------------------------------------------------------------
  showLogin = (v) =>
  {
    this.setState((prevState) => ({...prevState, showLogin: v}));
  }

  showFindId = (v) =>
  {
    this.setState((prevState) => ({...prevState, showFindId: v}));
  }

  showFindPw = (v) =>
  {
    this.setState((prevState) => ({...prevState, showFindPw: v}));
  }

  handleLoginEvent = (eventCode) =>
  {
    if (this.state.loading)
      return;

    switch(eventCode)
    {
      case 0: //닫기
        this.showLogin(false);
        return;
      case 1: //회원가입
        this.handleRegister();
        return;
      case 2: //id찾기
        this.showLogin(false);
        this.showFindId(true);
        return;
      case 3: //비번찾기
        this.showLogin(false);
        this.showFindPw(true);
        return;
      case 100: //로그인 성공
        this.showLogin(false);
        return;
    }
  }

  handleRegister = () =>
  {
    if (this.state.loading)
      return;

    this.showLogin(false);
    this.showFindId(false);
    this.showFindPw(false);
    this.handleNavi(5);
  }

  handleFindPw = () =>
  {
    if (this.state.loading)
      return;

    this.showLogin(false);
    this.showFindId(false);
    this.showFindPw(true);
  }

  handleFindIdLogin = () =>
  {
    if (this.state.loading)
      return;

    this.showLogin(true);
    this.showFindId(false);
    this.showFindPw(false);
  }
  //-----------------------------------------------------------------------------------------------------------------------------
  handleNavi = (naviIndex) =>
  {
    if (this.state.loading)
      return;

    if (LOG_ENABLE)
      console.log(`handleNavi : ${naviIndex}`);

    //console.log(`${this.props.navigate}`);

    this.showMobileMenu(false);

    handleAppNavi(this.props.navigate, naviIndex);
  }

  handleSnsOrder = (orderType) =>
  {
    if (this.state.loading)
      return;

    if (LOG_ENABLE)
      console.log(`handleSnsOrder : ${orderType}`);

    AdsStoreInstance.setOrderType(orderType);

    handleAppNavi(this.props.navigate, 0);
  }

  handleMenu = (menuIndex) =>
  {
    if (this.state.loading)
      return;

    switch(menuIndex)
    {
      case 0: //로그인
        this.showLogin(true);
        return;
      case 6: //이용약관
        this.showTerm(true);
        return;
      case 7: //개인정보 처리방침
        this.showPrivacy(true);
        return;
      case 100: //로그아웃
        if (!window.confirm('로그아웃 할까요?'))
          return;

        this.processLogOut();
        return;
    }
  }

  processLogOut = () =>
  {
    resetAllUserData();
    gotoFirstPage();
  }

  //사이징----------------------------------------------------------------------------------------------
  setSize = (navi, cont, height) =>
  {
    this.setState((prevState) => ({...prevState, naviWidth : navi, contWidth : cont, height : height}));
  }

  resetContsize = () =>
  {
    //네비게이션 영역은 전체의 16%정도
    let naviWidth = 0.16 * window.innerWidth;

    if (naviWidth < 300) //최소크기300
      naviWidth = 300;

    //콘텐츠영역크기 계산
    let contWidth = window.innerWidth - naviWidth;

    if (contWidth < 1220)
      contWidth = 1220;

    this.setSize(naviWidth, contWidth, window.innerHeight - 70)
  }

  onResize = () => {
    this.resetContsize();
  }
  //사이징----------------------------------------------------------------------------------------------
  componentDidMount() {
    this.resetContsize();

    window.addEventListener("resize", this.onResize);

    this.intervalId = setInterval(this.update, 1000);

    //로그인되어 있지 않은경우, 혹시 새로고침했을수도 있다.
    if (!LoginStoreInstance.loggedIn)
    {
      if (LoginStoreInstance.loadToken()) //인증토큰 정보가 존재한다면
      {
        this.setLoading(true);
        LoginStoreInstance.loadProfile(this.onProfileLoadedForRefresh);
      }
      else
      {
        this.loadFaq(1);
      }
    }
    else
    {
      if (!LoginStoreInstance.isProfileReady) //프로필이 준비되지 않았다면
      {
        this.setLoading(true);
        LoginStoreInstance.loadProfile(this.onProfileLoaded);
      }
      else
      {
        this.loadFaq(1);

        //마지막 포인트 조회 시점부터 현재까지 경과 시간이 1분이상 경과한경우
        if (LoginStoreInstance.getPassedPointTime() > 60)
        {
          LoginStoreInstance.catchPointRefreshTime();
          LoginStoreInstance.refreshUserPoint(null);
        }
      }
    }
  }

  componentWillUnmount = () =>
  {
    if (this.intervalId !== -1)
    {
      clearInterval(this.intervalId);
      this.intervalId = -1;
    }

    window.removeEventListener("resize", this.onResize);
  }

  onProfileLoadedForRefresh = (resultCode) =>
  {
    this.setLoading(false);

    if (resultCode !== 0)
    {
      LoginStoreInstance.removeToken();
      return;
    }

    this.loadFaq(1);
  }

  onProfileLoaded = (resultCode) =>
  {
    this.setLoading(false);

    if (resultCode !== 0)
    {
      alert(`프로필 읽기 중 오류가 발생했습니다.\n다시 로그인해 주세요.`);
      this.processLogOut();
      return;
    }

    this.loadFaq(1);
  }

  loadFaq = (pageNum) =>
  {
    if (!this.state.loading)
      this.setLoading(true);

    FaqStoreInstance.loadFaq(this.faqMode, ((pageNum - 1) * 10), this.onLoadFaq);
  }

  onLoadFaq = (resultCode, firstLoading, totalCount, faqList) =>
  {
    this.setLoading(false);

    if (resultCode !== 0)
    {
      alert(`자주 묻는 질문 사항 로딩 중 오류가 발생했습니다.\n잠시 후 이용해 주세요.\n오류코드 : ${resultCode}`);
      return;
    }

    if (firstLoading)
    {
      this.setFaqCount(totalCount);
      this.calcPageCount(totalCount);
    }

    if (IsValidS(faqList))
      this.setFaqList([...faqList]);
    else
      this.setFaqList(null);
  }

  //전체 페이지 개수를 카운트
  calcPageCount = (faqCount) =>
  {
    let pageCount = Math.floor(faqCount / 10);

    if ((faqCount % 10) !== 0)
      ++pageCount;

    let endPage = 5;

    if (endPage > pageCount)
      endPage = pageCount;

    this.setState((prevState) => ({...prevState, startPage : 1, endPage : endPage, currentPage : 1, pageCount : pageCount, lastPage: pageCount}));
  }

  setLoading = (v) =>
  {
    this.setState((prevState) => ({...prevState, loading : v}));
  }

  getFaqBtn = (enabled, caption, widthMargin, index) =>
  {
    /*if (mobileOn)
    {
      if (enabled)
      {
        return <button className={`faqbtn1_mb btncur font12202 ${widthMargin ? "marginright5" : ""}`} onClick={() => this.handleTab(index)}>{caption}</button>
      }
      else
      {
        return <button className={`faqbtn2_mb btncur font12b3 ${widthMargin ? "marginright5" : ""}`} onClick={() => this.handleTab(index)}>{caption}</button>
      }
    }
    else*/
    {
      if (enabled)
      {
        return <button className={`faqbtn1 btncur font14btn2 ${widthMargin ? "marginright5" : ""}`} onClick={() => this.handleTab(index)}>{caption}</button>
      }
      else
      {
        return <button className={`faqbtn2 btncur font14btn3 ${widthMargin ? "marginright5" : ""}`} onClick={() => this.handleTab(index)}>{caption}</button>
      }
    }
  }

  handleTab = (index) =>
  {
    if (index === this.state.faqMode || this.state.loading)
      return;

    this.setFaqMode(index);
    this.loadFaq(1);
  }

  //페이지 선택처리
  handlePage = (pageNumber) =>
  {
    if (pageNumber === -1) //이전 페이지
    {
      let startPage = this.state.startPage - this.maxPageNum;
      let endPage = this.state.endPage - this.maxPageNum;

      if (startPage < 1)
        startPage = 1;
      
      if (endPage > this.state.pageCount)
        endPage = this.state.pageCount;

      this.setState((prevState) => ({...prevState, currentPage : startPage, startPage : startPage, endPage : endPage}));

      this.loadFaq(startPage);
    }
    else if (pageNumber === -2) //다음 페이지
    {
      let startPage = this.state.endPage + 1;
      let endPage = startPage + this.maxPageNum;

      if (startPage > this.state.pageCount)
        startPage = this.state.pageCount;
      
      if (endPage > this.state.pageCount)
        endPage = this.state.pageCount;

      this.setState((prevState) => ({...prevState, currentPage : startPage, startPage : startPage, endPage : endPage}));
      this.loadFaq(startPage);
    }
    else //특정 페이지 선택
    {
      this.setCurrentPage(pageNumber);
      this.loadFaq(pageNumber);
    }
  }

  handleKeyPress = (e) =>
  {
    if (LOG_ENABLE)
      console.log("handleKeyPress");

    if (e.key === "Enter")
    {
      this.handleSearch();
    }
  }

  handleSearch = () =>
  {
    if (this.state.loading)
      return;

    if (!IsValidS(this.state.searchWord))
    {
      alert('검색어를 입력해 주세요.');
      return;
    }

    this.setLoading(true);
    FaqStoreInstance.searchFaq(this.faqMode, this.state.searchWord, this.onSearchResult);
  }

  onSearchResult = (resultCode, list) =>
  {
    this.setLoading(false);

    if (resultCode !== 0)
    {
      alert(`자주 묻는 질문 검색 중 오류가 발생했습니다.\n오류코드 : ${resultCode}`);
      return;
    }

    this.setSearchMode(true);

    if (IsValidS(list))
      this.setFaqList([...list]);
    else
      this.setFaqList(null);
  }

  handleTalk = () =>
  {
    gotoTalkPage();
  }
  
  renderPc = (faqList) =>
  {
    return (
      <div className='pgcont coltc'>
        {
          this.state.showMobileMenu ? (<MobileMenu handleNavi={this.handleNavi} handleMenu={this.handleMenu} />) : null
        }
        {
          this.state.showLogin ? (<Login handleLoginEvent={this.handleLoginEvent}/>) : null
        }
        {
          this.state.showFindId ? (<FindId handleRegister={this.handleRegister} handleClose={() => this.showFindId(false)} handleFindPw={this.handleFindPw} handleLogin={this.handleFindIdLogin}/>) : null
        }
        {
          this.state.showFindPw ? (<FindPw handleClose={() => this.showFindPw(false)} handleRegister={this.handleRegister} />) : null
        }
        {
          this.state.showTerm ? (<Term handleClose={() => this.showTerm(false)}/>) : null
        }
        {
          this.state.showPrivacy ? (<Privacy handleClose={() => this.showPrivacy(false)}/>) : null
        }
        {
          this.state.loading ? (<Loading />) : null
        }
        <TopBar handleNavi={this.handleNavi} handleMenu={this.handleMenu} selected={2} withLine={true}/>

        <div className='pgcont2 rowlt'>
          <NaviBar width={this.state.naviWidth} height={this.state.height} handleSnsOrder={this.handleSnsOrder} handleNavi={this.handleNavi} handleMenu={this.handleMenu}/>

          <div className='homecont colel' style={{width: this.state.contWidth, height: this.state.height}}>
            <div className='homecont2 coltl' style={{width: this.state.contWidth - 40 - 50, height: this.state.height - 100}}>

              <label className='font20 wt600 color31 mgtop20'>자주 묻는 질문</label>

              <div className='rowcc mgtop20'>
                <input type='text' className='font14b_2 faqinp' value={this.state.searchWord} onChange={(e) => this.setSearchWord(e.target.value)}
                  placeholder='검색어를 입력해 주세요.'
                  onKeyPress={this.handleKeyPress}
                />
                <button className='faqserbtn btncur font16 wt600 color20 mglt5' onClick={this.handleSearch}>검색</button>
              </div>

              <div className='rowlc margintop15'>
                {this.getFaqBtn(this.state.faqMode === -1, "전체", true, -1)}
                {this.getFaqBtn(this.state.faqMode === 1, "취소/환불", true, 1)}
                {this.getFaqBtn(this.state.faqMode === 3, "리워드 광고", true, 3)}
                {this.getFaqBtn(this.state.faqMode === 2, "SNS 주문", false, 2)}
              </div>

              <div className='faqitemarea colcc margintop28'>
                {faqList}
              </div>

              {
                this.state.pageCount > 1 ? (
                  <Paging
                    needAfter={this.state.startPage > 1}
                    needNext={this.state.endPage < this.state.lastPage}
                    startPage={this.state.startPage}
                    endPage={this.state.endPage}
                    currentPage={this.state.currentPage}
                    pgStyle="mgtop50"
                    handlePage={this.handlePage}
                  />
                ) : null
              }          

              <div className='wd100p colcl mgtop30'>
                <label className='font18 wt500 color20'>원하시는 답변을 얻지 못했나요?</label>
                <label className='font16 wt400 color31 margintop5'>화면 오른쪽 하단에 위치한 카카오톡 문의하기를 통해 문의해 주시면 친절하게 답변 드립니다.</label>
              </div>

              <div className='wd100p colcl mgtop30 mgbt50'>
                <label className='font18 wt500 color20'>고객센터 운영 시간</label>
                <label className='font16 wt400 color31 mgtop5'>공휴일 제외 평일 09:00~18:00 (점심시간 12:00~13:00)</label>
              </div>
            </div>
            <FooterBar handleMenu={this.handleMenu}/>
          </div>
        </div>

        <Talk handleTalk={this.handleTalk} />
      </div>

      
    );
  }

  renderMobile = (faqList) =>
  {
    return (
      <div className='faqbg flexcoltc'>
        {
          this.state.showMobileMenu ? (<MobileMenu handleNavi={this.handleNavi} handleMenu={this.handleMenu} userPoint={LoginStoreInstance.getUserPoint}/>) : null
        }

        {
          this.state.showTerm ? (<Term handleClose={() => this.showTerm(false)}/>) : null
        }
        
        {
          this.state.showPrivacy ? (<Privacy handleClose={() => this.showPrivacy(false)}/>) : null
        }
        
        {
          this.state.loading ? (<Loading />) : null
        }

        <Talk handleTalk={this.handleTalk} />

        <TopBar handleNavi={this.handleNavi} handleMenu={this.handleMenu} selected={2} withLine={true}/>
        <div className='faqcont_mb coltl'>

          <label className='font18b mgtop20'>자주 묻는 질문</label>
          <label className='font14b_2 mgtop5'>SNS플라이를 이용중인 고객님들이 궁금해하시는 질문과 답변을 확인하실 수 있습니다.</label>

          <div className='wd100p rowlc mgtop25'>
            {this.getFaqBtn(this.state.faqMode === -1, "전체", true, -1)}
            {this.getFaqBtn(this.state.faqMode === 1, "취소/환불", true, 1)}
            {this.getFaqBtn(this.state.faqMode === 2, "서비스", false, 2)}
          </div>

          <div className='colcr faqserinparea_mb margintop40'>
            <div className='rowsc faqserinparea_mb'>
              <input type='text' className='font14b_2 faqinp' value={this.state.searchWord} onChange={(e) => this.setSearchWord(e.target.value)}
                placeholder='검색어를 입력해 주세요.'
                onKeyPress={this.handleKeyPress}
              />
              <img src={GetImgUrl("com/mb/search.png")} alt="검색" className='mgrt10 btncur img2222' onClick={this.handleSearch}/>
            </div>
            
            <div className='faqserline'></div>
          </div>

          <div className='faqitemarea colcc mgtop28'>
            {faqList}
          </div>

          {
            this.state.pageCount > 1 ? (
              <Paging
                needAfter={this.state.startPage > 1}
                needNext={this.state.endPage < this.state.lastPage}
                startPage={this.state.startPage}
                endPage={this.state.endPage}
                currentPage={this.state.currentPage}
                pgStyle="mgtop30"
                handlePage={this.handlePage}
              />
            ) : null
          }          

          <div className='wd100p colcl mgtop55'>
            <label className='font1620'>원하시는 답변을 얻지 못했나요?</label>
            <label className='font14b_2 margintop5 lht20'>화면 오른쪽 하단에 위치한 카카오톡 문의하기를 통해 문의해 주시면 친절하게 답변 드립니다.</label>
          </div>

          <div className='wd100p colcl mgtop55 mgbt70'>
            <label className='font1620'>고객센터 운영 시간</label>
            <label className='font14b_2 mgtop5 lht20'>공휴일 제외 평일 09:00~18:00<br/>(점심시간 12:00~13:00)</label>
          </div>
        </div>
        <FooterBar handleMenu={this.handleMenu}/>
      </div>
    );
  }

  render()
  {
    let faqList = null;

    /*if (mobileOn)
    {
      if (IsValidS(this.state.faqList))
      {
        let index = 0;
        faqList = this.state.faqList.map((item) => {
          ++index;
          return <FaqItemMb key={item.idx} item={item} withTopLine={index === 1} withBottomLine={true}/>
        });
      }

      return this.renderMobile(faqList);
    }
    else*/
    {
      if (IsValidS(this.state.faqList))
      {
        let index = 0;
        faqList = this.state.faqList.map((item) => {
          ++index;
          return <FaqItem key={item.idx} item={item} withTopLine={index === 1} withBottomLine={true}/>
        });
      }

      return this.renderPc(faqList);
    }
  }
}

export default withNavigation(Faq);

