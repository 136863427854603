import './Bingo.css';
import '../../App.css';
import { GetImgUrl } from '../../URL/AppUrl';

const BingoDescSb = (props) =>
{
  return (
    <div className='wd100p coltl mgtop10'>
      <label className='font15 wt400 color31 lht23'>빙고팡팡 내에서 빙고가 플레이되려면 심볼이 필수이며, 심볼이 있어야 빙고가 생성됩니다.<br/><br/><span className='color20 wt600'>광고 이미지 심볼 첨부 가이드</span></label>
      
      <ul className='wd90p font15 wt400 color31 lht23'>
        <li>광고 이미지 사이즈 : 900*600px 크기의 JPEG 또는 PNG 이미지 1개</li>
        <li>광고 영역과 게임 영역이 분리되어 있으며, 광고 이미지를 심볼로 제작하여 빙고를 생성하므로, 광고 영역에 들어갈 이미지가 필요합니다</li>
        <li>광고 이미지 심볼이 3등분되기 때문에 이미지 사이에 5(px)의 간격이 생깁니다.<br/>텍스트가 잘릴 수 있는 부분이 있는지 확인해 주세요.</li>
        <li>광고 이미지 요소는 자유롭게 구성할 수 있지만, 플랫폼 운영 정책에 위배되는 이미지나 텍스트가 포함된 경우 반영되지 않습니다.</li>
      </ul>

      <div className='wd100p rowcc'>
        <img src={GetImgUrl("bingo/stp231.png")} alt="설명" className='bgst2img3'/>
      </div>

      <label className='font15 wt400 color31 lht23 mgtop30'><span className='color20 wt600'>광고 심볼 첨부 가이드</span></label>
      
      <ul className='wd90p font15 wt400 color31 lht23'>
        <li>머니팡애즈에 심볼을 제작해드리기 위해 심볼 가이드를 확인하시고, 양식을 작성한 후 필요한 파일을 첨부해 주시면 제작을 도와드리겠습니다.</li>
        <li>3개의 주요심볼, 5개의 일반심볼 그리고 1개의 조커 심볼로 총 9개의 심볼로 구성되어 있으며<br/>주요심볼, 일반심볼은 이미지, 이미지+텍스트, 텍스트 중 자유롭게 디자인할 수 있습니다.</li>
        <li>조커심볼의 경우 로고 파일로만 제작됩니다.</li>
      </ul>

      <div className='wd100p rowcc'>
        <img src={GetImgUrl("bingo/stp232n.png")} alt="설명" className='bgst2img4'/>
      </div>
    </div>
  );
}

export default BingoDescSb;