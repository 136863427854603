import React from 'react';
import './Purchase.css';
import '../../App.css';
import { IsValidS } from '../../Util/Util';
import PurchaseListItem from './PurchaseListItem';
import Paging from '../Paging/Paging';
import { mobileOn } from '../../URL/AppUrl';
import PurchaseListItemMb from './PurchaseListItemMb';
import { PointOrderInstance } from '../../Stores/PointOrder';
import { LoginStoreInstance } from '../../Stores/LoginStore';

class PurchaseList extends React.Component
{
  state = {
    loading: false,

    startPage: 1, //현재의 첫페이지
    endPage: 5, //현재의 끝페이지
    lastPage: 5, //마지막 페이지번호
    pageCount: 6, //전체 페이지의 개수
    currentPage: 1, //현재 선택된 페이지

    orderList: null, //주문목록
    orderCount: 0, //전체 주문개수
  };

  intervalId = -1;
  needOrder = true;

  setLoading = (v) =>
  {
    this.setState((prevState) => ({...prevState, loading : v}));
  }

  setCurrentPage = (v) =>
  {
    this.setState((prevState) => ({...prevState, currentPage: v}));
  }

  setOrderInfo = (list, count, pageUpdated) =>
  {
    this.setState((prevState) => ({...prevState, orderList : list, orderCount: count, pageUpdated : pageUpdated}));
  }

  setOrderList = (list) =>
  {
    this.setState((prevState) => ({...prevState, orderList : list}));
  }

  //전체 페이지 개수를 카운트
  calcPageCount = (orderCount) =>
  {
    let pageCount = Math.floor(orderCount / 10);

    if ((orderCount % 10) !== 0)
      ++pageCount;

    let endPage = 5;

    if (endPage > pageCount)
      endPage = pageCount;

    this.setState((prevState) => ({...prevState, startPage : 1, endPage : endPage, currentPage : 1, pageCount : pageCount, lastPage: pageCount}));
  }

  handleOrderLoad = (pageNum) =>
  {
    if (this.state.loading)
      return false;

    this.loadOrder(pageNum);

    return true;
  }

  loadOrder = (pageNum) =>
  {
    if (!this.state.loading)
      this.setLoading(true);

    PointOrderInstance.loadOrders((pageNum - 1) * 10, this.onOrderLoaded);
  }

  onOrderLoaded = (resultCode, first, totalCount, list) =>
  {
    this.setLoading(false);

    if (resultCode !== 0)
    {
      alert(`포인트 충전 내역 조회 중 오류가 발생했습니다.\n잠시 후 이용해 주세요.\n오류코드 ${resultCode}`);
      return;
    }

    if (first)
      this.setOrderInfo(list, totalCount, true);
    else
      this.setOrderList(list);

    if (first !== 0)
    {
      this.calcPageCount(totalCount);
    }
  }

  //페이지 선택처리
  handlePage = (pageNumber) =>
  {
    if (pageNumber === -1) //이전 페이지
    {
      let startPage = this.state.startPage - this.maxPageNum;
      let endPage = this.state.endPage - this.maxPageNum;

      if (startPage < 1)
        startPage = 1;
      
      if (endPage > this.state.pageCount)
        endPage = this.state.pageCount;

      if (this.handleOrderLoad(startPage))
        this.setState((prevState) => ({...prevState, currentPage : startPage, startPage : startPage, endPage : endPage}));
        
    }
    else if (pageNumber === -2) //다음 페이지
    {
      let startPage = this.state.endPage + 1;
      let endPage = startPage + this.maxPageNum;

      if (startPage > this.state.pageCount)
        startPage = this.state.pageCount;
      
      if (endPage > this.state.pageCount)
        endPage = this.state.pageCount;

      if (this.handleOrderLoad(startPage))
        this.setState((prevState) => ({...prevState, currentPage : startPage, startPage : startPage, endPage : endPage}));
    }
    else //특정 페이지 선택
    {
      if (this.handleOrderLoad(pageNumber))
        this.setCurrentPage(pageNumber);
    }
  }

  update = () =>
  {
    //주문 읽기가 필요하고, 프로필이 준비되었다면
    if (this.needOrder && LoginStoreInstance.isProfileReady)
    {
      this.needOrder = false;
      this.loadOrder(1);
    }
  }

  componentDidMount() {

    if (LoginStoreInstance.isProfileReady)
    {
      this.needOrder = false;
      this.loadOrder(1);
    }

    this.intervalId = setInterval(this.update, 1000);
  }

  componentWillUnmount = () =>
  {
    if (this.intervalId !== -1)
    {
      clearInterval(this.intervalId);
      this.intervalId = -1;
    }
  }

  handleOrder = (item) =>
  {
    if (this.props.handleOrder)
      this.props.handleOrder(item);
  }

  renderPc = (orderList) =>
  {
    return (
      <div className='purchaselistbg flexcoltl marginbottom50 mglt40'>
        <label className='font20 wt500 color31'>충전내역</label>

        <div className='purlistcont flexcoltc margintop25'>
          <div className='purlisthead flexrowcc'>
              <div className='purtt1 flexrowcc'>
                <label className='font15 wt500 color31'>충전일시</label>
              </div>

              <div className='purtt2 flexrowcc'>
                <label className='font15 wt500 color31'>충전금액</label>
              </div>

              <div className='purtt3 flexrowcc'>
                <label className='font15 wt500 color31'>충전방식</label>
              </div>

              <div className='purtt4 flexrowcc'>
                <label className='font15 wt500 color31'>충전계좌</label>
              </div>

              <div className='purtt5 flexrowcc'>
                
              </div>
          </div>  

          <div className='purlistitemcont flexcoltc'>

            {
              orderList ? (orderList) : (
                <label className='font16b margintop30'>충전 내역이 없습니다.</label>
              )
            }
          </div>

          {
            this.state.pageCount > 1 ? (
              <Paging
                needAfter={this.state.startPage > 1}
                needNext={this.state.endPage < this.state.lastPage}
                startPage={this.state.startPage}
                endPage={this.state.endPage}
                currentPage={this.state.currentPage}
                pgStyle="margintop30"
                handlePage={this.handlePage}
              />
            ) : null
          } 
        </div>
      </div>
    );
  }

  renderMobile = (orderList) =>
  {
    return (
      <div className='purchaselistbg_mb flexcoltl margintop30 marginbottom60'>
        <label className='font18b'>충전내역</label>

        <div className='purlistitemcont_mb flexcoltc margintop15'>
          {
            orderList ? (orderList) : (
              <label className='font16b margintop30'>충전 내역이 없습니다.</label>
            )
          }
        </div>

        {
          this.state.pageCount > 1 ? (
            <Paging
              needAfter={this.state.startPage > 1}
              needNext={this.state.endPage < this.state.lastPage}
              startPage={this.state.startPage}
              endPage={this.state.endPage}
              currentPage={this.state.currentPage}
              pgStyle="margintop50"
              handlePage={this.handlePage}
            />
          ) : null
        } 
      </div>
    );
  }

  render()
  {
    let orderList = null;

    /*if (mobileOn)
    {
      if (IsValidS(this.state.orderList))
      {
        orderList = this.state.orderList.map((item) => {
          return <PurchaseListItemMb key={item.idx} item={item} handleOrder={this.handleOrder}/>
        });
      }

      return this.renderMobile(orderList);
    }
    else*/
    {
      if (IsValidS(this.state.orderList))
      {
        orderList = this.state.orderList.map((item) => {
          return <PurchaseListItem key={item.idx} item={item} handleOrder={this.handleOrder}/>
        });
      }

      return this.renderPc(orderList);
    }
  }
}

export default PurchaseList;